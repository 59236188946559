import { Indicator, NavigationItem, NavigationLink } from '@porsche-kado/ui';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { useAbilityContext } from '../../context';
import { useSupportRequestsActivityQuery } from '../../graphql';
import { useMigrationsStatus } from '../../migrations';

export const SupportNavigationItem = () => {
  const { t } = useTranslation();
  const ability = useAbilityContext();
  const { hasMigratedSupportRequestSeenStatus } = useMigrationsStatus();
  const { data } = useSupportRequestsActivityQuery(undefined, {
    enabled: hasMigratedSupportRequestSeenStatus,
  });
  const unreadCount =
    data?.supportRequests.filter((sr) => sr.hasNewActivity).length ?? 0;

  if (ability.can('read', 'SupportRequest')) {
    return (
      <NavigationItem>
        <Indicator
          label={unreadCount}
          disabled={unreadCount === 0}
          active={unreadCount > 0}
          aria-label={t('unread', { count: unreadCount })}
        >
          <NavigationLink icon="chat" hideLabel>
            <Link to="/support">{t('supportRequest.headline')}</Link>
          </NavigationLink>
        </Indicator>
      </NavigationItem>
    );
  }

  return null;
};
