import { PHeading, PText } from '@porsche-design-system/components-react';
import { COMMON_NAMESPACE } from '@porsche-kado/i18n';
import { styled } from '@porsche-kado/ui';
import NotAuthorizedIcon from '@porsche-kado/ui/icons/not-authorized.svg?react';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '$xLarge',
});

const Icon = styled(NotAuthorizedIcon, {
  fontSize: '180px',
  margin: '$xLarge 0',
});

export const NotAuthorized = () => {
  const { t } = useTranslation(COMMON_NAMESPACE);
  return (
    <Wrapper>
      <PHeading role="heading" tag="h3" size="x-large">
        {t('notAuthorized.title')}
      </PHeading>
      <Icon />
      <PText>{t('notAuthorized.text')}</PText>
    </Wrapper>
  );
};
