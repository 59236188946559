import { PHeading } from '@porsche-design-system/components-react';
import { DataTable } from '@porsche-kado/ui';
import { useParams } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../config/i18n';
import { usePersonInfoForGreenlandQuery } from '../../../graphql';

export const Greenland = () => {
  const { t } = useTranslation(NAMESPACES);

  const { personId } = useParams({ from: '/admin/person-info/$personId/$app' });

  const { data, isLoading } = usePersonInfoForGreenlandQuery(
    { id: +personId },
    {
      select: (data) => ({
        person: data.person,
        additionalOrganizations:
          data.userManagementItems[0]?.additionalOrganizations,
      }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const columns = useMemo(() => {
    const columnHelper =
      createColumnHelper<
        NonNullable<typeof data>['additionalOrganizations'][0]
      >();

    return [
      columnHelper.accessor('id', {
        cell: (data) => data.getValue(),
        header: () => t('common:id'),
      }),
      columnHelper.accessor('name', {
        cell: (data) => data.getValue(),
        header: () => t('common:name'),
      }),
    ];
  }, [t]);

  return (
    <>
      <PHeading size="small" tag="h3">
        {t('admin.personInfo.additionalOrganizations')}
      </PHeading>

      <DataTable
        isLoading={isLoading}
        caption={t('admin.personInfo.additionalOrganizations')}
        data={data?.additionalOrganizations ?? []}
        columns={columns}
        idAccessor="name"
        pagination={{
          position: 'bottom',
        }}
        i18n={{
          filterLabel: (columnName) =>
            t('common:iconLabel.filter', { columnName }),
          optionAll: t('common:all'),
          buttonReset: t('common:action.reset'),
          buttonFilter: t('common:action.filter'),
          actionSearch: t('common:action.search'),
          noData: t('common:noData'),
        }}
      />
    </>
  );
};
