import {
  PButton,
  PCheckboxWrapper,
  PFieldset,
  PInlineNotification,
} from '@porsche-design-system/components-react';
import { ActionGroup, Label, Message, Spacer, styled } from '@porsche-kado/ui';
import { useQueryClient } from '@tanstack/react-query';
import { ClientError } from 'graphql-request';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  getAppName,
  OrganizationSelect,
  PersonSelect,
} from '../../../../components';
import { App } from '../../../../config/app';
import { NAMESPACES } from '../../../../config/i18n';
import {
  useCreateUserManagementItemMutation,
  UserManagementItemsQuery,
  UserManagementItemsQueryVariables,
  useUpdateUserManagementItemMutation,
  useUserManagementItemsQuery,
} from '../../../../graphql';
import { useNotification } from '../../../../hooks';

const Content = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$medium $large',

  '@s': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

const FullContentChild = styled('div', {
  gridColumn: '1 / -1',
});

const ApplicationListWrapper = styled('div', {
  display: 'grid',
  gap: '$medium $large',
  gridTemplateColumns: 'repeat(2, 1fr)',

  '@s': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
});

type FormValues = {
  personId: number;
  additionalOrganizationIds: number[];
  application: Record<App, boolean>;
};

export const CreateAdditionalOrganization = () => {
  const { t } = useTranslation(NAMESPACES);
  const { addToast } = useNotification();
  const queryClient = useQueryClient();

  const createUserManagementItemMutation = useCreateUserManagementItemMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(useUserManagementItemsQuery.getKey());
    },
  });

  const updateUserManagementItemMutation = useUpdateUserManagementItemMutation({
    onSuccess: (data) => {
      queryClient.setQueryData<UserManagementItemsQuery>(
        useUserManagementItemsQuery.getKey(),
        (cache) =>
          cache?.userManagementItems
            ? {
                userManagementItems: cache.userManagementItems.map(
                  (userManagementItems) =>
                    userManagementItems.id === data.updateUserManagementItem.id
                      ? { ...data.updateUserManagementItem }
                      : userManagementItems,
                ),
              }
            : undefined,
      );
    },
  });

  const {
    handleSubmit,
    control,
    reset: resetForm,
    formState,
    register,
    getFieldState,
  } = useForm<FormValues>({
    defaultValues: {
      personId: undefined,
      additionalOrganizationIds: [],
      application: Object.values(App).reduce((acc, value) => {
        acc[value] = false;
        return acc;
      }, {} as { [key: string]: boolean }),
    },
    resetOptions: {
      keepDefaultValues: true,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async ({
    personId,
    additionalOrganizationIds,
    application,
  }) => {
    const applications = Object.entries(application)
      .filter(([_, value]) => value)
      .map(([application]) => application);

    const variables: UserManagementItemsQueryVariables = {
      input: {
        filter: {
          person: { _eq: personId },
          application: { _in: applications },
        },
      },
    };
    const { userManagementItems } = await queryClient.fetchQuery(
      useUserManagementItemsQuery.getKey(variables),
      useUserManagementItemsQuery.fetcher(variables),
    );

    Promise.all(
      applications.map((application) => {
        const item = userManagementItems.find(
          (item) => item.application === application,
        );

        if (item) {
          return updateUserManagementItemMutation.mutateAsync({
            input: {
              id: item.id,
              additionalOrganizationIds: {
                _push: additionalOrganizationIds,
              },
            },
          });
        }

        return createUserManagementItemMutation.mutateAsync({
          input: {
            additionalOrganizationIds,
            application,
            personId,
          },
        });
      }),
    );
  };

  const isError =
    createUserManagementItemMutation.isError ||
    updateUserManagementItemMutation.isError;

  const error = createUserManagementItemMutation.isError
    ? createUserManagementItemMutation.error
    : updateUserManagementItemMutation.isError
    ? updateUserManagementItemMutation.error
    : undefined;

  useEffect(() => {
    const isError =
      createUserManagementItemMutation.isError ||
      updateUserManagementItemMutation.isError;

    const isSuccess =
      createUserManagementItemMutation.isSuccess ||
      updateUserManagementItemMutation.isSuccess;

    if (!isError && isSuccess) {
      addToast({
        text: t(
          'admin.userManagement.additionalOrganization.createdSuccessfully',
        ),
        state: 'success',
      });

      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createUserManagementItemMutation.isSuccess,
    updateUserManagementItemMutation.isSuccess,
    createUserManagementItemMutation.isError,
    updateUserManagementItemMutation.isError,
    t,
    resetForm,
  ]);

  return (
    <>
      {isError ? (
        <>
          <PInlineNotification
            heading={t('common:error')}
            description={`${
              error instanceof ClientError
                ? error.response.errors?.[0].message ?? error.message
                : `${error}`
            }`}
            state="error"
            onDismiss={() => {
              if (createUserManagementItemMutation.isError) {
                createUserManagementItemMutation.reset();
              }

              if (updateUserManagementItemMutation.isError) {
                updateUserManagementItemMutation.reset();
              }
            }}
          />

          <Spacer mb="$medium" />
        </>
      ) : null}

      <form onSubmit={handleSubmit(onSubmit)}>
        <PFieldset
          label={t('admin.userManagement.additionalOrganization.createHeading')}
          labelSize="small"
        >
          <Content>
            <Controller
              control={control}
              name="personId"
              rules={{ required: true }}
              render={({
                field: { ref, onChange, value, ...field },
                fieldState,
              }) => (
                <PersonSelect
                  {...field}
                  isRequired
                  label={t('common:person')}
                  aria-label={t('common:person')}
                  message={
                    fieldState.isTouched ? fieldState.error?.message : undefined
                  }
                  onChange={(person) => {
                    onChange(person?.id);
                  }}
                  value={value ? { id: value } : []}
                  filter={{ deletedAt: { _exists: false } }}
                />
              )}
            />

            <Controller
              control={control}
              name="additionalOrganizationIds"
              rules={{ required: true }}
              render={({
                field: { ref, onChange, value, ...field },
                fieldState,
              }) => (
                <OrganizationSelect
                  {...field}
                  isMulti
                  isRequired
                  label={t('common:additionalOrganizations')}
                  aria-label={t('common:additionalOrganizations')}
                  message={
                    fieldState.isTouched ? fieldState.error?.message : undefined
                  }
                  onChange={(organizations) => {
                    onChange(
                      organizations.map((organization) => organization.id),
                    );
                  }}
                  value={value?.map((id) => ({ id })) ?? []}
                />
              )}
            />

            <FullContentChild>
              <Label label={t('common:applications')} isRequired />

              <Spacer mb="$xSmall" />

              <ApplicationListWrapper>
                {Object.values(App).map((application) => (
                  <PCheckboxWrapper
                    key={application}
                    label={getAppName(application, t)}
                    hideLabel={false}
                  >
                    <input
                      {...register(`application.${application}`, {
                        validate: (_, { application }) =>
                          Object.values(application).some((value) => value),
                      })}
                      type="checkbox"
                    />
                  </PCheckboxWrapper>
                ))}
              </ApplicationListWrapper>

              <Message
                value={
                  getFieldState('application').isTouched
                    ? getFieldState('application').error?.message
                    : undefined
                }
                state={
                  getFieldState('application').isTouched &&
                  getFieldState('application').error
                    ? 'error'
                    : undefined
                }
              />
            </FullContentChild>
          </Content>
        </PFieldset>

        <ActionGroup>
          <div />
          <PButton
            type="submit"
            variant="primary"
            loading={formState.isSubmitting}
            role="button"
            disabled={!formState.isValid}
          >
            {t('common:action.save')}
          </PButton>
        </ActionGroup>
      </form>
    </>
  );
};
