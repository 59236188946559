import {
  PTextList,
  PTextListItem,
} from '@porsche-design-system/components-react';
import { Spinner } from '@porsche-kado/ui';
import { useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { DateOutput } from '../../../components';
import { NAMESPACES } from '../../../config/i18n';
import { usePersonInfoQuery } from '../../../graphql';
import { apps } from './helper';

export const Account = () => {
  const { t } = useTranslation(NAMESPACES);
  const { personId } = useParams({
    from: '/admin/person-info/$personId/account',
  });

  const { data: person, isLoading } = usePersonInfoQuery(
    { id: +personId },
    {
      select: (data) => data.person,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const availableApps = apps(person?.ability.dashboard ?? []);

  return (
    <>
      {isLoading && personId && !person && <Spinner />}

      {person && !isLoading && (
        <PTextList>
          <PTextListItem>
            {t('common:person')}:
            <PTextList>
              <PTextListItem>
                {t('common:id')}: {person.id}
              </PTextListItem>
              <PTextListItem>
                {t('common:name')}: {person.name}
              </PTextListItem>
              <PTextListItem>
                {t('admin.personInfo.ppnuid')}: {person.ppnuid}
              </PTextListItem>
              <PTextListItem>
                {t('common:mail')}: {person.mail}
              </PTextListItem>
              <PTextListItem>
                {t('common:deletedAt')}:{' '}
                {person.deletedAt ? (
                  <DateOutput date={person.deletedAt} />
                ) : (
                  '-'
                )}
              </PTextListItem>
            </PTextList>
          </PTextListItem>

          <PTextListItem>
            {t('common:organization')}:
            <PTextList>
              <PTextListItem>
                {t('common:id')}: {person.organization.id}
              </PTextListItem>
              <PTextListItem>
                {t('common:name')}: {person.organization.name}
              </PTextListItem>
            </PTextList>
          </PTextListItem>

          <PTextListItem>
            {t('common:account')}:
            <PTextList>
              <PTextListItem>
                {t('common:status')}: {person.pagCurrentAccountStatus}
              </PTextListItem>
            </PTextList>
          </PTextListItem>

          {person.roles.length > 0 && (
            <PTextListItem>
              {t('admin.personInfo.roles')}:
              <PTextList>
                {person.roles.map((role) => (
                  <PTextListItem key={role}>{role}</PTextListItem>
                ))}
              </PTextList>
            </PTextListItem>
          )}

          {availableApps.length > 0 && (
            <PTextListItem>
              {t('admin.personInfo.apps')}:
              <PTextList>
                {availableApps.map((app) => (
                  <PTextListItem key={app}>{app}</PTextListItem>
                ))}
              </PTextList>
            </PTextListItem>
          )}
        </PTextList>
      )}
    </>
  );
};
