import { ConfirmationModal } from '@porsche-kado/ui';
import { useQueryClient } from '@tanstack/react-query';
import { ClientError } from 'graphql-request';
import { useTranslation } from 'react-i18next';
import { getAppName } from '../../../../components';
import { NAMESPACES } from '../../../../config/i18n';
import {
  useDeleteUserManagementItemMutation,
  UserManagementItemsQuery,
  useUserManagementItemsQuery,
} from '../../../../graphql';
import { useNotification } from '../../../../hooks';

export const DeleteAdditionalOrganization = ({
  isOpen,
  userManagementItem,
  onClose: onCloseCallback,
}: {
  isOpen: boolean;
  userManagementItem: {
    id: string;
    application: string;
    person: {
      id: number;
      name: string;
    };
    additionalOrganizations: {
      id: number;
      name: string;
    }[];
  };
  onClose: () => void;
}) => {
  const { t } = useTranslation(NAMESPACES);
  const queryClient = useQueryClient();
  const { addToast } = useNotification();

  const deleteUserManagementItemMutation = useDeleteUserManagementItemMutation({
    onSuccess: (data) => {
      queryClient.setQueryData<UserManagementItemsQuery>(
        useUserManagementItemsQuery.getKey(),
        (cache) =>
          cache?.userManagementItems
            ? {
                userManagementItems: cache.userManagementItems.filter(
                  ({ id }) => id !== data.deleteUserManagementItem.id,
                ),
              }
            : undefined,
      );
    },
  });

  const onClose = () => {
    onCloseCallback();

    if (deleteUserManagementItemMutation.isError) {
      deleteUserManagementItemMutation.reset();
    }
  };

  return (
    <ConfirmationModal
      open={isOpen}
      description={t(
        'admin.userManagement.additionalOrganization.deleteDescription',
        {
          application: getAppName(userManagementItem.application, t),
          person: userManagementItem.person.name,
        },
      )}
      heading={t('admin.userManagement.additionalOrganization.deleteHeading')}
      i18n={{
        cancel: t('common:action.cancel'),
        confirm: t('common:action.delete'),
        error: t('common:error'),
      }}
      error={
        deleteUserManagementItemMutation.isError
          ? `${
              deleteUserManagementItemMutation.error instanceof ClientError
                ? deleteUserManagementItemMutation.error.response.errors?.[0]
                    .message ?? deleteUserManagementItemMutation.error.message
                : `${deleteUserManagementItemMutation.error}`
            }`
          : undefined
      }
      isLoading={deleteUserManagementItemMutation.isLoading}
      onDismiss={onClose}
      onConfirm={async () => {
        await deleteUserManagementItemMutation.mutateAsync(
          { id: userManagementItem.id },
          {
            onSuccess: () => {
              addToast({
                text: t(
                  'admin.userManagement.additionalOrganization.deletedSuccessfully',
                ),
                state: 'success',
              });

              onClose();
            },
          },
        );
      }}
    />
  );
};
