import {
  PTextFieldWrapper,
  PTextFieldWrapperProps,
} from '@porsche-design-system/components-react';
import { dropShadowMediumStyle } from '@porsche-design-system/components-react/styles';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DateRange,
  DayPickerProps,
  DayPicker as DefaultDayPicker,
  ModifiersStyles,
} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { styled, theme } from '../stitches.config';

dayjs.extend(customParseFormat);

const Overlay = styled('div', {
  position: 'absolute',
  backgroundColor: '$white',
  padding: '$small',
  zIndex: '$sidebar',
  borderRadius: '$medium',
  ...dropShadowMediumStyle,
});

const StyledDayPicker = styled(DefaultDayPicker, {
  '.rdp-day_button:hover': {
    backgroundColor: '$contrastLow',
  },
  '.rdp-day.rdp-today .rdp-day_button': {
    color: '$text',
    fontWeight: 'bold',
  },
  '.rdp-day.rdp-selected .rdp-day_button': {
    color: '$white',
    backgroundColor: '$contrastMedium',
  },
  '.rdp-dropdowns': {
    paddingLeft: '$medium',
  },
});

export type DayPickerInputProps = {
  name: string;
  format: string;
  required?: boolean;
  disabled?: boolean;
  min?: string;
  max?: string;
  selected?: Date | string;
  onSelect: (selected: Date | undefined) => void;
  inputRef?: React.Ref<HTMLInputElement>;
  onBlur?: (evt: React.FocusEvent) => void;
} & Pick<PTextFieldWrapperProps, 'label' | 'state' | 'message'>;

const styles = {
  day: {
    fontSize: '15px',
    fontWeight: 'normal',
  },
  month: {
    fontSize: '13px',
  },
  dropdowns: {
    paddingLeft: '$medium',
  },
  root: {
    '--rdp-today-color': theme.colors.black.toString(),
    '--rdp-day-width': '36px',
    '--rdp-day-height': '36px',
    '--rdp-accent-color': theme.colors.contrastMedium.toString(),
    '--rdp-background-color': theme.colors.contrastLow.toString(),
    '--rdp-outline': 'none',
    '--rdp-outline-selected': theme.colors.contrastMedium.toString(),
    '--rdp-range_middle-color': theme.colors.white.toString(),
    '--rdp-range_middle-background-color':
      theme.colors.contrastMedium.toString(),
    '--rdp-day_button-border-radius': theme.radii.medium.toString(),
  } as React.CSSProperties,
};

const modifiersStyles: ModifiersStyles = {};

const DayPickerInput = ({
  inputRef,
  label,
  name,
  format,
  required,
  min,
  max,
  disabled,
  selected,
  onSelect,
  state,
  message,
  onBlur,
}: DayPickerInputProps) => {
  const selectedDate = useMemo(() => {
    // useMemo is necessary to avoid creating a new instance of selectedDate on every render
    // which makes clearing the input field impossible PAIM-3259
    return typeof selected === 'string'
      ? dayjs(selected, format).toDate()
      : selected;
  }, [format, selected]);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>(
    selectedDate ? dayjs(selectedDate).format(format) : '',
  );
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = useCallback((event: MouseEvent) => {
    // Close overlay if click was outside the day picker
    if (ref.current && !ref.current.contains(event.target as Node)) {
      handleToggle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = (isVisible: boolean) => {
    setIsOpen(isVisible);

    if (isVisible) {
      window.addEventListener('click', handleClick);
      return;
    }

    window.removeEventListener('click', handleClick);
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.currentTarget;

    setInputValue(value);
    const date = dayjs(value, format, true);

    onSelect(date.isValid() ? date.toDate() : undefined);
  };

  useEffect(() => {
    setInputValue(selectedDate ? dayjs(selectedDate).format(format) : '');
  }, [selectedDate, format]);

  const id = useHtmlId();
  return (
    <div ref={ref}>
      <PTextFieldWrapper label={label} state={state} message={message}>
        <input
          autoComplete="off"
          ref={inputRef}
          required={required}
          disabled={disabled}
          type="text"
          name={name}
          value={inputValue}
          placeholder={format}
          min={min}
          max={max}
          onChange={handleInputChange}
          onClick={() => handleToggle(true)}
          onBlur={onBlur}
          aria-controls={isOpen ? `overlay-${id}` : undefined}
        />
      </PTextFieldWrapper>
      {isOpen && (
        <Overlay id={`overlay-${id}`}>
          <DayPicker
            mode="single"
            styles={styles}
            modifiersStyles={modifiersStyles}
            selected={selectedDate}
            defaultMonth={selectedDate}
            onSelect={(date: Date | undefined) => {
              onSelect(date);
              setInputValue(dayjs(date).format(format));
              handleToggle(false);
            }}
          />
        </Overlay>
      )}
    </div>
  );
};

const DayPicker = (props: DayPickerProps) => (
  <StyledDayPicker
    styles={styles}
    modifiersStyles={modifiersStyles}
    weekStartsOn={1}
    captionLayout="dropdown"
    startMonth={new Date(2000, 0)}
    endMonth={new Date(2099, 0)}
    {...props}
  />
);

export { DayPicker, DayPickerInput };
export type { DateRange };

let idCounter = 0;
const useHtmlId = (id?: string) => {
  const [randomId] = useState(() => `${++idCounter}`);

  return id ?? randomId;
};
