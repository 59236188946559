import {
  PHeading,
  PText,
  PTextList,
  PTextListItem,
} from '@porsche-design-system/components-react';
import { DataTable, Spacer } from '@porsche-kado/ui';
import { useParams } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../../config/i18n';
import { usePersonInfoForFieldForceManagementQuery } from '../../../../graphql';
import { MeetingAbilityInfo } from './MeetingAbilityInfo';

export const FieldForceManagement = () => {
  const { t } = useTranslation(NAMESPACES);

  const { personId } = useParams({ from: '/admin/person-info/$personId/$app' });

  const { data, isLoading } = usePersonInfoForFieldForceManagementQuery(
    { id: +personId },
    {
      select: (data) => ({
        person: data.person,
        additionalOrganizations:
          data.userManagementItems[0]?.additionalOrganizations,
      }),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const featureFlags = data?.person?.ability.fieldForceManagement?.filter(
    (ability) =>
      ability.action === 'use' &&
      ability.subject === 'FieldForceManagementFeature',
  );
  const hasFeatureFlags = featureFlags && (featureFlags?.length ?? 0) > 0;

  return (
    <>
      <Table
        caption={t('admin.personInfo.visibleCategories')}
        data={data?.person?.visibleCategories}
        isLoading={isLoading}
      />

      <Table
        caption={t('admin.personInfo.visibleGroups')}
        data={data?.person?.visibleGroups}
        isLoading={isLoading}
      />

      <Table
        caption={t('admin.personInfo.peerGroups')}
        data={data?.person?.peerGroups}
        isLoading={isLoading}
      />

      <Table
        caption={t('admin.personInfo.additionalOrganizations')}
        data={data?.additionalOrganizations}
        isLoading={isLoading}
      />

      <PHeading size="small" tag="h3">
        {t('common:featureFlags')}
      </PHeading>

      <Spacer mb="$small" />

      {hasFeatureFlags ? (
        <PTextList>
          {featureFlags.map((ability) => (
            <PTextListItem key={ability.conditions.name}>
              {ability.conditions.name}
            </PTextListItem>
          ))}
        </PTextList>
      ) : (
        <PText>{t('common:noData')}</PText>
      )}

      <Spacer mb="$large" />

      <PHeading size="small" tag="h3">
        {t('admin.personInfo.meetingInfo')}
      </PHeading>

      <Spacer mb="$small" />

      {data?.person?.id && <MeetingAbilityInfo personId={data.person.id} />}
    </>
  );
};

const Table = ({
  data,
  caption,
  isLoading,
}: {
  data?: { id: unknown; name: string }[] | null;
  caption: string;
  isLoading: boolean;
}) => {
  const { t } = useTranslation(NAMESPACES);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<NonNullable<typeof data>[0]>();

    return [
      columnHelper.accessor('id', {
        cell: (data) => data.getValue(),
        header: () => t('common:id'),
      }),
      columnHelper.accessor('name', {
        cell: (data) => data.getValue(),
        header: () => t('common:name'),
      }),
    ];
  }, [t]);

  return (
    <>
      <PHeading size="small" tag="h3">
        {caption}
      </PHeading>
      <DataTable
        caption={caption}
        isLoading={isLoading}
        data={data ?? []}
        columns={columns}
        idAccessor="name"
        pagination={{
          position: 'bottom',
        }}
        i18n={{
          filterLabel: (columnName) =>
            t('common:iconLabel.filter', { columnName }),
          optionAll: t('common:all'),
          buttonReset: t('common:action.reset'),
          buttonFilter: t('common:action.filter'),
          actionSearch: t('common:action.search'),
          noData: t('common:noData'),
        }}
      />
    </>
  );
};
