import type { TElement } from '@udecode/plate-common';
import { Plate, PlateProvider } from '@udecode/plate-core';
import { styled } from '../../stitches.config';
import { usePlugins } from './usePlugins';

const Wrapper = styled('div', {
  // Plate uses the 'overflow-wrap' property to force line breaks.
  // This does not work when used inside a CSS grid with 'fr' units.
  // Therefore this workaround is required.
  wordBreak: 'break-word',
});

export const RichText = <
  TMentionComponentValue extends { type: string },
  TMentionComponentPropsExtension,
>({
  value,
  includeImages,
  includeImageDownloadLink,
  MentionLabel,
  mentionProps,
  forceWordBreak = true,
  i18n,
  ...props
}: {
  value: TElement[];
  includeImages?: boolean;
  includeImageDownloadLink?: boolean;
  MentionLabel?: React.FunctionComponent<
    { value: TMentionComponentValue } & TMentionComponentPropsExtension
  >;
  mentionProps?: TMentionComponentPropsExtension;
  forceWordBreak?: boolean;
  i18n?: {
    captionPlaceholder?: string;
    gdprTooltip?: string;
    dropzoneDescription?: string;
    imageUploadHeading?: string;
    dragDescription?: string;
    uploadButtonText?: string;
    resetButtonText?: string;
    uploadErrorHeading?: string;
    uploadErrorDescription?: string;
    imagePlaceholder?: string | ((url: string) => string);
  };
}) => {
  const plugins = usePlugins({
    includeImages,
    includeImageDownloadLink,
    MentionLabel,
    mentionProps,
    i18n,
  });

  return (
    <PlateProvider
      key={JSON.stringify(value)}
      plugins={plugins}
      value={value}
      readOnly
    >
      {forceWordBreak ? (
        <Wrapper>
          <Plate editableProps={props} />
        </Wrapper>
      ) : (
        <Plate editableProps={props} />
      )}
    </PlateProvider>
  );
};
