import {
  PButton,
  PInlineNotification,
  PText,
} from '@porsche-design-system/components-react';
import { styled } from '../stitches.config';
import { ActionGroup } from './ActionGroup';
import { Modal } from './Modal';
import { Spacer } from './Spacer';

const Content = styled('div', {
  minWidth: '25rem',
});

export const ConfirmationModal = ({
  open,
  onDismiss,
  onConfirm,
  heading,
  description,
  i18n,
  isLoading,
  error,
}: {
  open: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
  heading: string;
  description: string;
  i18n: {
    cancel: string;
    confirm: string;
    error?: string;
  };
  isLoading?: boolean;
  error?: string;
}) => (
  <Modal
    open={open}
    onDismiss={() => {
      if (isLoading) return;
      onDismiss();
    }}
    aria={{ 'aria-label': heading }}
    size="initial"
  >
    <Modal.Header>{heading}</Modal.Header>

    {(error?.length ?? 0) > 0 ? (
      <>
        <PInlineNotification
          heading={i18n.error}
          description={error}
          state="error"
          dismissButton={false}
        />

        <Spacer mb="$medium" />
      </>
    ) : null}

    <Content>
      <PText>{description}</PText>
    </Content>

    <ActionGroup>
      <PButton
        type="button"
        role="button"
        variant="secondary"
        icon="close"
        disabled={isLoading}
        onClick={onDismiss}
      >
        {i18n.cancel}
      </PButton>
      <PButton
        type="button"
        role="button"
        variant="primary"
        loading={isLoading}
        onClick={onConfirm}
      >
        {i18n.confirm}
      </PButton>
    </ActionGroup>
  </Modal>
);
