import {
  PHeading,
  PTable,
  PTableBody,
  PTableCell,
  PTableHead,
  PTableHeadCell,
  PTableHeadRow,
  PTableRow,
} from '@porsche-design-system/components-react';
import { Spacer } from '@porsche-kado/ui';
import { Paragraph } from '../components';

export const CookiePolicy = () => (
  <>
    <PHeading role="heading" size="large" aria-level={1} tag="h1">
      Porsche Cookie Policy
    </PHeading>
    <Spacer mb="$medium" />
    <PHeading size="medium" aria-level={2} tag="h2">
      Scope of application
    </PHeading>
    <Paragraph>
      This Cookie Policy applies in addition to the general privacy policy for
      KADO and describes the type, scope, purposes of, legal basis for, and
      opt-out options for data processing in relation to cookies and similar
      measures. The general privacy policy for KADO applies to all other
      information. Date 8/2/2019
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      What are cookies?
    </PHeading>
    <Paragraph>
      We use &quot;cookies&quot; and similar measures (local storage, IndexedDB)
      in order to provide you with a comprehensive scope of functions, make it
      easier to use our service, and optimize our offerings. Cookies are small
      files, which are stored on your device by your Internet browser. Local
      storage consists of named key/value pairs which are stored inside your
      Internet browser. IndexedDB is a database inside your Internet browser
      used for storage of structured data. Both local storage and IndexedDB have
      security measures to provide each website only with their associated set
      of databases.
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      Categories of cookies
    </PHeading>
    <Paragraph>
      We use cookies and similar measures for different purposes and with
      different functions. We make a distinction according to the type of
      storage used (type of data record), whether the data storage is
      technically required (technical requirement), how long it is stored for
      and used (duration of storage), whether it was created by KADO itself or
      by a third party and who the provider who created the data record is
      (provider).
    </Paragraph>
    <PHeading size="small" aria-level={3} tag="h3">
      1. Technical requirement
    </PHeading>
    <Paragraph>
      Cookies required for technical reasons: We use certain cookies and similar
      measures because they are required for KADO and its functions to work
      properly. These data records are set automatically when KADO or a specific
      function is called up.
    </Paragraph>
    <PHeading size="small" aria-level={3} tag="h3">
      2. Duration of storage
    </PHeading>
    <Paragraph>
      Session cookies: Most cookies are required only for the duration of your
      current service call or session and are deleted or become invalid as soon
      as you exit KADO or your current session expires. Session cookies are
      used, for example, to retain certain information during your session such
      as your login details for KADO.{' '}
    </Paragraph>
    <Paragraph>
      Permanent cookies: Cookies are occasionally stored over a longer period
      for the purposes of recognizing you when you subsequently access KADO
      again and retrieving saved settings. This makes it faster and easier for
      you to access KADO and means that you do not have to repeat settings such
      as your selected language, for example. Permanent cookies are deleted
      automatically at the end of a predefined period when you visit the page or
      domain from which the cookie was set.
    </Paragraph>
    <Paragraph>
      Flow cookies: These cookies are used for communication between various
      internal Porsche servers. They are created at the start of a user
      interaction and deleted again when it is ended. Flow cookies are assigned
      a unique identification number during the interaction. However, this does
      not allow any conclusions to be drawn with respect to the identity of the
      actual customer or user.
    </Paragraph>
    <Paragraph>
      Local storage and IndexedDB data is stored until they are overwritten by
      new data or explicitly deleted by the application or the user.
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      Deletion of and objection to the use of cookies
    </PHeading>
    <Paragraph>
      The acceptance of the use of local storage and IndexedDB is a technical
      requirement when using KADO. Saved cookies, local storage and IndexedDB
      data can be deleted at any time in the system settings of your browser.
    </Paragraph>
    <PHeading size="medium" aria-level={2} tag="h2">
      The following data may be stored in your browser when you call up our KADO
    </PHeading>
    <PTable caption="Data stored in browser">
      <PTableHead>
        <PTableHeadRow>
          <PTableHeadCell>Name of data record</PTableHeadCell>
          <PTableHeadCell>Type of data record</PTableHeadCell>
          <PTableHeadCell>
            Technical
            <br /> requirement
          </PTableHeadCell>
          <PTableHeadCell>Duration of storage</PTableHeadCell>
          <PTableHeadCell>Provider</PTableHeadCell>
          <PTableHeadCell>Purpose and interest</PTableHeadCell>
        </PTableHeadRow>
      </PTableHead>
      <PTableBody>
        {[
          {
            name: 'persist:actionItems',
            purpose: 'It contains the local replica of action items.',
          },
          {
            name: 'persist:app',
            purpose:
              'It contains information needed for the synchronisation process.',
          },
          {
            name: 'persist:categories',
            purpose: 'It contains the local replica of categories.',
          },
          {
            name: 'persist:groups',
            purpose: 'It contains the local replica of groups.',
          },
          {
            name: 'persist:meetings',
            purpose: 'It contains the local replica of meetings.',
          },
          {
            name: 'persist:organizations',
            purpose: 'It contains the local replica of organizations.',
          },
          {
            name: 'persist:coachingModules',
            purpose: 'It contains the local replica of coaching modules.',
          },
          {
            name: 'persist:consultingMeetingTypes',
            purpose:
              'It contains the local replica of Consulting meeting types.',
          },
          {
            name: 'persist:persons',
            purpose: 'It contains the local replica of persons.',
          },
          {
            name: 'persist:strategyImpacts',
            purpose: 'It contains the local replica of strategy impacts.',
          },
          {
            name: 'persist:tags',
            purpose: 'It contains the local replica of tags.',
          },
          {
            name: 'persist:user',
            purpose: 'It contains the local replica of users.',
          },
          {
            name: '<fileID>',
            purpose: 'It contains uploaded files to be synchronized later.',
          },
        ].map((cookie) => (
          <PTableRow key={`cookie-${cookie.name}`}>
            <PTableCell>{cookie.name}</PTableCell>
            <PTableCell>IndexedDB</PTableCell>
            <PTableCell>Yes</PTableCell>
            <PTableCell>Until new data is received</PTableCell>
            <PTableCell>Dr. Ing. h.c. F. Porsche AG</PTableCell>
            <PTableCell>
              This record is required for the offline capabilities of KADO
              <br />
              {cookie.purpose}
            </PTableCell>
          </PTableRow>
        ))}
        <PTableRow>
          <PTableCell>i18nextLng</PTableCell>
          <PTableCell>Local storage</PTableCell>
          <PTableCell>Yes</PTableCell>
          <PTableCell>Until you change the language</PTableCell>
          <PTableCell>Dr. Ing. h.c. F. Porsche AG</PTableCell>
          <PTableCell>
            This record is used to determine the UI language.
          </PTableCell>
        </PTableRow>
        <PTableRow>
          <PTableCell>credentials</PTableCell>
          <PTableCell>Local storage</PTableCell>
          <PTableCell>Yes</PTableCell>
          <PTableCell>
            Until another user logs in using your Internet browser
          </PTableCell>
          <PTableCell>Dr. Ing. h.c. F. Porsche AG</PTableCell>
          <PTableCell>
            This record is used to authenticate against services for data
            retrieval.
          </PTableCell>
        </PTableRow>
        <PTableRow>
          <PTableCell>credentials</PTableCell>
          <PTableCell>Cookie</PTableCell>
          <PTableCell>Yes</PTableCell>
          <PTableCell>
            Until another user logs in using your Internet browser
          </PTableCell>
          <PTableCell>Dr. Ing. h.c. F. Porsche AG</PTableCell>
          <PTableCell>
            This record is used to authenticate against services for data
            retrieval.
          </PTableCell>
        </PTableRow>
        <PTableRow>
          <PTableCell>kado.readFeatureArticle.&lt;articleID&gt;</PTableCell>
          <PTableCell>Local storage</PTableCell>
          <PTableCell>Yes</PTableCell>
          <PTableCell>Persistent</PTableCell>
          <PTableCell>Dr. Ing. h.c. F. Porsche AG</PTableCell>
          <PTableCell>
            This record is used to mark articles that have not been seen before.
          </PTableCell>
        </PTableRow>
        <PTableRow>
          <PTableCell>
            kado.retailDesign.seenRetailDesignProject.&lt;projectID&gt;
          </PTableCell>
          <PTableCell>Local storage</PTableCell>
          <PTableCell>Yes</PTableCell>
          <PTableCell>Persistent</PTableCell>
          <PTableCell>Dr. Ing. h.c. F. Porsche AG</PTableCell>
          <PTableCell>
            This record is used to highlight comments on attachments that have
            not been seen before.
          </PTableCell>
        </PTableRow>
        <PTableRow>
          <PTableCell>
            kado.retailDesign.readRetailDesignProjectAttachmentComments.&lt;projectID&gt;.&lt;attachmentID&gt;
          </PTableCell>
          <PTableCell>Local storage</PTableCell>
          <PTableCell>Yes</PTableCell>
          <PTableCell>Persistent</PTableCell>
          <PTableCell>Dr. Ing. h.c. F. Porsche AG</PTableCell>
          <PTableCell>
            This record is used to highlight comments on attachments that have
            not been seen before.
          </PTableCell>
        </PTableRow>
        <PTableRow>
          <PTableCell>
            kado.multisensoryExperience.readMeasure.&lt;measureId&gt;
          </PTableCell>
          <PTableCell>Local storage</PTableCell>
          <PTableCell>Yes</PTableCell>
          <PTableCell>Persistent</PTableCell>
          <PTableCell>Dr. Ing. h.c. F. Porsche AG</PTableCell>
          <PTableCell>
            This record is used to highlight comments on tasks that have not
            been seen before.
          </PTableCell>
        </PTableRow>
        <PTableRow>
          <PTableCell>
            kado.multisensoryExperience.readMeasureTaskComments.&lt;taskId&gt;
          </PTableCell>
          <PTableCell>Local storage</PTableCell>
          <PTableCell>Yes</PTableCell>
          <PTableCell>Persistent</PTableCell>
          <PTableCell>Dr. Ing. h.c. F. Porsche AG</PTableCell>
          <PTableCell>
            This record is used to highlight comments on tasks that have not
            been seen before.
          </PTableCell>
        </PTableRow>
        <PTableRow>
          <PTableCell>
            kado.dashboard.migrationStatus.&lt;supportRequestID&gt;
          </PTableCell>
          <PTableCell>Local storage</PTableCell>
          <PTableCell>Yes</PTableCell>
          <PTableCell>Persistent</PTableCell>
          <PTableCell>Dr. Ing. h.c. F. Porsche AG</PTableCell>
          <PTableCell>This record is used to save migration status.</PTableCell>
        </PTableRow>
        <PTableRow>
          <PTableCell>networkPlanning.hideTableUsageHint</PTableCell>
          <PTableCell>Local storage</PTableCell>
          <PTableCell>Yes</PTableCell>
          <PTableCell>Until hint was requested again by the user</PTableCell>
          <PTableCell>Dr. Ing. h.c. F. Porsche AG</PTableCell>
          <PTableCell>
            This record is used to hide hint on how to navigate in the table.
          </PTableCell>
        </PTableRow>
        <PTableRow>
          <PTableCell>networkPlanning.changeHistory</PTableCell>
          <PTableCell>Session storage</PTableCell>
          <PTableCell>Yes</PTableCell>
          <PTableCell>Until the browser tab is closed</PTableCell>
          <PTableCell>Dr. Ing. h.c. F. Porsche AG</PTableCell>
          <PTableCell>
            This record is used to track and display the user&apos;s last
            changes.
          </PTableCell>
        </PTableRow>
        <PTableRow>
          <PTableCell>appUpdated</PTableCell>
          <PTableCell>Local storage</PTableCell>
          <PTableCell>Yes</PTableCell>
          <PTableCell>Short time</PTableCell>
          <PTableCell>Dr. Ing. h.c. F. Porsche AG</PTableCell>
          <PTableCell>
            This record is used to notify the user about a recent update of the
            web application.
          </PTableCell>
        </PTableRow>
        <PTableRow>
          <PTableCell>NRBA_SESSION</PTableCell>
          <PTableCell>Local storage</PTableCell>
          <PTableCell>Yes</PTableCell>
          <PTableCell>Persistent</PTableCell>
          <PTableCell>New Relic, Inc.</PTableCell>
          <PTableCell>
            This record is used to collect data on the user&apos;s navigation
            and behavior on the website.
          </PTableCell>
        </PTableRow>
      </PTableBody>
    </PTable>
  </>
);
