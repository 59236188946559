import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';
import { SupportRequests } from '../../pages';

const searchSchema = z
  .object({
    limit: z.number(),
    skip: z.number(),
    columnFilters: z.array(
      z.object({
        id: z.string(),
        value: z.unknown(),
      }),
    ),
    sorting: z.array(
      z.object({
        id: z.string(),
        desc: z.boolean(),
      }),
    ),
  })
  .partial();

export const Route = createFileRoute('/support/')({
  component: () => <SupportRequests />,
  validateSearch: searchSchema,
});
