import { createFileRoute } from '@tanstack/react-router';
import { Trans } from 'react-i18next';
import { UserManagement } from '../../../pages';

export const Route = createFileRoute('/admin/user-management/_auth/')({
  component: () => <UserManagement />,
  beforeLoad: () => {
    return {
      breadcrumb: () => <Trans i18nKey="admin.userManagement.headline" />,
    };
  },
});
