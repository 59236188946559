import { subject } from '@casl/ability';
import {
  PCheckboxWrapper,
  PHeading,
  PSpinner,
  PTabs,
  PTabsItem,
  PText,
} from '@porsche-design-system/components-react';
import { DateFormatSelect, Spacer, styled } from '@porsche-kado/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from '@tanstack/react-router';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimeOutput } from '../../components';
import { NAMESPACES } from '../../config/i18n';
import {
  PersonActionKind,
  useAbilityContext,
  usePersonContext,
} from '../../context';
import {
  PersonQuery,
  useDashboardEmailNotificationsQuery,
  usePersonQuery,
  useUpdatePersonSettingsMutation,
} from '../../graphql';
import { SupportRequestNotifications } from './SupportRequestNotifications';

const LimitWidth = styled('div', {
  maxWidth: '320px',
});

export const Profile = () => {
  const { t } = useTranslation(NAMESPACES);
  const { tab } = useParams({ strict: false });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const ability = useAbilityContext();
  const { dispatch: dispatchPerson, state: personState } = usePersonContext();

  const { mutate: updatePersonSettings } = useUpdatePersonSettingsMutation({
    onSuccess: (data) => {
      queryClient.setQueryData<PersonQuery>(
        usePersonQuery.getKey({
          ppnuid: data.updatePersonSettings.ppnuid,
        }),
        (cache) =>
          cache?.person
            ? {
                person: {
                  ...cache.person,
                  settings: {
                    ...cache.person?.settings,
                    ...data.updatePersonSettings.settings,
                  },
                },
              }
            : undefined,
      );
    },
  });

  const change24hFormat = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;

    updatePersonSettings({
      input: {
        is24HourFormat: checked,
      },
    });

    dispatchPerson({
      type: PersonActionKind.Change24HFormat,
      payload: checked,
    });
  };

  const changeDateFormat = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    updatePersonSettings({
      input: {
        dateFormat: value,
      },
    });

    dispatchPerson({
      type: PersonActionKind.ChangeDateFormat,
      payload: value,
    });
  };

  const canManageNotifications = ability.can(
    'manage',
    'DashboardEmailNotification',
  );

  const { isLoading: isLoadingNotifications, data: notifications } =
    useDashboardEmailNotificationsQuery(undefined, {
      enabled: canManageNotifications,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });

  return (
    <>
      <PHeading role="heading" aria-level={1} tag="h1" size="large">
        {t('common:profile.headline')}
      </PHeading>

      <Spacer mb="$medium" />

      <PTabs
        size="medium"
        onUpdate={({ detail: { activeTabIndex } }) => {
          navigate({
            replace: true,
            to: `/profile${getTabPath(activeTabIndex)}`,
          });
        }}
        activeTabIndex={getTabIndex(tab)}
      >
        <PTabsItem label={t('common:profile.generalSettings')}>
          <Spacer mb="$medium" />

          <PHeading role="heading" aria-level={2} tag="h2" size="small">
            {t('common:profile.dateSettings')}
          </PHeading>

          <Spacer mb="$medium" />

          <LimitWidth>
            <DateFormatSelect
              label={t('common:profile.dateFormat')}
              defaultValue={personState.settings?.dateFormat ?? undefined}
              onChange={changeDateFormat}
            />
          </LimitWidth>

          <Spacer mb="$medium" />

          <PCheckboxWrapper label={t('common:profile.24hFormat')}>
            <input
              type="checkbox"
              name="is24hFormat"
              checked={personState.settings?.is24HourFormat ?? false}
              onChange={change24hFormat}
            />
          </PCheckboxWrapper>

          <Spacer mb="$medium" />

          <PText color="contrast-medium">
            {t('common:profile.dateExample')}:{' '}
            <DateTimeOutput date={new Date()} />
          </PText>
        </PTabsItem>

        {canManageNotifications && (
          <PTabsItem label={t('common:profile.notifications')}>
            <Spacer mb="$medium" />

            {isLoadingNotifications ? (
              <PSpinner />
            ) : (
              <Notifications>
                {ability.can(
                  'manage',
                  subject('DashboardEmailNotification', {
                    person: personState.id,
                  }),
                ) && (
                  <SupportRequestNotifications
                    notification={notifications?.dashboardEmailNotifications[0]}
                  />
                )}
              </Notifications>
            )}
          </PTabsItem>
        )}
      </PTabs>
    </>
  );
};

const getTabIndex = (tab?: string) => {
  switch (tab) {
    case 'email-notifications':
      return 1;

    default:
      return 0;
  }
};

const getTabPath = (index: number) => {
  switch (index) {
    case 1:
      return '/email-notifications';

    default:
      return '';
  }
};

const Notifications = styled('div', {
  display: 'grid',
  rowGap: '$medium',

  '& > :not(:last-child)': {
    '&:after': {
      content: '',
      display: 'block',
      marginTop: '$medium',

      borderBottom: '1px solid $contrastLow',
    },
  },
});
