import { Auth } from '@aws-amplify/auth';
import {
  PButton,
  PHeading,
  PTextFieldWrapper,
} from '@porsche-design-system/components-react';
import { COMMON_NAMESPACE } from '@porsche-kado/i18n';
import { Spacer, styled } from '@porsche-kado/ui';
import NotLoggedInIcon from '@porsche-kado/ui/icons/not-logged-in.svg?react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const SignInForm = () => {
  const { t } = useTranslation(COMMON_NAMESPACE);

  const form = useForm({ values: { username: '', password: '' } });

  return (
    <Wrapper>
      <PHeading role="heading" tag="h3" size="x-large">
        {t('signIn.title')}
      </PHeading>

      <Icon />

      <Form
        onSubmit={form.handleSubmit(async ({ username, password }) => {
          try {
            await Auth.signIn(username, password);
          } catch (err) {
            form.setError('username', {
              message: err instanceof Error ? err.message : `${err}`,
            });
          }
        })}
      >
        <PTextFieldWrapper
          label={t('signIn.fields.username')}
          message={form.formState.errors.username?.message}
          state={
            form.formState.errors.username &&
            form.formState.touchedFields.username
              ? 'error'
              : 'none'
          }
        >
          <input
            type="text"
            {...form.register('username', { required: true })}
          />
        </PTextFieldWrapper>

        <Spacer mb="$medium" />

        <PTextFieldWrapper
          label={t('signIn.fields.password')}
          message={form.formState.errors.password?.message}
          state={
            form.formState.errors.password &&
            form.formState.touchedFields.password
              ? 'error'
              : 'none'
          }
        >
          <input
            type="password"
            {...form.register('password', { required: true })}
          />
        </PTextFieldWrapper>

        <Spacer mb="$medium" />

        <PButton
          aria-label={t('signIn.submit')}
          loading={form.formState.isSubmitting}
          type="submit"
        >
          {t('signIn.submit')}
        </PButton>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '$xLarge',
});

const Icon = styled(NotLoggedInIcon, {
  fontSize: '180px',
  margin: '$xLarge 0',
});

const Form = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  width: 350,
});
