/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { request } from '../lib/graphqlRequest';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AbilityRule: { input: any; output: any };
  BigInt: { input: number; output: number };
  Cursor: { input: any; output: any };
  Date: { input: string; output: string };
  DateMonth: { input: string; output: string };
  DateTime: { input: string; output: string };
  ExchangeRates: { input: any; output: any };
  FloatPerYear: { input: any; output: any };
  IntPerYear: { input: any; output: any };
  JSON: { input: Record<string, any>; output: Record<string, any> };
  ObjectID: { input: string; output: string };
  QuestionnaireTypeWeightsPerYear: { input: any; output: any };
  StringFilterRegexOptions: {
    input: 'i' | 'g' | 'm' | 'u' | undefined;
    output: 'i' | 'g' | 'm' | 'u' | undefined;
  };
  URL: { input: any; output: any };
  _Key: { input: any; output: any };
};

export type Ability = {
  __typename?: 'Ability';
  /** @deprecated Gateway access only */
  _personId: Scalars['BigInt']['output'];
  common: Array<Scalars['JSON']['output']>;
  dashboard: Array<Scalars['JSON']['output']>;
  /** @deprecated Use fieldForceManagementSubset.rules */
  fieldForceManagement: Array<Scalars['JSON']['output']>;
  fieldForceManagementSubset: FieldForceManagementAbility;
  greenland: Array<Scalars['JSON']['output']>;
  multisensoryExperience: Array<Scalars['JSON']['output']>;
  mysteryShopping: Array<Scalars['JSON']['output']>;
  networkPlanning: Array<Scalars['JSON']['output']>;
  porscheStrategy2030: Array<Scalars['JSON']['output']>;
  retailDesign: Array<Scalars['JSON']['output']>;
  /** @deprecated Use common or the app abilities instead */
  rules: Array<Scalars['JSON']['output']>;
  survey: Array<Scalars['JSON']['output']>;
};

export type AbilityCheckResult = {
  __typename?: 'AbilityCheckResult';
  allowed: Scalars['Boolean']['output'];
  reason?: Maybe<Scalars['String']['output']>;
};

export type ActionItem = {
  __typename?: 'ActionItem';
  agendaItems?: Maybe<Array<AgendaItem>>;
  attachments?: Maybe<Array<Attachment>>;
  author: PersonReference;
  categories?: Maybe<Array<Category>>;
  changelog?: Maybe<Array<Changelog>>;
  coachingModules?: Maybe<Array<CoachingModule>>;
  comments: Array<Comment>;
  confidentialExceptForPersons?: Maybe<Array<Person>>;
  createdAt: Scalars['DateTime']['output'];
  createdByResource?: Maybe<Resources>;
  description?: Maybe<Scalars['String']['output']>;
  dueDate: Scalars['DateTime']['output'];
  filledInAudit?: Maybe<FilledInAudit>;
  filledInAudits?: Maybe<Array<FilledInAudit>>;
  filledInSurveys?: Maybe<Array<FilledInSurvey>>;
  /** ffmAlias: actionItemId */
  id: Scalars['ObjectID']['output'];
  isConfidential?: Maybe<Scalars['Boolean']['output']>;
  isCreatedAsStandalone?: Maybe<Scalars['Boolean']['output']>;
  isFollowUp?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Field no longer supported */
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  kind?: Maybe<ActionItemKind>;
  kpiActualValue?: Maybe<Scalars['Float']['output']>;
  kpiInitialValue?: Maybe<Scalars['Float']['output']>;
  kpiTargetValue?: Maybe<Scalars['Float']['output']>;
  kpiUnit?: Maybe<Scalars['String']['output']>;
  maskedMeetings?: Maybe<Array<MaskedMeeting>>;
  organization: Organization;
  pbsaValue?: Maybe<Scalars['Float']['output']>;
  peerGroup?: Maybe<Scalars['ObjectID']['output']>;
  priority: ActionItemPriority;
  profitPotentialCurrency?: Maybe<Scalars['String']['output']>;
  profitPotentialIdentified?: Maybe<Scalars['Float']['output']>;
  profitPotentialRealized?: Maybe<Scalars['Float']['output']>;
  questionnaire?: Maybe<FilledInQuestionnaire>;
  questionnaires?: Maybe<Array<FilledInQuestionnaire>>;
  responsible: Person;
  source?: Maybe<Source>;
  status: ActionItemStatus;
  strategyImpact?: Maybe<StrategyImpact>;
  surveyAnswer?: Maybe<SurveyAnswer>;
  surveyInstances?: Maybe<Array<SurveyInstance>>;
  surveys?: Maybe<Array<Survey>>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  widget?: Maybe<ActionItemWidget>;
  widgets?: Maybe<Array<ActionItemWidget>>;
};

export type ActionItemChangelogArgs = {
  filter?: InputMaybe<ChangelogFilter>;
};

export type ActionItemFilledInAuditArgs = {
  widgetId: Scalars['ObjectID']['input'];
};

export type ActionItemQuestionnaireArgs = {
  widgetId: Scalars['ObjectID']['input'];
};

export type ActionItemWidgetArgs = {
  widgetId: Scalars['ObjectID']['input'];
};

export type ActionItemCountByCategory = {
  __typename?: 'ActionItemCountByCategory';
  key: Scalars['ObjectID']['output'];
  value: Scalars['Int']['output'];
};

export type ActionItemCountByStatus = {
  __typename?: 'ActionItemCountByStatus';
  key: ActionItemStatus;
  value: Scalars['Int']['output'];
};

export type ActionItemFilter = {
  _and?: InputMaybe<Array<InputMaybe<ActionItemFilter>>>;
  _id?: InputMaybe<ObjectIdFilter>;
  _or?: InputMaybe<Array<InputMaybe<ActionItemFilter>>>;
  author_personId?: InputMaybe<BigIntFilter>;
  categories_categoryId?: InputMaybe<ObjectIdFilter>;
  changelog?: InputMaybe<ChangelogFilter>;
  changelog_patch_value?: InputMaybe<StringFilter>;
  changelog_timestamp?: InputMaybe<DateTimeFilter>;
  coachingModules_coachingModuleId?: InputMaybe<ObjectIdFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdByResource_actionItemId?: InputMaybe<ObjectIdFilter>;
  createdByResource_auditId?: InputMaybe<ObjectIdFilter>;
  createdByResource_questionGroupId?: InputMaybe<BigIntFilter>;
  createdByResource_questionId?: InputMaybe<ObjectIdFilter>;
  createdByResource_questionnaireId?: InputMaybe<ObjectIdFilter>;
  createdByResource_widgetId?: InputMaybe<ObjectIdFilter>;
  dueDate?: InputMaybe<DateTimeFilter>;
  isConfidential?: InputMaybe<BooleanFilter>;
  isFollowUp?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate?: InputMaybe<BooleanFilter>;
  kind?: InputMaybe<ActionItemKindFilter>;
  organization_geography_region?: InputMaybe<StringFilter>;
  organization_organizationId?: InputMaybe<BigIntFilter>;
  priority?: InputMaybe<ActionItemPriorityFilter>;
  profitPotentialIdentified?: InputMaybe<BigIntFilter>;
  profitPotentialRealized?: InputMaybe<BigIntFilter>;
  responsible_personId?: InputMaybe<BigIntFilter>;
  rootOrganizationIds?: InputMaybe<
    Array<InputMaybe<Scalars['BigInt']['input']>>
  >;
  source?: InputMaybe<ActionItemSourceFilter>;
  status?: InputMaybe<ActionItemStatusFilter>;
  strategyImpactId?: InputMaybe<ObjectIdFilter>;
  tags?: InputMaybe<StringArrayFilter>;
  title?: InputMaybe<StringFilter>;
  /** @deprecated Field no longer supported */
  widgets_auditId?: InputMaybe<ObjectIdFilter>;
  /** @deprecated Field no longer supported */
  widgets_questionnaireId?: InputMaybe<ObjectIdFilter>;
  widgets_questionnaireOrAuditId?: InputMaybe<ObjectIdFilter>;
};

export type ActionItemInput = {
  actionItemId?: InputMaybe<Scalars['ObjectID']['input']>;
  agendaItems?: InputMaybe<Array<AgendaReferenceInput>>;
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  attachmentsToCopy?: InputMaybe<CopyActionItemAttachmentsInput>;
  author?: InputMaybe<PersonReferenceInput>;
  categories?: InputMaybe<Array<CategoryReferenceInput>>;
  coachingModules?: InputMaybe<Array<CoachingModuleReferenceInput>>;
  confidentialExceptForPersons?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdByResource?: InputMaybe<ResourcesInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['ObjectID']['input']>;
  isConfidential?: InputMaybe<Scalars['Boolean']['input']>;
  isCreatedAsStandalone?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<ActionItemKind>;
  kpiActualValue?: InputMaybe<Scalars['Float']['input']>;
  kpiInitialValue?: InputMaybe<Scalars['Float']['input']>;
  kpiTargetValue?: InputMaybe<Scalars['Float']['input']>;
  kpiUnit?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationReferenceInput;
  pbsaValue?: InputMaybe<Scalars['Float']['input']>;
  peerGroup?: InputMaybe<Scalars['ObjectID']['input']>;
  priority: ActionItemPriority;
  profitPotentialCurrency?: InputMaybe<Scalars['String']['input']>;
  profitPotentialIdentified?: InputMaybe<Scalars['Float']['input']>;
  profitPotentialRealized?: InputMaybe<Scalars['Float']['input']>;
  responsible: PersonReferenceInput;
  source?: InputMaybe<Source>;
  status: ActionItemStatus;
  strategyImpact?: InputMaybe<StrategyImpactReferenceInput>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  title: Scalars['String']['input'];
  widgets?: InputMaybe<ModifyActionItemWidgetsInput>;
};

export enum ActionItemKind {
  Pbe = 'PBE',
  Plt = 'PLT',
  Psc = 'PSC',
  Standard = 'STANDARD',
}

export type ActionItemKindFilter = {
  _eq?: InputMaybe<ActionItemKind>;
  _in?: InputMaybe<Array<InputMaybe<ActionItemKind>>>;
};

export enum ActionItemPriority {
  High = 'HIGH',
  Normal = 'NORMAL',
}

export type ActionItemPriorityFilter = {
  _in?: InputMaybe<Array<InputMaybe<ActionItemPriority>>>;
};

export type ActionItemRecipient = {
  __typename?: 'ActionItemRecipient';
  organization: OrganizationReference;
  responsible: PersonReference;
};

export type ActionItemRecipientInput = {
  organization: OrganizationReferenceInput;
  responsible: PersonReferenceInput;
};

export type ActionItemSort = {
  author_name?: InputMaybe<SortOrder>;
  categories?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dueDate?: InputMaybe<SortOrder>;
  kind?: InputMaybe<SortOrder>;
  organization_name?: InputMaybe<SortOrder>;
  priority?: InputMaybe<SortOrder>;
  responsible_name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  strategyImpact?: InputMaybe<SortOrder>;
  tags?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type ActionItemSourceFilter = {
  _eq?: InputMaybe<Source>;
  _exists?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<InputMaybe<Source>>>;
  _ne?: InputMaybe<Source>;
  _nin?: InputMaybe<Array<InputMaybe<Source>>>;
};

export type ActionItemStatistic = {
  __typename?: 'ActionItemStatistic';
  count: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
};

export type ActionItemStatisticInput = {
  dateFrom: Scalars['Date']['input'];
  dateTo: Scalars['Date']['input'];
  granularity: StatisticGranularity;
  organizationId: Scalars['BigInt']['input'];
};

export enum ActionItemStatus {
  '1Assigned' = '_1_ASSIGNED',
  '2InProgress' = '_2_IN_PROGRESS',
  '3NotPossible' = '_3_NOT_POSSIBLE',
  '4Done' = '_4_DONE',
  '5Finished' = '_5_FINISHED',
}

export type ActionItemStatusFilter = {
  _in?: InputMaybe<Array<InputMaybe<ActionItemStatus>>>;
  _nin?: InputMaybe<Array<InputMaybe<ActionItemStatus>>>;
};

export type ActionItemTemplate = {
  __typename?: 'ActionItemTemplate';
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  author?: Maybe<Person>;
  categories?: Maybe<Array<CategoryReference>>;
  coachingModules?: Maybe<Array<CoachingModule>>;
  confidentialExceptForPersons?: Maybe<Array<Person>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  /** ffmAlias: actionItemTemplateId */
  id: Scalars['ObjectID']['output'];
  isBroadcasting?: Maybe<Scalars['Boolean']['output']>;
  isConfidential?: Maybe<Scalars['Boolean']['output']>;
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  kind?: Maybe<ActionItemKind>;
  kpiActualValue?: Maybe<Scalars['Float']['output']>;
  kpiInitialValue?: Maybe<Scalars['Float']['output']>;
  kpiTargetValue?: Maybe<Scalars['Float']['output']>;
  kpiUnit?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<OrganizationReference>;
  peerGroup?: Maybe<Scalars['ObjectID']['output']>;
  priority?: Maybe<ActionItemPriority>;
  profitPotentialCurrency?: Maybe<Scalars['String']['output']>;
  profitPotentialIdentified?: Maybe<Scalars['Float']['output']>;
  profitPotentialRealized?: Maybe<Scalars['Float']['output']>;
  recipients?: Maybe<Array<ActionItemRecipient>>;
  responsible?: Maybe<PersonReference>;
  status?: Maybe<ActionItemStatus>;
  strategyImpact?: Maybe<StrategyImpact>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ActionItemTemplateInput = {
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  categories?: InputMaybe<Array<CategoryReferenceInput>>;
  coachingModules?: InputMaybe<Array<CoachingModuleReferenceInput>>;
  confidentialExceptForPersons?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ObjectID']['input']>;
  isBroadcasting?: InputMaybe<Scalars['Boolean']['input']>;
  isConfidential?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<ActionItemKind>;
  kpiActualValue?: InputMaybe<Scalars['Float']['input']>;
  kpiInitialValue?: InputMaybe<Scalars['Float']['input']>;
  kpiTargetValue?: InputMaybe<Scalars['Float']['input']>;
  kpiUnit?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationReferenceInput>;
  peerGroup?: InputMaybe<Scalars['ObjectID']['input']>;
  priority?: InputMaybe<ActionItemPriority>;
  profitPotentialCurrency?: InputMaybe<Scalars['String']['input']>;
  profitPotentialIdentified?: InputMaybe<Scalars['Float']['input']>;
  profitPotentialRealized?: InputMaybe<Scalars['Float']['input']>;
  recipients?: InputMaybe<Array<ActionItemRecipientInput>>;
  responsible?: InputMaybe<PersonReferenceInput>;
  status?: InputMaybe<ActionItemStatus>;
  strategyImpact?: InputMaybe<StrategyImpactReferenceInput>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  title: Scalars['String']['input'];
};

export type ActionItemWidget = {
  isRemoved?: Maybe<Scalars['Boolean']['output']>;
  kind: WidgetKind;
  widgetId: Scalars['ObjectID']['output'];
};

export type ActionItemsByCategory = {
  __typename?: 'ActionItemsByCategory';
  categories: Array<ActionItemCountByCategory>;
  organizations: Array<Scalars['BigInt']['output']>;
};

export type AgendaActionItem = ActionItem | MaskedActionItem;

export type AgendaItem = {
  __typename?: 'AgendaItem';
  actionItems?: Maybe<Array<AgendaActionItem>>;
  attachments?: Maybe<Array<Attachment>>;
  attendees?: Maybe<Array<PersonReference>>;
  categories?: Maybe<Array<Category>>;
  description?: Maybe<Scalars['String']['output']>;
  externals?: Maybe<Array<Scalars['String']['output']>>;
  itemId: Scalars['Int']['output'];
  meeting: Meeting;
  order?: Maybe<Scalars['Int']['output']>;
  scheduledTimeEnd?: Maybe<Scalars['DateTime']['output']>;
  scheduledTimeStart?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
};

export type AgendaItemInput = {
  actionItems?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']['input']>>>;
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  attachmentsToCopy?: InputMaybe<CopyAgendaItemAttachmentsInput>;
  attendees?: InputMaybe<Array<PersonReferenceInput>>;
  categories?: InputMaybe<Array<CategoryReferenceInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  externals?: InputMaybe<Array<Scalars['String']['input']>>;
  itemId: Scalars['Int']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  scheduledTimeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledTimeStart?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AgendaReferenceInput = {
  itemId: Scalars['Int']['input'];
  meeting: MeetingReferenceInput;
};

export type Analytics = {
  __typename?: 'Analytics';
  eventCount: Scalars['Int']['output'];
  eventDate: Scalars['Date']['output'];
  role?: Maybe<Scalars['String']['output']>;
};

export enum AnalyticsEvent {
  Login = 'LOGIN',
  OfflineMode = 'OFFLINE_MODE',
  OpenChangeHistory = 'OPEN_CHANGE_HISTORY',
}

export enum AnalyticsGranularity {
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR',
}

export type AnalyticsInput = {
  app: Scalars['String']['input'];
  dateFrom: Scalars['Date']['input'];
  dateTo: Scalars['Date']['input'];
  event: AnalyticsEvent;
  granularity: AnalyticsGranularity;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Announcement = {
  __typename?: 'Announcement';
  from?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ObjectID']['output'];
  isActive: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  to?: Maybe<Scalars['Date']['output']>;
};

export type AnswerSurveyMultipleChoiceQuestionInput = {
  attachments?: InputMaybe<ModifyAttachmentsInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  isNotApplicable?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
};

export type AnswerSurveyMultipleEntryQuestionInput = {
  attachments?: InputMaybe<ModifyAttachmentsInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  isNotApplicable?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<AnswerSurveyMultipleEntryQuestionInputModifyValue>;
};

export type AnswerSurveyMultipleEntryQuestionInputEntry = {
  entryId: Scalars['ObjectID']['input'];
  value: Scalars['Int']['input'];
};

export type AnswerSurveyMultipleEntryQuestionInputModifyValue = {
  _pull?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  _push?: InputMaybe<Array<AnswerSurveyMultipleEntryQuestionInputEntry>>;
  _update?: InputMaybe<
    Array<AnswerSurveyMultipleEntryQuestionInputUpdateEntry>
  >;
};

export type AnswerSurveyMultipleEntryQuestionInputUpdateEntry = {
  entryId: Scalars['ObjectID']['input'];
  id: Scalars['ObjectID']['input'];
  value: Scalars['Int']['input'];
};

export type AnswerSurveyNumericQuestionInput = {
  attachments?: InputMaybe<ModifyAttachmentsInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  isNotApplicable?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type AnswerSurveyQuestionInput = {
  actionItemIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  answerMultipleChoice?: InputMaybe<AnswerSurveyMultipleChoiceQuestionInput>;
  answerMultipleEntry?: InputMaybe<AnswerSurveyMultipleEntryQuestionInput>;
  answerNumeric?: InputMaybe<AnswerSurveyNumericQuestionInput>;
  answerText?: InputMaybe<AnswerSurveyTextQuestionInput>;
  answerYesNo?: InputMaybe<AnswerSurveyYesNoQuestionInput>;
  answeredFor: Scalars['BigInt']['input'];
  itemId: Scalars['ObjectID']['input'];
  surveyId: Scalars['ObjectID']['input'];
};

export type AnswerSurveyTextQuestionInput = {
  attachments?: InputMaybe<ModifyAttachmentsInput>;
  isNotApplicable?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type AnswerSurveyYesNoQuestionInput = {
  attachments?: InputMaybe<ModifyAttachmentsInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  isNotApplicable?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<SurveyYesNoAnswerValue>;
};

export enum AnswerType {
  QuestionnaireCurrencyAnswer = 'QuestionnaireCurrencyAnswer',
  QuestionnaireMultipleChoiceAnswer = 'QuestionnaireMultipleChoiceAnswer',
  QuestionnaireTextAnswer = 'QuestionnaireTextAnswer',
  QuestionnaireYesNoAnswer = 'QuestionnaireYesNoAnswer',
}

export type Answerable = Organization | Person;

export enum AnswerableType {
  Organization = 'organization',
  Person = 'person',
}

export enum AssignmentType {
  PorscheCenter = 'PORSCHE_CENTER',
  PorscheImporter = 'PORSCHE_IMPORTER',
}

export type Attachment = {
  __typename?: 'Attachment';
  createdAt: Scalars['DateTime']['output'];
  filePrefix?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  uploader?: Maybe<Person>;
};

export type AttachmentInput = {
  /** @deprecated Field no longer supported */
  createdAt?: InputMaybe<Scalars['String']['input']>;
  filePrefix?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
  type: Scalars['String']['input'];
};

export type Audit = {
  __typename?: 'Audit';
  additionalPersons?: Maybe<Array<Person>>;
  allowMultipleAssignments?: Maybe<Scalars['Boolean']['output']>;
  author: PersonReference;
  children: Array<AuditItem>;
  copiedFrom?: Maybe<Scalars['ObjectID']['output']>;
  copyAnswersActionItemId?: Maybe<Scalars['ObjectID']['output']>;
  /** ffmAlias: auditId */
  id: Scalars['ObjectID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAssigned?: Maybe<Scalars['Boolean']['output']>;
  languages: Array<Scalars['String']['output']>;
  name: Scalars['JSON']['output'];
  organizations: Array<Organization>;
};

export type AuditCopyAnswersActionItemIdArgs = {
  actionItemId: Scalars['ObjectID']['input'];
};

export type AuditAnswer = {
  item: AuditItem;
  questionGroupId?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type AuditAnswerInput = {
  itemId: Scalars['ObjectID']['input'];
  questionGroupId?: InputMaybe<Scalars['Int']['input']>;
  yesNoAnswer?: InputMaybe<AuditYesNoAnswerInput>;
};

export type AuditFilter = {
  _and?: InputMaybe<Array<InputMaybe<AuditFilter>>>;
  _or?: InputMaybe<Array<InputMaybe<AuditFilter>>>;
  author_personId?: InputMaybe<BigIntFilter>;
  isActive?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<TranslationFilter>;
  organizations_organizationId?: InputMaybe<BigIntFilter>;
};

export type AuditInput = {
  additionalPersons?: InputMaybe<Array<PersonReferenceInput>>;
  allowMultipleAssignments?: InputMaybe<Scalars['Boolean']['input']>;
  children?: InputMaybe<Array<AuditItemInput>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['JSON']['input']>;
  organizations?: InputMaybe<Array<OrganizationReferenceInput>>;
};

export type AuditItem = {
  /** ffmAlias: itemId */
  id: Scalars['ObjectID']['output'];
  kind: AuditItemKind;
  score?: Maybe<Scalars['Float']['output']>;
};

export type AuditItemInput = {
  allowComment?: InputMaybe<Scalars['Boolean']['input']>;
  allowFollowUp?: InputMaybe<Scalars['Boolean']['input']>;
  allowNotApplicable?: InputMaybe<Scalars['Boolean']['input']>;
  allowUpload?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['JSON']['input']>;
  dynamicMax?: InputMaybe<Scalars['Int']['input']>;
  dynamicMin?: InputMaybe<Scalars['Int']['input']>;
  dynamicTitle?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ObjectID']['input'];
  isCopied?: InputMaybe<Scalars['Boolean']['input']>;
  isDynamic?: InputMaybe<Scalars['Boolean']['input']>;
  kind: AuditItemKind;
  level?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Scalars['JSON']['input']>;
  requireComment?: InputMaybe<Scalars['Boolean']['input']>;
  requireUpload?: InputMaybe<Scalars['Boolean']['input']>;
  score?: InputMaybe<Scalars['Float']['input']>;
  scoreCountValue?: InputMaybe<YesNoScoreValue>;
  title?: InputMaybe<Scalars['JSON']['input']>;
};

export enum AuditItemKind {
  AuditSection = 'AuditSection',
  AuditYesNoQuestion = 'AuditYesNoQuestion',
}

export type AuditSection = AuditItem & {
  __typename?: 'AuditSection';
  dynamicMax?: Maybe<Scalars['Int']['output']>;
  dynamicMin?: Maybe<Scalars['Int']['output']>;
  dynamicTitle?: Maybe<Scalars['JSON']['output']>;
  /** ffmAlias: itemId */
  id: Scalars['ObjectID']['output'];
  isDynamic?: Maybe<Scalars['Boolean']['output']>;
  kind: AuditItemKind;
  level: Scalars['Int']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  title: Scalars['JSON']['output'];
};

export type AuditSort = {
  author_name?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type AuditYesNoAnswer = AuditAnswer & {
  __typename?: 'AuditYesNoAnswer';
  answer?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  followUp?: Maybe<Scalars['Boolean']['output']>;
  item: AuditItem;
  questionGroupId?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  uploads?: Maybe<Array<Attachment>>;
};

export type AuditYesNoAnswerInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  followUp?: InputMaybe<Scalars['Boolean']['input']>;
  score?: InputMaybe<Scalars['Float']['input']>;
  uploads?: InputMaybe<Array<AttachmentInput>>;
};

export type AuditYesNoQuestion = AuditItem & {
  __typename?: 'AuditYesNoQuestion';
  allowComment?: Maybe<Scalars['Boolean']['output']>;
  allowFollowUp?: Maybe<Scalars['Boolean']['output']>;
  allowNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  allowUpload?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['JSON']['output']>;
  /** ffmAlias: itemId */
  id: Scalars['ObjectID']['output'];
  isCopied?: Maybe<Scalars['Boolean']['output']>;
  kind: AuditItemKind;
  question: Scalars['JSON']['output'];
  requireComment?: Maybe<Scalars['Boolean']['output']>;
  requireUpload?: Maybe<Scalars['Boolean']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  scoreCountValue?: Maybe<YesNoScoreValue>;
};

export type BigIntFilter = {
  _eq?: InputMaybe<Scalars['BigInt']['input']>;
  _gt?: InputMaybe<Scalars['BigInt']['input']>;
  _gte?: InputMaybe<Scalars['BigInt']['input']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
  _lt?: InputMaybe<Scalars['BigInt']['input']>;
  _lte?: InputMaybe<Scalars['BigInt']['input']>;
  _ne?: InputMaybe<Scalars['BigInt']['input']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
};

export type BooleanFilter = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _exists?: InputMaybe<Scalars['Boolean']['input']>;
  _ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BroadcastActionItemInput = {
  actionItem: ActionItemInput;
  agenda?: InputMaybe<MeetingAgendaInput>;
  recipients: Array<ActionItemRecipientInput>;
};

export type CalculateSurveyResultInput = {
  answeredFor: Scalars['BigInt']['input'];
  surveyId: Scalars['ObjectID']['input'];
};

export type CalculateSurveyResultsInput = {
  answeredFors: Array<Scalars['BigInt']['input']>;
  surveyId: Scalars['ObjectID']['input'];
};

export type Category = {
  __typename?: 'Category';
  groups?: Maybe<Array<GroupReference>>;
  /** ffmAlias: categoryId */
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  persons?: Maybe<Array<PersonReference>>;
  translations?: Maybe<Scalars['JSON']['output']>;
};

export type CategoryCreationInput = {
  groups?: InputMaybe<Array<GroupReferenceInput>>;
  name: Scalars['String']['input'];
  persons?: InputMaybe<Array<PersonReferenceInput>>;
  translations?: InputMaybe<Scalars['JSON']['input']>;
};

export type CategoryReference = {
  __typename?: 'CategoryReference';
  categoryId: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
};

export type CategoryReferenceInput = {
  categoryId: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
};

export type CategoryUpdateInput = {
  groups?: InputMaybe<Array<GroupReferenceInput>>;
  name: Scalars['String']['input'];
  persons?: InputMaybe<Array<PersonReferenceInput>>;
  translations?: InputMaybe<Scalars['JSON']['input']>;
};

export type Changelog = {
  __typename?: 'Changelog';
  _id?: Maybe<Scalars['ObjectID']['output']>;
  patch?: Maybe<Scalars['JSON']['output']>;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
};

export type ChangelogFilter = {
  _and?: InputMaybe<Array<InputMaybe<ChangelogFilter>>>;
  _elemMatch?: InputMaybe<ChangelogFilter>;
  _or?: InputMaybe<Array<InputMaybe<ChangelogFilter>>>;
  patch?: InputMaybe<PatchFilter>;
  timestamp?: InputMaybe<DateTimeFilter>;
};

export enum ChartEntity {
  ActionItem = 'ActionItem',
  Meeting = 'Meeting',
}

export type ChartFilter = {
  ActionItem?: InputMaybe<ActionItemFilter>;
  Meeting?: InputMaybe<MeetingFilter>;
};

export enum ChartGroupProperty {
  ApproverPersonId = 'approver_personId',
  AttendeesPersonId = 'attendees_personId',
  AuthorPersonId = 'author_personId',
  CategoriesCategoryId = 'categories_categoryId',
  CoachingModulesCoachingModuleId = 'coachingModules_coachingModuleId',
  ConsultingMeetingTypeConsultingMeetingTypeId = 'consultingMeetingType_consultingMeetingTypeId',
  KpiUnit = 'kpiUnit',
  OrganizationOrganizationId = 'organization_organizationId',
  Priority = 'priority',
  ProfitPotentialCurrency = 'profitPotentialCurrency',
  ResponsiblePersonId = 'responsible_personId',
  Status = 'status',
  StrategyImpactId = 'strategyImpactId',
  Tags = 'tags',
  Type = 'type',
}

export type ChartProperties = {
  groupProperty?: InputMaybe<Array<InputMaybe<ChartGroupProperty>>>;
  valueAggregation?: InputMaybe<ChartValueAggregation>;
  valueProperty?: InputMaybe<Array<InputMaybe<ChartValueProperty>>>;
};

export enum ChartType {
  BarsHorizontal = 'BarsHorizontal',
  BarsVertical = 'BarsVertical',
  Sunburst = 'Sunburst',
}

export enum ChartValueAggregation {
  Hierarchy = 'hierarchy',
  StackedSum = 'stackedSum',
  Sum = 'sum',
}

export enum ChartValueProperty {
  AggregateCount = 'aggregate_count',
  CoachingDays = 'coachingDays',
  KpiActualValue = 'kpiActualValue',
  KpiInitialValue = 'kpiInitialValue',
  KpiTargetValue = 'kpiTargetValue',
  ProfitPotentialIdentified = 'profitPotentialIdentified',
  ProfitPotentialRealized = 'profitPotentialRealized',
}

export type Choice = {
  __typename?: 'Choice';
  choice?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ObjectID']['output'];
};

export type ChoiceInput = {
  choice: Scalars['JSON']['input'];
  id: Scalars['ObjectID']['input'];
};

export enum ChoiceType {
  Multiple = 'Multiple',
  Single = 'Single',
}

export type CoachingModule = {
  __typename?: 'CoachingModule';
  categoryIds?: Maybe<Array<Scalars['ObjectID']['output']>>;
  consultingMeetingTypeIds?: Maybe<Array<Scalars['ObjectID']['output']>>;
  /** ffmAlias: coachingModuleId */
  id: Scalars['ObjectID']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  meetingKind: MeetingKind;
  name: Scalars['String']['output'];
  organizationIds?: Maybe<Array<Scalars['BigInt']['output']>>;
  /** @deprecated Use consultingMeetingTypeIds instead */
  pbeMeetingTypeIds?: Maybe<Array<Scalars['ObjectID']['output']>>;
  translations?: Maybe<Scalars['JSON']['output']>;
};

export type CoachingModuleCreateInput = {
  categoryIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  consultingMeetingTypeIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  id?: InputMaybe<Scalars['ObjectID']['input']>;
  meetingKind: MeetingKind;
  name: Scalars['String']['input'];
  organizationIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  translations?: InputMaybe<Scalars['JSON']['input']>;
};

export type CoachingModuleReferenceInput = {
  coachingModuleId: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
};

export type CoachingModuleUpdateInput = {
  categoryIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  consultingMeetingTypeIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  meetingKind: MeetingKind;
  name: Scalars['String']['input'];
  organizationIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  translations?: InputMaybe<Scalars['JSON']['input']>;
};

export type Comment = {
  __typename?: 'Comment';
  attachments?: Maybe<Array<Attachment>>;
  author: PersonReference;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  text: Scalars['String']['output'];
};

export type CommentInput = {
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  id?: InputMaybe<Scalars['ObjectID']['input']>;
  text: Scalars['String']['input'];
};

export type CommentUpdateInput = {
  id: Scalars['ObjectID']['input'];
  text: Scalars['String']['input'];
};

export type ConsultingMeetingType = {
  __typename?: 'ConsultingMeetingType';
  /** ffmAlias: consultingMeetingTypeId */
  id: Scalars['ObjectID']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  meetingKind: MeetingKind;
  name: Scalars['String']['output'];
  organizationIds?: Maybe<Array<Scalars['BigInt']['output']>>;
  requiresCoachingModules?: Maybe<Scalars['Boolean']['output']>;
  translations?: Maybe<Scalars['JSON']['output']>;
};

export type ConsultingMeetingTypeCreateInput = {
  id?: InputMaybe<Scalars['ObjectID']['input']>;
  meetingKind: MeetingKind;
  name: Scalars['String']['input'];
  organizationIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  requiresCoachingModules?: InputMaybe<Scalars['Boolean']['input']>;
  translations?: InputMaybe<Scalars['JSON']['input']>;
};

export type ConsultingMeetingTypeReferenceInput = {
  consultingMeetingTypeId: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
};

export type ConsultingMeetingTypeUpdateInput = {
  meetingKind: MeetingKind;
  name: Scalars['String']['input'];
  organizationIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  requiresCoachingModules?: InputMaybe<Scalars['Boolean']['input']>;
  translations?: InputMaybe<Scalars['JSON']['input']>;
};

export type CopyActionItemAttachmentsInput = {
  actionItemId: Scalars['ObjectID']['input'];
};

export type CopyAgendaItemAttachmentsInput = {
  agendaItemId: Scalars['Int']['input'];
  meetingId: Scalars['ObjectID']['input'];
};

export type CopyMeetingAttachmentsInput = {
  meetingId: Scalars['ObjectID']['input'];
};

export type CountryFilter = {
  market?: InputMaybe<Scalars['String']['input']>;
};

export type CreateActionItemWidgetInput = {
  copyAnswersId?: InputMaybe<Scalars['ObjectID']['input']>;
  id: Scalars['ObjectID']['input'];
  kind: WidgetKind;
};

export type CreateDashboardEmailNotificationInput = {
  adhoc?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateFeatureArticleInput = {
  apps?: InputMaybe<Array<Scalars['String']['input']>>;
  article: Scalars['String']['input'];
  author?: InputMaybe<Scalars['BigInt']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  organizations?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  publishedAt?: InputMaybe<Scalars['Date']['input']>;
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  title: Scalars['String']['input'];
};

export type CreateFieldForceManagementEmailNotificationInput = {
  adhoc?: InputMaybe<Scalars['Boolean']['input']>;
  cron?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<FieldForceManagementEmailNotificationOptionInput>;
  topic: FieldForceManagementEmailNotificationTopic;
};

export type CreateFilterPreset = {
  filter: Scalars['JSON']['input'];
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  kind: FilterKind;
  name: Scalars['String']['input'];
};

export type CreateFilterPresetInput = {
  personId: Scalars['BigInt']['input'];
  preset: CreateFilterPreset;
};

export type CreateHelpArticleInput = {
  article?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  id: Scalars['ObjectID']['input'];
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  publishDate?: InputMaybe<Scalars['DateTime']['input']>;
  title: Scalars['String']['input'];
  type: HelpArticleType;
  visibleToRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateMultisensoryExperienceAreaInput = {
  format: MultisensoryExperienceFormat;
  name: Scalars['String']['input'];
};

export type CreateMultisensoryExperienceEmailNotificationInput = {
  adhoc?: InputMaybe<Scalars['Boolean']['input']>;
  cron?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMultisensoryExperienceFilterPresetInput = {
  filter?: InputMaybe<MultisensoryExperienceFilterPresetConfigInput>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type CreateMultisensoryExperienceInput = {
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  format: MultisensoryExperienceFormat;
  organizationId: Scalars['BigInt']['input'];
  plannedCompletion?: InputMaybe<Scalars['Date']['input']>;
  responsibleId: Scalars['BigInt']['input'];
  start?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<MultisensoryExperienceStatus>;
  title: Scalars['String']['input'];
};

export type CreateMultisensoryExperienceItemInput = {
  areaIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']['input']>>>;
  category?: InputMaybe<MultisensoryExperienceItemCategory>;
  currency?: InputMaybe<MultisensoryExperienceItemCurrency>;
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<AttachmentInput>;
  isActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
  supplier: Scalars['String']['input'];
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type CreateMultisensoryExperienceMeasureInput = {
  areaId: Scalars['ObjectID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  experienceId: Scalars['ObjectID']['input'];
  media?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
};

export type CreateMultisensoryExperienceMeasureTaskInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  description: Scalars['String']['input'];
  itemId?: InputMaybe<Scalars['ObjectID']['input']>;
  measureId: Scalars['ObjectID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<MultisensoryExperienceMeasureTaskStatus>;
};

export type CreateMysteryShoppingCriteriaInput = {
  checkId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organization: Scalars['BigInt']['input'];
  scenarioName: Scalars['String']['input'];
  score: Scalars['Float']['input'];
};

export type CreateMysteryShoppingImportUploadUrlInput = {
  id: Scalars['ObjectID']['input'];
};

export type CreateMysteryShoppingScenarioInput = {
  checkId: Scalars['String']['input'];
  dsjPointsMax?: InputMaybe<Scalars['Float']['input']>;
  dsjPointsReached?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  organization: Scalars['BigInt']['input'];
  totalPointsMax: Scalars['Float']['input'];
  totalPointsReached: Scalars['Float']['input'];
  type: MysteryShoppingScenarioType;
};

export type CreateNetworkPlanningOrganizationInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  market: Scalars['String']['input'];
  name: Scalars['String']['input'];
  opening: Scalars['DateMonth']['input'];
  type: NetworkPlanningOrganizationType;
};

export type CreateOccasionRelatedApprovalInput = {
  adHocResponsibleId?: InputMaybe<Scalars['BigInt']['input']>;
  applicantIds: Array<Scalars['BigInt']['input']>;
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  comment: Scalars['String']['input'];
  date: Scalars['Date']['input'];
  reason: Reason;
  reports: Array<Scalars['String']['input']>;
};

export type CreatePorscheStrategy2030EmailNotificationInput = {
  adhoc?: InputMaybe<Scalars['Boolean']['input']>;
  cron?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<PorscheStrategy2030EmailNotificationOptionInput>;
  topic: PorscheStrategy2030EmailNotificationTopic;
};

export type CreateRetailDesignDocumentInput = {
  mandatory?: InputMaybe<Scalars['Boolean']['input']>;
  mandatoryForStatus?: InputMaybe<Array<RetailDesignProjectStatus>>;
  mandatoryOnlyForFormatIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  name: Scalars['String']['input'];
  translations?: InputMaybe<Scalars['JSON']['input']>;
};

export type CreateRetailDesignEmailNotificationInput = {
  adhoc?: InputMaybe<Scalars['Boolean']['input']>;
  cron?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<RetailDesignEmailNotificationOptionInput>;
  topic: RetailDesignEmailNotificationTopic;
};

export type CreateRetailDesignFilterPresetInput = {
  filter?: InputMaybe<RetailDesignFilterPresetConfigInput>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type CreateRetailDesignFormatInput = {
  /** @deprecated Use hasNoWorkshopThroughput instead */
  hasNoAftersalesThroughput?: InputMaybe<Scalars['Boolean']['input']>;
  hasNoBodyAndPaintThroughput?: InputMaybe<Scalars['Boolean']['input']>;
  hasNoNCSalesVolume?: InputMaybe<Scalars['Boolean']['input']>;
  hasNoWorkshopThroughput?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  needsAftersalesApproval?: InputMaybe<Scalars['Boolean']['input']>;
  translations?: InputMaybe<Scalars['JSON']['input']>;
};

export type CreateRetailDesignProjectInput = {
  attachments?: InputMaybe<
    Array<InputMaybe<RetailDesignProjectAttachmentInput>>
  >;
  city: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  expectedBodyAndPaintThroughputPerDay?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  expectedSalesVolumePerYear?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  expectedWorkshopThroughputPerDay?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  formatId: Scalars['ObjectID']['input'];
  organizationId: Scalars['BigInt']['input'];
  plannedConstructionCompletion?: InputMaybe<Scalars['Date']['input']>;
  plannedConstructionStart?: InputMaybe<Scalars['Date']['input']>;
  relationType?: InputMaybe<RetailDesignProjectRelationType>;
  status?: InputMaybe<RetailDesignProjectStatus>;
  street?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  typeId?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type CreateRetailDesignProjectTypeInput = {
  name: Scalars['String']['input'];
  translations?: InputMaybe<Scalars['JSON']['input']>;
};

export type CreateStrategyActionItemInput = {
  attachments: Array<AttachmentInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  kpiId: Scalars['ObjectID']['input'];
  okr: Scalars['String']['input'];
  organizationId: Scalars['BigInt']['input'];
  responsibleId: Scalars['BigInt']['input'];
  status: StrategyActionItemStatus;
  title: Scalars['String']['input'];
  workPackages: Array<StrategyActionItemWorkPackageInput>;
  year: Scalars['Int']['input'];
};

export type CreateStrategyFieldInput = {
  color: Scalars['String']['input'];
  icon?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organization: Scalars['BigInt']['input'];
  stage: Scalars['Int']['input'];
};

export type CreateStrategyKpiInput = {
  organization: Scalars['BigInt']['input'];
  stage: Scalars['Int']['input'];
  strategyField: Scalars['ObjectID']['input'];
  title: Scalars['String']['input'];
};

export type CreateStrategyStageInput = {
  visibleForDealer: Scalars['Date']['input'];
  visibleForManager: Scalars['Date']['input'];
  year: Scalars['Int']['input'];
};

export type CreateSupportRequestInput = {
  application: Scalars['String']['input'];
  attachments?: InputMaybe<Array<AttachmentInput>>;
  description: Scalars['String']['input'];
  details?: InputMaybe<SupportRequestDetailsInput>;
  organizationId: Scalars['BigInt']['input'];
  referencePersonId?: InputMaybe<Scalars['BigInt']['input']>;
  title: Scalars['String']['input'];
};

export type CreateSurveyEmailNotificationInput = {
  adhoc?: InputMaybe<Scalars['Boolean']['input']>;
  cron?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<SurveyEmailNotificationOptionInput>;
  topic: SurveyEmailNotificationTopic;
};

export type CreateSurveyEntryValueUnitInput = {
  id: Scalars['ObjectID']['input'];
  nameLocalizations: Array<LocalizedStringInput>;
  type: SurveyEntryValueUnitType;
};

export type CreateSurveyInput = {
  answersAllowedByIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  canCreateActionItems: Scalars['Boolean']['input'];
  color?: InputMaybe<Scalars['String']['input']>;
  effectiveFrom: Scalars['Date']['input'];
  effectiveTo?: InputMaybe<Scalars['Date']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  items: Array<CreateSurveyItemInput>;
  languages: Array<Scalars['String']['input']>;
  nameLocalizations: Array<LocalizedStringInput>;
  organizationIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  personIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  typeId: Scalars['ObjectID']['input'];
  weight: Scalars['Int']['input'];
};

export type CreateSurveyItemInput = {
  allowComment?: InputMaybe<Scalars['Boolean']['input']>;
  allowMultiple?: InputMaybe<Scalars['Boolean']['input']>;
  allowNotApplicable?: InputMaybe<Scalars['Boolean']['input']>;
  allowUpload?: InputMaybe<Scalars['Boolean']['input']>;
  attachmentsLocalizations?: InputMaybe<Array<LocalizedAttachmentsInput>>;
  commentRequired?: InputMaybe<SurveyYesNoQuestionRequiredFieldInput>;
  /** @deprecated Field no longer supported */
  dependingOnQuestionAnswer?: InputMaybe<Scalars['String']['input']>;
  dependingOnQuestionAnswers?: InputMaybe<Array<Scalars['String']['input']>>;
  dependingOnQuestionId?: InputMaybe<Scalars['ObjectID']['input']>;
  depth: Scalars['Int']['input'];
  descriptionLocalizations?: InputMaybe<Array<LocalizedStringInput>>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  entries?: InputMaybe<Array<CreateSurveyMultipleEntryQuestionEntryInput>>;
  entryValueUnit?: InputMaybe<CreateSurveyEntryValueUnitInput>;
  id: Scalars['ObjectID']['input'];
  kind: SurveyItemKind;
  nameLocalizations: Array<LocalizedStringInput>;
  options?: InputMaybe<Array<CreateSurveyMultipleChoiceQuestionOptionInput>>;
  uploadRequired?: InputMaybe<SurveyYesNoQuestionRequiredFieldInput>;
  valueUnit?: InputMaybe<CreateSurveyValueUnitInput>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  weightNo?: InputMaybe<Scalars['Int']['input']>;
  weightYes?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateSurveyMultipleChoiceQuestionOptionInput = {
  id: Scalars['ObjectID']['input'];
  nameLocalizations: Array<LocalizedStringInput>;
  weight: Scalars['Int']['input'];
};

export type CreateSurveyMultipleEntryQuestionEntryInput = {
  id: Scalars['ObjectID']['input'];
  nameLocalizations: Array<LocalizedStringInput>;
};

export type CreateSurveyReportConfigChartInput = {
  aggregationLevel?: InputMaybe<SurveyReportAggregationLevel>;
  cutoffDate?: InputMaybe<Scalars['Date']['input']>;
  onlyIncludeOrganizationsWithAnswers?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<SortOrder>;
  orderBy?: InputMaybe<SurveyReportOrderBy>;
  organizationIds: Array<Scalars['BigInt']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  surveyIds: Array<Scalars['ObjectID']['input']>;
  surveyItemIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  title: Scalars['String']['input'];
  type: SurveyReportChartType;
};

export type CreateSurveyReportConfigInput = {
  name: Scalars['String']['input'];
  sections: Array<CreateSurveyReportConfigSectionInput>;
};

export type CreateSurveyReportConfigSectionInput = {
  charts: Array<CreateSurveyReportConfigChartInput>;
  width: Scalars['Int']['input'];
};

export type CreateSurveyTypeInput = {
  nameLocalizations: Array<LocalizedStringInput>;
};

export type CreateSurveyValueUnitInput = {
  id: Scalars['ObjectID']['input'];
  nameLocalizations: Array<LocalizedStringInput>;
};

export type CreateUserManagementItemInput = {
  additionalOrganizationIds: Array<Scalars['BigInt']['input']>;
  application: Scalars['String']['input'];
  personId: Scalars['BigInt']['input'];
};

export enum Currency {
  Aud = 'AUD',
  Brl = 'BRL',
  Cad = 'CAD',
  Chf = 'CHF',
  Cny = 'CNY',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Jpy = 'JPY',
  Krw = 'KRW',
  Nok = 'NOK',
  Ntd = 'NTD',
  Pln = 'PLN',
  Rub = 'RUB',
  Sek = 'SEK',
  Usd = 'USD',
}

export type CustomReportingData = {
  __typename?: 'CustomReportingData';
  keyNames: Array<Scalars['String']['output']>;
  keys: Array<Scalars['String']['output']>;
  meta?: Maybe<Scalars['JSON']['output']>;
  stackedKeyNames?: Maybe<Scalars['JSON']['output']>;
  stackedKeys?: Maybe<Array<Scalars['String']['output']>>;
  stackedValues?: Maybe<Scalars['JSON']['output']>;
  /** @deprecated Field no longer supported */
  value: Array<Scalars['Float']['output']>;
  values: Array<Scalars['Float']['output']>;
};

export type DashboardEmailNotification = {
  __typename?: 'DashboardEmailNotification';
  adhoc?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ObjectID']['output'];
  person: Person;
};

export type DateTimeFilter = {
  _eq?: InputMaybe<Scalars['DateTime']['input']>;
  _exists?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['DateTime']['input']>;
  _gte?: InputMaybe<Scalars['DateTime']['input']>;
  _lt?: InputMaybe<Scalars['DateTime']['input']>;
  _lte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeleteStrategyActionItemInput = {
  id: Scalars['ObjectID']['input'];
};

export type DeleteStrategyFieldInput = {
  id: Scalars['ObjectID']['input'];
};

export type DeleteStrategyKpiInput = {
  id: Scalars['ObjectID']['input'];
};

export type DeleteStrategyStageInput = {
  id: Scalars['ObjectID']['input'];
};

export enum DownloadReference {
  /** @deprecated Use 'FieldForceManagement' instead */
  ActionItem = 'ActionItem',
  Dashboard = 'Dashboard',
  FieldForceManagement = 'FieldForceManagement',
  Greenland = 'Greenland',
  MultisensoryExperience = 'MultisensoryExperience',
  /** @deprecated Use 'Greenland' instead */
  OccasionRelatedApproval = 'OccasionRelatedApproval',
  PorscheStrategy2030 = 'PorscheStrategy2030',
  RetailDesign = 'RetailDesign',
  Survey = 'Survey',
}

export enum DownloadType {
  Attachment = 'Attachment',
  Image = 'Image',
  Video = 'Video',
}

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  /** ffmAlias: exchangeRateId */
  id: Scalars['ObjectID']['output'];
  rates: Scalars['ExchangeRates']['output'];
  year: Scalars['Int']['output'];
};

export type ExchangeRateInput = {
  rates: Scalars['ExchangeRates']['input'];
  year: Scalars['Int']['input'];
};

export type FeatureArticle = {
  __typename?: 'FeatureArticle';
  apps: Array<Scalars['String']['output']>;
  article: Scalars['String']['output'];
  author: Person;
  id: Scalars['ObjectID']['output'];
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  organizations: Array<Organization>;
  publishedAt?: Maybe<Scalars['Date']['output']>;
  roles: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type FieldForceManagementAbility = {
  __typename?: 'FieldForceManagementAbility';
  checkMeeting: FieldForceManagementAbilityCheckMeetingResult;
  rules: Array<Scalars['AbilityRule']['output']>;
};

export type FieldForceManagementAbilityCheckMeetingArgs = {
  id: Scalars['ObjectID']['input'];
  includeActionItems?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FieldForceManagementAbilityCheckActionItemResult = {
  __typename?: 'FieldForceManagementAbilityCheckActionItemResult';
  delete: AbilityCheckResult;
  read: AbilityCheckResult;
  subject: Scalars['String']['output'];
  update: AbilityCheckResult;
};

export type FieldForceManagementAbilityCheckMeetingResult = {
  __typename?: 'FieldForceManagementAbilityCheckMeetingResult';
  actionItems?: Maybe<Array<FieldForceManagementAbilityCheckActionItemResult>>;
  delete: AbilityCheckResult;
  read: AbilityCheckResult;
  subject: Scalars['String']['output'];
  update: AbilityCheckResult;
};

export type FieldForceManagementCalendarExport = {
  __typename?: 'FieldForceManagementCalendarExport';
  id: Scalars['ObjectID']['output'];
  owner: Person;
  path: Scalars['String']['output'];
};

export type FieldForceManagementDashboardWidget = {
  __typename?: 'FieldForceManagementDashboardWidget';
  id: Scalars['ObjectID']['output'];
  settings: FieldForceManagementDashboardWidgetSettings;
  type: FieldForceManagementDashboardWidgetType;
};

export type FieldForceManagementDashboardWidgetPbeGrossProfitPotentialIdentifiedSettings =
  {
    __typename?: 'FieldForceManagementDashboardWidgetPbeGrossProfitPotentialIdentifiedSettings';
    endDate: Scalars['DateTime']['output'];
    organizationIds: Array<Scalars['BigInt']['output']>;
    startDate: Scalars['DateTime']['output'];
  };

export type FieldForceManagementDashboardWidgetPbeGrossProfitPotentialIdentifiedSettingsInput =
  {
    endDate: Scalars['DateTime']['input'];
    organizationIds: Array<Scalars['BigInt']['input']>;
    startDate: Scalars['DateTime']['input'];
  };

export type FieldForceManagementDashboardWidgetPbeStatusOverviewSettings = {
  __typename?: 'FieldForceManagementDashboardWidgetPbeStatusOverviewSettings';
  actionItemStatus: Array<ActionItemStatus>;
  organizationIds: Array<Scalars['BigInt']['output']>;
};

export type FieldForceManagementDashboardWidgetPbeStatusOverviewSettingsInput =
  {
    actionItemStatus: Array<ActionItemStatus>;
    organizationIds: Array<Scalars['BigInt']['input']>;
  };

export type FieldForceManagementDashboardWidgetSettings =
  | FieldForceManagementDashboardWidgetPbeGrossProfitPotentialIdentifiedSettings
  | FieldForceManagementDashboardWidgetPbeStatusOverviewSettings;

export type FieldForceManagementDashboardWidgetSettingsInput = {
  pbeGrossProfitPotentialIdentified?: InputMaybe<FieldForceManagementDashboardWidgetPbeGrossProfitPotentialIdentifiedSettingsInput>;
  pbeStatusOverview?: InputMaybe<FieldForceManagementDashboardWidgetPbeStatusOverviewSettingsInput>;
};

export enum FieldForceManagementDashboardWidgetType {
  PbeGrossProfitPotentialIdentified = 'PBE_GROSS_PROFIT_POTENTIAL_IDENTIFIED',
  PbeStatusOverview = 'PBE_STATUS_OVERVIEW',
}

export type FieldForceManagementEmailNotification = {
  __typename?: 'FieldForceManagementEmailNotification';
  adhoc?: Maybe<Scalars['Boolean']['output']>;
  cron?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  options?: Maybe<FieldForceManagementEmailNotificationOption>;
  person: Person;
  topic: FieldForceManagementEmailNotificationTopic;
};

export type FieldForceManagementEmailNotificationOption = {
  __typename?: 'FieldForceManagementEmailNotificationOption';
  actionItemAssignedAsResponsible?: Maybe<Scalars['Boolean']['output']>;
  actionItemCommentedOn?: Maybe<Scalars['Boolean']['output']>;
  actionItemDone?: Maybe<Scalars['Boolean']['output']>;
  actionItemDueDateChanged?: Maybe<Scalars['Boolean']['output']>;
  actionItemFinished?: Maybe<Scalars['Boolean']['output']>;
  includeMyChanges?: Maybe<Scalars['Boolean']['output']>;
  meetingAddedAsAttendee?: Maybe<Scalars['Boolean']['output']>;
  meetingApprovalRejected?: Maybe<Scalars['Boolean']['output']>;
  meetingCommentedOn?: Maybe<Scalars['Boolean']['output']>;
  meetingCreated?: Maybe<Scalars['Boolean']['output']>;
  meetingFinished?: Maybe<Scalars['Boolean']['output']>;
  meetingRescheduled?: Maybe<Scalars['Boolean']['output']>;
  meetingSentForApproval?: Maybe<Scalars['Boolean']['output']>;
  organizationIds?: Maybe<Array<Scalars['BigInt']['output']>>;
  personIds?: Maybe<Array<Scalars['BigInt']['output']>>;
};

export type FieldForceManagementEmailNotificationOptionInput = {
  actionItemAssignedAsResponsible?: InputMaybe<Scalars['Boolean']['input']>;
  actionItemCommentedOn?: InputMaybe<Scalars['Boolean']['input']>;
  actionItemDone?: InputMaybe<Scalars['Boolean']['input']>;
  actionItemDueDateChanged?: InputMaybe<Scalars['Boolean']['input']>;
  actionItemFinished?: InputMaybe<Scalars['Boolean']['input']>;
  includeMyChanges?: InputMaybe<Scalars['Boolean']['input']>;
  meetingAddedAsAttendee?: InputMaybe<Scalars['Boolean']['input']>;
  meetingApprovalRejected?: InputMaybe<Scalars['Boolean']['input']>;
  meetingCommentedOn?: InputMaybe<Scalars['Boolean']['input']>;
  meetingCreated?: InputMaybe<Scalars['Boolean']['input']>;
  meetingFinished?: InputMaybe<Scalars['Boolean']['input']>;
  meetingRescheduled?: InputMaybe<Scalars['Boolean']['input']>;
  meetingSentForApproval?: InputMaybe<Scalars['Boolean']['input']>;
  organizationIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  personIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export enum FieldForceManagementEmailNotificationTopic {
  FieldForceManagementEvents = 'FIELD_FORCE_MANAGEMENT_EVENTS',
  FieldForceManagementFilterBasedEvents = 'FIELD_FORCE_MANAGEMENT_FILTER_BASED_EVENTS',
  FieldForceManagementReminders = 'FIELD_FORCE_MANAGEMENT_REMINDERS',
  FieldForceManagementSummary = 'FIELD_FORCE_MANAGEMENT_SUMMARY',
}

export type FieldForceManagementFeatureFlag = {
  __typename?: 'FieldForceManagementFeatureFlag';
  id: Scalars['ObjectID']['output'];
  name: FieldForceManagementFeatureFlagName;
  organizations: Array<Organization>;
  persons: Array<Person>;
};

export enum FieldForceManagementFeatureFlagName {
  ActionItemsHaveNoDoneStatus = 'ACTION_ITEMS_HAVE_NO_DONE_STATUS',
  ApprovalProcess = 'APPROVAL_PROCESS',
  ApprovalProcessWithSelfApproval = 'APPROVAL_PROCESS_WITH_SELF_APPROVAL',
  Audit = 'AUDIT',
  CalendarExport = 'CALENDAR_EXPORT',
  ConvertCurrency = 'CONVERT_CURRENCY',
  DealerCanFinishActionItems = 'DEALER_CAN_FINISH_ACTION_ITEMS',
  EmailNotifications = 'EMAIL_NOTIFICATIONS',
  FilterBasedNotifications = 'FILTER_BASED_NOTIFICATIONS',
  IrisDashboard = 'IRIS_DASHBOARD',
  LegacyMeetings = 'LEGACY_MEETINGS',
  ManageQuestionnaires = 'MANAGE_QUESTIONNAIRES',
  PbeReportsWithConfidentials = 'PBE_REPORTS_WITH_CONFIDENTIALS',
  PscReportsWithConfidentials = 'PSC_REPORTS_WITH_CONFIDENTIALS',
  RoadToXReports = 'ROAD_TO_X_REPORTS',
  ShowRoadToXReporting = 'SHOW_ROAD_TO_X_REPORTING',
}

export type FilledInAudit = ActionItemWidget & {
  __typename?: 'FilledInAudit';
  actionItem: ActionItem;
  answers: Array<AuditAnswer>;
  audit: Audit;
  isRemoved?: Maybe<Scalars['Boolean']['output']>;
  kind: WidgetKind;
  updatedAt: Scalars['DateTime']['output'];
  widgetId: Scalars['ObjectID']['output'];
};

export type FilledInQuestionnaire = ActionItemWidget & {
  __typename?: 'FilledInQuestionnaire';
  actionItem: ActionItem;
  answers: Array<QuestionnaireAnswer>;
  isRemoved?: Maybe<Scalars['Boolean']['output']>;
  kind: WidgetKind;
  questionnaire: Questionnaire;
  updatedAt: Scalars['DateTime']['output'];
  widgetId: Scalars['ObjectID']['output'];
};

export type FilledInSurvey = ActionItemWidget & {
  __typename?: 'FilledInSurvey';
  isRemoved?: Maybe<Scalars['Boolean']['output']>;
  kind: WidgetKind;
  surveyId: Scalars['ObjectID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  widgetId: Scalars['ObjectID']['output'];
};

export enum FilterKind {
  ActionItem = 'actionItem',
  Meeting = 'meeting',
}

export type FilterPreset = {
  __typename?: 'FilterPreset';
  filter: Scalars['JSON']['output'];
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  kind: FilterKind;
  name: Scalars['String']['output'];
};

export type FilterPresetInput = {
  filter: Scalars['JSON']['input'];
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  kind: FilterKind;
  name: Scalars['String']['input'];
};

export type ForUserOptions = {
  onlyAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated Field no longer supported */
  withDeletionAndPseudonymizationMarker?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  /** @deprecated Field no longer supported */
  withDeletionMarker?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GeoRegionFilter = {
  ancestors?: InputMaybe<BigIntFilter>;
};

export type Group = {
  __typename?: 'Group';
  /** ffmAlias: groupId */
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  visibleGroups?: Maybe<Array<GroupReference>>;
};

export type GroupReference = {
  __typename?: 'GroupReference';
  groupId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GroupReferenceInput = {
  groupId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type HelpArticle = {
  __typename?: 'HelpArticle';
  article?: Maybe<Scalars['String']['output']>;
  attachments?: Maybe<Array<Attachment>>;
  author?: Maybe<Person>;
  /** ffmAlias: helpArticleId */
  id: Scalars['ObjectID']['output'];
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  publishDate?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  type: HelpArticleType;
  visibleToRoles?: Maybe<Array<Scalars['String']['output']>>;
};

export type HelpArticleSort = {
  isPublished?: InputMaybe<SortOrder>;
  publishDate?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export enum HelpArticleType {
  Faq = 'FAQ',
  QuickStartGuide = 'QUICK_START_GUIDE',
}

export type IdFilter = {
  _eq?: InputMaybe<Scalars['ID']['input']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type IntFilter = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _exists?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _ne?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type IntValueWithYear = {
  __typename?: 'IntValueWithYear';
  value?: Maybe<Scalars['Int']['output']>;
  year: Scalars['Int']['output'];
};

export type LegacyMeeting = {
  __typename?: 'LegacyMeeting';
  downloadLink?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  market: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organization: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type LegacyMeetingConnection = {
  __typename?: 'LegacyMeetingConnection';
  docs: Array<Maybe<LegacyMeeting>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type LegacyMeetingsFilterInput = {
  market?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type LegacyMeetingsInfo = {
  __typename?: 'LegacyMeetingsInfo';
  markets?: Maybe<Array<Scalars['String']['output']>>;
  organizations?: Maybe<Array<Scalars['String']['output']>>;
  years?: Maybe<Array<Scalars['Int']['output']>>;
};

export type LocalizedAttachments = {
  __typename?: 'LocalizedAttachments';
  locale: Scalars['String']['output'];
  value?: Maybe<Array<Attachment>>;
};

export type LocalizedAttachmentsInput = {
  locale: Scalars['String']['input'];
  value?: InputMaybe<Array<AttachmentInput>>;
};

export type LocalizedString = {
  __typename?: 'LocalizedString';
  locale: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type LocalizedStringInput = {
  locale: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MarketFilter = {
  region?: InputMaybe<Scalars['String']['input']>;
};

export type MaskedActionItem = {
  __typename?: 'MaskedActionItem';
  agendaItems?: Maybe<Array<AgendaItem>>;
  /** ffmAlias: actionItemId */
  id: Scalars['ObjectID']['output'];
  status: ActionItemStatus;
};

export type MaskedMeeting = {
  __typename?: 'MaskedMeeting';
  /** ffmAlias: meetingId */
  id: Scalars['ObjectID']['output'];
};

export type Meeting = {
  __typename?: 'Meeting';
  agenda?: Maybe<Array<AgendaItem>>;
  approver?: Maybe<PersonReference>;
  attachments?: Maybe<Array<Attachment>>;
  attendees?: Maybe<Array<Person>>;
  author: Person;
  categories?: Maybe<Array<Category>>;
  changelog?: Maybe<Array<Changelog>>;
  coachingModules?: Maybe<Array<CoachingModule>>;
  comments: Array<Comment>;
  consultants?: Maybe<Array<Person>>;
  consultingMeetingType?: Maybe<Array<ConsultingMeetingType>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  externals?: Maybe<Array<Scalars['String']['output']>>;
  /** ffmAlias: meetingId */
  id: Scalars['ObjectID']['output'];
  isConfidential?: Maybe<Scalars['Boolean']['output']>;
  kind?: Maybe<MeetingKind>;
  organization: Organization;
  pbeIrisLink?: Maybe<Scalars['String']['output']>;
  /** @deprecated Field no longer supported */
  pbeMeetingTypeDescription?: Maybe<Scalars['String']['output']>;
  peerGroup?: Maybe<Scalars['ObjectID']['output']>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  scheduledTimeEnd: Scalars['DateTime']['output'];
  scheduledTimeStart: Scalars['DateTime']['output'];
  status: MeetingStatus;
  statusChanges?: Maybe<Array<StatusChange>>;
  subject: Scalars['String']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
  type: MeetingType;
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['Int']['output'];
};

export type MeetingAgendaInput = {
  itemId: Scalars['Int']['input'];
  meetingId: Scalars['ObjectID']['input'];
};

export enum MeetingDistinctBy {
  Organization = 'organization',
}

export type MeetingFilter = {
  _and?: InputMaybe<Array<InputMaybe<MeetingFilter>>>;
  _id?: InputMaybe<IdFilter>;
  _or?: InputMaybe<Array<InputMaybe<MeetingFilter>>>;
  approver_personId?: InputMaybe<BigIntFilter>;
  attendees_personId?: InputMaybe<BigIntFilter>;
  author_personId?: InputMaybe<BigIntFilter>;
  categories_categoryId?: InputMaybe<ObjectIdFilter>;
  coachingModules_coachingModuleId?: InputMaybe<ObjectIdFilter>;
  consultants_personId?: InputMaybe<BigIntFilter>;
  consultingMeetingType_consultingMeetingTypeId?: InputMaybe<ObjectIdFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  isConfidential?: InputMaybe<BooleanFilter>;
  kind?: InputMaybe<MeetingKindFilter>;
  organization_geography_region?: InputMaybe<StringFilter>;
  organization_organizationId?: InputMaybe<BigIntFilter>;
  rootOrganizationIds?: InputMaybe<
    Array<InputMaybe<Scalars['BigInt']['input']>>
  >;
  scheduledTimeEnd?: InputMaybe<DateTimeFilter>;
  scheduledTimeStart?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<MeetingStatusFilter>;
  subject?: InputMaybe<StringFilter>;
  tags?: InputMaybe<StringArrayFilter>;
  type?: InputMaybe<MeetingTypeFilter>;
};

export enum MeetingKind {
  Pbe = 'PBE',
  Plt = 'PLT',
  Psc = 'PSC',
  Standard = 'STANDARD',
}

export type MeetingKindFilter = {
  _eq?: InputMaybe<MeetingKind>;
  _in?: InputMaybe<Array<InputMaybe<MeetingKind>>>;
};

export type MeetingReferenceInput = {
  meetingId: Scalars['ObjectID']['input'];
};

export type MeetingSort = {
  author_name?: InputMaybe<SortOrder>;
  categories?: InputMaybe<SortOrder>;
  consultingMeetingType?: InputMaybe<SortOrder>;
  kind?: InputMaybe<SortOrder>;
  organization_name?: InputMaybe<SortOrder>;
  scheduledTimeStart?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subject?: InputMaybe<SortOrder>;
  tags?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type MeetingStatistic = {
  __typename?: 'MeetingStatistic';
  count: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
};

export type MeetingStatisticInput = {
  dateFrom: Scalars['Date']['input'];
  dateTo: Scalars['Date']['input'];
  granularity: StatisticGranularity;
  organizationId: Scalars['BigInt']['input'];
};

export enum MeetingStatus {
  Finished = 'FINISHED',
  Open = 'OPEN',
  PendingApproval = 'PENDING_APPROVAL',
}

export type MeetingStatusFilter = {
  _eq?: InputMaybe<MeetingStatus>;
  _in?: InputMaybe<Array<InputMaybe<MeetingStatus>>>;
  _nin?: InputMaybe<Array<InputMaybe<MeetingStatus>>>;
};

export enum MeetingType {
  Other = 'Other',
  Phone = 'Phone',
  VirtualVisit = 'VirtualVisit',
  Visit = 'Visit',
}

export type MeetingTypeFilter = {
  _eq?: InputMaybe<MeetingType>;
  _in?: InputMaybe<Array<InputMaybe<MeetingType>>>;
};

export type ModifyActionItemWidgetsInput = {
  _pull?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  _push?: InputMaybe<Array<CreateActionItemWidgetInput>>;
};

export type ModifyAttachmentsInput = {
  _pull?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  _push?: InputMaybe<Array<AttachmentInput>>;
  _update?: InputMaybe<Array<UpdateAttachmentInput>>;
};

export type ModifyCommentsInput = {
  _pull?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  _push?: InputMaybe<Array<CommentInput>>;
  _update?: InputMaybe<Array<UpdateCommentInput>>;
};

export type ModifyImplementationImagesInput = {
  _move?: InputMaybe<Array<MoveArrayElementInput>>;
  _pull?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  _push?: InputMaybe<Array<AttachmentInput>>;
  _update?: InputMaybe<Array<UpdateAttachmentInput>>;
};

export type ModifyMediaInput = {
  _move?: InputMaybe<Array<MoveArrayElementInput>>;
  _pull?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  _push?: InputMaybe<Array<AttachmentInput>>;
  _update?: InputMaybe<Array<UpdateAttachmentInput>>;
};

export type ModifyMultisensoryExperienceMeasureTaskPlacementsInput = {
  _pull?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  _push?: InputMaybe<Array<MultisensoryExperienceMeasureTaskPlacementInput>>;
  _update?: InputMaybe<Array<MultisensoryExperienceMeasureTaskPlacementInput>>;
};

export type ModifyMysteryShoppingCriteriaActionItemIdsInput = {
  _push?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
};

export type ModifyRetailDesignProjectAttachmentInput = {
  _pull?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  _push?: InputMaybe<Array<RetailDesignProjectAttachmentInput>>;
  _update?: InputMaybe<Array<UpdateRetailDesignProjectAttachmentInput>>;
};

export type ModifySurveyReportConfigChartInput = {
  _move?: InputMaybe<Array<MoveSurveyReportConfigChartInput>>;
  _pull?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  _push?: InputMaybe<Array<CreateSurveyReportConfigChartInput>>;
  _update?: InputMaybe<Array<UpdateSurveyReportConfigChartInput>>;
};

export type ModifySurveyReportConfigSectionInput = {
  _move?: InputMaybe<Array<MoveSurveyReportConfigSectionInput>>;
  _pull?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  _push?: InputMaybe<Array<CreateSurveyReportConfigSectionInput>>;
  _update?: InputMaybe<Array<UpdateSurveyReportConfigSectionInput>>;
};

export type ModifyUserManagementItemOrganizationIdsInput = {
  _pull?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  _push?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export type ModifyWorkPackagesInput = {
  _pull?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  _push?: InputMaybe<Array<StrategyActionItemWorkPackageInput>>;
  _update?: InputMaybe<Array<UpdateStrategyActionItemWorkPackageInput>>;
};

export type MoveArrayElementInput = {
  fromIndex: Scalars['Int']['input'];
  toIndex: Scalars['Int']['input'];
};

export type MoveSurveyReportConfigChartInput = {
  fromIndex: Scalars['Int']['input'];
  toIndex: Scalars['Int']['input'];
};

export type MoveSurveyReportConfigSectionInput = {
  fromIndex: Scalars['Int']['input'];
  toIndex: Scalars['Int']['input'];
};

export type MultisensoryExperience = {
  __typename?: 'MultisensoryExperience';
  attachments: Array<Attachment>;
  author: Person;
  changeHistory: Array<MultisensoryExperienceChangeHistory>;
  comments: Array<Comment>;
  description?: Maybe<Scalars['String']['output']>;
  format: MultisensoryExperienceFormat;
  id: Scalars['ObjectID']['output'];
  market: Organization;
  measures: Array<MultisensoryExperienceMeasure>;
  organization: Organization;
  plannedCompletion?: Maybe<Scalars['Date']['output']>;
  responsible: Person;
  start?: Maybe<Scalars['Date']['output']>;
  status: MultisensoryExperienceStatus;
  taskCount: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type MultisensoryExperienceTaskCountArgs = {
  filter?: InputMaybe<TaskCountFilter>;
};

export type MultisensoryExperienceArea = {
  __typename?: 'MultisensoryExperienceArea';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  format: MultisensoryExperienceFormat;
  id: Scalars['ObjectID']['output'];
  isInUse: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type MultisensoryExperienceAreaFilter = {
  _and?: InputMaybe<Array<MultisensoryExperienceAreaFilter>>;
  _or?: InputMaybe<Array<MultisensoryExperienceAreaFilter>>;
  format?: InputMaybe<MultisensoryExperienceFormatFilter>;
  name?: InputMaybe<StringFilter>;
};

export type MultisensoryExperienceAreasInput = {
  filter?: InputMaybe<MultisensoryExperienceAreaFilter>;
};

export type MultisensoryExperienceChangeHistory = Patch & {
  __typename?: 'MultisensoryExperienceChangeHistory';
  date: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  meta: MultisensoryExperienceChangeHistoryMeta;
  ops: Scalars['JSON']['output'];
  person: Person;
  ref: Scalars['ObjectID']['output'];
};

export type MultisensoryExperienceChangeHistoryOpsArgs = {
  input?: InputMaybe<PatchOpsInput>;
};

export type MultisensoryExperienceChangeHistoryMeta = {
  __typename?: 'MultisensoryExperienceChangeHistoryMeta';
  entity: MultisensoryExperienceChangeHistoryMetaEntityType;
  experience?: Maybe<MultisensoryExperience>;
  measure?: Maybe<MultisensoryExperienceMeasure>;
  measureTask?: Maybe<MultisensoryExperienceMeasureTask>;
};

export enum MultisensoryExperienceChangeHistoryMetaEntityType {
  MultisensoryExperience = 'MultisensoryExperience',
  MultisensoryExperienceMeasure = 'MultisensoryExperienceMeasure',
  MultisensoryExperienceMeasureTask = 'MultisensoryExperienceMeasureTask',
}

export type MultisensoryExperienceEmailNotification = {
  __typename?: 'MultisensoryExperienceEmailNotification';
  adhoc?: Maybe<Scalars['Boolean']['output']>;
  cron?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  person: Person;
};

export type MultisensoryExperienceFilter = {
  _and?: InputMaybe<Array<MultisensoryExperienceFilter>>;
  _or?: InputMaybe<Array<MultisensoryExperienceFilter>>;
  author?: InputMaybe<BigIntFilter>;
  format?: InputMaybe<MultisensoryExperienceFormatFilter>;
  organization?: InputMaybe<BigIntFilter>;
  plannedCompletion?: InputMaybe<DateTimeFilter>;
  responsible?: InputMaybe<BigIntFilter>;
  start?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<MultisensoryExperienceStatusFilter>;
  title?: InputMaybe<StringFilter>;
};

export type MultisensoryExperienceFilterPreset = {
  __typename?: 'MultisensoryExperienceFilterPreset';
  filter: MultisensoryExperienceFilterPresetConfig;
  id: Scalars['ObjectID']['output'];
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export type MultisensoryExperienceFilterPresetConfig = {
  __typename?: 'MultisensoryExperienceFilterPresetConfig';
  author?: Maybe<Array<Scalars['BigInt']['output']>>;
  format?: Maybe<Array<MultisensoryExperienceFormat>>;
  onlyCreated?: Maybe<Scalars['Boolean']['output']>;
  organization?: Maybe<Array<Scalars['BigInt']['output']>>;
  plannedCompletionFrom?: Maybe<Scalars['DateTime']['output']>;
  plannedCompletionTo?: Maybe<Scalars['DateTime']['output']>;
  responsible?: Maybe<Array<Scalars['BigInt']['output']>>;
  startFrom?: Maybe<Scalars['DateTime']['output']>;
  startTo?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Array<MultisensoryExperienceStatus>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type MultisensoryExperienceFilterPresetConfigInput = {
  author?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  format?: InputMaybe<Array<MultisensoryExperienceFormat>>;
  onlyCreated?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  plannedCompletionFrom?: InputMaybe<Scalars['DateTime']['input']>;
  plannedCompletionTo?: InputMaybe<Scalars['DateTime']['input']>;
  responsible?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startFrom?: InputMaybe<Scalars['DateTime']['input']>;
  startTo?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Array<MultisensoryExperienceStatus>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum MultisensoryExperienceFormat {
  PorscheApprovedAndServiceCenter = 'PORSCHE_APPROVED_AND_SERVICE_CENTER',
  PorscheApprovedCenter = 'PORSCHE_APPROVED_CENTER',
  PorscheCenterCa2000 = 'PORSCHE_CENTER_CA2000',
  PorscheCenterDestinationPorsche = 'PORSCHE_CENTER_DESTINATION_PORSCHE',
  PorscheClassicCenter = 'PORSCHE_CLASSIC_CENTER',
  PorscheExperienceCenter = 'PORSCHE_EXPERIENCE_CENTER',
  PorscheExpressCityServiceCenter = 'PORSCHE_EXPRESS_CITY_SERVICE_CENTER',
  PorschePreOwnedCarCenter = 'PORSCHE_PRE_OWNED_CAR_CENTER',
  PorscheSalesPopUp = 'PORSCHE_SALES_POP_UP',
  PorscheServiceCenter = 'PORSCHE_SERVICE_CENTER',
  PorscheServiceFactory = 'PORSCHE_SERVICE_FACTORY',
  PorscheShowroom = 'PORSCHE_SHOWROOM',
  PorscheStore = 'PORSCHE_STORE',
  PorscheStudio = 'PORSCHE_STUDIO',
  SatellitePorscheCenter = 'SATELLITE_PORSCHE_CENTER',
}

export type MultisensoryExperienceFormatFilter = {
  _eq?: InputMaybe<MultisensoryExperienceFormat>;
  _in?: InputMaybe<Array<InputMaybe<MultisensoryExperienceFormat>>>;
};

export type MultisensoryExperienceItem = {
  __typename?: 'MultisensoryExperienceItem';
  areas: Array<MultisensoryExperienceArea>;
  category?: Maybe<MultisensoryExperienceItemCategory>;
  currency: MultisensoryExperienceItemCurrency;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  image?: Maybe<Attachment>;
  isActive: Scalars['Boolean']['output'];
  isInUse: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  referenceId?: Maybe<Scalars['String']['output']>;
  supplier: Scalars['String']['output'];
  url?: Maybe<Scalars['URL']['output']>;
};

export enum MultisensoryExperienceItemCategory {
  CoffeeEssentials = 'COFFEE_ESSENTIALS',
  CommunicationMaterials = 'COMMUNICATION_MATERIALS',
  Decoration = 'DECORATION',
  Divers = 'DIVERS',
  Furniture = 'FURNITURE',
  KitchenEssentials = 'KITCHEN_ESSENTIALS',
  Literature = 'LITERATURE',
  PlantsAndPlantpots = 'PLANTS_AND_PLANTPOTS',
  PlaytimeEssentials = 'PLAYTIME_ESSENTIALS',
  Scent = 'SCENT',
  Sound = 'SOUND',
  ToiletryHygieneEssentials = 'TOILETRY_HYGIENE_ESSENTIALS',
  WallDecoration = 'WALL_DECORATION',
}

export type MultisensoryExperienceItemCategoryFilter = {
  _eq?: InputMaybe<MultisensoryExperienceItemCategory>;
  _in?: InputMaybe<Array<InputMaybe<MultisensoryExperienceItemCategory>>>;
};

export enum MultisensoryExperienceItemCurrency {
  Eur = 'EUR',
}

export type MultisensoryExperienceItemFilter = {
  _and?: InputMaybe<Array<MultisensoryExperienceItemFilter>>;
  _or?: InputMaybe<Array<MultisensoryExperienceItemFilter>>;
  areas?: InputMaybe<ObjectIdFilter>;
  category?: InputMaybe<MultisensoryExperienceItemCategoryFilter>;
  isActive?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  supplier?: InputMaybe<StringFilter>;
};

export type MultisensoryExperienceItemsInput = {
  filter?: InputMaybe<MultisensoryExperienceItemFilter>;
};

export type MultisensoryExperienceMeasure = {
  __typename?: 'MultisensoryExperienceMeasure';
  area: MultisensoryExperienceArea;
  author: Person;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  experience: MultisensoryExperience;
  id: Scalars['ObjectID']['output'];
  implementationImages: Array<Attachment>;
  media: Array<Attachment>;
  tasks: Array<MultisensoryExperienceMeasureTask>;
};

export type MultisensoryExperienceMeasureFilter = {
  _and?: InputMaybe<Array<MultisensoryExperienceMeasureFilter>>;
  _or?: InputMaybe<Array<MultisensoryExperienceMeasureFilter>>;
  area?: InputMaybe<ObjectIdFilter>;
  author?: InputMaybe<BigIntFilter>;
  experience?: InputMaybe<ObjectIdFilter>;
};

export type MultisensoryExperienceMeasureTask = {
  __typename?: 'MultisensoryExperienceMeasureTask';
  amount?: Maybe<Scalars['Int']['output']>;
  comments: Array<Comment>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ObjectID']['output'];
  item?: Maybe<MultisensoryExperienceItem>;
  measure: MultisensoryExperienceMeasure;
  name: Scalars['String']['output'];
  placements: Array<MultisensoryExperienceMeasureTaskPlacement>;
  status: MultisensoryExperienceMeasureTaskStatus;
};

export type MultisensoryExperienceMeasureTaskFilter = {
  _and?: InputMaybe<Array<MultisensoryExperienceMeasureTaskFilter>>;
  _or?: InputMaybe<Array<MultisensoryExperienceMeasureTaskFilter>>;
  item?: InputMaybe<ObjectIdFilter>;
  measure?: InputMaybe<ObjectIdFilter>;
};

export type MultisensoryExperienceMeasureTaskPlacement = {
  __typename?: 'MultisensoryExperienceMeasureTaskPlacement';
  id: Scalars['ObjectID']['output'];
  mediaId: Scalars['ObjectID']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type MultisensoryExperienceMeasureTaskPlacementInput = {
  id?: InputMaybe<Scalars['ObjectID']['input']>;
  mediaId: Scalars['ObjectID']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export enum MultisensoryExperienceMeasureTaskStatus {
  Delivered = 'DELIVERED',
  Implemented = 'IMPLEMENTED',
  Open = 'OPEN',
  Ordered = 'ORDERED',
}

export type MultisensoryExperienceMeasureTaskStatusFilter = {
  _eq?: InputMaybe<MultisensoryExperienceMeasureTaskStatus>;
};

export type MultisensoryExperienceMeasureTasksInput = {
  filter?: InputMaybe<MultisensoryExperienceMeasureTaskFilter>;
};

export type MultisensoryExperienceMeasuresInput = {
  filter?: InputMaybe<MultisensoryExperienceMeasureFilter>;
};

export enum MultisensoryExperienceStatus {
  AcceptedByMarket = 'ACCEPTED_BY_MARKET',
  AcceptedByPag = 'ACCEPTED_BY_PAG',
  Draft = 'DRAFT',
  Implemented = 'IMPLEMENTED',
  InPagReview = 'IN_PAG_REVIEW',
  NeedsRevision = 'NEEDS_REVISION',
}

export type MultisensoryExperienceStatusFilter = {
  _eq?: InputMaybe<MultisensoryExperienceStatus>;
  _in?: InputMaybe<Array<InputMaybe<MultisensoryExperienceStatus>>>;
  _ne?: InputMaybe<MultisensoryExperienceStatus>;
};

export type MultisensoryExperiencesInput = {
  filter?: InputMaybe<MultisensoryExperienceFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** @deprecated Use updateActionItemWidgets instead */
  addAudit: FilledInAudit;
  /** @deprecated Field no longer supported */
  addFilterPreset?: Maybe<Scalars['Boolean']['output']>;
  addMeetingPbeIrisLink?: Maybe<Meeting>;
  addPbeOnlyOrg?: Maybe<Array<Person>>;
  addPeerGroupToPersons: Array<Person>;
  /** @deprecated Use updateActionItemWidgets instead */
  addQuestionnaire: FilledInQuestionnaire;
  answerAudit: FilledInAudit;
  answerQuestionnaire: FilledInQuestionnaire;
  answerSurveyQuestion: SurveyAnswer;
  assignGroups?: Maybe<Array<Group>>;
  assignLegacyMeetingsBucketToOrganization?: Maybe<
    Scalars['Boolean']['output']
  >;
  broadcastActionItem?: Maybe<Array<Maybe<ActionItem>>>;
  calculateSurveyResult?: Maybe<SurveyResult>;
  calculateSurveyResults: Array<SurveyResult>;
  commentOnActionItem: Comment;
  commentOnMeeting: Comment;
  copyAudit: Audit;
  copyQuestionnaire: Questionnaire;
  createActionItem?: Maybe<ActionItem>;
  createActionItemTemplate: ActionItemTemplate;
  createAudit: Audit;
  createCategory?: Maybe<Category>;
  createCoachingModule?: Maybe<CoachingModule>;
  createConsultingMeetingType?: Maybe<ConsultingMeetingType>;
  createDashboardEmailNotification: DashboardEmailNotification;
  createDownloadUrl: Scalars['String']['output'];
  createExchangeRate: ExchangeRate;
  createFeatureArticle: FeatureArticle;
  createFieldForceManagementCalendarExport: FieldForceManagementCalendarExport;
  createFieldForceManagementEmailNotification: FieldForceManagementEmailNotification;
  createFilterPreset?: Maybe<FilterPreset>;
  createHelpArticle: HelpArticle;
  createMeeting?: Maybe<Meeting>;
  createMultisensoryExperience: MultisensoryExperience;
  createMultisensoryExperienceArea: MultisensoryExperienceArea;
  createMultisensoryExperienceAreas: Array<MultisensoryExperienceArea>;
  createMultisensoryExperienceEmailNotification: MultisensoryExperienceEmailNotification;
  createMultisensoryExperienceFilterPreset: MultisensoryExperienceFilterPreset;
  createMultisensoryExperienceItem: MultisensoryExperienceItem;
  createMultisensoryExperienceMeasure: MultisensoryExperienceMeasure;
  createMultisensoryExperienceMeasureTask: MultisensoryExperienceMeasureTask;
  createMysteryShoppingImportUploadUrl: MysteryShoppingImportUploadUrl;
  createNetworkPlanningOrganization: NetworkPlanningOrganization;
  createOccasionRelatedApproval: OccasionRelatedApproval;
  createPbeIrisKpi?: Maybe<PbeIrisKpi>;
  createPeerGroup: PeerGroup;
  createPorscheStrategy2030EmailNotification: PorscheStrategy2030EmailNotification;
  createQuestionnaire: Questionnaire;
  createQuestionnaireTypeConfig?: Maybe<QuestionnaireTypeConfig>;
  createRetailDesignDocument: RetailDesignDocument;
  createRetailDesignEmailNotification: RetailDesignEmailNotification;
  createRetailDesignFilterPreset: RetailDesignFilterPreset;
  createRetailDesignFormat: RetailDesignFormat;
  createRetailDesignProject: RetailDesignProject;
  createRetailDesignProjectType: RetailDesignProjectType;
  createStrategyActionItem: StrategyActionItem;
  createStrategyField?: Maybe<StrategyField>;
  createStrategyImpact?: Maybe<StrategyImpact>;
  createStrategyKpi?: Maybe<StrategyKpi>;
  createStrategyStage?: Maybe<StrategyStage>;
  createSupportRequest: SupportRequest;
  createSurvey: Survey;
  createSurveyEmailNotification: SurveyEmailNotification;
  createSurveyReportConfig: SurveyReportConfig;
  createSurveyType: SurveyType;
  createUserManagementItem: UserManagementItem;
  deleteActionItem?: Maybe<ActionItem>;
  deleteActionItemTemplate: ActionItemTemplate;
  deleteCoachingModule?: Maybe<Scalars['Boolean']['output']>;
  deleteConsultingMeetingType?: Maybe<Scalars['Boolean']['output']>;
  deleteDashboardEmailNotification: DashboardEmailNotification;
  deleteExchangeRate: ExchangeRate;
  deleteFeatureArticle: FeatureArticle;
  deleteFieldForceManagementCalendarExport: FieldForceManagementCalendarExport;
  deleteFieldForceManagementEmailNotification: FieldForceManagementEmailNotification;
  deleteGroupAssignment: Group;
  deleteHelpArticle: HelpArticle;
  deleteMeeting?: Maybe<Meeting>;
  deleteMultisensoryExperience: MultisensoryExperience;
  deleteMultisensoryExperienceArea: MultisensoryExperienceArea;
  deleteMultisensoryExperienceEmailNotification: MultisensoryExperienceEmailNotification;
  deleteMultisensoryExperienceFilterPreset: MultisensoryExperienceFilterPreset;
  deleteMultisensoryExperienceItem: MultisensoryExperienceItem;
  deleteMultisensoryExperienceMeasure: MultisensoryExperienceMeasure;
  deleteMultisensoryExperienceMeasureTask: MultisensoryExperienceMeasureTask;
  deleteNetworkPlanningOrganizations: Array<Maybe<NetworkPlanningOrganization>>;
  deletePbeIrisKpi?: Maybe<PbeIrisKpi>;
  deletePbeOnlyOrg: Person;
  deletePeerGroup: PeerGroup;
  deletePeerGroupFromPerson: Person;
  deletePorscheStrategy2030EmailNotification: PorscheStrategy2030EmailNotification;
  deleteQuestionnaire: Questionnaire;
  deleteRetailDesignDocument: RetailDesignDocument;
  deleteRetailDesignEmailNotification: RetailDesignEmailNotification;
  deleteRetailDesignFilterPreset: RetailDesignFilterPreset;
  deleteRetailDesignFormat: RetailDesignFormat;
  deleteRetailDesignProject: RetailDesignProject;
  deleteRetailDesignProjectType: RetailDesignProjectType;
  deleteStrategyActionItem?: Maybe<StrategyActionItem>;
  deleteStrategyField?: Maybe<StrategyField>;
  deleteStrategyKpi?: Maybe<StrategyKpi>;
  deleteStrategyStage?: Maybe<StrategyStage>;
  deleteSupportRequest: SupportRequest;
  deleteSurvey: Survey;
  deleteSurveyEmailNotification: SurveyEmailNotification;
  deleteSurveyReportConfig: SurveyReportConfig;
  deleteSurveyType: SurveyType;
  deleteUserManagementItem: UserManagementItem;
  mysteryShoppingImport: MysteryShoppingImport;
  removeAttachmentFromActionItemComment?: Maybe<ActionItem>;
  removeAttachmentFromMeetingComment?: Maybe<Meeting>;
  /** @deprecated Use updateActionItemWidgets instead */
  removeAudit: ActionItemWidget;
  removeFilterPreset?: Maybe<Scalars['Boolean']['output']>;
  removeMeetingPbeIrisLink?: Maybe<Meeting>;
  /** @deprecated Use updateActionItemWidgets instead */
  removeQuestionnaire: ActionItemWidget;
  removeReport?: Maybe<Scalars['Boolean']['output']>;
  removeReportTab?: Maybe<ReportTab>;
  resetSurveyAnswer: SurveyAnswer;
  sendEmail?: Maybe<Scalars['Boolean']['output']>;
  startPpnMasterdataImport: PpnMasterdataImportStatus;
  translateSurvey: SurveyTranslation;
  updateActionItem?: Maybe<ActionItem>;
  updateActionItemComment: Comment;
  updateActionItemStatus?: Maybe<ActionItem>;
  updateActionItemTemplate: ActionItemTemplate;
  updateAnnouncement: Announcement;
  updateAudit: Audit;
  updateCategory?: Maybe<Category>;
  updateCoachingModule?: Maybe<CoachingModule>;
  updateConsultingMeetingType?: Maybe<ConsultingMeetingType>;
  updateDashboardEmailNotification: DashboardEmailNotification;
  updateExchangeRate: ExchangeRate;
  updateFeatureArticle: FeatureArticle;
  updateFieldForceManagementCalendarExport: FieldForceManagementCalendarExport;
  updateFieldForceManagementDashboardWidget: FieldForceManagementDashboardWidget;
  updateFieldForceManagementEmailNotification: FieldForceManagementEmailNotification;
  updateFieldForceManagementFeatureFlag: FieldForceManagementFeatureFlag;
  updateFilterPreset?: Maybe<FilterPreset>;
  updateHelpArticle: HelpArticle;
  updateMeeting?: Maybe<Meeting>;
  updateMeetingComment: Comment;
  updateMeetingStatus?: Maybe<Meeting>;
  updateMultisensoryExperience: MultisensoryExperience;
  updateMultisensoryExperienceArea: MultisensoryExperienceArea;
  updateMultisensoryExperienceEmailNotification: MultisensoryExperienceEmailNotification;
  updateMultisensoryExperienceFilterPreset: MultisensoryExperienceFilterPreset;
  updateMultisensoryExperienceItem: MultisensoryExperienceItem;
  updateMultisensoryExperienceMeasure: MultisensoryExperienceMeasure;
  updateMultisensoryExperienceMeasureTask: MultisensoryExperienceMeasureTask;
  updateMysteryShoppingCriterias: Array<MysteryShoppingCriteria>;
  updateNetworkPlanningFeatureFlag: NetworkPlanningFeatureFlag;
  updateNetworkPlanningOrganization: NetworkPlanningOrganization;
  updateOccasionRelatedApproval: OccasionRelatedApproval;
  updateOccasionRelatedApprovalActionItem: OccasionRelatedApproval;
  updateOccasionRelatedApprovalReview: OccasionRelatedApproval;
  updatePbeIrisKpi?: Maybe<PbeIrisKpi>;
  updatePeerGroup: PeerGroup;
  updatePerson: Person;
  updatePersonSettings: Person;
  updatePorscheStrategy2030EmailNotification: PorscheStrategy2030EmailNotification;
  updateQuestionnaire: Questionnaire;
  updateQuestionnaireTypeConfig?: Maybe<QuestionnaireTypeConfig>;
  updateReport?: Maybe<ReportItem>;
  updateReportTab?: Maybe<ReportTab>;
  updateRetailDesignAbsenceNote: RetailDesignAbsenceNote;
  updateRetailDesignDocument: RetailDesignDocument;
  updateRetailDesignEmailNotification: RetailDesignEmailNotification;
  updateRetailDesignFilterPreset: RetailDesignFilterPreset;
  updateRetailDesignFormat: RetailDesignFormat;
  updateRetailDesignProject: RetailDesignProject;
  updateRetailDesignProjectType: RetailDesignProjectType;
  updateStrategyActionItem: StrategyActionItem;
  updateStrategyField?: Maybe<StrategyField>;
  updateStrategyFields?: Maybe<Array<Maybe<StrategyField>>>;
  updateStrategyImpact?: Maybe<StrategyImpact>;
  updateStrategyKpi?: Maybe<StrategyKpi>;
  updateStrategyStage?: Maybe<StrategyStage>;
  updateSupportRequest: SupportRequest;
  updateSurvey: Survey;
  updateSurveyEmailNotification: SurveyEmailNotification;
  updateSurveyReportConfig: SurveyReportConfig;
  updateSurveyType: SurveyType;
  updateUserManagementItem: UserManagementItem;
};

export type MutationAddAuditArgs = {
  actionItemId: Scalars['ObjectID']['input'];
  copyAnswersActionItemId?: InputMaybe<Scalars['ObjectID']['input']>;
  id: Scalars['ObjectID']['input'];
};

export type MutationAddFilterPresetArgs = {
  personId: Scalars['BigInt']['input'];
  preset: FilterPresetInput;
};

export type MutationAddMeetingPbeIrisLinkArgs = {
  id: Scalars['ObjectID']['input'];
  pbeIrisLink: Scalars['String']['input'];
};

export type MutationAddPbeOnlyOrgArgs = {
  pbeOnlyOrganizations: Array<OrganizationReferenceInput>;
  personIds: Array<Scalars['BigInt']['input']>;
};

export type MutationAddPeerGroupToPersonsArgs = {
  id: Scalars['ObjectID']['input'];
  personIds: Array<Scalars['BigInt']['input']>;
};

export type MutationAddQuestionnaireArgs = {
  actionItemId: Scalars['ObjectID']['input'];
  copyAnswersActionItemId?: InputMaybe<Scalars['ObjectID']['input']>;
  id: Scalars['ObjectID']['input'];
};

export type MutationAnswerAuditArgs = {
  input: Array<AuditAnswerInput>;
  widgetId: Scalars['ObjectID']['input'];
};

export type MutationAnswerQuestionnaireArgs = {
  input: Array<QuestionnaireAnswerInput>;
  widgetId: Scalars['ObjectID']['input'];
};

export type MutationAnswerSurveyQuestionArgs = {
  input: AnswerSurveyQuestionInput;
};

export type MutationAssignGroupsArgs = {
  ids: Array<Scalars['String']['input']>;
  visibleGroups?: InputMaybe<Array<GroupReferenceInput>>;
};

export type MutationAssignLegacyMeetingsBucketToOrganizationArgs = {
  bucket: Scalars['String']['input'];
  organizationId: Scalars['BigInt']['input'];
};

export type MutationBroadcastActionItemArgs = {
  input: BroadcastActionItemInput;
};

export type MutationCalculateSurveyResultArgs = {
  input: CalculateSurveyResultInput;
};

export type MutationCalculateSurveyResultsArgs = {
  input: CalculateSurveyResultsInput;
};

export type MutationCommentOnActionItemArgs = {
  actionItemId: Scalars['ObjectID']['input'];
  comment: CommentInput;
};

export type MutationCommentOnMeetingArgs = {
  comment: CommentInput;
  meetingId: Scalars['ObjectID']['input'];
};

export type MutationCopyAuditArgs = {
  allowCopyAnswers: Scalars['Boolean']['input'];
  id: Scalars['ObjectID']['input'];
};

export type MutationCopyQuestionnaireArgs = {
  allowCopyAnswers: Scalars['Boolean']['input'];
  id: Scalars['ObjectID']['input'];
};

export type MutationCreateActionItemArgs = {
  input: ActionItemInput;
};

export type MutationCreateActionItemTemplateArgs = {
  actionItemTemplate: ActionItemTemplateInput;
};

export type MutationCreateAuditArgs = {
  input: NewAuditInput;
};

export type MutationCreateCategoryArgs = {
  category: CategoryCreationInput;
};

export type MutationCreateCoachingModuleArgs = {
  coachingModule: CoachingModuleCreateInput;
};

export type MutationCreateConsultingMeetingTypeArgs = {
  consultingMeetingType: ConsultingMeetingTypeCreateInput;
};

export type MutationCreateDashboardEmailNotificationArgs = {
  input: CreateDashboardEmailNotificationInput;
};

export type MutationCreateDownloadUrlArgs = {
  id?: InputMaybe<Scalars['ObjectID']['input']>;
  key?: InputMaybe<Scalars['ID']['input']>;
  reference: DownloadReference;
  type: DownloadType;
};

export type MutationCreateExchangeRateArgs = {
  input: ExchangeRateInput;
};

export type MutationCreateFeatureArticleArgs = {
  featureArticle: CreateFeatureArticleInput;
};

export type MutationCreateFieldForceManagementEmailNotificationArgs = {
  input: CreateFieldForceManagementEmailNotificationInput;
};

export type MutationCreateFilterPresetArgs = {
  input: CreateFilterPresetInput;
};

export type MutationCreateHelpArticleArgs = {
  helpArticle: CreateHelpArticleInput;
};

export type MutationCreateMeetingArgs = {
  meeting: NewMeetingInput;
};

export type MutationCreateMultisensoryExperienceArgs = {
  input: CreateMultisensoryExperienceInput;
};

export type MutationCreateMultisensoryExperienceAreaArgs = {
  input: CreateMultisensoryExperienceAreaInput;
};

export type MutationCreateMultisensoryExperienceAreasArgs = {
  input: Array<CreateMultisensoryExperienceAreaInput>;
};

export type MutationCreateMultisensoryExperienceEmailNotificationArgs = {
  input: CreateMultisensoryExperienceEmailNotificationInput;
};

export type MutationCreateMultisensoryExperienceFilterPresetArgs = {
  input: CreateMultisensoryExperienceFilterPresetInput;
};

export type MutationCreateMultisensoryExperienceItemArgs = {
  input: CreateMultisensoryExperienceItemInput;
};

export type MutationCreateMultisensoryExperienceMeasureArgs = {
  input: CreateMultisensoryExperienceMeasureInput;
};

export type MutationCreateMultisensoryExperienceMeasureTaskArgs = {
  input: CreateMultisensoryExperienceMeasureTaskInput;
};

export type MutationCreateMysteryShoppingImportUploadUrlArgs = {
  input: CreateMysteryShoppingImportUploadUrlInput;
};

export type MutationCreateNetworkPlanningOrganizationArgs = {
  input: CreateNetworkPlanningOrganizationInput;
};

export type MutationCreateOccasionRelatedApprovalArgs = {
  input: CreateOccasionRelatedApprovalInput;
};

export type MutationCreatePbeIrisKpiArgs = {
  pbeIrisKpi: PbeIrisKpiInput;
};

export type MutationCreatePeerGroupArgs = {
  peerGroup: PeerGroupInput;
};

export type MutationCreatePorscheStrategy2030EmailNotificationArgs = {
  input: CreatePorscheStrategy2030EmailNotificationInput;
};

export type MutationCreateQuestionnaireArgs = {
  input: NewQuestionnaireInput;
};

export type MutationCreateQuestionnaireTypeConfigArgs = {
  input: QuestionnaireTypeConfigCreationInput;
};

export type MutationCreateRetailDesignDocumentArgs = {
  input: CreateRetailDesignDocumentInput;
};

export type MutationCreateRetailDesignEmailNotificationArgs = {
  input: CreateRetailDesignEmailNotificationInput;
};

export type MutationCreateRetailDesignFilterPresetArgs = {
  input: CreateRetailDesignFilterPresetInput;
};

export type MutationCreateRetailDesignFormatArgs = {
  input: CreateRetailDesignFormatInput;
};

export type MutationCreateRetailDesignProjectArgs = {
  input: CreateRetailDesignProjectInput;
};

export type MutationCreateRetailDesignProjectTypeArgs = {
  input: CreateRetailDesignProjectTypeInput;
};

export type MutationCreateStrategyActionItemArgs = {
  input: CreateStrategyActionItemInput;
};

export type MutationCreateStrategyFieldArgs = {
  input: CreateStrategyFieldInput;
};

export type MutationCreateStrategyImpactArgs = {
  strategyImpact: StrategyImpactCreationInput;
};

export type MutationCreateStrategyKpiArgs = {
  input: CreateStrategyKpiInput;
};

export type MutationCreateStrategyStageArgs = {
  input: CreateStrategyStageInput;
};

export type MutationCreateSupportRequestArgs = {
  input: CreateSupportRequestInput;
};

export type MutationCreateSurveyArgs = {
  input: CreateSurveyInput;
};

export type MutationCreateSurveyEmailNotificationArgs = {
  input: CreateSurveyEmailNotificationInput;
};

export type MutationCreateSurveyReportConfigArgs = {
  input: CreateSurveyReportConfigInput;
};

export type MutationCreateSurveyTypeArgs = {
  input: CreateSurveyTypeInput;
};

export type MutationCreateUserManagementItemArgs = {
  input: CreateUserManagementItemInput;
};

export type MutationDeleteActionItemArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteActionItemTemplateArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteCoachingModuleArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteConsultingMeetingTypeArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteDashboardEmailNotificationArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteExchangeRateArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteFeatureArticleArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteFieldForceManagementEmailNotificationArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteGroupAssignmentArgs = {
  id: Scalars['String']['input'];
  visibleGroupId: Scalars['String']['input'];
};

export type MutationDeleteHelpArticleArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteMeetingArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteMultisensoryExperienceArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteMultisensoryExperienceAreaArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteMultisensoryExperienceEmailNotificationArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteMultisensoryExperienceFilterPresetArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteMultisensoryExperienceItemArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteMultisensoryExperienceMeasureArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteMultisensoryExperienceMeasureTaskArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteNetworkPlanningOrganizationsArgs = {
  ids: Array<NetworkPlanningNetworkPlanningIdInput>;
};

export type MutationDeletePbeIrisKpiArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeletePbeOnlyOrgArgs = {
  orgIdToDelete: Scalars['BigInt']['input'];
  personId: Scalars['BigInt']['input'];
};

export type MutationDeletePeerGroupArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeletePeerGroupFromPersonArgs = {
  id: Scalars['ObjectID']['input'];
  personId: Scalars['BigInt']['input'];
};

export type MutationDeletePorscheStrategy2030EmailNotificationArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteQuestionnaireArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteRetailDesignDocumentArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteRetailDesignEmailNotificationArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteRetailDesignFilterPresetArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteRetailDesignFormatArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteRetailDesignProjectArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteRetailDesignProjectTypeArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteStrategyActionItemArgs = {
  input: DeleteStrategyActionItemInput;
};

export type MutationDeleteStrategyFieldArgs = {
  input: DeleteStrategyFieldInput;
};

export type MutationDeleteStrategyKpiArgs = {
  input: DeleteStrategyKpiInput;
};

export type MutationDeleteStrategyStageArgs = {
  input: DeleteStrategyStageInput;
};

export type MutationDeleteSupportRequestArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteSurveyArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteSurveyEmailNotificationArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteSurveyReportConfigArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteSurveyTypeArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationDeleteUserManagementItemArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationMysteryShoppingImportArgs = {
  input: MysteryShoppingImportInput;
};

export type MutationRemoveAttachmentFromActionItemCommentArgs = {
  actionItemId: Scalars['ObjectID']['input'];
  attachmentId: Scalars['ObjectID']['input'];
  commentId: Scalars['ObjectID']['input'];
};

export type MutationRemoveAttachmentFromMeetingCommentArgs = {
  attachmentId: Scalars['ObjectID']['input'];
  commentId: Scalars['ObjectID']['input'];
  meetingId: Scalars['ObjectID']['input'];
};

export type MutationRemoveAuditArgs = {
  actionItemId: Scalars['ObjectID']['input'];
  id: Scalars['ObjectID']['input'];
};

export type MutationRemoveFilterPresetArgs = {
  input: RemoveFilterPresetInput;
};

export type MutationRemoveMeetingPbeIrisLinkArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationRemoveQuestionnaireArgs = {
  actionItemId: Scalars['ObjectID']['input'];
  id: Scalars['ObjectID']['input'];
};

export type MutationRemoveReportArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationRemoveReportTabArgs = {
  key: Scalars['ObjectID']['input'];
};

export type MutationResetSurveyAnswerArgs = {
  id: Scalars['ObjectID']['input'];
};

export type MutationSendEmailArgs = {
  message: Scalars['String']['input'];
};

export type MutationTranslateSurveyArgs = {
  input: TranslateSurveyInput;
};

export type MutationUpdateActionItemArgs = {
  actionItem: ActionItemInput;
  id: Scalars['ObjectID']['input'];
};

export type MutationUpdateActionItemCommentArgs = {
  actionItemId: Scalars['ObjectID']['input'];
  comment: CommentUpdateInput;
};

export type MutationUpdateActionItemStatusArgs = {
  id: Scalars['ObjectID']['input'];
  input: UpdateActionItemStatusInput;
};

export type MutationUpdateActionItemTemplateArgs = {
  actionItemTemplate: ActionItemTemplateInput;
  id: Scalars['ObjectID']['input'];
};

export type MutationUpdateAnnouncementArgs = {
  input: UpdateAnnouncementInput;
};

export type MutationUpdateAuditArgs = {
  id: Scalars['ObjectID']['input'];
  input: AuditInput;
};

export type MutationUpdateCategoryArgs = {
  category: CategoryUpdateInput;
  id: Scalars['ObjectID']['input'];
};

export type MutationUpdateCoachingModuleArgs = {
  coachingModule: CoachingModuleUpdateInput;
  id: Scalars['ObjectID']['input'];
};

export type MutationUpdateConsultingMeetingTypeArgs = {
  consultingMeetingType: ConsultingMeetingTypeUpdateInput;
  id: Scalars['ObjectID']['input'];
};

export type MutationUpdateDashboardEmailNotificationArgs = {
  input: UpdateDashboardEmailNotificationInput;
};

export type MutationUpdateExchangeRateArgs = {
  id: Scalars['ObjectID']['input'];
  input: ExchangeRateInput;
};

export type MutationUpdateFeatureArticleArgs = {
  featureArticle: UpdateFeatureArticleInput;
};

export type MutationUpdateFieldForceManagementDashboardWidgetArgs = {
  input: UpdateFieldForceManagementDashboardWidgetInput;
};

export type MutationUpdateFieldForceManagementEmailNotificationArgs = {
  input: UpdateFieldForceManagementEmailNotificationInput;
};

export type MutationUpdateFieldForceManagementFeatureFlagArgs = {
  input: UpdateFieldForceManagementFeatureFlagInput;
};

export type MutationUpdateFilterPresetArgs = {
  input: UpdateFilterPresetInput;
};

export type MutationUpdateHelpArticleArgs = {
  helpArticle: UpdateHelpArticleInput;
  id: Scalars['ObjectID']['input'];
};

export type MutationUpdateMeetingArgs = {
  id: Scalars['ObjectID']['input'];
  meeting: UpdateMeetingInput;
};

export type MutationUpdateMeetingCommentArgs = {
  comment: CommentUpdateInput;
  meetingId: Scalars['ObjectID']['input'];
};

export type MutationUpdateMeetingStatusArgs = {
  id: Scalars['ObjectID']['input'];
  input: UpdateMeetingStatusInput;
};

export type MutationUpdateMultisensoryExperienceArgs = {
  input: UpdateMultisensoryExperienceInput;
};

export type MutationUpdateMultisensoryExperienceAreaArgs = {
  input: UpdateMultisensoryExperienceAreaInput;
};

export type MutationUpdateMultisensoryExperienceEmailNotificationArgs = {
  input: UpdateMultisensoryExperienceEmailNotificationInput;
};

export type MutationUpdateMultisensoryExperienceFilterPresetArgs = {
  input: UpdateMultisensoryExperienceFilterPresetInput;
};

export type MutationUpdateMultisensoryExperienceItemArgs = {
  input: UpdateMultisensoryExperienceItemInput;
};

export type MutationUpdateMultisensoryExperienceMeasureArgs = {
  input: UpdateMultisensoryExperienceMeasureInput;
};

export type MutationUpdateMultisensoryExperienceMeasureTaskArgs = {
  input: UpdateMultisensoryExperienceMeasureTaskInput;
};

export type MutationUpdateMysteryShoppingCriteriasArgs = {
  input: UpdateMysteryShoppingCriteriasInput;
};

export type MutationUpdateNetworkPlanningFeatureFlagArgs = {
  input: UpdateNetworkPlanningFeatureFlagInput;
};

export type MutationUpdateNetworkPlanningOrganizationArgs = {
  input: UpdateNetworkPlanningOrganizationInput;
  networkPlanningId?: InputMaybe<Scalars['ObjectID']['input']>;
  organizationId?: InputMaybe<Scalars['BigInt']['input']>;
};

export type MutationUpdateOccasionRelatedApprovalArgs = {
  input: UpdateOccasionRelatedApprovalInput;
};

export type MutationUpdateOccasionRelatedApprovalActionItemArgs = {
  input: UpdateOccasionRelatedApprovalActionItemInput;
};

export type MutationUpdateOccasionRelatedApprovalReviewArgs = {
  input: UpdateOccasionRelatedApprovalReviewInput;
};

export type MutationUpdatePbeIrisKpiArgs = {
  id: Scalars['ObjectID']['input'];
  pbeIrisKpi: PbeIrisKpiInput;
};

export type MutationUpdatePeerGroupArgs = {
  id: Scalars['ObjectID']['input'];
  peerGroup: PeerGroupInput;
};

export type MutationUpdatePersonArgs = {
  input: UpdatePersonInput;
};

export type MutationUpdatePersonSettingsArgs = {
  input: UpdatePersonSettingsInput;
};

export type MutationUpdatePorscheStrategy2030EmailNotificationArgs = {
  input: UpdatePorscheStrategy2030EmailNotificationInput;
};

export type MutationUpdateQuestionnaireArgs = {
  id: Scalars['ObjectID']['input'];
  input: QuestionnaireInput;
};

export type MutationUpdateQuestionnaireTypeConfigArgs = {
  id: Scalars['ObjectID']['input'];
  input: QuestionnaireTypeConfigEditInput;
};

export type MutationUpdateReportArgs = {
  input: ReportItemInput;
};

export type MutationUpdateReportTabArgs = {
  input: ReportTabInput;
};

export type MutationUpdateRetailDesignAbsenceNoteArgs = {
  input: UpdateRetailDesignAbsenceNoteInput;
};

export type MutationUpdateRetailDesignDocumentArgs = {
  input: UpdateRetailDesignDocumentInput;
};

export type MutationUpdateRetailDesignEmailNotificationArgs = {
  input: UpdateRetailDesignEmailNotificationInput;
};

export type MutationUpdateRetailDesignFilterPresetArgs = {
  input: UpdateRetailDesignFilterPresetInput;
};

export type MutationUpdateRetailDesignFormatArgs = {
  input: UpdateRetailDesignFormatInput;
};

export type MutationUpdateRetailDesignProjectArgs = {
  input: UpdateRetailDesignProjectInput;
};

export type MutationUpdateRetailDesignProjectTypeArgs = {
  input: UpdateRetailDesignProjectTypeInput;
};

export type MutationUpdateStrategyActionItemArgs = {
  input: UpdateStrategyActionItemInput;
};

export type MutationUpdateStrategyFieldArgs = {
  input: UpdateStrategyFieldInput;
};

export type MutationUpdateStrategyFieldsArgs = {
  input: Array<UpdateStrategyFieldInput>;
};

export type MutationUpdateStrategyImpactArgs = {
  id: Scalars['ObjectID']['input'];
  strategyImpact: StrategyImpactUpdateInput;
};

export type MutationUpdateStrategyKpiArgs = {
  input: UpdateStrategyKpiInput;
};

export type MutationUpdateStrategyStageArgs = {
  input: UpdateStrategyStageInput;
};

export type MutationUpdateSupportRequestArgs = {
  input: UpdateSupportRequestInput;
};

export type MutationUpdateSurveyArgs = {
  input: UpdateSurveyInput;
};

export type MutationUpdateSurveyEmailNotificationArgs = {
  input: UpdateSurveyEmailNotificationInput;
};

export type MutationUpdateSurveyReportConfigArgs = {
  input: UpdateSurveyReportConfigInput;
};

export type MutationUpdateSurveyTypeArgs = {
  input: UpdateSurveyTypeInput;
};

export type MutationUpdateUserManagementItemArgs = {
  input: UpdateUserManagementItemInput;
};

export type MysteryShoppingCriteria = {
  __typename?: 'MysteryShoppingCriteria';
  actionItemIds: Array<Scalars['ObjectID']['output']>;
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  sampleSize?: Maybe<Scalars['Int']['output']>;
  scenario?: Maybe<MysteryShoppingScenario>;
  score: Scalars['Float']['output'];
};

export type MysteryShoppingCriteriaFilter = {
  checkId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  organization?: InputMaybe<BigIntFilter>;
  rootOrganizationIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  scenarioName?: InputMaybe<StringFilter>;
  wave?: InputMaybe<ObjectIdFilter>;
};

export type MysteryShoppingImport = {
  __typename?: 'MysteryShoppingImport';
  waves: Array<MysteryShoppingWave>;
};

export type MysteryShoppingImportInput = {
  id: Scalars['ObjectID']['input'];
};

export type MysteryShoppingImportUploadUrl = {
  __typename?: 'MysteryShoppingImportUploadUrl';
  kmsKeyId: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type MysteryShoppingScenario = {
  __typename?: 'MysteryShoppingScenario';
  dsjScore?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
  previousYear?: Maybe<MysteryShoppingScenario>;
  totalScore: Scalars['Float']['output'];
};

export type MysteryShoppingScenarioPreviousYearArgs = {
  filter?: InputMaybe<MysteryShoppingScenarioFilter>;
  groupBy?: InputMaybe<Scalars['String']['input']>;
};

export type MysteryShoppingScenarioFilter = {
  _id?: InputMaybe<ObjectIdFilter>;
  checkId?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  organization?: InputMaybe<BigIntFilter>;
  type?: InputMaybe<MysteryShoppingScenarioTypeFilter>;
  wave?: InputMaybe<ObjectIdFilter>;
};

export enum MysteryShoppingScenarioType {
  OffSite = 'OFF_SITE',
  OnSite = 'ON_SITE',
}

export type MysteryShoppingScenarioTypeFilter = {
  _eq?: InputMaybe<MysteryShoppingScenarioType>;
};

export type MysteryShoppingWave = {
  __typename?: 'MysteryShoppingWave';
  dsjScore?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  organizations: Array<Organization>;
  sampleSize: Scalars['Int']['output'];
  scenarios: Array<MysteryShoppingScenario>;
  totalScore: Scalars['Float']['output'];
  year: Scalars['Int']['output'];
};

export type MysteryShoppingWaveOrganizationsArgs = {
  filter?: InputMaybe<OrganizationFilter>;
};

export type MysteryShoppingWaveScore = {
  __typename?: 'MysteryShoppingWaveScore';
  dsjScore?: Maybe<Scalars['Float']['output']>;
  sampleSize: Scalars['Int']['output'];
  totalScore: Scalars['Float']['output'];
};

export type MysteryShoppingWaveScoreFilter = {
  scenario?: InputMaybe<MysteryShoppingScenarioFilter>;
  wave?: InputMaybe<ObjectIdFilter>;
};

export type NetworkPlanningCharger = {
  __typename?: 'NetworkPlanningCharger';
  maxPower?: Maybe<Scalars['Int']['output']>;
  numberOfChargers?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<NetworkPlanningChargerStatus>;
  type?: Maybe<NetworkPlanningChargerType>;
  vendor?: Maybe<Scalars['String']['output']>;
};

export type NetworkPlanningChargerInput = {
  maxPower?: InputMaybe<Scalars['Int']['input']>;
  numberOfChargers?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<NetworkPlanningChargerStatus>;
  type?: InputMaybe<NetworkPlanningChargerType>;
  vendor?: InputMaybe<Scalars['String']['input']>;
};

export enum NetworkPlanningChargerOperationalStatus {
  FreeCharging = 'FREE_CHARGING',
  NonFunctioning = 'NON_FUNCTIONING',
  Planned = 'PLANNED',
  PublicCharging = 'PUBLIC_CHARGING',
}

export enum NetworkPlanningChargerStatus {
  FreeCharging = 'FREE_CHARGING',
  InInstallation = 'IN_INSTALLATION',
  NonFunctioning = 'NON_FUNCTIONING',
  Planned = 'PLANNED',
  PublicCharging = 'PUBLIC_CHARGING',
  RfidCharging = 'RFID_CHARGING',
}

export enum NetworkPlanningChargerType {
  Cbx = 'CBX',
  Premium = 'PREMIUM',
  ThirdParty = 'THIRD_PARTY',
}

export type NetworkPlanningFeatureFlag = {
  __typename?: 'NetworkPlanningFeatureFlag';
  id: Scalars['ObjectID']['output'];
  name: NetworkPlanningFeatureFlagName;
  organizations: Array<Organization>;
  persons: Array<Person>;
};

export enum NetworkPlanningFeatureFlagName {
  ShowEperformanceTabs = 'SHOW_EPERFORMANCE_TABS',
  ShowForecastkpiTab = 'SHOW_FORECASTKPI_TAB',
}

export type NetworkPlanningId =
  | NetworkPlanningNetworkPlanningId
  | NetworkPlanningOrganizationId;

export type NetworkPlanningNetworkPlanningId = {
  __typename?: 'NetworkPlanningNetworkPlanningID';
  networkPlanningId: Scalars['ObjectID']['output'];
  organizationId?: Maybe<Scalars['BigInt']['output']>;
};

export type NetworkPlanningNetworkPlanningIdInput = {
  networkPlanningId: Scalars['ObjectID']['input'];
};

export type NetworkPlanningOrganization = {
  __typename?: 'NetworkPlanningOrganization';
  actualInvestor?: Maybe<Scalars['String']['output']>;
  batteryCapacity?: Maybe<Scalars['Int']['output']>;
  batteryRepairHub?: Maybe<Scalars['Boolean']['output']>;
  chargerMaxPower?: Maybe<Scalars['Int']['output']>;
  chargerOperationalStatus?: Maybe<NetworkPlanningChargerOperationalStatus>;
  chargingBackendName?: Maybe<Scalars['String']['output']>;
  chargingBackendVendor?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  connectedInvestor?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  customerAreaCharger?: Maybe<NetworkPlanningCharger>;
  dpCompletionExterieur?: Maybe<Scalars['DateMonth']['output']>;
  dpCompletionInterieur?: Maybe<Scalars['DateMonth']['output']>;
  dpUpgradeCompleted?: Maybe<Scalars['Boolean']['output']>;
  id: NetworkPlanningId;
  importer?: Maybe<Scalars['String']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  lastCiUpdate?: Maybe<Scalars['Int']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  loadManagementPresent?: Maybe<Scalars['Boolean']['output']>;
  loadManagementVendor?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  market?: Maybe<Scalars['String']['output']>;
  multiBrand?: Maybe<NetworkPlanningOrganizationBrand>;
  name: Scalars['String']['output'];
  ncSales?: Maybe<Scalars['FloatPerYear']['output']>;
  numberOfWiredChargers?: Maybe<Scalars['Int']['output']>;
  numberOfWirelessChargers?: Maybe<Scalars['Int']['output']>;
  opening?: Maybe<Scalars['DateMonth']['output']>;
  partner?: Maybe<NetworkPlanningOrganizationPartner>;
  partnerNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  serviceAreaCharger?: Maybe<NetworkPlanningCharger>;
  solarPanelsMaxOutput?: Maybe<Scalars['Int']['output']>;
  sqm?: Maybe<Scalars['FloatPerYear']['output']>;
  status?: Maybe<OrganizationStatus>;
  street?: Maybe<Scalars['String']['output']>;
  subregion?: Maybe<NetworkPlanningOrganizationSubregion>;
  termination?: Maybe<Scalars['DateMonth']['output']>;
  type?: Maybe<NetworkPlanningOrganizationType>;
  uio?: Maybe<Scalars['FloatPerYear']['output']>;
  upgradeType?: Maybe<NetworkPlanningOrganizationUpgradeType>;
  wiredChargerVendor?: Maybe<Scalars['String']['output']>;
  wirelessChargerVendor?: Maybe<Scalars['String']['output']>;
  workbays?: Maybe<Scalars['IntPerYear']['output']>;
};

export enum NetworkPlanningOrganizationBrand {
  Exclusive = 'EXCLUSIVE',
  Multibrand = 'MULTIBRAND',
}

export type NetworkPlanningOrganizationId = {
  __typename?: 'NetworkPlanningOrganizationID';
  organizationId: Scalars['BigInt']['output'];
};

export enum NetworkPlanningOrganizationPartner {
  ClassicExclusivePartner = 'CLASSIC_EXCLUSIVE_PARTNER',
  ClassicPartner = 'CLASSIC_PARTNER',
  ExclusivePartner = 'EXCLUSIVE_PARTNER',
}

export enum NetworkPlanningOrganizationSubregion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
  V41 = 'V41',
  V42 = 'V42',
  V43 = 'V43',
  V51 = 'V51',
  V52 = 'V52',
  V53 = 'V53',
}

export enum NetworkPlanningOrganizationType {
  PorscheApprovedCenter = 'PORSCHE_APPROVED_CENTER',
  PorscheApprovedServiceCenter = 'PORSCHE_APPROVED_SERVICE_CENTER',
  PorscheCenter = 'PORSCHE_CENTER',
  PorscheClassicCenter = 'PORSCHE_CLASSIC_CENTER',
  PorscheExpressCityServiceCenter = 'PORSCHE_EXPRESS_CITY_SERVICE_CENTER',
  PorscheNow = 'PORSCHE_NOW',
  PorschePreOwnedCenter = 'PORSCHE_PRE_OWNED_CENTER',
  PorscheServiceCenter = 'PORSCHE_SERVICE_CENTER',
  PorscheServiceFactory = 'PORSCHE_SERVICE_FACTORY',
  PorscheShowroom = 'PORSCHE_SHOWROOM',
  PorscheStore = 'PORSCHE_STORE',
  PorscheStudio = 'PORSCHE_STUDIO',
  PorscheWorkshop = 'PORSCHE_WORKSHOP',
  SatellitePorscheCenter = 'SATELLITE_PORSCHE_CENTER',
}

export enum NetworkPlanningOrganizationUpgradeType {
  FitoutProject = 'FITOUT_PROJECT',
  NewBuilding = 'NEW_BUILDING',
  RefurbishmentProject = 'REFURBISHMENT_PROJECT',
}

export type NewAuditInput = {
  additionalPersons?: InputMaybe<Array<PersonReferenceInput>>;
  allowMultipleAssignments: Scalars['Boolean']['input'];
  auditId?: InputMaybe<Scalars['ObjectID']['input']>;
  children: Array<AuditItemInput>;
  id?: InputMaybe<Scalars['ObjectID']['input']>;
  languages: Array<Scalars['String']['input']>;
  name: Scalars['JSON']['input'];
  organizations: Array<OrganizationReferenceInput>;
};

export type NewMeetingInput = {
  agenda?: InputMaybe<Array<InputMaybe<AgendaItemInput>>>;
  approver?: InputMaybe<PersonReferenceInput>;
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  attachmentsToCopy?: InputMaybe<CopyMeetingAttachmentsInput>;
  attendees?: InputMaybe<Array<InputMaybe<PersonReferenceInput>>>;
  categories?: InputMaybe<Array<InputMaybe<CategoryReferenceInput>>>;
  coachingModules?: InputMaybe<Array<CoachingModuleReferenceInput>>;
  consultants?: InputMaybe<Array<InputMaybe<PersonReferenceInput>>>;
  consultingMeetingType?: InputMaybe<
    Array<InputMaybe<ConsultingMeetingTypeReferenceInput>>
  >;
  /** This field is only available in test environments */
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externals?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['ObjectID']['input']>;
  isConfidential?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<MeetingKind>;
  meetingId?: InputMaybe<Scalars['ObjectID']['input']>;
  organization: OrganizationReferenceInput;
  pbeIrisLink?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Field no longer supported */
  pbeMeetingTypeDescription?: InputMaybe<Scalars['String']['input']>;
  peerGroup?: InputMaybe<Scalars['ObjectID']['input']>;
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  scheduledTimeEnd: Scalars['DateTime']['input'];
  scheduledTimeStart: Scalars['DateTime']['input'];
  status?: InputMaybe<MeetingStatus>;
  subject: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<MeetingType>;
};

export type NewQuestionnaireInput = {
  additionalPersons?: InputMaybe<Array<PersonReferenceInput>>;
  allowMultipleAssignments: Scalars['Boolean']['input'];
  allowMultipleAssignmentsForPerson?: InputMaybe<Scalars['Boolean']['input']>;
  children: Array<QuestionnaireItemInput>;
  id?: InputMaybe<Scalars['ObjectID']['input']>;
  languages: Array<Scalars['String']['input']>;
  name: Scalars['JSON']['input'];
  organizations: Array<OrganizationReferenceInput>;
  questionnaireId?: InputMaybe<Scalars['ObjectID']['input']>;
  type: QuestionnaireType;
};

export type ObjectIdFilter = {
  _eq?: InputMaybe<Scalars['ObjectID']['input']>;
  _exists?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']['input']>>>;
  _nin?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']['input']>>>;
};

export type OccasionRelatedApproval = {
  __typename?: 'OccasionRelatedApproval';
  actionItemIds?: Maybe<Array<Scalars['ObjectID']['output']>>;
  adHocResponsible?: Maybe<Person>;
  applicants: Array<Person>;
  attachments?: Maybe<Array<Attachment>>;
  author: Person;
  comment: Scalars['String']['output'];
  comments: Array<Comment>;
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['ObjectID']['output'];
  patches?: Maybe<Array<Maybe<OccasionRelatedApprovalPatch>>>;
  reason: Reason;
  reports: Array<Scalars['String']['output']>;
  review: Review;
};

export type OccasionRelatedApprovalPatch = Patch & {
  __typename?: 'OccasionRelatedApprovalPatch';
  date: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  ops: Scalars['JSON']['output'];
  person: Person;
  ref: Scalars['ObjectID']['output'];
};

export type OccasionRelatedApprovalPatchOpsArgs = {
  input?: InputMaybe<PatchOpsInput>;
};

export type Organization = {
  __typename?: 'Organization';
  ancestors?: Maybe<Array<Organization>>;
  businessType?: Maybe<OrganizationBusinessType>;
  children?: Maybe<Array<Organization>>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  facilityType?: Maybe<OrganizationFacilityType>;
  filledInAudits?: Maybe<Array<FilledInAudit>>;
  filledInQuestionnaires?: Maybe<Array<FilledInQuestionnaire>>;
  geography?: Maybe<OrganizationGeography>;
  hasChildren?: Maybe<Scalars['Boolean']['output']>;
  /** ffmAlias: organizationId */
  id: Scalars['BigInt']['output'];
  importer?: Maybe<OrganizationReference>;
  isImporterOfRegionalOffice?: Maybe<Scalars['Boolean']['output']>;
  isRegionalOffice?: Maybe<Scalars['Boolean']['output']>;
  isVRegion?: Maybe<Scalars['Boolean']['output']>;
  localization?: Maybe<OrganizationLocalization>;
  mysteryShoppingScore?: Maybe<MysteryShoppingWaveScore>;
  name: Scalars['String']['output'];
  parent?: Maybe<Organization>;
  parentId?: Maybe<Scalars['BigInt']['output']>;
  parentMarket?: Maybe<Organization>;
  partnerNumber?: Maybe<Scalars['String']['output']>;
  postalcode?: Maybe<Scalars['String']['output']>;
  profitPotentialIdentified?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<OrganizationStatus>;
  strategyFields?: Maybe<Array<Maybe<StrategyField>>>;
  strategyKpis?: Maybe<Array<Maybe<StrategyKpi>>>;
  street?: Maybe<Scalars['String']['output']>;
  type: OrganizationType;
  vRegion?: Maybe<OrganizationReference>;
};

export type OrganizationChildrenArgs = {
  direct?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationFilledInAuditsArgs = {
  auditId?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type OrganizationFilledInQuestionnairesArgs = {
  questionnaireId?: InputMaybe<Scalars['ObjectID']['input']>;
  type?: InputMaybe<QuestionnaireType>;
};

export type OrganizationMysteryShoppingScoreArgs = {
  filter?: InputMaybe<MysteryShoppingWaveScoreFilter>;
};

export type OrganizationParentMarketArgs = {
  topLevel?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationProfitPotentialIdentifiedArgs = {
  currency: Currency;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationStrategyFieldsArgs = {
  input?: InputMaybe<StrategyFieldsFilterInput>;
};

export type OrganizationStrategyKpisArgs = {
  input?: InputMaybe<StrategyKpisInput>;
};

export enum OrganizationBusinessType {
  Contractor = 'CONTRACTOR',
  External = 'EXTERNAL',
  PorscheAg = 'PORSCHE_AG',
  PorscheDealer = 'PORSCHE_DEALER',
  PorscheImporter = 'PORSCHE_IMPORTER',
  PorscheSubsidiary = 'PORSCHE_SUBSIDIARY',
}

export type OrganizationBusinessTypeFilter = {
  _in?: InputMaybe<Array<InputMaybe<OrganizationBusinessType>>>;
};

export enum OrganizationFacilityType {
  ContactCenter = 'CONTACT_CENTER',
  ExternalDealer = 'EXTERNAL_DEALER',
  ExternalVendor = 'EXTERNAL_VENDOR',
  ExternalWorkshop = 'EXTERNAL_WORKSHOP',
  IndependentImporter = 'INDEPENDENT_IMPORTER',
  IndependentOperator = 'INDEPENDENT_OPERATOR',
  IndependentRegionalOffice = 'INDEPENDENT_REGIONAL_OFFICE',
  InternalDealerImporter = 'INTERNAL_DEALER_IMPORTER',
  InternalDealerPag = 'INTERNAL_DEALER_PAG',
  InternalDealerSubsidiary = 'INTERNAL_DEALER_SUBSIDIARY',
  Nsc = 'NSC',
  PorscheAg = 'PORSCHE_AG',
  PorscheApprovedAndServiceCenter = 'PORSCHE_APPROVED_AND_SERVICE_CENTER',
  PorscheApprovedCenter = 'PORSCHE_APPROVED_CENTER',
  PorscheCenter = 'PORSCHE_CENTER',
  PorscheCenterGroup = 'PORSCHE_CENTER_GROUP',
  PorscheClassicCenter = 'PORSCHE_CLASSIC_CENTER',
  PorscheCustomerInteractionCenter = 'PORSCHE_CUSTOMER_INTERACTION_CENTER',
  PorscheExperienceCenter = 'PORSCHE_EXPERIENCE_CENTER',
  PorscheExperienceCenterTemp = 'PORSCHE_EXPERIENCE_CENTER_TEMP',
  PorscheExpressCityServiceCenter = 'PORSCHE_EXPRESS_CITY_SERVICE_CENTER',
  PorscheOwnedImporterSubsidiary = 'PORSCHE_OWNED_IMPORTER_SUBSIDIARY',
  PorscheOwnedSubsidiary = 'PORSCHE_OWNED_SUBSIDIARY',
  PorschePreDeliveryInspectionWorkshop = 'PORSCHE_PRE_DELIVERY_INSPECTION_WORKSHOP',
  PorschePreOwnedCarCenter = 'PORSCHE_PRE_OWNED_CAR_CENTER',
  PorscheRegionalOffice = 'PORSCHE_REGIONAL_OFFICE',
  PorscheSalesPopUp = 'PORSCHE_SALES_POP_UP',
  PorscheSe = 'PORSCHE_SE',
  PorscheServiceCenter = 'PORSCHE_SERVICE_CENTER',
  PorscheServiceFactory = 'PORSCHE_SERVICE_FACTORY',
  PorscheShowroom = 'PORSCHE_SHOWROOM',
  PorscheStore = 'PORSCHE_STORE',
  PorscheStudio = 'PORSCHE_STUDIO',
  PorscheTrainingCenter = 'PORSCHE_TRAINING_CENTER',
  PorscheWorkshop = 'PORSCHE_WORKSHOP',
  SatellitePorscheCenter = 'SATELLITE_PORSCHE_CENTER',
  Supplier = 'SUPPLIER',
}

export type OrganizationFacilityTypeFilter = {
  _in?: InputMaybe<Array<InputMaybe<OrganizationFacilityType>>>;
};

export type OrganizationFilter = {
  _and?: InputMaybe<Array<OrganizationFilter>>;
  _id?: InputMaybe<BigIntFilter>;
  _or?: InputMaybe<Array<OrganizationFilter>>;
  ancestors?: InputMaybe<BigIntFilter>;
  businessType?: InputMaybe<OrganizationBusinessTypeFilter>;
  children?: InputMaybe<BigIntFilter>;
  facilityType?: InputMaybe<OrganizationFacilityTypeFilter>;
  name?: InputMaybe<StringFilter>;
  partnerNumber?: InputMaybe<StringFilter>;
  status?: InputMaybe<OrganizationStatusFilter>;
  street?: InputMaybe<StringFilter>;
  type?: InputMaybe<OrganizationType>;
};

export type OrganizationGeography = {
  __typename?: 'OrganizationGeography';
  area?: Maybe<Scalars['String']['output']>;
  market?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export type OrganizationLocalization = {
  __typename?: 'OrganizationLocalization';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type OrganizationReference = {
  __typename?: 'OrganizationReference';
  name: Scalars['String']['output'];
  organizationId: Scalars['BigInt']['output'];
};

export type OrganizationReferenceInput = {
  name: Scalars['String']['input'];
  organizationId: Scalars['BigInt']['input'];
};

export enum OrganizationStatus {
  Archived = 'ARCHIVED',
  Closed = 'CLOSED',
  Operative = 'OPERATIVE',
  Planned = 'PLANNED',
  RampUp = 'RAMP_UP',
  WhiteSpot = 'WHITE_SPOT',
}

export type OrganizationStatusFilter = {
  _eq?: InputMaybe<OrganizationStatus>;
};

export enum OrganizationType {
  Market = 'MARKET',
  Organization = 'ORGANIZATION',
  OrganizationalUnit = 'ORGANIZATIONAL_UNIT',
  OrganizationUnit = 'ORGANIZATION_UNIT',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type PaginatedActionItems = {
  __typename?: 'PaginatedActionItems';
  docs: Array<ActionItem>;
  total: Scalars['Int']['output'];
};

export type PaginatedAudits = {
  __typename?: 'PaginatedAudits';
  docs: Array<Audit>;
  total: Scalars['Int']['output'];
};

export type PaginatedHelpArticles = {
  __typename?: 'PaginatedHelpArticles';
  docs: Array<HelpArticle>;
  total: Scalars['Int']['output'];
};

export type PaginatedMeetings = {
  __typename?: 'PaginatedMeetings';
  docs: Array<Meeting>;
  total: Scalars['Int']['output'];
};

export type PaginatedOrganizations = {
  __typename?: 'PaginatedOrganizations';
  docs: Array<Organization>;
  total: Scalars['Int']['output'];
};

export type PaginatedPersons = {
  __typename?: 'PaginatedPersons';
  docs: Array<Person>;
  total: Scalars['Int']['output'];
};

export type PaginatedQuestionnaireTypeConfig = {
  __typename?: 'PaginatedQuestionnaireTypeConfig';
  docs: Array<QuestionnaireTypeConfig>;
  total: Scalars['Int']['output'];
};

export type PaginatedQuestionnaires = {
  __typename?: 'PaginatedQuestionnaires';
  docs: Array<Questionnaire>;
  total: Scalars['Int']['output'];
};

export type Patch = {
  date: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  ops: Scalars['JSON']['output'];
  person: Person;
  ref: Scalars['ObjectID']['output'];
};

export type PatchOpsArgs = {
  input?: InputMaybe<PatchOpsInput>;
};

export type PatchFilter = {
  _elemMatch?: InputMaybe<PatchFilter>;
  op?: InputMaybe<StringFilter>;
  path?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringFilter>;
};

export type PatchOpsFilter = {
  path?: InputMaybe<StringFilter>;
};

export type PatchOpsInput = {
  filter?: InputMaybe<PatchOpsFilter>;
};

export type PbeIrisFilter = {
  _or?: InputMaybe<Array<InputMaybe<PbeIrisFilter>>>;
  coachingModuleIds?: InputMaybe<ObjectIdFilter>;
  /** @deprecated Use coachingModuleIds instead */
  pbeCoachingModuleIds?: InputMaybe<ObjectIdFilter>;
  type?: InputMaybe<PbeIrisKpiTypeFilter>;
};

export type PbeIrisKpi = {
  __typename?: 'PbeIrisKpi';
  coachingModuleIds?: Maybe<Array<Scalars['ObjectID']['output']>>;
  /** ffmAlias: pbeIrisKpiId */
  id: Scalars['ObjectID']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use coachingModuleIds instead */
  pbeCoachingModuleIds?: Maybe<Array<Scalars['ObjectID']['output']>>;
  translations?: Maybe<Scalars['JSON']['output']>;
  type: PbeIrisKpiType;
};

export type PbeIrisKpiInput = {
  coachingModuleIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  /** @deprecated Use coachingModuleIds instead */
  pbeCoachingModuleIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  translations?: InputMaybe<Scalars['JSON']['input']>;
  type: PbeIrisKpiType;
};

export enum PbeIrisKpiType {
  CoachingModules = 'COACHING_MODULES',
  Optional = 'OPTIONAL',
  Required = 'REQUIRED',
}

export type PbeIrisKpiTypeFilter = {
  _eq?: InputMaybe<PbeIrisKpiType>;
  _in?: InputMaybe<Array<InputMaybe<PbeIrisKpiType>>>;
  _nin?: InputMaybe<Array<InputMaybe<PbeIrisKpiType>>>;
};

export type PeerGroup = {
  __typename?: 'PeerGroup';
  /** ffmAlias: peerGroupId */
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
};

export type PeerGroupInput = {
  name: Scalars['String']['input'];
};

export type PeerGroupSort = {
  name?: InputMaybe<SortOrder>;
};

export type Person = {
  __typename?: 'Person';
  ability: Ability;
  /** @deprecated Use userManagement instead. */
  additionalOrganizations?: Maybe<Array<OrganizationReference>>;
  consents?: Maybe<PersonConsents>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  filterPresets?: Maybe<Array<FilterPreset>>;
  givenName?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<Group>>;
  /** ffmAlias: personId */
  id: Scalars['BigInt']['output'];
  mail: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
  pagCurrentAccountStatus?: Maybe<Scalars['String']['output']>;
  /** @deprecated Field no longer supported */
  pagOrgFunction?: Maybe<Scalars['String']['output']>;
  pbeOnlyOrganizations?: Maybe<Array<OrganizationReference>>;
  peerGroups?: Maybe<Array<PeerGroup>>;
  ppnuid: Scalars['String']['output'];
  pseudonymized?: Maybe<Scalars['Boolean']['output']>;
  reportTabs?: Maybe<Array<ReportTab>>;
  /** @deprecated Field no longer supported */
  reports?: Maybe<Array<ReportColumn>>;
  roles: Array<Scalars['String']['output']>;
  settings?: Maybe<PersonSettings>;
  visibleCategories?: Maybe<Array<Category>>;
  visibleGroups?: Maybe<Array<Group>>;
  visibleOrganizations?: Maybe<Array<Organization>>;
};

export type PersonFilterPresetsArgs = {
  kind?: InputMaybe<FilterKind>;
};

export type PersonConsents = {
  __typename?: 'PersonConsents';
  userDeletion?: Maybe<Scalars['DateTime']['output']>;
};

export type PersonConsentsInput = {
  userDeletion?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PersonFilter = {
  _and?: InputMaybe<Array<InputMaybe<PersonFilter>>>;
  _id?: InputMaybe<BigIntFilter>;
  _or?: InputMaybe<Array<InputMaybe<PersonFilter>>>;
  deletedAt?: InputMaybe<DateTimeFilter>;
  name?: InputMaybe<StringFilter>;
  organization_organizationId?: InputMaybe<BigIntFilter>;
  personId?: InputMaybe<BigIntFilter>;
  pseudonymized?: InputMaybe<BooleanFilter>;
  roles?: InputMaybe<StringArrayFilter>;
};

export type PersonReference = {
  __typename?: 'PersonReference';
  name: Scalars['String']['output'];
  organization?: Maybe<OrganizationReference>;
  personId: Scalars['BigInt']['output'];
};

export type PersonReferenceInput = {
  name: Scalars['String']['input'];
  organization?: InputMaybe<OrganizationReferenceInput>;
  personId: Scalars['BigInt']['input'];
};

export type PersonSettings = {
  __typename?: 'PersonSettings';
  /** @deprecated Gateway access only */
  _personId: Scalars['BigInt']['output'];
  actionItemListColumns: Array<Scalars['String']['output']>;
  dateFormat?: Maybe<Scalars['String']['output']>;
  defaultApprover?: Maybe<PersonReference>;
  defaultCurrency?: Maybe<Scalars['String']['output']>;
  /** @deprecated Field no longer supported */
  emailNotificationsActive?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Field no longer supported */
  emailNotificationsBatch?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Field no longer supported */
  emailNotificationsBatchCron?: Maybe<Scalars['String']['output']>;
  /** @deprecated Field no longer supported */
  emailNotificationsOwnActions?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Field no longer supported */
  emailNotificationsTopics?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Field no longer supported */
  emailRemindersActive?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Field no longer supported */
  emailReportActive?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Field no longer supported */
  emailReportCron?: Maybe<Scalars['String']['output']>;
  emailReportTimezone?: Maybe<Scalars['String']['output']>;
  exportMode?: Maybe<Scalars['String']['output']>;
  is24HourFormat?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Field no longer supported */
  isDailySummaryEmailActive?: Maybe<Scalars['Boolean']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  meetingListColumns: Array<Scalars['String']['output']>;
  printMode?: Maybe<PrintMode>;
  /** @deprecated Field no longer supported */
  sendDailySummaryEmailAtTime?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Field no longer supported */
  weekStartsOn?: Maybe<Scalars['String']['output']>;
};

export type PersonSettingsInput = {
  dateFormat?: InputMaybe<Scalars['String']['input']>;
  defaultCurrency?: InputMaybe<Scalars['String']['input']>;
  emailReportTimezone?: InputMaybe<Scalars['String']['input']>;
  exportMode?: InputMaybe<Scalars['String']['input']>;
  is24HourFormat?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  printMode?: InputMaybe<PrintMode>;
};

export type PorscheStrategy2030EmailNotification = {
  __typename?: 'PorscheStrategy2030EmailNotification';
  adhoc?: Maybe<Scalars['Boolean']['output']>;
  cron?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  options?: Maybe<PorscheStrategy2030EmailNotificationOption>;
  person: Person;
  topic: PorscheStrategy2030EmailNotificationTopic;
};

export type PorscheStrategy2030EmailNotificationOption = {
  __typename?: 'PorscheStrategy2030EmailNotificationOption';
  includeMyChanges?: Maybe<Scalars['Boolean']['output']>;
  strategyActionItemAssignedAsResponsible?: Maybe<Scalars['Boolean']['output']>;
  strategyActionItemCommentedOn?: Maybe<Scalars['Boolean']['output']>;
  strategyActionItemFinished?: Maybe<Scalars['Boolean']['output']>;
};

export type PorscheStrategy2030EmailNotificationOptionInput = {
  includeMyChanges?: InputMaybe<Scalars['Boolean']['input']>;
  strategyActionItemAssignedAsResponsible?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  strategyActionItemCommentedOn?: InputMaybe<Scalars['Boolean']['input']>;
  strategyActionItemFinished?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum PorscheStrategy2030EmailNotificationTopic {
  PorscheStrategy_2030StrategyActionItemEvents = 'PORSCHE_STRATEGY_2030_STRATEGY_ACTION_ITEM_EVENTS',
}

export type PpnMasterdataImportStatus = {
  __typename?: 'PpnMasterdataImportStatus';
  error?: Maybe<Scalars['String']['output']>;
  finishedAt?: Maybe<Scalars['DateTime']['output']>;
  groupsFinishedAt?: Maybe<Scalars['DateTime']['output']>;
  isRunning: Scalars['Boolean']['output'];
  organizationsFinishedAt?: Maybe<Scalars['DateTime']['output']>;
  personsFinishedAt?: Maybe<Scalars['DateTime']['output']>;
  startedAt: Scalars['DateTime']['output'];
};

export enum PrintMode {
  Full = 'full',
  Minimal = 'minimal',
}

export type ProfitPotential = {
  __typename?: 'ProfitPotential';
  organization: OrganizationReference;
  profitPotentialCurrency?: Maybe<Scalars['String']['output']>;
  profitPotentialIdentified: Scalars['Float']['output'];
  profitPotentialNotPossible: Scalars['Float']['output'];
  profitPotentialRealized: Scalars['Float']['output'];
};

export type ProfitPotentialRaw = {
  __typename?: 'ProfitPotentialRaw';
  identified: Array<Maybe<ActionItem>>;
  notPossible: Array<Maybe<ActionItem>>;
  realized: Array<Maybe<ActionItem>>;
};

export type Query = {
  __typename?: 'Query';
  _ability: Ability;
  _actionItems: Array<Maybe<ActionItem>>;
  _organizations: Array<Maybe<Organization>>;
  _personSettings: PersonSettings;
  _persons: Array<Maybe<Person>>;
  actionItemStatistic: Array<Maybe<ActionItemStatistic>>;
  actionItemSunburst?: Maybe<Sunburst>;
  actionItems: PaginatedActionItems;
  actionItemsByCategory: ActionItemsByCategory;
  actionItemsByStatus: Array<ActionItemCountByStatus>;
  /** @deprecated Field no longer supported */
  allActionItems: PaginatedActionItems;
  allMeetings: PaginatedMeetings;
  analytics?: Maybe<Array<Analytics>>;
  announcements: Array<Announcement>;
  audit?: Maybe<Audit>;
  audits: PaginatedAudits;
  auditsForActionItem: PaginatedAudits;
  categories?: Maybe<Array<Category>>;
  cities: Array<Scalars['String']['output']>;
  countries: Array<Scalars['String']['output']>;
  customReporting?: Maybe<Array<CustomReportingData>>;
  dashboardEmailNotifications: Array<DashboardEmailNotification>;
  featureArticle?: Maybe<FeatureArticle>;
  featureArticles: Array<FeatureArticle>;
  fieldForceManagementCalendarExport?: Maybe<FieldForceManagementCalendarExport>;
  fieldForceManagementDashboardWidgets: Array<FieldForceManagementDashboardWidget>;
  fieldForceManagementEmailNotifications: Array<FieldForceManagementEmailNotification>;
  fieldForceManagementFeatureFlag?: Maybe<FieldForceManagementFeatureFlag>;
  fieldForceManagementFeatureFlags: Array<FieldForceManagementFeatureFlag>;
  /** @deprecated Field no longer supported */
  findOrganizations: Array<Organization>;
  geoRegions: Array<Scalars['String']['output']>;
  /** @deprecated Field no longer supported */
  getActionItem?: Maybe<ActionItem>;
  getActionItemTemplate?: Maybe<ActionItemTemplate>;
  getActionItemTemplates: Array<ActionItemTemplate>;
  getAllGroups?: Maybe<Array<Group>>;
  getAllGroupsWithVisibleGroups?: Maybe<Array<Group>>;
  getAllHelpArticles: PaginatedHelpArticles;
  getAllHelpArticlesForUser: PaginatedHelpArticles;
  /** @deprecated Field no longer supported */
  getCategories?: Maybe<Array<Category>>;
  /** @deprecated Field no longer supported */
  getCategory?: Maybe<Category>;
  getCoachingModule?: Maybe<CoachingModule>;
  getCoachingModules?: Maybe<Array<CoachingModule>>;
  getConsultingMeetingType?: Maybe<ConsultingMeetingType>;
  getConsultingMeetingTypes?: Maybe<Array<ConsultingMeetingType>>;
  /** @deprecated Field no longer supported */
  getDownloadLink?: Maybe<Scalars['String']['output']>;
  getExchangeRate?: Maybe<ExchangeRate>;
  getExchangeRates?: Maybe<Array<ExchangeRate>>;
  getHelpArticle?: Maybe<HelpArticle>;
  getLoggedInUser?: Maybe<Person>;
  getMeeting?: Maybe<Meeting>;
  /** @deprecated Field no longer supported */
  getOrganization?: Maybe<Organization>;
  /** @deprecated Field no longer supported */
  getOrganizations: Array<Organization>;
  getOrganizationsCount: Scalars['Int']['output'];
  /** @deprecated Field no longer supported */
  getOrganizationsForUser?: Maybe<PaginatedOrganizations>;
  getPbeIrisKpi?: Maybe<PbeIrisKpi>;
  getPbeIrisKpis?: Maybe<Array<PbeIrisKpi>>;
  getPeerGroups: Array<PeerGroup>;
  /** @deprecated Use person instead */
  getPerson?: Maybe<Person>;
  getPersonsCountForOfflineSync: Scalars['Int']['output'];
  /** @deprecated Use persons query instead */
  getPersonsForOfflineSync?: Maybe<Array<Person>>;
  /** @deprecated Use persons instead */
  getPersonsForUser: PaginatedPersons;
  getPersonsWithPbeOnlyOrgs?: Maybe<Array<Person>>;
  getPersonsWithPeerGroups: Array<Maybe<Person>>;
  getStrategyImpact?: Maybe<StrategyImpact>;
  /** @deprecated Use strategyImpacts instead */
  getStrategyImpacts?: Maybe<Array<StrategyImpact>>;
  /** @deprecated Use tags instead */
  getTags: Array<Scalars['String']['output']>;
  /** @deprecated Field no longer supported */
  getUploadLinks: UploadUrls;
  legacyMeeting?: Maybe<LegacyMeeting>;
  legacyMeetings: LegacyMeetingConnection;
  legacyMeetingsInfo: LegacyMeetingsInfo;
  legacyMeetingsUploadLinks: UploadUrls;
  markets: Array<Scalars['String']['output']>;
  meetingStatistic: Array<Maybe<MeetingStatistic>>;
  meetingSunburst?: Maybe<Sunburst>;
  multisensoryExperience?: Maybe<MultisensoryExperience>;
  multisensoryExperienceArea?: Maybe<MultisensoryExperienceArea>;
  multisensoryExperienceAreas: Array<MultisensoryExperienceArea>;
  multisensoryExperienceEmailNotifications: Array<MultisensoryExperienceEmailNotification>;
  multisensoryExperienceFilterPresets?: Maybe<
    Array<MultisensoryExperienceFilterPreset>
  >;
  multisensoryExperienceItem?: Maybe<MultisensoryExperienceItem>;
  multisensoryExperienceItems: Array<MultisensoryExperienceItem>;
  multisensoryExperienceMeasure?: Maybe<MultisensoryExperienceMeasure>;
  multisensoryExperienceMeasureTask?: Maybe<MultisensoryExperienceMeasureTask>;
  multisensoryExperienceMeasureTasks: Array<MultisensoryExperienceMeasureTask>;
  multisensoryExperienceMeasures: Array<MultisensoryExperienceMeasure>;
  multisensoryExperiences: Array<MultisensoryExperience>;
  mysteryShoppingBottomCriterias: Array<MysteryShoppingCriteria>;
  mysteryShoppingCriterias: Array<MysteryShoppingCriteria>;
  mysteryShoppingScenarios: Array<MysteryShoppingScenario>;
  mysteryShoppingWave?: Maybe<MysteryShoppingWave>;
  mysteryShoppingWaves: Array<MysteryShoppingWave>;
  networkPlanningFeatureFlag?: Maybe<NetworkPlanningFeatureFlag>;
  networkPlanningFeatureFlags: Array<NetworkPlanningFeatureFlag>;
  networkPlanningMarkets: Array<Scalars['String']['output']>;
  networkPlanningOrganizations: Array<Maybe<NetworkPlanningOrganization>>;
  occasionRelatedApproval?: Maybe<OccasionRelatedApproval>;
  occasionRelatedApprovalReports: Array<Maybe<Scalars['String']['output']>>;
  occasionRelatedApprovals: Array<OccasionRelatedApproval>;
  organization?: Maybe<Organization>;
  organizations: PaginatedOrganizations;
  person?: Maybe<Person>;
  persons: PaginatedPersons;
  porscheStrategy2030EmailNotifications: Array<PorscheStrategy2030EmailNotification>;
  ppnMasterdataImportStatus?: Maybe<PpnMasterdataImportStatus>;
  profitPotential: Array<ProfitPotential>;
  profitPotentialRaw: ProfitPotentialRaw;
  questionnaire?: Maybe<Questionnaire>;
  questionnaireForAdminArea?: Maybe<Questionnaire>;
  questionnaireRoadToXReport: QuestionnaireRoadToXReport;
  questionnaireTypeConfig?: Maybe<QuestionnaireTypeConfig>;
  questionnaireTypeConfigs: PaginatedQuestionnaireTypeConfig;
  questionnaires: PaginatedQuestionnaires;
  questionnairesForActionItem: PaginatedQuestionnaires;
  questionnairesForAdminArea: PaginatedQuestionnaires;
  regions: Array<Scalars['String']['output']>;
  retailDesignAbsenceNotes: Array<RetailDesignAbsenceNote>;
  retailDesignCities: Array<Scalars['String']['output']>;
  retailDesignDocument?: Maybe<RetailDesignDocument>;
  retailDesignDocuments: Array<RetailDesignDocument>;
  retailDesignEmailNotifications: Array<RetailDesignEmailNotification>;
  retailDesignFilterPresets?: Maybe<Array<RetailDesignFilterPreset>>;
  retailDesignFormat?: Maybe<RetailDesignFormat>;
  retailDesignFormats: Array<RetailDesignFormat>;
  retailDesignProject?: Maybe<RetailDesignProject>;
  retailDesignProjectType?: Maybe<RetailDesignProjectType>;
  retailDesignProjectTypes: Array<RetailDesignProjectType>;
  retailDesignProjects: Array<RetailDesignProject>;
  strategyActionItem?: Maybe<StrategyActionItem>;
  strategyActionItems?: Maybe<Array<Maybe<StrategyActionItem>>>;
  strategyField?: Maybe<StrategyField>;
  strategyFields: Array<StrategyField>;
  strategyImpacts?: Maybe<Array<StrategyImpact>>;
  strategyKpi?: Maybe<StrategyKpi>;
  strategyKpis: Array<StrategyKpi>;
  strategyStage?: Maybe<StrategyStage>;
  strategyStages: Array<StrategyStage>;
  supportRequest?: Maybe<SupportRequest>;
  supportRequests: Array<SupportRequest>;
  survey?: Maybe<Survey>;
  surveyEmailNotifications: Array<SurveyEmailNotification>;
  surveyInstances: SurveyInstanceConnection;
  surveyReportConfig?: Maybe<SurveyReportConfig>;
  surveyReportConfigs: Array<SurveyReportConfig>;
  surveyReports: Array<SurveyReport>;
  surveyResult?: Maybe<SurveyResult>;
  surveyResults: Array<SurveyResult>;
  surveyResultsByItem: SurveyResultsByItemConnection;
  surveyResultsByItemOverTime: Array<SurveyResultsByItemOverTimeNode>;
  surveyResultsByOrganization: SurveyResultsByOrganizationConnection;
  surveyResultsByOrganizationOverTime: Array<SurveyResultsByOrganizationOverTimeNode>;
  surveyType?: Maybe<SurveyType>;
  surveyTypes: Array<SurveyType>;
  surveys: Array<Survey>;
  tags: Array<Scalars['String']['output']>;
  topLevelOrganizations: Array<Organization>;
  uploadUrls: UploadUrls;
  userManagementItem?: Maybe<UserManagementItem>;
  userManagementItems: Array<UserManagementItem>;
};

export type Query_AbilityArgs = {
  key: Scalars['_Key']['input'];
};

export type Query_ActionItemsArgs = {
  keys: Array<Scalars['_Key']['input']>;
};

export type Query_OrganizationsArgs = {
  keys: Array<Scalars['_Key']['input']>;
};

export type Query_PersonSettingsArgs = {
  key: Scalars['_Key']['input'];
};

export type Query_PersonsArgs = {
  keys: Array<Scalars['_Key']['input']>;
};

export type QueryActionItemStatisticArgs = {
  input: ActionItemStatisticInput;
};

export type QueryActionItemSunburstArgs = {
  filter?: InputMaybe<ActionItemFilter>;
  rootOrgaId?: InputMaybe<Scalars['Int']['input']>;
  rootOrgaIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryActionItemsArgs = {
  filter?: InputMaybe<ActionItemFilter>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ActionItemSort>;
};

export type QueryActionItemsByCategoryArgs = {
  filter?: InputMaybe<ActionItemFilter>;
  rootOrgaId?: InputMaybe<Scalars['Int']['input']>;
  rootOrgaIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryActionItemsByStatusArgs = {
  filter?: InputMaybe<ActionItemFilter>;
};

export type QueryAllActionItemsArgs = {
  filter?: InputMaybe<ActionItemFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ActionItemSort>;
};

export type QueryAllMeetingsArgs = {
  distinct?: InputMaybe<MeetingDistinctBy>;
  filter?: InputMaybe<MeetingFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<MeetingSort>;
};

export type QueryAnalyticsArgs = {
  input: AnalyticsInput;
};

export type QueryAuditArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryAuditsArgs = {
  filter?: InputMaybe<AuditFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AuditSort>;
};

export type QueryAuditsForActionItemArgs = {
  actionItemId: Scalars['ObjectID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AuditSort>;
};

export type QueryCountriesArgs = {
  filter?: InputMaybe<CountryFilter>;
};

export type QueryCustomReportingArgs = {
  entity: ChartEntity;
  filter: ChartFilter;
  properties: ChartProperties;
  reportType?: InputMaybe<ReportType>;
  type: ChartType;
};

export type QueryFeatureArticleArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryFieldForceManagementEmailNotificationsArgs = {
  topic?: InputMaybe<FieldForceManagementEmailNotificationTopic>;
};

export type QueryFieldForceManagementFeatureFlagArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryFindOrganizationsArgs = {
  searchString: Scalars['String']['input'];
};

export type QueryGeoRegionsArgs = {
  filter?: InputMaybe<GeoRegionFilter>;
};

export type QueryGetActionItemArgs = {
  id: Scalars['ObjectID']['input'];
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
};

export type QueryGetActionItemTemplateArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryGetAllGroupsArgs = {
  searchString?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetAllHelpArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<HelpArticleSort>;
};

export type QueryGetAllHelpArticlesForUserArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<HelpArticleType>;
};

export type QueryGetCategoryArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryGetCoachingModuleArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryGetConsultingMeetingTypeArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryGetDownloadLinkArgs = {
  fileId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type QueryGetExchangeRateArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryGetHelpArticleArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryGetMeetingArgs = {
  id: Scalars['ObjectID']['input'];
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
};

export type QueryGetOrganizationArgs = {
  id: Scalars['BigInt']['input'];
};

export type QueryGetOrganizationsArgs = {
  ids: Array<Scalars['BigInt']['input']>;
};

export type QueryGetOrganizationsForUserArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchString?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetPbeIrisKpiArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryGetPbeIrisKpisArgs = {
  filter?: InputMaybe<PbeIrisFilter>;
};

export type QueryGetPeerGroupsArgs = {
  sort?: InputMaybe<PeerGroupSort>;
};

export type QueryGetPersonArgs = {
  id: Scalars['BigInt']['input'];
};

export type QueryGetPersonsForOfflineSyncArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetPersonsForUserArgs = {
  filter?: InputMaybe<PersonFilter>;
  forUserOptions?: InputMaybe<ForUserOptions>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchString?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetStrategyImpactArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryGetTagsArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetUploadLinksArgs = {
  fileNames: Array<Scalars['String']['input']>;
  type?: InputMaybe<UploadType>;
};

export type QueryLegacyMeetingArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLegacyMeetingsArgs = {
  filter?: InputMaybe<LegacyMeetingsFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLegacyMeetingsInfoArgs = {
  market?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryLegacyMeetingsUploadLinksArgs = {
  fileNames: Array<Scalars['String']['input']>;
  market: Scalars['String']['input'];
};

export type QueryMarketsArgs = {
  filter?: InputMaybe<MarketFilter>;
};

export type QueryMeetingStatisticArgs = {
  input: MeetingStatisticInput;
};

export type QueryMeetingSunburstArgs = {
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MeetingFilter>;
  rootOrgaId?: InputMaybe<Scalars['Int']['input']>;
  rootOrgaIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryMultisensoryExperienceArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryMultisensoryExperienceAreaArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryMultisensoryExperienceAreasArgs = {
  input?: InputMaybe<MultisensoryExperienceAreasInput>;
};

export type QueryMultisensoryExperienceItemArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryMultisensoryExperienceItemsArgs = {
  input?: InputMaybe<MultisensoryExperienceItemsInput>;
};

export type QueryMultisensoryExperienceMeasureArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryMultisensoryExperienceMeasureTaskArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryMultisensoryExperienceMeasureTasksArgs = {
  input?: InputMaybe<MultisensoryExperienceMeasureTasksInput>;
};

export type QueryMultisensoryExperienceMeasuresArgs = {
  input?: InputMaybe<MultisensoryExperienceMeasuresInput>;
};

export type QueryMultisensoryExperiencesArgs = {
  input?: InputMaybe<MultisensoryExperiencesInput>;
};

export type QueryMysteryShoppingBottomCriteriasArgs = {
  filter?: InputMaybe<MysteryShoppingCriteriaFilter>;
};

export type QueryMysteryShoppingCriteriasArgs = {
  filter?: InputMaybe<MysteryShoppingCriteriaFilter>;
};

export type QueryMysteryShoppingScenariosArgs = {
  filter?: InputMaybe<MysteryShoppingScenarioFilter>;
  groupBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryMysteryShoppingWaveArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryNetworkPlanningFeatureFlagArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryNetworkPlanningMarketsArgs = {
  filter?: InputMaybe<MarketFilter>;
};

export type QueryNetworkPlanningOrganizationsArgs = {
  region?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOccasionRelatedApprovalArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryOrganizationArgs = {
  id?: InputMaybe<Scalars['BigInt']['input']>;
};

export type QueryOrganizationsArgs = {
  filter?: InputMaybe<OrganizationFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchString?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPersonArgs = {
  id?: InputMaybe<Scalars['BigInt']['input']>;
  ppnuid?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPersonsArgs = {
  filter?: InputMaybe<PersonFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchString?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPorscheStrategy2030EmailNotificationsArgs = {
  topic?: InputMaybe<PorscheStrategy2030EmailNotificationTopic>;
};

export type QueryProfitPotentialArgs = {
  currency?: InputMaybe<Currency>;
  endDate: Scalars['DateTime']['input'];
  kind?: InputMaybe<ActionItemKind>;
  organizationId?: InputMaybe<Scalars['BigInt']['input']>;
  organizationIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startDate: Scalars['DateTime']['input'];
};

export type QueryProfitPotentialRawArgs = {
  currency?: InputMaybe<Currency>;
  endDate: Scalars['DateTime']['input'];
  kind?: InputMaybe<ActionItemKind>;
  organizationIds: Array<Scalars['BigInt']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export type QueryQuestionnaireArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryQuestionnaireForAdminAreaArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryQuestionnaireRoadToXReportArgs = {
  organizationId: Scalars['BigInt']['input'];
};

export type QueryQuestionnaireTypeConfigArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryQuestionnaireTypeConfigsArgs = {
  filter?: InputMaybe<QuestionnaireTypeConfigFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<QuestionnaireTypeConfigSort>;
};

export type QueryQuestionnairesArgs = {
  filter?: InputMaybe<QuestionnaireFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<QuestionnaireSort>;
};

export type QueryQuestionnairesForActionItemArgs = {
  actionItemId: Scalars['ObjectID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<QuestionnaireSort>;
};

export type QueryQuestionnairesForAdminAreaArgs = {
  filter?: InputMaybe<QuestionnaireFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<QuestionnaireSort>;
};

export type QueryRetailDesignCitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchString?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRetailDesignDocumentArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryRetailDesignFormatArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryRetailDesignProjectArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryRetailDesignProjectTypeArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryRetailDesignProjectsArgs = {
  input?: InputMaybe<RetailDesignProjectsInput>;
};

export type QueryStrategyActionItemArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryStrategyActionItemsArgs = {
  input?: InputMaybe<StrategyActionItemsInput>;
};

export type QueryStrategyFieldArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryStrategyFieldsArgs = {
  input?: InputMaybe<StrategyFieldsFilterInput>;
};

export type QueryStrategyKpiArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryStrategyKpisArgs = {
  input?: InputMaybe<StrategyKpisInput>;
};

export type QueryStrategyStageArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryStrategyStagesArgs = {
  input?: InputMaybe<StrategyStagesFilterInput>;
};

export type QuerySupportRequestArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QuerySurveyArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QuerySurveyInstancesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filter?: InputMaybe<SurveyInstanceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  orderBy?: InputMaybe<SurveyInstanceOrderBy>;
};

export type QuerySurveyReportConfigArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QuerySurveyReportsArgs = {
  input: SurveyReportInput;
};

export type QuerySurveyResultArgs = {
  answeredFor: Scalars['BigInt']['input'];
  surveyId: Scalars['ObjectID']['input'];
};

export type QuerySurveyResultsArgs = {
  surveyId: Scalars['ObjectID']['input'];
};

export type QuerySurveyResultsByItemArgs = {
  input: SurveyResultsByItemInput;
};

export type QuerySurveyResultsByItemOverTimeArgs = {
  input: SurveyResultsByItemOverTimeInput;
};

export type QuerySurveyResultsByOrganizationArgs = {
  input: SurveyResultsByOrganizationInput;
};

export type QuerySurveyResultsByOrganizationOverTimeArgs = {
  input: SurveyResultsByOrganizationOverTimeInput;
};

export type QuerySurveyTypeArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QuerySurveysArgs = {
  filter?: InputMaybe<SurveyFilter>;
};

export type QueryUploadUrlsArgs = {
  filenames: Array<Scalars['String']['input']>;
  reference: UploadReference;
  type: UploadType;
};

export type QueryUserManagementItemArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryUserManagementItemsArgs = {
  input?: InputMaybe<UserManagementItemsInput>;
};

export type Questionnaire = {
  __typename?: 'Questionnaire';
  additionalPersons?: Maybe<Array<Person>>;
  allowMultipleAssignments?: Maybe<Scalars['Boolean']['output']>;
  allowMultipleAssignmentsForPerson?: Maybe<Scalars['Boolean']['output']>;
  author: PersonReference;
  children: Array<QuestionnaireItem>;
  copiedFrom?: Maybe<Scalars['ObjectID']['output']>;
  copyAnswersActionItemId?: Maybe<Scalars['ObjectID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** ffmAlias: questionnaireId */
  id: Scalars['ObjectID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAssigned?: Maybe<Scalars['Boolean']['output']>;
  languages: Array<Scalars['String']['output']>;
  name: Scalars['JSON']['output'];
  organizations: Array<Organization>;
  type: QuestionnaireType;
};

export type QuestionnaireCopyAnswersActionItemIdArgs = {
  actionItemId: Scalars['ObjectID']['input'];
};

export type QuestionnaireAnswer = {
  item: QuestionnaireItem;
};

export type QuestionnaireAnswerInput = {
  currencyAnswer?: InputMaybe<QuestionnaireCurrencyAnswerInput>;
  itemId: Scalars['ObjectID']['input'];
  kind: AnswerType;
  multipleChoiceAnswer?: InputMaybe<QuestionnaireMultipleChoiceAnswerInput>;
  notApplicable?: InputMaybe<Scalars['Boolean']['input']>;
  textAnswer?: InputMaybe<QuestionnaireTextAnswerInput>;
  yesNoAnswer?: InputMaybe<QuestionnaireYesNoAnswerInput>;
};

export type QuestionnaireCurrencyAnswer = QuestionnaireAnswer & {
  __typename?: 'QuestionnaireCurrencyAnswer';
  answer?: Maybe<Scalars['Float']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  followUp?: Maybe<Scalars['Boolean']['output']>;
  item: QuestionnaireItem;
  notApplicable?: Maybe<Scalars['Boolean']['output']>;
  uploads?: Maybe<Array<Attachment>>;
};

export type QuestionnaireCurrencyAnswerInput = {
  answer?: InputMaybe<Scalars['Float']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  followUp?: InputMaybe<Scalars['Boolean']['input']>;
  notApplicable?: InputMaybe<Scalars['Boolean']['input']>;
  uploads?: InputMaybe<Array<AttachmentInput>>;
};

export type QuestionnaireCurrencyQuestion = QuestionnaireItem & {
  __typename?: 'QuestionnaireCurrencyQuestion';
  allowComment?: Maybe<Scalars['Boolean']['output']>;
  allowFollowUp?: Maybe<Scalars['Boolean']['output']>;
  allowNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  allowUpload?: Maybe<Scalars['Boolean']['output']>;
  attachments?: Maybe<Scalars['JSON']['output']>;
  currency: Currency;
  description?: Maybe<Scalars['JSON']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  /** ffmAlias: itemId */
  id: Scalars['ObjectID']['output'];
  isCopied?: Maybe<Scalars['Boolean']['output']>;
  kind: QuestionnaireItemKind;
  question: Scalars['JSON']['output'];
};

export type QuestionnaireFilter = {
  _and?: InputMaybe<Array<InputMaybe<QuestionnaireFilter>>>;
  _or?: InputMaybe<Array<InputMaybe<QuestionnaireFilter>>>;
  author_personId?: InputMaybe<BigIntFilter>;
  isActive?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<TranslationFilter>;
  organizations_organizationId?: InputMaybe<BigIntFilter>;
  type?: InputMaybe<QuestionnaireTypeFilter>;
};

export type QuestionnaireInput = {
  additionalPersons?: InputMaybe<Array<PersonReferenceInput>>;
  allowMultipleAssignments?: InputMaybe<Scalars['Boolean']['input']>;
  allowMultipleAssignmentsForPerson?: InputMaybe<Scalars['Boolean']['input']>;
  children?: InputMaybe<Array<QuestionnaireItemInput>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['JSON']['input']>;
  organizations?: InputMaybe<Array<OrganizationReferenceInput>>;
  type?: InputMaybe<QuestionnaireType>;
};

export type QuestionnaireItem = {
  /** ffmAlias: itemId */
  id: Scalars['ObjectID']['output'];
  kind: QuestionnaireItemKind;
};

export type QuestionnaireItemInput = {
  allowComment?: InputMaybe<Scalars['Boolean']['input']>;
  allowFollowUp?: InputMaybe<Scalars['Boolean']['input']>;
  allowNotApplicable?: InputMaybe<Scalars['Boolean']['input']>;
  allowUpload?: InputMaybe<Scalars['Boolean']['input']>;
  attachments?: InputMaybe<Scalars['JSON']['input']>;
  choiceType?: InputMaybe<ChoiceType>;
  choices?: InputMaybe<Array<ChoiceInput>>;
  currency?: InputMaybe<Currency>;
  description?: InputMaybe<Scalars['JSON']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ObjectID']['input'];
  isCopied?: InputMaybe<Scalars['Boolean']['input']>;
  kind: QuestionnaireItemKind;
  level?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Scalars['JSON']['input']>;
  requireComment?: InputMaybe<Scalars['Boolean']['input']>;
  requireCommentForYes?: InputMaybe<Scalars['Boolean']['input']>;
  requireUpload?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['JSON']['input']>;
};

export enum QuestionnaireItemKind {
  QuestionnaireCurrencyQuestion = 'QuestionnaireCurrencyQuestion',
  QuestionnaireMultipleChoiceQuestion = 'QuestionnaireMultipleChoiceQuestion',
  QuestionnaireSection = 'QuestionnaireSection',
  QuestionnaireTextQuestion = 'QuestionnaireTextQuestion',
  QuestionnaireYesNoQuestion = 'QuestionnaireYesNoQuestion',
}

export type QuestionnaireMultipleChoiceAnswer = QuestionnaireAnswer & {
  __typename?: 'QuestionnaireMultipleChoiceAnswer';
  answers?: Maybe<Array<Choice>>;
  comment?: Maybe<Scalars['String']['output']>;
  followUp?: Maybe<Scalars['Boolean']['output']>;
  item: QuestionnaireItem;
  notApplicable?: Maybe<Scalars['Boolean']['output']>;
  uploads?: Maybe<Array<Attachment>>;
};

export type QuestionnaireMultipleChoiceAnswerInput = {
  choiceIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  followUp?: InputMaybe<Scalars['Boolean']['input']>;
  notApplicable?: InputMaybe<Scalars['Boolean']['input']>;
  uploads?: InputMaybe<Array<AttachmentInput>>;
};

export type QuestionnaireMultipleChoiceQuestion = QuestionnaireItem & {
  __typename?: 'QuestionnaireMultipleChoiceQuestion';
  allowComment?: Maybe<Scalars['Boolean']['output']>;
  allowFollowUp?: Maybe<Scalars['Boolean']['output']>;
  allowNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  allowUpload?: Maybe<Scalars['Boolean']['output']>;
  attachments?: Maybe<Scalars['JSON']['output']>;
  choiceType?: Maybe<ChoiceType>;
  choices?: Maybe<Array<Choice>>;
  description?: Maybe<Scalars['JSON']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  /** ffmAlias: itemId */
  id: Scalars['ObjectID']['output'];
  isCopied?: Maybe<Scalars['Boolean']['output']>;
  kind: QuestionnaireItemKind;
  question: Scalars['JSON']['output'];
};

export type QuestionnaireRoadToX = {
  __typename?: 'QuestionnaireRoadToX';
  answers?: Maybe<Array<QuestionnaireRoadToXAnswer>>;
  label: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  readiness?: Maybe<Array<QuestionnaireRoadToXReadiness>>;
  worldReadiness?: Maybe<Scalars['Float']['output']>;
};

export type QuestionnaireRoadToXAnswer = {
  __typename?: 'QuestionnaireRoadToXAnswer';
  organizationId: Scalars['BigInt']['output'];
  value: Scalars['String']['output'];
};

export type QuestionnaireRoadToXOrganization = {
  __typename?: 'QuestionnaireRoadToXOrganization';
  isImporterOfRegionalOffice: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['BigInt']['output'];
  readiness: Scalars['Float']['output'];
};

export type QuestionnaireRoadToXReadiness = {
  __typename?: 'QuestionnaireRoadToXReadiness';
  organizationId: Scalars['BigInt']['output'];
  value: Scalars['Float']['output'];
};

export type QuestionnaireRoadToXReport = {
  __typename?: 'QuestionnaireRoadToXReport';
  organizations?: Maybe<Array<QuestionnaireRoadToXOrganization>>;
  questionnaire?: Maybe<Array<QuestionnaireRoadToX>>;
  worldReadiness: Scalars['Float']['output'];
};

export type QuestionnaireSection = QuestionnaireItem & {
  __typename?: 'QuestionnaireSection';
  /** ffmAlias: itemId */
  id: Scalars['ObjectID']['output'];
  kind: QuestionnaireItemKind;
  level: Scalars['Int']['output'];
  title: Scalars['JSON']['output'];
};

export type QuestionnaireSort = {
  author_name?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type QuestionnaireTextAnswer = QuestionnaireAnswer & {
  __typename?: 'QuestionnaireTextAnswer';
  answer?: Maybe<Scalars['String']['output']>;
  followUp?: Maybe<Scalars['Boolean']['output']>;
  item: QuestionnaireItem;
  notApplicable?: Maybe<Scalars['Boolean']['output']>;
  uploads?: Maybe<Array<Attachment>>;
};

export type QuestionnaireTextAnswerInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  followUp?: InputMaybe<Scalars['Boolean']['input']>;
  notApplicable?: InputMaybe<Scalars['Boolean']['input']>;
  uploads?: InputMaybe<Array<AttachmentInput>>;
};

export type QuestionnaireTextQuestion = QuestionnaireItem & {
  __typename?: 'QuestionnaireTextQuestion';
  allowFollowUp?: Maybe<Scalars['Boolean']['output']>;
  allowNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  allowUpload?: Maybe<Scalars['Boolean']['output']>;
  attachments?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['JSON']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  /** ffmAlias: itemId */
  id: Scalars['ObjectID']['output'];
  isCopied?: Maybe<Scalars['Boolean']['output']>;
  kind: QuestionnaireItemKind;
  question: Scalars['JSON']['output'];
};

export enum QuestionnaireType {
  AccidentServiceManagement = 'ACCIDENT_SERVICE_MANAGEMENT',
  EperformanceRoadToXDealer = 'EPERFORMANCE_ROAD_TO_X_DEALER',
  EperformanceRoadToXImporter = 'EPERFORMANCE_ROAD_TO_X_IMPORTER',
  EperformanceTaycanReadiness = 'EPERFORMANCE_TAYCAN_READINESS',
  Greenland = 'GREENLAND',
  Pdsr = 'PDSR',
  PorscheApproved = 'PORSCHE_APPROVED',
  Qst = 'QST',
  Standard = 'STANDARD',
  SustainabilityDealership = 'SUSTAINABILITY_DEALERSHIP',
  Test = 'TEST',
}

export type QuestionnaireTypeConfig = {
  __typename?: 'QuestionnaireTypeConfig';
  /** ffmAlias: questionnaireTypeConfigId */
  id: Scalars['ObjectID']['output'];
  organization: Organization;
  planningRound?: Maybe<Scalars['String']['output']>;
  type: QuestionnaireType;
  weightsPerYear?: Maybe<Scalars['QuestionnaireTypeWeightsPerYear']['output']>;
};

export type QuestionnaireTypeConfigCreationInput = {
  id?: InputMaybe<Scalars['ObjectID']['input']>;
  organizationId: Scalars['BigInt']['input'];
  planningRound?: InputMaybe<Scalars['String']['input']>;
  type: QuestionnaireType;
  weightsPerYear?: InputMaybe<
    Scalars['QuestionnaireTypeWeightsPerYear']['input']
  >;
};

export type QuestionnaireTypeConfigEditInput = {
  organizationId: Scalars['BigInt']['input'];
  planningRound?: InputMaybe<Scalars['String']['input']>;
  type: QuestionnaireType;
  weightsPerYear?: InputMaybe<
    Scalars['QuestionnaireTypeWeightsPerYear']['input']
  >;
};

export type QuestionnaireTypeConfigFilter = {
  organizationId?: InputMaybe<BigIntFilter>;
  type?: InputMaybe<QuestionnaireTypeFilter>;
};

export type QuestionnaireTypeConfigSort = {
  type?: InputMaybe<SortOrder>;
};

export type QuestionnaireTypeFilter = {
  _eq?: InputMaybe<QuestionnaireType>;
  _in?: InputMaybe<Array<InputMaybe<QuestionnaireType>>>;
  _nin?: InputMaybe<Array<InputMaybe<QuestionnaireType>>>;
};

export type QuestionnaireYesNoAnswer = QuestionnaireAnswer & {
  __typename?: 'QuestionnaireYesNoAnswer';
  answer?: Maybe<YesNoAnswer>;
  comment?: Maybe<Scalars['String']['output']>;
  followUp?: Maybe<Scalars['Boolean']['output']>;
  item: QuestionnaireItem;
  notApplicable?: Maybe<Scalars['Boolean']['output']>;
  uploads?: Maybe<Array<Attachment>>;
};

export type QuestionnaireYesNoAnswerInput = {
  answer?: InputMaybe<YesNoAnswer>;
  comment?: InputMaybe<Scalars['String']['input']>;
  followUp?: InputMaybe<Scalars['Boolean']['input']>;
  notApplicable?: InputMaybe<Scalars['Boolean']['input']>;
  uploads?: InputMaybe<Array<AttachmentInput>>;
};

export type QuestionnaireYesNoQuestion = QuestionnaireItem & {
  __typename?: 'QuestionnaireYesNoQuestion';
  allowComment?: Maybe<Scalars['Boolean']['output']>;
  allowFollowUp?: Maybe<Scalars['Boolean']['output']>;
  allowNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  allowUpload?: Maybe<Scalars['Boolean']['output']>;
  attachments?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['JSON']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  /** ffmAlias: itemId */
  id: Scalars['ObjectID']['output'];
  isCopied?: Maybe<Scalars['Boolean']['output']>;
  kind: QuestionnaireItemKind;
  question: Scalars['JSON']['output'];
  /** If true, a comment is required if the answer is no. */
  requireComment?: Maybe<Scalars['Boolean']['output']>;
  requireCommentForYes?: Maybe<Scalars['Boolean']['output']>;
  requireUpload?: Maybe<Scalars['Boolean']['output']>;
};

export enum Reason {
  ConsultancyProject = 'CONSULTANCY_PROJECT',
  DealerFinancingDealerSupport = 'DEALER_FINANCING_DEALER_SUPPORT',
  EvaluationOfTargetDeviation = 'EVALUATION_OF_TARGET_DEVIATION',
  InvestmentPlanning = 'INVESTMENT_PLANNING',
  PreparationOfInvestorMeeting = 'PREPARATION_OF_INVESTOR_MEETING',
  QuotaAllocation = 'QUOTA_ALLOCATION',
  ReviewHourlyRateWarranty = 'REVIEW_HOURLY_RATE_WARRANTY',
  ReviewOfBusinessPlan = 'REVIEW_OF_BUSINESS_PLAN',
  RiskAnalysis = 'RISK_ANALYSIS',
  RiskManagement = 'RISK_MANAGEMENT',
}

export type RemoveFilterPresetInput = {
  kind: FilterKind;
  name: Scalars['String']['input'];
  personId: Scalars['BigInt']['input'];
};

export type ReportColumn = {
  __typename?: 'ReportColumn';
  items: Array<Maybe<ReportItem>>;
  width: Scalars['Int']['output'];
};

export type ReportColumnInput = {
  items: Array<InputMaybe<ReportItemInput>>;
  width: Scalars['Int']['input'];
};

export type ReportItem = {
  __typename?: 'ReportItem';
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  props?: Maybe<Scalars['JSON']['output']>;
  type: ReportType;
};

export type ReportItemInput = {
  id: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
  props?: InputMaybe<Scalars['JSON']['input']>;
  type: ReportType;
};

export type ReportTab = {
  __typename?: 'ReportTab';
  columns: Array<ReportColumn>;
  key: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
};

export type ReportTabInput = {
  columns: Array<ReportColumnInput>;
  key: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
};

export enum ReportType {
  Custom = 'CUSTOM',
  PbeActionItemsImplemented = 'PBE_ACTION_ITEMS_IMPLEMENTED',
  PbeCoachingDays = 'PBE_COACHING_DAYS',
  PbeCoachingDaysByCoachingModule = 'PBE_COACHING_DAYS_BY_COACHING_MODULE',
  PbeCoachingModulesByCoachingDay = 'PBE_COACHING_MODULES_BY_COACHING_DAY',
  PbeFocusArea = 'PBE_FOCUS_AREA',
  PbeProfitPotential = 'PBE_PROFIT_POTENTIAL',
  PbeRolloutStatus = 'PBE_ROLLOUT_STATUS',
  PscActionItemsImplemented = 'PSC_ACTION_ITEMS_IMPLEMENTED',
  PscCoachingDays = 'PSC_COACHING_DAYS',
  PscCoachingDaysByCoachingModule = 'PSC_COACHING_DAYS_BY_COACHING_MODULE',
  PscCoachingModulesByCoachingDay = 'PSC_COACHING_MODULES_BY_COACHING_DAY',
  PscFocusArea = 'PSC_FOCUS_AREA',
  PscProfitPotential = 'PSC_PROFIT_POTENTIAL',
  PscRolloutStatus = 'PSC_ROLLOUT_STATUS',
}

export type Resources = {
  __typename?: 'Resources';
  actionItemId?: Maybe<Scalars['ObjectID']['output']>;
  auditId?: Maybe<Scalars['ObjectID']['output']>;
  greenland_occasionRelatedApprovalId?: Maybe<Scalars['ObjectID']['output']>;
  mysteryShopping_criteriaId?: Maybe<
    Array<Maybe<Scalars['ObjectID']['output']>>
  >;
  questionGroupId?: Maybe<Scalars['Int']['output']>;
  questionId?: Maybe<Scalars['ObjectID']['output']>;
  questionnaireId?: Maybe<Scalars['ObjectID']['output']>;
  survey_surveyAnswerId?: Maybe<Scalars['ObjectID']['output']>;
  widgetId?: Maybe<Scalars['ObjectID']['output']>;
};

export type ResourcesInput = {
  actionItemId?: InputMaybe<Scalars['ObjectID']['input']>;
  auditId?: InputMaybe<Scalars['ObjectID']['input']>;
  greenland_occasionRelatedApprovalId?: InputMaybe<
    Scalars['ObjectID']['input']
  >;
  mysteryShopping_criteriaId?: InputMaybe<
    Array<InputMaybe<Scalars['ObjectID']['input']>>
  >;
  questionGroupId?: InputMaybe<Scalars['Int']['input']>;
  questionId?: InputMaybe<Scalars['ObjectID']['input']>;
  questionnaireId?: InputMaybe<Scalars['ObjectID']['input']>;
  survey_surveyAnswerId?: InputMaybe<Scalars['ObjectID']['input']>;
  widgetId?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type RetailDesignAbsenceNote = {
  __typename?: 'RetailDesignAbsenceNote';
  description: Scalars['String']['output'];
  from?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ObjectID']['output'];
  isActive: Scalars['Boolean']['output'];
  to?: Maybe<Scalars['Date']['output']>;
};

export type RetailDesignDocument = {
  __typename?: 'RetailDesignDocument';
  id: Scalars['ObjectID']['output'];
  mandatory?: Maybe<Scalars['Boolean']['output']>;
  mandatoryForStatus: Array<RetailDesignProjectStatus>;
  mandatoryOnlyForFormatIds?: Maybe<Array<Scalars['ObjectID']['output']>>;
  name: Scalars['String']['output'];
  translations?: Maybe<Scalars['JSON']['output']>;
};

export type RetailDesignEmailNotification = {
  __typename?: 'RetailDesignEmailNotification';
  adhoc?: Maybe<Scalars['Boolean']['output']>;
  cron?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  options?: Maybe<RetailDesignEmailNotificationOption>;
  person: Person;
  topic: RetailDesignEmailNotificationTopic;
};

export type RetailDesignEmailNotificationOption = {
  __typename?: 'RetailDesignEmailNotificationOption';
  notifyDaysBeforeCompletion?: Maybe<Scalars['Int']['output']>;
};

export type RetailDesignEmailNotificationOptionInput = {
  notifyDaysBeforeCompletion?: InputMaybe<Scalars['Int']['input']>;
};

export enum RetailDesignEmailNotificationTopic {
  RetailDesignProjectDocumentUpload = 'RETAIL_DESIGN_PROJECT_DOCUMENT_UPLOAD',
  RetailDesignProjectNearingCompletion = 'RETAIL_DESIGN_PROJECT_NEARING_COMPLETION',
  RetailDesignProjectStatusChange = 'RETAIL_DESIGN_PROJECT_STATUS_CHANGE',
}

export type RetailDesignFilterPreset = {
  __typename?: 'RetailDesignFilterPreset';
  filter: RetailDesignFilterPresetConfig;
  id: Scalars['ObjectID']['output'];
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export type RetailDesignFilterPresetConfig = {
  __typename?: 'RetailDesignFilterPresetConfig';
  author?: Maybe<Array<Scalars['BigInt']['output']>>;
  city?: Maybe<Array<Scalars['String']['output']>>;
  expectedSalesVolumePerYearFrom?: Maybe<Scalars['Int']['output']>;
  expectedSalesVolumePerYearTo?: Maybe<Scalars['Int']['output']>;
  expectedWorkshopThroughputPerDayFrom?: Maybe<Scalars['Int']['output']>;
  expectedWorkshopThroughputPerDayTo?: Maybe<Scalars['Int']['output']>;
  format?: Maybe<Array<Scalars['String']['output']>>;
  hasBookmark?: Maybe<Scalars['Boolean']['output']>;
  onlyCreated?: Maybe<Scalars['Boolean']['output']>;
  organization?: Maybe<Array<Scalars['BigInt']['output']>>;
  plannedConstructionCompletionFrom?: Maybe<Scalars['DateTime']['output']>;
  plannedConstructionCompletionTo?: Maybe<Scalars['DateTime']['output']>;
  plannedConstructionStartFrom?: Maybe<Scalars['DateTime']['output']>;
  plannedConstructionStartTo?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Array<Scalars['String']['output']>>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Array<Scalars['String']['output']>>;
};

export type RetailDesignFilterPresetConfigInput = {
  author?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  city?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedSalesVolumePerYearFrom?: InputMaybe<Scalars['Int']['input']>;
  expectedSalesVolumePerYearTo?: InputMaybe<Scalars['Int']['input']>;
  expectedWorkshopThroughputPerDayFrom?: InputMaybe<Scalars['Int']['input']>;
  expectedWorkshopThroughputPerDayTo?: InputMaybe<Scalars['Int']['input']>;
  format?: InputMaybe<Array<Scalars['String']['input']>>;
  hasBookmark?: InputMaybe<Scalars['Boolean']['input']>;
  onlyCreated?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  plannedConstructionCompletionFrom?: InputMaybe<Scalars['DateTime']['input']>;
  plannedConstructionCompletionTo?: InputMaybe<Scalars['DateTime']['input']>;
  plannedConstructionStartFrom?: InputMaybe<Scalars['DateTime']['input']>;
  plannedConstructionStartTo?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type RetailDesignFormat = {
  __typename?: 'RetailDesignFormat';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  hasAssignedProjects?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use hasNoWorkshopThroughput instead */
  hasNoAftersalesThroughput?: Maybe<Scalars['Boolean']['output']>;
  hasNoBodyAndPaintThroughput?: Maybe<Scalars['Boolean']['output']>;
  hasNoNCSalesVolume?: Maybe<Scalars['Boolean']['output']>;
  hasNoWorkshopThroughput?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  needsAftersalesApproval?: Maybe<Scalars['Boolean']['output']>;
  translations?: Maybe<Scalars['JSON']['output']>;
};

export type RetailDesignProject = {
  __typename?: 'RetailDesignProject';
  afterSalesApproved?: Maybe<Scalars['Boolean']['output']>;
  attachment?: Maybe<RetailDesignProjectAttachment>;
  attachments: Array<RetailDesignProjectAttachment>;
  author: Person;
  city: Scalars['String']['output'];
  comments: Array<Comment>;
  description?: Maybe<Scalars['String']['output']>;
  expectedBodyAndPaintThroughputPerDay: Array<IntValueWithYear>;
  expectedSalesVolumePerYear: Array<IntValueWithYear>;
  expectedWorkshopThroughputPerDay: Array<IntValueWithYear>;
  format: RetailDesignFormat;
  hasBookmark?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ObjectID']['output'];
  isInRegionalOfficeFlow: Scalars['Boolean']['output'];
  iteration: Scalars['Int']['output'];
  organization: Organization;
  patches: Array<RetailDesignProjectPatch>;
  plannedConstructionCompletion?: Maybe<Scalars['Date']['output']>;
  plannedConstructionStart?: Maybe<Scalars['Date']['output']>;
  relationType?: Maybe<RetailDesignProjectRelationType>;
  retailDesignApproved?: Maybe<Scalars['Boolean']['output']>;
  status: RetailDesignProjectStatus;
  street?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<RetailDesignProjectType>;
};

export type RetailDesignProjectAttachmentArgs = {
  id: Scalars['ObjectID']['input'];
};

export type RetailDesignProjectPatchesArgs = {
  input?: InputMaybe<RetailDesignProjectPatchesInput>;
};

export type RetailDesignProjectAttachment = {
  __typename?: 'RetailDesignProjectAttachment';
  comments: Array<Comment>;
  createdAt: Scalars['DateTime']['output'];
  filePrefix?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  /** @deprecated visibility = 'REGIONAL_OFFICE' can be checked */
  isHiddenForPag?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  uploader?: Maybe<Person>;
  visibility: RetailDesignProjectAttachmentVisibility;
};

export type RetailDesignProjectAttachmentInput = {
  filePrefix?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
  type: Scalars['String']['input'];
  visibility?: InputMaybe<RetailDesignProjectAttachmentVisibility>;
};

export enum RetailDesignProjectAttachmentVisibility {
  PagInternal = 'PAG_INTERNAL',
  Public = 'PUBLIC',
  RegionalOfficeInternal = 'REGIONAL_OFFICE_INTERNAL',
}

export type RetailDesignProjectFilter = {
  _and?: InputMaybe<Array<InputMaybe<RetailDesignProjectFilter>>>;
  author?: InputMaybe<BigIntFilter>;
  bookmarkedBy?: InputMaybe<BigIntFilter>;
  city?: InputMaybe<StringArrayFilter>;
  expectedBodyAndPaintThroughputPerDayAfterConstruction?: InputMaybe<IntFilter>;
  expectedSalesVolumePerYearAfterConstruction?: InputMaybe<IntFilter>;
  expectedWorkshopThroughputPerDayAfterConstruction?: InputMaybe<IntFilter>;
  format?: InputMaybe<ObjectIdFilter>;
  organization?: InputMaybe<RetailDesignProjectOrganizationFilter>;
  plannedConstructionCompletion?: InputMaybe<DateTimeFilter>;
  plannedConstructionStart?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<RetailDesignProjectStatusFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<ObjectIdFilter>;
};

export type RetailDesignProjectOrganizationFilter = {
  _eq?: InputMaybe<Scalars['BigInt']['input']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>;
};

export type RetailDesignProjectPatch = Patch & {
  __typename?: 'RetailDesignProjectPatch';
  date: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  ops: Scalars['JSON']['output'];
  person: Person;
  ref: Scalars['ObjectID']['output'];
};

export type RetailDesignProjectPatchOpsArgs = {
  input?: InputMaybe<PatchOpsInput>;
};

export type RetailDesignProjectPatchFilter = {
  _and?: InputMaybe<Array<RetailDesignProjectPatchFilter>>;
  _or?: InputMaybe<Array<RetailDesignProjectPatchFilter>>;
  ops?: InputMaybe<RetailDesignProjectPatchOpFilter>;
  ops_op?: InputMaybe<StringFilter>;
  ops_path?: InputMaybe<StringFilter>;
};

export type RetailDesignProjectPatchOpFilter = {
  _elemMatch?: InputMaybe<RetailDesignProjectPatchOpObjectFilter>;
  op?: InputMaybe<StringFilter>;
  path?: InputMaybe<StringFilter>;
};

export type RetailDesignProjectPatchOpObjectFilter = {
  op?: InputMaybe<StringFilter>;
  path?: InputMaybe<StringFilter>;
};

export type RetailDesignProjectPatchesInput = {
  filter?: InputMaybe<RetailDesignProjectPatchFilter>;
};

export enum RetailDesignProjectRelationType {
  New = 'NEW',
  Replacement = 'REPLACEMENT',
}

export enum RetailDesignProjectStatus {
  Accepted = 'ACCEPTED',
  Draft = 'DRAFT',
  InOperation = 'IN_OPERATION',
  PagNeedsRevision = 'PAG_NEEDS_REVISION',
  PagOnHold = 'PAG_ON_HOLD',
  PagReview = 'PAG_REVIEW',
  RegionalOfficeNeedsRevision = 'REGIONAL_OFFICE_NEEDS_REVISION',
  RegionalOfficeOnHold = 'REGIONAL_OFFICE_ON_HOLD',
  RegionalOfficeReview = 'REGIONAL_OFFICE_REVIEW',
}

export type RetailDesignProjectStatusFilter = {
  _eq?: InputMaybe<RetailDesignProjectStatus>;
  _in?: InputMaybe<Array<InputMaybe<RetailDesignProjectStatus>>>;
  _ne?: InputMaybe<RetailDesignProjectStatus>;
};

export type RetailDesignProjectType = {
  __typename?: 'RetailDesignProjectType';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  hasAssignedProjects?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  translations?: Maybe<Scalars['JSON']['output']>;
};

export type RetailDesignProjectsInput = {
  filter?: InputMaybe<RetailDesignProjectFilter>;
};

export enum Review {
  Flagged = 'FLAGGED',
  NotFlagged = 'NOT_FLAGGED',
  Resolved = 'RESOLVED',
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum Source {
  Greenland = 'GREENLAND',
  MysteryShopping = 'MYSTERY_SHOPPING',
  Pbsa = 'PBSA',
  Survey = 'SURVEY',
}

export enum StatisticGranularity {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
}

export type StatusChange = {
  __typename?: 'StatusChange';
  from: MeetingStatus;
  reason?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  to: MeetingStatus;
  user: Person;
};

export type StrategyActionItem = {
  __typename?: 'StrategyActionItem';
  attachments: Array<Attachment>;
  author: PersonReference;
  comments: Array<Comment>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  kpi: StrategyKpi;
  okr: Scalars['String']['output'];
  organization: Organization;
  responsible: PersonReference;
  status: StrategyActionItemStatus;
  title: Scalars['String']['output'];
  workPackages: Array<StrategyActionItemWorkPackage>;
  year: Scalars['Int']['output'];
};

export type StrategyActionItemFilter = {
  kpi_stage?: InputMaybe<IntFilter>;
  organization_organizationId?: InputMaybe<BigIntFilter>;
  rootOrganizationIds?: InputMaybe<
    Array<InputMaybe<Scalars['BigInt']['input']>>
  >;
  year?: InputMaybe<IntFilter>;
};

export enum StrategyActionItemStatus {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Open = 'OPEN',
}

export type StrategyActionItemWorkPackage = {
  __typename?: 'StrategyActionItemWorkPackage';
  id: Scalars['ObjectID']['output'];
  status: StrategyActionItemWorkPackageStatus;
  targetMonth: Scalars['DateMonth']['output'];
  title: Scalars['String']['output'];
};

export type StrategyActionItemWorkPackageInput = {
  status: StrategyActionItemWorkPackageStatus;
  targetMonth: Scalars['DateMonth']['input'];
  title: Scalars['String']['input'];
};

export enum StrategyActionItemWorkPackageStatus {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Open = 'OPEN',
}

export type StrategyActionItemsInput = {
  filter?: InputMaybe<StrategyActionItemFilter>;
};

export type StrategyField = {
  __typename?: 'StrategyField';
  assignmentType: AssignmentType;
  color: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  isAssigned: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
  stage: Scalars['Int']['output'];
};

export type StrategyFieldFilter = {
  _id?: InputMaybe<ObjectIdFilter>;
  assignmentType?: InputMaybe<AssignmentType>;
  organization?: InputMaybe<BigIntFilter>;
  stage?: InputMaybe<IntFilter>;
};

export type StrategyFieldsFilterInput = {
  filter?: InputMaybe<StrategyFieldFilter>;
};

export type StrategyImpact = {
  __typename?: 'StrategyImpact';
  author: PersonReference;
  /** ffmAlias: strategyImpactId */
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  organization: OrganizationReference;
  translations?: Maybe<Scalars['JSON']['output']>;
};

export type StrategyImpactCreationInput = {
  author: PersonReferenceInput;
  name: Scalars['String']['input'];
  organization: OrganizationReferenceInput;
  translations?: InputMaybe<Scalars['JSON']['input']>;
};

export type StrategyImpactReferenceInput = {
  name: Scalars['String']['input'];
  strategyImpactId: Scalars['ObjectID']['input'];
};

export type StrategyImpactUpdateInput = {
  name: Scalars['String']['input'];
  organization: OrganizationReferenceInput;
  translations?: InputMaybe<Scalars['JSON']['input']>;
};

export type StrategyKpi = {
  __typename?: 'StrategyKpi';
  id: Scalars['ObjectID']['output'];
  isAssigned: Scalars['Boolean']['output'];
  organization: Organization;
  stage: Scalars['Int']['output'];
  strategyField: StrategyField;
  title: Scalars['String']['output'];
};

export type StrategyKpiFilter = {
  _or?: InputMaybe<Array<InputMaybe<StrategyKpiFilter>>>;
  organization?: InputMaybe<BigIntFilter>;
  stage?: InputMaybe<IntFilter>;
  strategyField?: InputMaybe<ObjectIdFilter>;
};

export type StrategyKpisInput = {
  filter?: InputMaybe<StrategyKpiFilter>;
};

export type StrategyStage = {
  __typename?: 'StrategyStage';
  id: Scalars['ObjectID']['output'];
  isAssigned: Scalars['Boolean']['output'];
  visibleForDealer: Scalars['Date']['output'];
  visibleForManager: Scalars['Date']['output'];
  year: Scalars['Int']['output'];
};

export type StrategyStageFilter = {
  _id?: InputMaybe<ObjectIdFilter>;
  year?: InputMaybe<IntFilter>;
};

export type StrategyStagesFilterInput = {
  filter?: InputMaybe<StrategyStageFilter>;
};

export type StringArrayFilter = {
  _in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type StringFilter = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _exists?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  _options?: InputMaybe<Scalars['StringFilterRegexOptions']['input']>;
  _regex?: InputMaybe<Scalars['String']['input']>;
};

export type Sunburst = {
  __typename?: 'Sunburst';
  d3Children?: Maybe<Scalars['JSON']['output']>;
  hasData?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['BigInt']['output']>;
  parentId?: Maybe<Scalars['BigInt']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type SupportRequest = {
  __typename?: 'SupportRequest';
  application: Scalars['String']['output'];
  attachments?: Maybe<Array<Attachment>>;
  author: Person;
  comments: Array<Comment>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  details?: Maybe<SupportRequestDetails>;
  hasNewActivity: Scalars['Boolean']['output'];
  id: Scalars['ObjectID']['output'];
  organization: Organization;
  referencePerson?: Maybe<Person>;
  status: SupportRequestStatus;
  title: Scalars['String']['output'];
};

export type SupportRequestDetails = {
  __typename?: 'SupportRequestDetails';
  browserWindowSize: SupportRequestSize;
  screenSize: SupportRequestSize;
  userAgent: Scalars['String']['output'];
};

export type SupportRequestDetailsInput = {
  browserWindowSize: SupportRequestSizeInput;
  screenSize: SupportRequestSizeInput;
  userAgent: Scalars['String']['input'];
};

export type SupportRequestSize = {
  __typename?: 'SupportRequestSize';
  height: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
};

export type SupportRequestSizeInput = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export enum SupportRequestStatus {
  Closed = 'CLOSED',
  InProgress = 'IN_PROGRESS',
  Open = 'OPEN',
}

export type Survey = {
  __typename?: 'Survey';
  answerableFor: Array<Answerable>;
  answersAllowedBy?: Maybe<Array<Person>>;
  author: Person;
  canCreateActionItems: Scalars['Boolean']['output'];
  color: Scalars['String']['output'];
  effectiveFrom: Scalars['Date']['output'];
  effectiveTo?: Maybe<Scalars['Date']['output']>;
  hasAssignedAnswers?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ObjectID']['output'];
  instances: Array<SurveyInstance>;
  isActive: Scalars['Boolean']['output'];
  items: Array<SurveyItem>;
  languages: Array<Scalars['String']['output']>;
  /** Localized field for nameLocalizations */
  name: Scalars['String']['output'];
  nameLocalizations: Array<LocalizedString>;
  organizations?: Maybe<Array<Organization>>;
  persons?: Maybe<Array<Person>>;
  type: SurveyType;
  weight: Scalars['Int']['output'];
  weightsChangedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SurveyAnswer = {
  actionItemIds?: Maybe<Array<Scalars['ObjectID']['output']>>;
  answeredFor: Scalars['BigInt']['output'];
  dependingAnswers?: Maybe<Array<SurveyAnswer>>;
  id: Scalars['ObjectID']['output'];
  isAnswered: Scalars['Boolean']['output'];
  itemId: Scalars['ObjectID']['output'];
  survey: Survey;
};

export enum SurveyChartDataOutput {
  Item = 'ITEM',
  Organization = 'ORGANIZATION',
}

export type SurveyEmailNotification = {
  __typename?: 'SurveyEmailNotification';
  adhoc?: Maybe<Scalars['Boolean']['output']>;
  cron?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  options?: Maybe<SurveyEmailNotificationOption>;
  person: Person;
  topic: SurveyEmailNotificationTopic;
};

export type SurveyEmailNotificationOption = {
  __typename?: 'SurveyEmailNotificationOption';
  surveyAssigned?: Maybe<Scalars['Boolean']['output']>;
  surveyCompleted?: Maybe<Scalars['Boolean']['output']>;
};

export type SurveyEmailNotificationOptionInput = {
  surveyAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  surveyCompleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SurveyEmailNotificationTopic {
  SurveyEvents = 'SURVEY_EVENTS',
  SurveyReminders = 'SURVEY_REMINDERS',
}

export type SurveyEntryValueUnit = {
  __typename?: 'SurveyEntryValueUnit';
  id: Scalars['ObjectID']['output'];
  /** Localized field for nameLocalizations */
  name: Scalars['String']['output'];
  nameLocalizations: Array<LocalizedString>;
  type: SurveyEntryValueUnitType;
};

export enum SurveyEntryValueUnitType {
  Number = 'NUMBER',
}

export type SurveyFilter = {
  _and?: InputMaybe<Array<SurveyFilter>>;
  _id?: InputMaybe<ObjectIdFilter>;
  _or?: InputMaybe<Array<SurveyFilter>>;
  isActive?: InputMaybe<BooleanFilter>;
  items_id?: InputMaybe<ObjectIdFilter>;
  type?: InputMaybe<ObjectIdFilter>;
};

export type SurveyInstance = {
  __typename?: 'SurveyInstance';
  answeredFor: Answerable;
  answeredForType: AnswerableType;
  answeredPercentage: Scalars['Float']['output'];
  answersAllowedBy?: Maybe<Array<Person>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  result?: Maybe<SurveyResult>;
  survey: Survey;
  totalPercentage: Scalars['Float']['output'];
};

export type SurveyInstanceResultArgs = {
  cutoffDate?: InputMaybe<Scalars['Date']['input']>;
};

export type SurveyInstanceConnection = {
  __typename?: 'SurveyInstanceConnection';
  edges: Array<SurveyInstanceEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SurveyInstanceEdge = {
  __typename?: 'SurveyInstanceEdge';
  cursor: Scalars['Cursor']['output'];
  node: SurveyInstance;
};

export type SurveyInstanceFilter = {
  _and?: InputMaybe<Array<SurveyInstanceFilter>>;
  _id?: InputMaybe<ObjectIdFilter>;
  _or?: InputMaybe<Array<SurveyInstanceFilter>>;
  answeredForType?: InputMaybe<StringFilter>;
  answeredFor_id?: InputMaybe<BigIntFilter>;
  answeredFor_organization_id?: InputMaybe<BigIntFilter>;
  answeredPercentage?: InputMaybe<IntFilter>;
  author_id?: InputMaybe<BigIntFilter>;
  author_name?: InputMaybe<StringFilter>;
  nameLocalized?: InputMaybe<StringFilter>;
  organizationName?: InputMaybe<StringFilter>;
  totalPercentage?: InputMaybe<IntFilter>;
  type_id?: InputMaybe<ObjectIdFilter>;
  type_nameLocalized?: InputMaybe<StringFilter>;
};

export enum SurveyInstanceOrderBy {
  AnsweredPercentage = 'answeredPercentage',
  AuthorName = 'author_name',
  NameLocalized = 'nameLocalized',
  OrganizationName = 'organizationName',
  TotalPercentage = 'totalPercentage',
  TypeId = 'type_id',
  TypeNameLocalized = 'type_nameLocalized',
}

export type SurveyItem = {
  depth: Scalars['Int']['output'];
  id: Scalars['ObjectID']['output'];
  kind: SurveyItemKind;
  /** Localized field for nameLocalizations */
  name: Scalars['String']['output'];
  nameLocalizations: Array<LocalizedString>;
};

export type SurveyItemFilter = {
  _id?: InputMaybe<ObjectIdFilter>;
};

export enum SurveyItemKind {
  MultipleChoiceQuestion = 'MULTIPLE_CHOICE_QUESTION',
  MultipleEntryQuestion = 'MULTIPLE_ENTRY_QUESTION',
  NumericQuestion = 'NUMERIC_QUESTION',
  Section = 'SECTION',
  TextQuestion = 'TEXT_QUESTION',
  YesNoQuestion = 'YES_NO_QUESTION',
}

export type SurveyMultipleChoiceAnswer = SurveyAnswer & {
  __typename?: 'SurveyMultipleChoiceAnswer';
  actionItemIds?: Maybe<Array<Scalars['ObjectID']['output']>>;
  answeredFor: Scalars['BigInt']['output'];
  attachments?: Maybe<Array<Attachment>>;
  comment?: Maybe<Scalars['String']['output']>;
  dependingAnswers?: Maybe<Array<SurveyAnswer>>;
  id: Scalars['ObjectID']['output'];
  isAnswered: Scalars['Boolean']['output'];
  isNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  itemId: Scalars['ObjectID']['output'];
  survey: Survey;
  value?: Maybe<Array<Scalars['ObjectID']['output']>>;
};

export type SurveyMultipleChoiceQuestion = SurveyItem & {
  __typename?: 'SurveyMultipleChoiceQuestion';
  allowComment?: Maybe<Scalars['Boolean']['output']>;
  allowMultiple?: Maybe<Scalars['Boolean']['output']>;
  allowNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  allowUpload?: Maybe<Scalars['Boolean']['output']>;
  answer?: Maybe<SurveyMultipleChoiceAnswer>;
  /** Localized field for attachmentsLocalizations */
  attachments?: Maybe<Array<Attachment>>;
  attachmentsLocalizations?: Maybe<Array<LocalizedAttachments>>;
  /** @deprecated Field no longer supported */
  dependingOnQuestionAnswer?: Maybe<Scalars['String']['output']>;
  dependingOnQuestionAnswers?: Maybe<Array<Scalars['String']['output']>>;
  dependingOnQuestionId?: Maybe<Scalars['ObjectID']['output']>;
  depth: Scalars['Int']['output'];
  /** Localized field for descriptionLocalizations */
  description?: Maybe<Scalars['String']['output']>;
  descriptionLocalizations?: Maybe<Array<LocalizedString>>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ObjectID']['output'];
  kind: SurveyItemKind;
  /** Localized field for nameLocalizations */
  name: Scalars['String']['output'];
  nameLocalizations: Array<LocalizedString>;
  options: Array<SurveyMultipleChoiceQuestionOption>;
};

export type SurveyMultipleChoiceQuestionAnswerArgs = {
  answeredFor: Scalars['BigInt']['input'];
};

export type SurveyMultipleChoiceQuestionOption = {
  __typename?: 'SurveyMultipleChoiceQuestionOption';
  id: Scalars['ObjectID']['output'];
  /** Localized field for nameLocalizations */
  name: Scalars['String']['output'];
  nameLocalizations: Array<LocalizedString>;
  weight: Scalars['Int']['output'];
};

export type SurveyMultipleEntryAnswer = SurveyAnswer & {
  __typename?: 'SurveyMultipleEntryAnswer';
  actionItemIds?: Maybe<Array<Scalars['ObjectID']['output']>>;
  answeredFor: Scalars['BigInt']['output'];
  attachments?: Maybe<Array<Attachment>>;
  comment?: Maybe<Scalars['String']['output']>;
  dependingAnswers?: Maybe<Array<SurveyAnswer>>;
  id: Scalars['ObjectID']['output'];
  isAnswered: Scalars['Boolean']['output'];
  isNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  itemId: Scalars['ObjectID']['output'];
  survey: Survey;
  value?: Maybe<Array<SurveyMultipleEntryAnswerEntry>>;
};

export type SurveyMultipleEntryAnswerEntry = {
  __typename?: 'SurveyMultipleEntryAnswerEntry';
  entryId: Scalars['ObjectID']['output'];
  id: Scalars['ObjectID']['output'];
  value: Scalars['Int']['output'];
};

export type SurveyMultipleEntryQuestion = SurveyItem & {
  __typename?: 'SurveyMultipleEntryQuestion';
  allowComment?: Maybe<Scalars['Boolean']['output']>;
  allowNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  allowUpload?: Maybe<Scalars['Boolean']['output']>;
  answer?: Maybe<SurveyMultipleEntryAnswer>;
  /** Localized field for attachmentsLocalizations */
  attachments?: Maybe<Array<Attachment>>;
  attachmentsLocalizations?: Maybe<Array<LocalizedAttachments>>;
  /** @deprecated Field no longer supported */
  dependingOnQuestionAnswer?: Maybe<Scalars['String']['output']>;
  dependingOnQuestionAnswers?: Maybe<Array<Scalars['String']['output']>>;
  dependingOnQuestionId?: Maybe<Scalars['ObjectID']['output']>;
  depth: Scalars['Int']['output'];
  /** Localized field for descriptionLocalizations */
  description?: Maybe<Scalars['String']['output']>;
  descriptionLocalizations?: Maybe<Array<LocalizedString>>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  entries: Array<SurveyMultipleEntryQuestionEntry>;
  entryValueUnit?: Maybe<SurveyEntryValueUnit>;
  id: Scalars['ObjectID']['output'];
  kind: SurveyItemKind;
  /** Localized field for nameLocalizations */
  name: Scalars['String']['output'];
  nameLocalizations: Array<LocalizedString>;
};

export type SurveyMultipleEntryQuestionAnswerArgs = {
  answeredFor: Scalars['BigInt']['input'];
};

export type SurveyMultipleEntryQuestionEntry = {
  __typename?: 'SurveyMultipleEntryQuestionEntry';
  id: Scalars['ObjectID']['output'];
  /** Localized field for nameLocalizations */
  name: Scalars['String']['output'];
  nameLocalizations: Array<LocalizedString>;
};

export type SurveyNumericAnswer = SurveyAnswer & {
  __typename?: 'SurveyNumericAnswer';
  actionItemIds?: Maybe<Array<Scalars['ObjectID']['output']>>;
  answeredFor: Scalars['BigInt']['output'];
  attachments?: Maybe<Array<Attachment>>;
  comment?: Maybe<Scalars['String']['output']>;
  dependingAnswers?: Maybe<Array<SurveyAnswer>>;
  id: Scalars['ObjectID']['output'];
  isAnswered: Scalars['Boolean']['output'];
  isNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  itemId: Scalars['ObjectID']['output'];
  survey: Survey;
  value?: Maybe<Scalars['Float']['output']>;
};

export type SurveyNumericQuestion = SurveyItem & {
  __typename?: 'SurveyNumericQuestion';
  allowComment?: Maybe<Scalars['Boolean']['output']>;
  allowNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  allowUpload?: Maybe<Scalars['Boolean']['output']>;
  answer?: Maybe<SurveyNumericAnswer>;
  /** Localized field for attachmentsLocalizations */
  attachments?: Maybe<Array<Attachment>>;
  attachmentsLocalizations?: Maybe<Array<LocalizedAttachments>>;
  /** @deprecated Field no longer supported */
  dependingOnQuestionAnswer?: Maybe<Scalars['String']['output']>;
  dependingOnQuestionAnswers?: Maybe<Array<Scalars['String']['output']>>;
  dependingOnQuestionId?: Maybe<Scalars['ObjectID']['output']>;
  depth: Scalars['Int']['output'];
  /** Localized field for descriptionLocalizations */
  description?: Maybe<Scalars['String']['output']>;
  descriptionLocalizations?: Maybe<Array<LocalizedString>>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ObjectID']['output'];
  kind: SurveyItemKind;
  /** Localized field for nameLocalizations */
  name: Scalars['String']['output'];
  nameLocalizations: Array<LocalizedString>;
  valueUnit?: Maybe<SurveyValueUnit>;
};

export type SurveyNumericQuestionAnswerArgs = {
  answeredFor: Scalars['BigInt']['input'];
};

export type SurveyReport = {
  __typename?: 'SurveyReport';
  color: Scalars['String']['output'];
  comparisonValue?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ObjectID']['output'];
  /** Localized field for nameLocalizations */
  name: Scalars['String']['output'];
  nameLocalizations: Array<LocalizedString>;
  sections: Array<SurveySectionReport>;
  value: Scalars['Float']['output'];
  valueOverTime?: Maybe<Array<SurveyReportValueWithTime>>;
};

export enum SurveyReportAggregationLevel {
  Market = 'MARKET',
  None = 'NONE',
  Pag = 'PAG',
  Region = 'REGION',
}

export enum SurveyReportChartType {
  ByItem = 'BY_ITEM',
  ByOrganization = 'BY_ORGANIZATION',
  Ranked = 'RANKED',
}

export type SurveyReportConfig = {
  __typename?: 'SurveyReportConfig';
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  person: Person;
  sections: Array<SurveyReportConfigSection>;
};

export type SurveyReportConfigChart = {
  __typename?: 'SurveyReportConfigChart';
  aggregationLevel?: Maybe<SurveyReportAggregationLevel>;
  cutoffDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ObjectID']['output'];
  onlyIncludeOrganizationsWithAnswers?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<SortOrder>;
  orderBy?: Maybe<SurveyReportOrderBy>;
  organizationIds: Array<Scalars['BigInt']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
  surveyIds: Array<Scalars['ObjectID']['output']>;
  surveyItemIds?: Maybe<Array<Scalars['ObjectID']['output']>>;
  title: Scalars['String']['output'];
  type: SurveyReportChartType;
};

export type SurveyReportConfigSection = {
  __typename?: 'SurveyReportConfigSection';
  charts: Array<SurveyReportConfigChart>;
  id: Scalars['ObjectID']['output'];
  width: Scalars['Int']['output'];
};

export type SurveyReportInput = {
  compareToDate?: InputMaybe<Scalars['Date']['input']>;
  compareToOrganizations?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  cutoffDate?: InputMaybe<Scalars['Date']['input']>;
  organizations: Array<Scalars['BigInt']['input']>;
  surveyFilter?: InputMaybe<SurveyFilter>;
};

export enum SurveyReportOrderBy {
  Name = 'name',
  Value = 'value',
}

export type SurveyReportValueWithTime = {
  __typename?: 'SurveyReportValueWithTime';
  timestamp: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type SurveyResult = {
  __typename?: 'SurveyResult';
  answeredFor: Answerable;
  answeredPercentage: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['ObjectID']['output'];
  itemResults: Array<Maybe<SurveyResultItem>>;
  totalPercentage: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Field no longer supported */
  weightedResult?: Maybe<Scalars['Float']['output']>;
};

export type SurveyResultItem = {
  __typename?: 'SurveyResultItem';
  isAnswered?: Maybe<Scalars['Boolean']['output']>;
  itemId: Scalars['ObjectID']['output'];
  percentageOfTotal?: Maybe<Scalars['Float']['output']>;
  possiblePoints: Scalars['Float']['output'];
  reachedPoints: Scalars['Float']['output'];
  /** @deprecated Field no longer supported */
  weightedResult?: Maybe<Scalars['Float']['output']>;
};

export type SurveyResultsByItemConnection = {
  __typename?: 'SurveyResultsByItemConnection';
  nodes: Array<SurveyResultsByItemNode>;
};

export type SurveyResultsByItemInput = {
  aggregationLevel?: InputMaybe<SurveyReportAggregationLevel>;
  cutoffDate?: InputMaybe<Scalars['Date']['input']>;
  onlyIncludeOrganizationsWithAnswers?: InputMaybe<Scalars['Boolean']['input']>;
  organizations: Array<Scalars['BigInt']['input']>;
  surveyFilter: SurveyFilter;
  surveyItemFilter?: InputMaybe<SurveyItemFilter>;
};

export type SurveyResultsByItemNode = {
  __typename?: 'SurveyResultsByItemNode';
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  values: Array<SurveyResultsByOrganizationNode>;
};

export type SurveyResultsByItemOverTimeInput = {
  aggregationLevel?: InputMaybe<SurveyReportAggregationLevel>;
  cutoffDate: Scalars['Date']['input'];
  onlyIncludeOrganizationsWithAnswers?: InputMaybe<Scalars['Boolean']['input']>;
  organizations: Array<Scalars['BigInt']['input']>;
  startDate: Scalars['Date']['input'];
  surveyFilter: SurveyFilter;
  surveyItemFilter?: InputMaybe<SurveyItemFilter>;
};

export type SurveyResultsByItemOverTimeNode = {
  __typename?: 'SurveyResultsByItemOverTimeNode';
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  values: Array<SurveyResultsByOrganizationOverTimeNode>;
};

export type SurveyResultsByOrganizationConnection = {
  __typename?: 'SurveyResultsByOrganizationConnection';
  nodes: Array<SurveyResultsByOrganizationNode>;
  totalCount: Scalars['Int']['output'];
};

export type SurveyResultsByOrganizationInput = {
  aggregationLevel?: InputMaybe<SurveyReportAggregationLevel>;
  cutoffDate?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyIncludeOrganizationsWithAnswers?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<SortOrder>;
  orderBy?: InputMaybe<SurveyReportOrderBy>;
  organizations: Array<Scalars['BigInt']['input']>;
  surveyFilter: SurveyFilter;
  /** @deprecated Use surveyFilter.items_id instead. */
  surveyItemId?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type SurveyResultsByOrganizationNode = {
  __typename?: 'SurveyResultsByOrganizationNode';
  id: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  organizationCount: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type SurveyResultsByOrganizationOverTimeInput = {
  aggregationLevel?: InputMaybe<SurveyReportAggregationLevel>;
  cutoffDate: Scalars['Date']['input'];
  onlyIncludeOrganizationsWithAnswers?: InputMaybe<Scalars['Boolean']['input']>;
  organizations: Array<Scalars['BigInt']['input']>;
  startDate: Scalars['Date']['input'];
  surveyFilter: SurveyFilter;
  /** @deprecated Use surveyFilter.items_id instead. */
  surveyItemId?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type SurveyResultsByOrganizationOverTimeNode = {
  __typename?: 'SurveyResultsByOrganizationOverTimeNode';
  id: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  valueOverTime: Array<SurveyReportValueWithTime>;
};

export type SurveySection = SurveyItem & {
  __typename?: 'SurveySection';
  depth: Scalars['Int']['output'];
  id: Scalars['ObjectID']['output'];
  kind: SurveyItemKind;
  /** Localized field for nameLocalizations */
  name: Scalars['String']['output'];
  nameLocalizations: Array<LocalizedString>;
  weight: Scalars['Int']['output'];
};

export type SurveySectionReport = {
  __typename?: 'SurveySectionReport';
  comparisonValue?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ObjectID']['output'];
  /** Localized field for nameLocalizations */
  name: Scalars['String']['output'];
  nameLocalizations: Array<LocalizedString>;
  value: Scalars['Float']['output'];
  valueOverTime?: Maybe<Array<SurveyReportValueWithTime>>;
};

export type SurveyTextAnswer = SurveyAnswer & {
  __typename?: 'SurveyTextAnswer';
  actionItemIds?: Maybe<Array<Scalars['ObjectID']['output']>>;
  answeredFor: Scalars['BigInt']['output'];
  attachments?: Maybe<Array<Attachment>>;
  dependingAnswers?: Maybe<Array<SurveyAnswer>>;
  id: Scalars['ObjectID']['output'];
  isAnswered: Scalars['Boolean']['output'];
  isNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  itemId: Scalars['ObjectID']['output'];
  survey: Survey;
  value?: Maybe<Scalars['String']['output']>;
};

export type SurveyTextQuestion = SurveyItem & {
  __typename?: 'SurveyTextQuestion';
  allowNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  allowUpload?: Maybe<Scalars['Boolean']['output']>;
  answer?: Maybe<SurveyTextAnswer>;
  /** Localized field for attachmentsLocalizations */
  attachments?: Maybe<Array<Attachment>>;
  attachmentsLocalizations?: Maybe<Array<LocalizedAttachments>>;
  /** @deprecated Field no longer supported */
  dependingOnQuestionAnswer?: Maybe<Scalars['String']['output']>;
  dependingOnQuestionAnswers?: Maybe<Array<Scalars['String']['output']>>;
  dependingOnQuestionId?: Maybe<Scalars['ObjectID']['output']>;
  depth: Scalars['Int']['output'];
  /** Localized field for descriptionLocalizations */
  description?: Maybe<Scalars['String']['output']>;
  descriptionLocalizations?: Maybe<Array<LocalizedString>>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ObjectID']['output'];
  kind: SurveyItemKind;
  /** Localized field for nameLocalizations */
  name: Scalars['String']['output'];
  nameLocalizations: Array<LocalizedString>;
};

export type SurveyTextQuestionAnswerArgs = {
  answeredFor: Scalars['BigInt']['input'];
};

export type SurveyTranslation = {
  __typename?: 'SurveyTranslation';
  items: Array<SurveyTranslationItem>;
  nameLocalizations: Array<LocalizedString>;
};

export type SurveyTranslationEntry = {
  __typename?: 'SurveyTranslationEntry';
  nameLocalizations: Array<LocalizedString>;
};

export type SurveyTranslationEntryInput = {
  nameLocalizations: Array<LocalizedStringInput>;
};

export type SurveyTranslationInput = {
  items: Array<SurveyTranslationItemInput>;
  nameLocalizations: Array<LocalizedStringInput>;
};

export type SurveyTranslationItem = {
  kind: SurveyItemKind;
  nameLocalizations: Array<LocalizedString>;
};

export type SurveyTranslationItemInput = {
  descriptionLocalizations?: InputMaybe<Array<LocalizedStringInput>>;
  entries?: InputMaybe<Array<SurveyTranslationEntryInput>>;
  kind: SurveyItemKind;
  nameLocalizations: Array<LocalizedStringInput>;
  options?: InputMaybe<Array<SurveyTranslationOptionInput>>;
};

export type SurveyTranslationMultipleChoiceQuestion = SurveyTranslationItem & {
  __typename?: 'SurveyTranslationMultipleChoiceQuestion';
  descriptionLocalizations?: Maybe<Array<LocalizedString>>;
  kind: SurveyItemKind;
  nameLocalizations: Array<LocalizedString>;
  options?: Maybe<Array<SurveyTranslationOption>>;
};

export type SurveyTranslationMultipleEntryQuestion = SurveyTranslationItem & {
  __typename?: 'SurveyTranslationMultipleEntryQuestion';
  descriptionLocalizations?: Maybe<Array<LocalizedString>>;
  entries?: Maybe<Array<SurveyTranslationEntry>>;
  kind: SurveyItemKind;
  nameLocalizations: Array<LocalizedString>;
};

export type SurveyTranslationNumericQuestion = SurveyTranslationItem & {
  __typename?: 'SurveyTranslationNumericQuestion';
  descriptionLocalizations?: Maybe<Array<LocalizedString>>;
  kind: SurveyItemKind;
  nameLocalizations: Array<LocalizedString>;
};

export type SurveyTranslationOption = {
  __typename?: 'SurveyTranslationOption';
  nameLocalizations: Array<LocalizedString>;
};

export type SurveyTranslationOptionInput = {
  nameLocalizations: Array<LocalizedStringInput>;
};

export type SurveyTranslationSection = SurveyTranslationItem & {
  __typename?: 'SurveyTranslationSection';
  kind: SurveyItemKind;
  nameLocalizations: Array<LocalizedString>;
};

export type SurveyTranslationTextQuestion = SurveyTranslationItem & {
  __typename?: 'SurveyTranslationTextQuestion';
  descriptionLocalizations?: Maybe<Array<LocalizedString>>;
  kind: SurveyItemKind;
  nameLocalizations: Array<LocalizedString>;
};

export type SurveyTranslationYesNoQuestion = SurveyTranslationItem & {
  __typename?: 'SurveyTranslationYesNoQuestion';
  descriptionLocalizations?: Maybe<Array<LocalizedString>>;
  kind: SurveyItemKind;
  nameLocalizations: Array<LocalizedString>;
};

export type SurveyType = {
  __typename?: 'SurveyType';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  hasAssignedSurveys?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ObjectID']['output'];
  /** Localized field for nameLocalizations */
  name: Scalars['String']['output'];
  nameLocalizations: Array<LocalizedString>;
  surveys: Array<Survey>;
};

export type SurveyTypeSurveysArgs = {
  filter?: InputMaybe<SurveyFilter>;
};

export type SurveyValueUnit = {
  __typename?: 'SurveyValueUnit';
  id: Scalars['ObjectID']['output'];
  /** Localized field for nameLocalizations */
  name: Scalars['String']['output'];
  nameLocalizations: Array<LocalizedString>;
};

export type SurveyYesNoAnswer = SurveyAnswer & {
  __typename?: 'SurveyYesNoAnswer';
  actionItemIds?: Maybe<Array<Scalars['ObjectID']['output']>>;
  answeredFor: Scalars['BigInt']['output'];
  attachments?: Maybe<Array<Attachment>>;
  comment?: Maybe<Scalars['String']['output']>;
  dependingAnswers?: Maybe<Array<SurveyAnswer>>;
  id: Scalars['ObjectID']['output'];
  isAnswered: Scalars['Boolean']['output'];
  isNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  itemId: Scalars['ObjectID']['output'];
  survey: Survey;
  value?: Maybe<SurveyYesNoAnswerValue>;
};

export enum SurveyYesNoAnswerValue {
  No = 'No',
  Yes = 'Yes',
}

export type SurveyYesNoQuestion = SurveyItem & {
  __typename?: 'SurveyYesNoQuestion';
  allowComment?: Maybe<Scalars['Boolean']['output']>;
  allowNotApplicable?: Maybe<Scalars['Boolean']['output']>;
  allowUpload?: Maybe<Scalars['Boolean']['output']>;
  answer?: Maybe<SurveyYesNoAnswer>;
  /** Localized field for attachmentsLocalizations */
  attachments?: Maybe<Array<Attachment>>;
  attachmentsLocalizations?: Maybe<Array<LocalizedAttachments>>;
  commentRequired?: Maybe<SurveyYesNoQuestionRequiredField>;
  /** @deprecated Field no longer supported */
  dependingOnQuestionAnswer?: Maybe<Scalars['String']['output']>;
  dependingOnQuestionAnswers?: Maybe<Array<Scalars['String']['output']>>;
  dependingOnQuestionId?: Maybe<Scalars['ObjectID']['output']>;
  depth: Scalars['Int']['output'];
  /** Localized field for descriptionLocalizations */
  description?: Maybe<Scalars['String']['output']>;
  descriptionLocalizations?: Maybe<Array<LocalizedString>>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ObjectID']['output'];
  kind: SurveyItemKind;
  /** Localized field for nameLocalizations */
  name: Scalars['String']['output'];
  nameLocalizations: Array<LocalizedString>;
  uploadRequired?: Maybe<SurveyYesNoQuestionRequiredField>;
  weightNo: Scalars['Int']['output'];
  weightYes: Scalars['Int']['output'];
};

export type SurveyYesNoQuestionAnswerArgs = {
  answeredFor: Scalars['BigInt']['input'];
};

export type SurveyYesNoQuestionRequiredField = {
  __typename?: 'SurveyYesNoQuestionRequiredField';
  onNo?: Maybe<Scalars['Boolean']['output']>;
  onYes?: Maybe<Scalars['Boolean']['output']>;
};

export type SurveyYesNoQuestionRequiredFieldInput = {
  onNo?: InputMaybe<Scalars['Boolean']['input']>;
  onYes?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaskCountFilter = {
  status?: InputMaybe<MultisensoryExperienceMeasureTaskStatusFilter>;
};

export type TranslateSurveyInput = {
  sourceLanguageCode: Scalars['String']['input'];
  survey: SurveyTranslationInput;
  targetLanguageCodes: Array<Scalars['String']['input']>;
};

export type TranslationFilter = {
  locale: Scalars['String']['input'];
  value: StringFilter;
};

export type UpdateActionItemStatusInput = {
  status: ActionItemStatus;
};

export type UpdateAnnouncementInput = {
  from?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['ObjectID']['input']>;
  isActive: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateAttachmentInput = {
  filePrefix?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ObjectID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCommentInput = {
  attachments?: InputMaybe<ModifyAttachmentsInput>;
  id: Scalars['ObjectID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDashboardEmailNotificationInput = {
  adhoc?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ObjectID']['input'];
};

export type UpdateFeatureArticleInput = {
  apps?: InputMaybe<Array<Scalars['String']['input']>>;
  article?: InputMaybe<Scalars['String']['input']>;
  author?: InputMaybe<Scalars['BigInt']['input']>;
  id: Scalars['ObjectID']['input'];
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  organizations?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  publishedAt?: InputMaybe<Scalars['Date']['input']>;
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFieldForceManagementDashboardWidgetInput = {
  id?: InputMaybe<Scalars['ObjectID']['input']>;
  settings: FieldForceManagementDashboardWidgetSettingsInput;
  type: FieldForceManagementDashboardWidgetType;
};

export type UpdateFieldForceManagementEmailNotificationInput = {
  adhoc?: InputMaybe<Scalars['Boolean']['input']>;
  cron?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ObjectID']['input'];
  options?: InputMaybe<FieldForceManagementEmailNotificationOptionInput>;
  topic?: InputMaybe<FieldForceManagementEmailNotificationTopic>;
};

export type UpdateFieldForceManagementFeatureFlagInput = {
  id: Scalars['ObjectID']['input'];
  organizations: Array<Scalars['BigInt']['input']>;
  persons: Array<Scalars['BigInt']['input']>;
};

export type UpdateFilterPreset = {
  isFavorite: Scalars['Boolean']['input'];
  kind: FilterKind;
  name: Scalars['String']['input'];
};

export type UpdateFilterPresetInput = {
  personId: Scalars['BigInt']['input'];
  preset: UpdateFilterPreset;
};

export type UpdateHelpArticleInput = {
  article?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  publishDate?: InputMaybe<Scalars['DateTime']['input']>;
  title: Scalars['String']['input'];
  type: HelpArticleType;
  visibleToRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateMeetingInput = {
  agenda?: InputMaybe<Array<InputMaybe<AgendaItemInput>>>;
  approver?: InputMaybe<PersonReferenceInput>;
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  attendees?: InputMaybe<Array<InputMaybe<PersonReferenceInput>>>;
  author?: InputMaybe<PersonReferenceInput>;
  categories?: InputMaybe<Array<InputMaybe<CategoryReferenceInput>>>;
  coachingModules?: InputMaybe<Array<CoachingModuleReferenceInput>>;
  comments?: InputMaybe<Array<InputMaybe<CommentInput>>>;
  consultants?: InputMaybe<Array<InputMaybe<PersonReferenceInput>>>;
  consultingMeetingType?: InputMaybe<
    Array<InputMaybe<ConsultingMeetingTypeReferenceInput>>
  >;
  description?: InputMaybe<Scalars['String']['input']>;
  externals?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['ObjectID']['input']>;
  isConfidential?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<MeetingKind>;
  meetingId?: InputMaybe<Scalars['ObjectID']['input']>;
  organization?: InputMaybe<OrganizationReferenceInput>;
  /** @deprecated Field no longer supported */
  pbeMeetingTypeDescription?: InputMaybe<Scalars['String']['input']>;
  peerGroup?: InputMaybe<Scalars['ObjectID']['input']>;
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  scheduledTimeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledTimeStart?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<MeetingStatus>;
  subject?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<MeetingType>;
};

export type UpdateMeetingStatusInput = {
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  status: MeetingStatus;
};

export type UpdateMultisensoryExperienceAreaInput = {
  format?: InputMaybe<MultisensoryExperienceFormat>;
  id: Scalars['ObjectID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMultisensoryExperienceEmailNotificationInput = {
  adhoc?: InputMaybe<Scalars['Boolean']['input']>;
  cron?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ObjectID']['input'];
};

export type UpdateMultisensoryExperienceFilterPresetInput = {
  filter?: InputMaybe<MultisensoryExperienceFilterPresetConfigInput>;
  id: Scalars['ObjectID']['input'];
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMultisensoryExperienceInput = {
  attachments?: InputMaybe<ModifyAttachmentsInput>;
  comments?: InputMaybe<ModifyCommentsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ObjectID']['input'];
  plannedCompletion?: InputMaybe<Scalars['Date']['input']>;
  responsibleId?: InputMaybe<Scalars['BigInt']['input']>;
  start?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<MultisensoryExperienceStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMultisensoryExperienceItemInput = {
  areaIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']['input']>>>;
  category?: InputMaybe<MultisensoryExperienceItemCategory>;
  currency?: InputMaybe<MultisensoryExperienceItemCurrency>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ObjectID']['input'];
  image?: InputMaybe<UpdateAttachmentInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  referenceId?: InputMaybe<Scalars['String']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['URL']['input']>;
};

export type UpdateMultisensoryExperienceMeasureInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ObjectID']['input'];
  implementationImages?: InputMaybe<ModifyImplementationImagesInput>;
  media?: InputMaybe<ModifyMediaInput>;
};

export type UpdateMultisensoryExperienceMeasureTaskInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<ModifyCommentsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ObjectID']['input'];
  itemId?: InputMaybe<Scalars['ObjectID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  placements?: InputMaybe<ModifyMultisensoryExperienceMeasureTaskPlacementsInput>;
  status?: InputMaybe<MultisensoryExperienceMeasureTaskStatus>;
};

export type UpdateMysteryShoppingCriteriasInput = {
  actionItemIds?: InputMaybe<ModifyMysteryShoppingCriteriaActionItemIdsInput>;
  ids: Array<Scalars['ObjectID']['input']>;
};

export type UpdateNetworkPlanningFeatureFlagInput = {
  id: Scalars['ObjectID']['input'];
  organizations: Array<Scalars['BigInt']['input']>;
  persons: Array<Scalars['BigInt']['input']>;
};

export type UpdateNetworkPlanningOrganizationInput = {
  actualInvestor?: InputMaybe<Scalars['String']['input']>;
  batteryCapacity?: InputMaybe<Scalars['Int']['input']>;
  batteryRepairHub?: InputMaybe<Scalars['Boolean']['input']>;
  chargerMaxPower?: InputMaybe<Scalars['Int']['input']>;
  chargerOperationalStatus?: InputMaybe<NetworkPlanningChargerOperationalStatus>;
  chargingBackendName?: InputMaybe<Scalars['String']['input']>;
  chargingBackendVendor?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  connectedInvestor?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customerAreaCharger?: InputMaybe<NetworkPlanningChargerInput>;
  dpCompletionExterieur?: InputMaybe<Scalars['DateMonth']['input']>;
  dpCompletionInterieur?: InputMaybe<Scalars['DateMonth']['input']>;
  dpUpgradeCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  importer?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  lastCiUpdate?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  loadManagementPresent?: InputMaybe<Scalars['Boolean']['input']>;
  loadManagementVendor?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  market?: InputMaybe<Scalars['String']['input']>;
  multiBrand?: InputMaybe<NetworkPlanningOrganizationBrand>;
  name?: InputMaybe<Scalars['String']['input']>;
  ncSales?: InputMaybe<Scalars['FloatPerYear']['input']>;
  numberOfWiredChargers?: InputMaybe<Scalars['Int']['input']>;
  numberOfWirelessChargers?: InputMaybe<Scalars['Int']['input']>;
  opening?: InputMaybe<Scalars['DateMonth']['input']>;
  organization?: InputMaybe<Scalars['BigInt']['input']>;
  partner?: InputMaybe<NetworkPlanningOrganizationPartner>;
  partnerNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  serviceAreaCharger?: InputMaybe<NetworkPlanningChargerInput>;
  solarPanelsMaxOutput?: InputMaybe<Scalars['Int']['input']>;
  sqm?: InputMaybe<Scalars['FloatPerYear']['input']>;
  status?: InputMaybe<OrganizationStatus>;
  street?: InputMaybe<Scalars['String']['input']>;
  subregion?: InputMaybe<NetworkPlanningOrganizationSubregion>;
  termination?: InputMaybe<Scalars['DateMonth']['input']>;
  type?: InputMaybe<NetworkPlanningOrganizationType>;
  uio?: InputMaybe<Scalars['FloatPerYear']['input']>;
  upgradeType?: InputMaybe<NetworkPlanningOrganizationUpgradeType>;
  wiredChargerVendor?: InputMaybe<Scalars['String']['input']>;
  wirelessChargerVendor?: InputMaybe<Scalars['String']['input']>;
  workbays?: InputMaybe<Scalars['IntPerYear']['input']>;
};

export type UpdateOccasionRelatedApprovalActionItemInput = {
  actionItemIds: Array<Scalars['ObjectID']['input']>;
  id: Scalars['ObjectID']['input'];
};

export type UpdateOccasionRelatedApprovalInput = {
  adHocResponsibleId?: InputMaybe<Scalars['BigInt']['input']>;
  applicantIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  attachments?: InputMaybe<ModifyAttachmentsInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<ModifyCommentsInput>;
  date?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ObjectID']['input'];
  reason?: InputMaybe<Reason>;
  reports?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateOccasionRelatedApprovalReviewInput = {
  id: Scalars['ObjectID']['input'];
  review: Review;
};

export type UpdatePersonInput = {
  consents?: InputMaybe<PersonConsentsInput>;
  id?: InputMaybe<Scalars['BigInt']['input']>;
  settings?: InputMaybe<PersonSettingsInput>;
};

export type UpdatePersonSettingsInput = {
  actionItemListColumns?: InputMaybe<Array<Scalars['String']['input']>>;
  dateFormat?: InputMaybe<Scalars['String']['input']>;
  defaultApprover?: InputMaybe<PersonReferenceInput>;
  defaultCurrency?: InputMaybe<Scalars['String']['input']>;
  emailReportTimezone?: InputMaybe<Scalars['String']['input']>;
  exportMode?: InputMaybe<Scalars['String']['input']>;
  is24HourFormat?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  meetingListColumns?: InputMaybe<Array<Scalars['String']['input']>>;
  ppnuid?: InputMaybe<Scalars['String']['input']>;
  printMode?: InputMaybe<PrintMode>;
  /** @deprecated Field no longer supported */
  weekStartsOn?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePorscheStrategy2030EmailNotificationInput = {
  adhoc?: InputMaybe<Scalars['Boolean']['input']>;
  cron?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ObjectID']['input'];
  options?: InputMaybe<PorscheStrategy2030EmailNotificationOptionInput>;
  topic: PorscheStrategy2030EmailNotificationTopic;
};

export type UpdateRetailDesignAbsenceNoteInput = {
  description: Scalars['String']['input'];
  from?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['ObjectID']['input']>;
  isActive: Scalars['Boolean']['input'];
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateRetailDesignDocumentInput = {
  id: Scalars['ObjectID']['input'];
  mandatory?: InputMaybe<Scalars['Boolean']['input']>;
  mandatoryForStatus?: InputMaybe<Array<RetailDesignProjectStatus>>;
  mandatoryOnlyForFormatIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  name: Scalars['String']['input'];
  translations?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateRetailDesignEmailNotificationInput = {
  adhoc?: InputMaybe<Scalars['Boolean']['input']>;
  cron?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ObjectID']['input'];
  options?: InputMaybe<RetailDesignEmailNotificationOptionInput>;
  topic: RetailDesignEmailNotificationTopic;
};

export type UpdateRetailDesignFilterPresetInput = {
  filter?: InputMaybe<RetailDesignFilterPresetConfigInput>;
  id: Scalars['ObjectID']['input'];
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRetailDesignFormatInput = {
  /** @deprecated Use hasNoWorkshopThroughput instead */
  hasNoAftersalesThroughput?: InputMaybe<Scalars['Boolean']['input']>;
  hasNoBodyAndPaintThroughput?: InputMaybe<Scalars['Boolean']['input']>;
  hasNoNCSalesVolume?: InputMaybe<Scalars['Boolean']['input']>;
  hasNoWorkshopThroughput?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
  needsAftersalesApproval?: InputMaybe<Scalars['Boolean']['input']>;
  translations?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateRetailDesignProjectAttachmentInput = {
  comments?: InputMaybe<ModifyCommentsInput>;
  filePrefix?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ObjectID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<RetailDesignProjectAttachmentVisibility>;
};

export type UpdateRetailDesignProjectInput = {
  afterSalesApproved?: InputMaybe<Scalars['Boolean']['input']>;
  attachments?: InputMaybe<ModifyRetailDesignProjectAttachmentInput>;
  bookmark?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<ModifyCommentsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  expectedBodyAndPaintThroughputPerDay?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  expectedSalesVolumePerYear?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  expectedWorkshopThroughputPerDay?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  formatId?: InputMaybe<Scalars['ObjectID']['input']>;
  id: Scalars['ObjectID']['input'];
  organizationId?: InputMaybe<Scalars['BigInt']['input']>;
  plannedConstructionCompletion?: InputMaybe<Scalars['Date']['input']>;
  plannedConstructionStart?: InputMaybe<Scalars['Date']['input']>;
  relationType?: InputMaybe<RetailDesignProjectRelationType>;
  retailDesignApproved?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<RetailDesignProjectStatus>;
  street?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type UpdateRetailDesignProjectTypeInput = {
  id: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
  translations?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateStrategyActionItemInput = {
  attachments?: InputMaybe<ModifyAttachmentsInput>;
  comments?: InputMaybe<ModifyCommentsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ObjectID']['input'];
  kpiId?: InputMaybe<Scalars['ObjectID']['input']>;
  okr?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['BigInt']['input']>;
  responsibleId?: InputMaybe<Scalars['BigInt']['input']>;
  status?: InputMaybe<StrategyActionItemStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
  workPackages?: InputMaybe<ModifyWorkPackagesInput>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateStrategyActionItemWorkPackageInput = {
  id: Scalars['ObjectID']['input'];
  status?: InputMaybe<StrategyActionItemWorkPackageStatus>;
  targetMonth?: InputMaybe<Scalars['DateMonth']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStrategyFieldInput = {
  assignmentType?: InputMaybe<AssignmentType>;
  color?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ObjectID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  organization: Scalars['BigInt']['input'];
  stage?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateStrategyKpiInput = {
  id: Scalars['ObjectID']['input'];
  organization?: InputMaybe<Scalars['BigInt']['input']>;
  stage: Scalars['Int']['input'];
  strategyField: Scalars['ObjectID']['input'];
  title: Scalars['String']['input'];
};

export type UpdateStrategyStageInput = {
  id: Scalars['ObjectID']['input'];
  visibleForDealer: Scalars['Date']['input'];
  visibleForManager: Scalars['Date']['input'];
  year: Scalars['Int']['input'];
};

export type UpdateSupportRequestInput = {
  comments?: InputMaybe<ModifyCommentsInput>;
  id: Scalars['ObjectID']['input'];
  lastSeenAt?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<SupportRequestStatus>;
};

export type UpdateSurveyEmailNotificationInput = {
  adhoc?: InputMaybe<Scalars['Boolean']['input']>;
  cron?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ObjectID']['input'];
  options?: InputMaybe<SurveyEmailNotificationOptionInput>;
  topic: SurveyEmailNotificationTopic;
};

export type UpdateSurveyEntryValueUnitInput = {
  id: Scalars['ObjectID']['input'];
  nameLocalizations: Array<LocalizedStringInput>;
  type: SurveyEntryValueUnitType;
};

export type UpdateSurveyInput = {
  answersAllowedByIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  canCreateActionItems?: InputMaybe<Scalars['Boolean']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  effectiveFrom?: InputMaybe<Scalars['Date']['input']>;
  effectiveTo?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ObjectID']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  items?: InputMaybe<Array<UpdateSurveyItemInput>>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLocalizations?: InputMaybe<Array<LocalizedStringInput>>;
  organizationIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  personIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  typeId?: InputMaybe<Scalars['ObjectID']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateSurveyItemInput = {
  allowComment?: InputMaybe<Scalars['Boolean']['input']>;
  allowMultiple?: InputMaybe<Scalars['Boolean']['input']>;
  allowNotApplicable?: InputMaybe<Scalars['Boolean']['input']>;
  allowUpload?: InputMaybe<Scalars['Boolean']['input']>;
  attachmentsLocalizations?: InputMaybe<Array<LocalizedAttachmentsInput>>;
  commentRequired?: InputMaybe<SurveyYesNoQuestionRequiredFieldInput>;
  /** @deprecated Field no longer supported */
  dependingOnQuestionAnswer?: InputMaybe<Scalars['String']['input']>;
  dependingOnQuestionAnswers?: InputMaybe<Array<Scalars['String']['input']>>;
  dependingOnQuestionId?: InputMaybe<Scalars['ObjectID']['input']>;
  depth: Scalars['Int']['input'];
  descriptionLocalizations?: InputMaybe<Array<LocalizedStringInput>>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  entries?: InputMaybe<Array<UpdateSurveyMultipleEntryQuestionEntryInput>>;
  entryValueUnit?: InputMaybe<UpdateSurveyEntryValueUnitInput>;
  id: Scalars['ObjectID']['input'];
  kind: SurveyItemKind;
  nameLocalizations: Array<LocalizedStringInput>;
  options?: InputMaybe<Array<UpdateSurveyMultipleChoiceQuestionOptionInput>>;
  uploadRequired?: InputMaybe<SurveyYesNoQuestionRequiredFieldInput>;
  valueUnit?: InputMaybe<UpdateSurveyValueUnitInput>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  weightNo?: InputMaybe<Scalars['Int']['input']>;
  weightYes?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateSurveyMultipleChoiceQuestionOptionInput = {
  id: Scalars['ObjectID']['input'];
  nameLocalizations: Array<LocalizedStringInput>;
  weight: Scalars['Int']['input'];
};

export type UpdateSurveyMultipleEntryQuestionEntryInput = {
  id: Scalars['ObjectID']['input'];
  nameLocalizations: Array<LocalizedStringInput>;
};

export type UpdateSurveyReportConfigChartInput = {
  aggregationLevel?: InputMaybe<SurveyReportAggregationLevel>;
  cutoffDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ObjectID']['input'];
  onlyIncludeOrganizationsWithAnswers?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<SortOrder>;
  orderBy?: InputMaybe<SurveyReportOrderBy>;
  organizationIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  surveyIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  surveyItemIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<SurveyReportChartType>;
};

export type UpdateSurveyReportConfigInput = {
  id: Scalars['ObjectID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  sections?: InputMaybe<ModifySurveyReportConfigSectionInput>;
};

export type UpdateSurveyReportConfigSectionInput = {
  charts?: InputMaybe<ModifySurveyReportConfigChartInput>;
  id: Scalars['ObjectID']['input'];
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateSurveyTypeInput = {
  id: Scalars['ObjectID']['input'];
  nameLocalizations: Array<LocalizedStringInput>;
};

export type UpdateSurveyValueUnitInput = {
  id: Scalars['ObjectID']['input'];
  nameLocalizations: Array<LocalizedStringInput>;
};

export type UpdateUserManagementItemInput = {
  additionalOrganizationIds?: InputMaybe<ModifyUserManagementItemOrganizationIdsInput>;
  id: Scalars['ObjectID']['input'];
};

export enum UploadReference {
  /** @deprecated Use 'FieldForceManagement' instead */
  ActionItem = 'ActionItem',
  Dashboard = 'Dashboard',
  FieldForceManagement = 'FieldForceManagement',
  Greenland = 'Greenland',
  MultisensoryExperience = 'MultisensoryExperience',
  /** @deprecated Use 'Greenland' instead */
  OccasionRelatedApproval = 'OccasionRelatedApproval',
  PorscheStrategy2030 = 'PorscheStrategy2030',
  /** @deprecated Use 'FieldForceManagement' instead */
  Questionnaire = 'Questionnaire',
  RetailDesign = 'RetailDesign',
  Survey = 'Survey',
}

export enum UploadType {
  Attachment = 'Attachment',
  Image = 'Image',
  Import = 'Import',
  Video = 'Video',
}

export type UploadUrls = {
  __typename?: 'UploadUrls';
  kmsKeyId: Scalars['String']['output'];
  urls: Array<Scalars['String']['output']>;
};

export type UserManagementItem = {
  __typename?: 'UserManagementItem';
  additionalOrganizations: Array<Organization>;
  application: Scalars['String']['output'];
  id: Scalars['ObjectID']['output'];
  person: Person;
};

export type UserManagementItemFilter = {
  _and?: InputMaybe<Array<UserManagementItemFilter>>;
  _or?: InputMaybe<Array<UserManagementItemFilter>>;
  application?: InputMaybe<StringFilter>;
  person?: InputMaybe<BigIntFilter>;
};

export type UserManagementItemsInput = {
  filter?: InputMaybe<UserManagementItemFilter>;
};

export enum WidgetKind {
  FilledInAudit = 'FilledInAudit',
  FilledInQuestionnaire = 'FilledInQuestionnaire',
  FilledInSurvey = 'FilledInSurvey',
}

export enum YesNoAnswer {
  No = 'No',
  Yes = 'Yes',
}

export enum YesNoScoreValue {
  No = 'No',
  Yes = 'Yes',
}

export type AnalyticsQueryVariables = Exact<{
  input: AnalyticsInput;
}>;

export type AnalyticsQuery = {
  __typename?: 'Query';
  analytics?: Array<{
    __typename?: 'Analytics';
    eventCount: number;
    eventDate: string;
    role?: string | null;
  }> | null;
};

export type AnnouncementsQueryVariables = Exact<{ [key: string]: never }>;

export type AnnouncementsQuery = {
  __typename?: 'Query';
  announcements: Array<{
    __typename?: 'Announcement';
    id: string;
    message: string;
    isActive: boolean;
    from?: string | null;
    to?: string | null;
  }>;
};

export type UpdateAnnouncementMutationVariables = Exact<{
  input: UpdateAnnouncementInput;
}>;

export type UpdateAnnouncementMutation = {
  __typename?: 'Mutation';
  updateAnnouncement: {
    __typename?: 'Announcement';
    id: string;
    message: string;
    isActive: boolean;
    to?: string | null;
    from?: string | null;
  };
};

export type DashboardEmailNotificationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DashboardEmailNotificationsQuery = {
  __typename?: 'Query';
  dashboardEmailNotifications: Array<{
    __typename?: 'DashboardEmailNotification';
    id: string;
    adhoc?: boolean | null;
  }>;
};

export type UpdateDashboardEmailNotificationMutationVariables = Exact<{
  input: UpdateDashboardEmailNotificationInput;
}>;

export type UpdateDashboardEmailNotificationMutation = {
  __typename?: 'Mutation';
  updateDashboardEmailNotification: {
    __typename?: 'DashboardEmailNotification';
    id: string;
    adhoc?: boolean | null;
  };
};

export type CreateDashboardEmailNotificationMutationVariables = Exact<{
  input: CreateDashboardEmailNotificationInput;
}>;

export type CreateDashboardEmailNotificationMutation = {
  __typename?: 'Mutation';
  createDashboardEmailNotification: {
    __typename?: 'DashboardEmailNotification';
    id: string;
    adhoc?: boolean | null;
  };
};

export type DeleteDashboardEmailNotificationMutationVariables = Exact<{
  id: Scalars['ObjectID']['input'];
}>;

export type DeleteDashboardEmailNotificationMutation = {
  __typename?: 'Mutation';
  deleteDashboardEmailNotification: {
    __typename?: 'DashboardEmailNotification';
    id: string;
  };
};

export type FeatureArticleQueryVariables = Exact<{
  id: Scalars['ObjectID']['input'];
}>;

export type FeatureArticleQuery = {
  __typename?: 'Query';
  featureArticle?: {
    __typename?: 'FeatureArticle';
    id: string;
    title: string;
    article: string;
    isPublished?: boolean | null;
    publishedAt?: string | null;
    apps: Array<string>;
    roles: Array<string>;
    author: { __typename?: 'Person'; id: number; name: string };
    organizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }>;
  } | null;
};

export type FeatureArticlesQueryVariables = Exact<{ [key: string]: never }>;

export type FeatureArticlesQuery = {
  __typename?: 'Query';
  featureArticles: Array<{
    __typename?: 'FeatureArticle';
    id: string;
    title: string;
    article: string;
    isPublished?: boolean | null;
    publishedAt?: string | null;
    apps: Array<string>;
    roles: Array<string>;
    author: { __typename?: 'Person'; id: number; name: string };
  }>;
};

export type AdminFeatureArticlesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AdminFeatureArticlesQuery = {
  __typename?: 'Query';
  featureArticles: Array<{
    __typename?: 'FeatureArticle';
    id: string;
    title: string;
    article: string;
    isPublished?: boolean | null;
    publishedAt?: string | null;
    apps: Array<string>;
    roles: Array<string>;
    author: { __typename?: 'Person'; id: number; name: string };
    organizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }>;
  }>;
};

export type CreateFeatureArticleMutationVariables = Exact<{
  input: CreateFeatureArticleInput;
}>;

export type CreateFeatureArticleMutation = {
  __typename?: 'Mutation';
  createFeatureArticle: { __typename?: 'FeatureArticle'; id: string };
};

export type UpdateFeatureArticleMutationVariables = Exact<{
  input: UpdateFeatureArticleInput;
}>;

export type UpdateFeatureArticleMutation = {
  __typename?: 'Mutation';
  updateFeatureArticle: {
    __typename?: 'FeatureArticle';
    id: string;
    title: string;
    article: string;
    isPublished?: boolean | null;
    publishedAt?: string | null;
    apps: Array<string>;
    roles: Array<string>;
    author: { __typename?: 'Person'; id: number; name: string };
    organizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }>;
  };
};

export type DeleteFeatureArticleMutationVariables = Exact<{
  id: Scalars['ObjectID']['input'];
}>;

export type DeleteFeatureArticleMutation = {
  __typename?: 'Mutation';
  deleteFeatureArticle: { __typename?: 'FeatureArticle'; id: string };
};

export type OrganizationsQueryVariables = Exact<{
  searchString?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationFilter>;
}>;

export type OrganizationsQuery = {
  __typename?: 'Query';
  organizations: {
    __typename?: 'PaginatedOrganizations';
    docs: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
      partnerNumber?: string | null;
      status?: OrganizationStatus | null;
      organizationId: number;
    }>;
  };
};

export type OrganizationQueryVariables = Exact<{
  id?: InputMaybe<Scalars['BigInt']['input']>;
}>;

export type OrganizationQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    name: string;
    organizationId: number;
  } | null;
};

export type PersonsQueryVariables = Exact<{
  searchString?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PersonFilter>;
}>;

export type PersonsQuery = {
  __typename?: 'Query';
  persons: {
    __typename?: 'PaginatedPersons';
    docs: Array<{
      __typename?: 'Person';
      id: number;
      name: string;
      ppnuid: string;
      organization: { __typename?: 'Organization'; id: number; name: string };
    }>;
  };
};

export type PersonByIdQueryVariables = Exact<{
  id: Scalars['BigInt']['input'];
}>;

export type PersonByIdQuery = {
  __typename?: 'Query';
  person?: { __typename?: 'Person'; id: number; name: string } | null;
};

export type PersonQueryVariables = Exact<{
  ppnuid: Scalars['String']['input'];
}>;

export type PersonQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: number;
    ppnuid: string;
    name: string;
    roles: Array<string>;
    organization: {
      __typename?: 'Organization';
      parentMarket?: {
        __typename?: 'Organization';
        organizationId: number;
      } | null;
    };
    ability: { __typename?: 'Ability'; dashboard: Array<Record<string, any>> };
    settings?: {
      __typename?: 'PersonSettings';
      dateFormat?: string | null;
      is24HourFormat?: boolean | null;
      locale?: string | null;
    } | null;
    consents?: {
      __typename?: 'PersonConsents';
      userDeletion?: string | null;
    } | null;
  } | null;
};

export type UpdatePersonSettingsMutationVariables = Exact<{
  input: UpdatePersonSettingsInput;
}>;

export type UpdatePersonSettingsMutation = {
  __typename?: 'Mutation';
  updatePersonSettings: {
    __typename?: 'Person';
    ppnuid: string;
    settings?: { __typename?: 'PersonSettings'; locale?: string | null } | null;
  };
};

export type UpdatePersonMutationVariables = Exact<{
  input: UpdatePersonInput;
}>;

export type UpdatePersonMutation = {
  __typename?: 'Mutation';
  updatePerson: {
    __typename?: 'Person';
    id: number;
    ppnuid: string;
    consents?: {
      __typename?: 'PersonConsents';
      userDeletion?: string | null;
    } | null;
  };
};

export type PersonFieldsFragment = {
  __typename?: 'Person';
  id: number;
  ppnuid: string;
  name: string;
  mail: string;
  pagCurrentAccountStatus?: string | null;
  deletedAt?: string | null;
  roles: Array<string>;
  groups?: Array<{ __typename?: 'Group'; id: string; name: string }> | null;
  visibleGroups?: Array<{
    __typename?: 'Group';
    id: string;
    name: string;
  }> | null;
  visibleOrganizations?: Array<{
    __typename?: 'Organization';
    id: number;
    name: string;
  }> | null;
  peerGroups?: Array<{
    __typename?: 'PeerGroup';
    id: string;
    name: string;
  }> | null;
  visibleCategories?: Array<{
    __typename?: 'Category';
    id: string;
    name: string;
  }> | null;
  organization: { __typename?: 'Organization'; id: number; name: string };
  settings?: {
    __typename?: 'PersonSettings';
    dateFormat?: string | null;
    is24HourFormat?: boolean | null;
    locale?: string | null;
    defaultCurrency?: string | null;
    exportMode?: string | null;
    printMode?: PrintMode | null;
    emailReportTimezone?: string | null;
  } | null;
  consents?: {
    __typename?: 'PersonConsents';
    userDeletion?: string | null;
  } | null;
};

export type PersonInfoQueryVariables = Exact<{
  id: Scalars['BigInt']['input'];
}>;

export type PersonInfoQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: number;
    ppnuid: string;
    name: string;
    mail: string;
    pagCurrentAccountStatus?: string | null;
    deletedAt?: string | null;
    roles: Array<string>;
    ability: { __typename?: 'Ability'; dashboard: Array<Record<string, any>> };
    groups?: Array<{ __typename?: 'Group'; id: string; name: string }> | null;
    visibleGroups?: Array<{
      __typename?: 'Group';
      id: string;
      name: string;
    }> | null;
    visibleOrganizations?: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }> | null;
    peerGroups?: Array<{
      __typename?: 'PeerGroup';
      id: string;
      name: string;
    }> | null;
    visibleCategories?: Array<{
      __typename?: 'Category';
      id: string;
      name: string;
    }> | null;
    organization: { __typename?: 'Organization'; id: number; name: string };
    settings?: {
      __typename?: 'PersonSettings';
      dateFormat?: string | null;
      is24HourFormat?: boolean | null;
      locale?: string | null;
      defaultCurrency?: string | null;
      exportMode?: string | null;
      printMode?: PrintMode | null;
      emailReportTimezone?: string | null;
    } | null;
    consents?: {
      __typename?: 'PersonConsents';
      userDeletion?: string | null;
    } | null;
  } | null;
};

export type PersonInfoForFieldForceManagementQueryVariables = Exact<{
  id: Scalars['BigInt']['input'];
}>;

export type PersonInfoForFieldForceManagementQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: number;
    ppnuid: string;
    name: string;
    mail: string;
    pagCurrentAccountStatus?: string | null;
    deletedAt?: string | null;
    roles: Array<string>;
    ability: {
      __typename?: 'Ability';
      fieldForceManagement: Array<Record<string, any>>;
    };
    groups?: Array<{ __typename?: 'Group'; id: string; name: string }> | null;
    visibleGroups?: Array<{
      __typename?: 'Group';
      id: string;
      name: string;
    }> | null;
    visibleOrganizations?: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }> | null;
    peerGroups?: Array<{
      __typename?: 'PeerGroup';
      id: string;
      name: string;
    }> | null;
    visibleCategories?: Array<{
      __typename?: 'Category';
      id: string;
      name: string;
    }> | null;
    organization: { __typename?: 'Organization'; id: number; name: string };
    settings?: {
      __typename?: 'PersonSettings';
      dateFormat?: string | null;
      is24HourFormat?: boolean | null;
      locale?: string | null;
      defaultCurrency?: string | null;
      exportMode?: string | null;
      printMode?: PrintMode | null;
      emailReportTimezone?: string | null;
    } | null;
    consents?: {
      __typename?: 'PersonConsents';
      userDeletion?: string | null;
    } | null;
  } | null;
  userManagementItems: Array<{
    __typename?: 'UserManagementItem';
    additionalOrganizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }>;
  }>;
};

export type PersonInfoForRetailDesignQueryVariables = Exact<{
  id: Scalars['BigInt']['input'];
}>;

export type PersonInfoForRetailDesignQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: number;
    ppnuid: string;
    name: string;
    mail: string;
    pagCurrentAccountStatus?: string | null;
    deletedAt?: string | null;
    roles: Array<string>;
    ability: {
      __typename?: 'Ability';
      retailDesign: Array<Record<string, any>>;
    };
    groups?: Array<{ __typename?: 'Group'; id: string; name: string }> | null;
    visibleGroups?: Array<{
      __typename?: 'Group';
      id: string;
      name: string;
    }> | null;
    visibleOrganizations?: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }> | null;
    peerGroups?: Array<{
      __typename?: 'PeerGroup';
      id: string;
      name: string;
    }> | null;
    visibleCategories?: Array<{
      __typename?: 'Category';
      id: string;
      name: string;
    }> | null;
    organization: { __typename?: 'Organization'; id: number; name: string };
    settings?: {
      __typename?: 'PersonSettings';
      dateFormat?: string | null;
      is24HourFormat?: boolean | null;
      locale?: string | null;
      defaultCurrency?: string | null;
      exportMode?: string | null;
      printMode?: PrintMode | null;
      emailReportTimezone?: string | null;
    } | null;
    consents?: {
      __typename?: 'PersonConsents';
      userDeletion?: string | null;
    } | null;
  } | null;
  userManagementItems: Array<{
    __typename?: 'UserManagementItem';
    additionalOrganizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }>;
  }>;
};

export type PersonInfoForPorscheStrategy2030QueryVariables = Exact<{
  id: Scalars['BigInt']['input'];
}>;

export type PersonInfoForPorscheStrategy2030Query = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: number;
    ppnuid: string;
    name: string;
    mail: string;
    pagCurrentAccountStatus?: string | null;
    deletedAt?: string | null;
    roles: Array<string>;
    ability: {
      __typename?: 'Ability';
      porscheStrategy2030: Array<Record<string, any>>;
    };
    groups?: Array<{ __typename?: 'Group'; id: string; name: string }> | null;
    visibleGroups?: Array<{
      __typename?: 'Group';
      id: string;
      name: string;
    }> | null;
    visibleOrganizations?: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }> | null;
    peerGroups?: Array<{
      __typename?: 'PeerGroup';
      id: string;
      name: string;
    }> | null;
    visibleCategories?: Array<{
      __typename?: 'Category';
      id: string;
      name: string;
    }> | null;
    organization: { __typename?: 'Organization'; id: number; name: string };
    settings?: {
      __typename?: 'PersonSettings';
      dateFormat?: string | null;
      is24HourFormat?: boolean | null;
      locale?: string | null;
      defaultCurrency?: string | null;
      exportMode?: string | null;
      printMode?: PrintMode | null;
      emailReportTimezone?: string | null;
    } | null;
    consents?: {
      __typename?: 'PersonConsents';
      userDeletion?: string | null;
    } | null;
  } | null;
  userManagementItems: Array<{
    __typename?: 'UserManagementItem';
    additionalOrganizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }>;
  }>;
};

export type PersonInfoForSurveyQueryVariables = Exact<{
  id: Scalars['BigInt']['input'];
}>;

export type PersonInfoForSurveyQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: number;
    ppnuid: string;
    name: string;
    mail: string;
    pagCurrentAccountStatus?: string | null;
    deletedAt?: string | null;
    roles: Array<string>;
    ability: { __typename?: 'Ability'; survey: Array<Record<string, any>> };
    groups?: Array<{ __typename?: 'Group'; id: string; name: string }> | null;
    visibleGroups?: Array<{
      __typename?: 'Group';
      id: string;
      name: string;
    }> | null;
    visibleOrganizations?: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }> | null;
    peerGroups?: Array<{
      __typename?: 'PeerGroup';
      id: string;
      name: string;
    }> | null;
    visibleCategories?: Array<{
      __typename?: 'Category';
      id: string;
      name: string;
    }> | null;
    organization: { __typename?: 'Organization'; id: number; name: string };
    settings?: {
      __typename?: 'PersonSettings';
      dateFormat?: string | null;
      is24HourFormat?: boolean | null;
      locale?: string | null;
      defaultCurrency?: string | null;
      exportMode?: string | null;
      printMode?: PrintMode | null;
      emailReportTimezone?: string | null;
    } | null;
    consents?: {
      __typename?: 'PersonConsents';
      userDeletion?: string | null;
    } | null;
  } | null;
  userManagementItems: Array<{
    __typename?: 'UserManagementItem';
    additionalOrganizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }>;
  }>;
};

export type PersonInfoForGreenlandQueryVariables = Exact<{
  id: Scalars['BigInt']['input'];
}>;

export type PersonInfoForGreenlandQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: number;
    ppnuid: string;
    name: string;
    mail: string;
    pagCurrentAccountStatus?: string | null;
    deletedAt?: string | null;
    roles: Array<string>;
    ability: { __typename?: 'Ability'; greenland: Array<Record<string, any>> };
    groups?: Array<{ __typename?: 'Group'; id: string; name: string }> | null;
    visibleGroups?: Array<{
      __typename?: 'Group';
      id: string;
      name: string;
    }> | null;
    visibleOrganizations?: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }> | null;
    peerGroups?: Array<{
      __typename?: 'PeerGroup';
      id: string;
      name: string;
    }> | null;
    visibleCategories?: Array<{
      __typename?: 'Category';
      id: string;
      name: string;
    }> | null;
    organization: { __typename?: 'Organization'; id: number; name: string };
    settings?: {
      __typename?: 'PersonSettings';
      dateFormat?: string | null;
      is24HourFormat?: boolean | null;
      locale?: string | null;
      defaultCurrency?: string | null;
      exportMode?: string | null;
      printMode?: PrintMode | null;
      emailReportTimezone?: string | null;
    } | null;
    consents?: {
      __typename?: 'PersonConsents';
      userDeletion?: string | null;
    } | null;
  } | null;
  userManagementItems: Array<{
    __typename?: 'UserManagementItem';
    additionalOrganizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }>;
  }>;
};

export type PersonInfoForMysteryShoppingQueryVariables = Exact<{
  id: Scalars['BigInt']['input'];
}>;

export type PersonInfoForMysteryShoppingQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: number;
    ppnuid: string;
    name: string;
    mail: string;
    pagCurrentAccountStatus?: string | null;
    deletedAt?: string | null;
    roles: Array<string>;
    ability: {
      __typename?: 'Ability';
      mysteryShopping: Array<Record<string, any>>;
    };
    groups?: Array<{ __typename?: 'Group'; id: string; name: string }> | null;
    visibleGroups?: Array<{
      __typename?: 'Group';
      id: string;
      name: string;
    }> | null;
    visibleOrganizations?: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }> | null;
    peerGroups?: Array<{
      __typename?: 'PeerGroup';
      id: string;
      name: string;
    }> | null;
    visibleCategories?: Array<{
      __typename?: 'Category';
      id: string;
      name: string;
    }> | null;
    organization: { __typename?: 'Organization'; id: number; name: string };
    settings?: {
      __typename?: 'PersonSettings';
      dateFormat?: string | null;
      is24HourFormat?: boolean | null;
      locale?: string | null;
      defaultCurrency?: string | null;
      exportMode?: string | null;
      printMode?: PrintMode | null;
      emailReportTimezone?: string | null;
    } | null;
    consents?: {
      __typename?: 'PersonConsents';
      userDeletion?: string | null;
    } | null;
  } | null;
  userManagementItems: Array<{
    __typename?: 'UserManagementItem';
    additionalOrganizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }>;
  }>;
};

export type PersonInfoForNetworkPlanningQueryVariables = Exact<{
  id: Scalars['BigInt']['input'];
}>;

export type PersonInfoForNetworkPlanningQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: number;
    ppnuid: string;
    name: string;
    mail: string;
    pagCurrentAccountStatus?: string | null;
    deletedAt?: string | null;
    roles: Array<string>;
    ability: {
      __typename?: 'Ability';
      networkPlanning: Array<Record<string, any>>;
    };
    groups?: Array<{ __typename?: 'Group'; id: string; name: string }> | null;
    visibleGroups?: Array<{
      __typename?: 'Group';
      id: string;
      name: string;
    }> | null;
    visibleOrganizations?: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }> | null;
    peerGroups?: Array<{
      __typename?: 'PeerGroup';
      id: string;
      name: string;
    }> | null;
    visibleCategories?: Array<{
      __typename?: 'Category';
      id: string;
      name: string;
    }> | null;
    organization: { __typename?: 'Organization'; id: number; name: string };
    settings?: {
      __typename?: 'PersonSettings';
      dateFormat?: string | null;
      is24HourFormat?: boolean | null;
      locale?: string | null;
      defaultCurrency?: string | null;
      exportMode?: string | null;
      printMode?: PrintMode | null;
      emailReportTimezone?: string | null;
    } | null;
    consents?: {
      __typename?: 'PersonConsents';
      userDeletion?: string | null;
    } | null;
  } | null;
  userManagementItems: Array<{
    __typename?: 'UserManagementItem';
    additionalOrganizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }>;
  }>;
};

export type PersonInfoForMultisensoryExperienceQueryVariables = Exact<{
  id: Scalars['BigInt']['input'];
}>;

export type PersonInfoForMultisensoryExperienceQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: number;
    ppnuid: string;
    name: string;
    mail: string;
    pagCurrentAccountStatus?: string | null;
    deletedAt?: string | null;
    roles: Array<string>;
    ability: {
      __typename?: 'Ability';
      multisensoryExperience: Array<Record<string, any>>;
    };
    groups?: Array<{ __typename?: 'Group'; id: string; name: string }> | null;
    visibleGroups?: Array<{
      __typename?: 'Group';
      id: string;
      name: string;
    }> | null;
    visibleOrganizations?: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }> | null;
    peerGroups?: Array<{
      __typename?: 'PeerGroup';
      id: string;
      name: string;
    }> | null;
    visibleCategories?: Array<{
      __typename?: 'Category';
      id: string;
      name: string;
    }> | null;
    organization: { __typename?: 'Organization'; id: number; name: string };
    settings?: {
      __typename?: 'PersonSettings';
      dateFormat?: string | null;
      is24HourFormat?: boolean | null;
      locale?: string | null;
      defaultCurrency?: string | null;
      exportMode?: string | null;
      printMode?: PrintMode | null;
      emailReportTimezone?: string | null;
    } | null;
    consents?: {
      __typename?: 'PersonConsents';
      userDeletion?: string | null;
    } | null;
  } | null;
  userManagementItems: Array<{
    __typename?: 'UserManagementItem';
    additionalOrganizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }>;
  }>;
};

export type CheckMeetingAbilitiesQueryVariables = Exact<{
  meetingId: Scalars['ObjectID']['input'];
  personId: Scalars['BigInt']['input'];
}>;

export type CheckMeetingAbilitiesQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: number;
    ability: {
      __typename?: 'Ability';
      fieldForceManagementSubset: {
        __typename?: 'FieldForceManagementAbility';
        checkMeeting: {
          __typename?: 'FieldForceManagementAbilityCheckMeetingResult';
          subject: string;
          read: {
            __typename?: 'AbilityCheckResult';
            allowed: boolean;
            reason?: string | null;
          };
          update: {
            __typename?: 'AbilityCheckResult';
            allowed: boolean;
            reason?: string | null;
          };
          delete: {
            __typename?: 'AbilityCheckResult';
            allowed: boolean;
            reason?: string | null;
          };
          actionItems?: Array<{
            __typename?: 'FieldForceManagementAbilityCheckActionItemResult';
            subject: string;
            read: {
              __typename?: 'AbilityCheckResult';
              allowed: boolean;
              reason?: string | null;
            };
            update: {
              __typename?: 'AbilityCheckResult';
              allowed: boolean;
              reason?: string | null;
            };
            delete: {
              __typename?: 'AbilityCheckResult';
              allowed: boolean;
              reason?: string | null;
            };
          }> | null;
        };
      };
    };
  } | null;
};

export type PpnMasterdataImportStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PpnMasterdataImportStatusQuery = {
  __typename?: 'Query';
  ppnMasterdataImportStatus?: {
    __typename?: 'PpnMasterdataImportStatus';
    isRunning: boolean;
    startedAt: string;
    finishedAt?: string | null;
    organizationsFinishedAt?: string | null;
    personsFinishedAt?: string | null;
    groupsFinishedAt?: string | null;
    error?: string | null;
  } | null;
};

export type StartPpnMasterdataImportMutationVariables = Exact<{
  [key: string]: never;
}>;

export type StartPpnMasterdataImportMutation = {
  __typename?: 'Mutation';
  startPpnMasterdataImport: {
    __typename?: 'PpnMasterdataImportStatus';
    isRunning: boolean;
    startedAt: string;
    finishedAt?: string | null;
    organizationsFinishedAt?: string | null;
    personsFinishedAt?: string | null;
    groupsFinishedAt?: string | null;
    error?: string | null;
  };
};

export type SupportRequestQueryVariables = Exact<{
  id: Scalars['ObjectID']['input'];
}>;

export type SupportRequestQuery = {
  __typename?: 'Query';
  supportRequest?: {
    __typename?: 'SupportRequest';
    id: string;
    status: SupportRequestStatus;
    title: string;
    description: string;
    application: string;
    hasNewActivity: boolean;
    createdAt: string;
    organization: { __typename?: 'Organization'; name: string };
    author: { __typename?: 'Person'; name: string };
    referencePerson?: { __typename?: 'Person'; name: string } | null;
    details?: {
      __typename?: 'SupportRequestDetails';
      userAgent: string;
      screenSize: {
        __typename?: 'SupportRequestSize';
        width: number;
        height: number;
      };
      browserWindowSize: {
        __typename?: 'SupportRequestSize';
        width: number;
        height: number;
      };
    } | null;
    attachments?: Array<{
      __typename?: 'Attachment';
      id: string;
      name: string;
      type: string;
      size: number;
      createdAt: string;
    }> | null;
    comments: Array<{
      __typename?: 'Comment';
      id: string;
      text: string;
      createdAt: string;
      author: { __typename?: 'PersonReference'; name: string; id: number };
      attachments?: Array<{
        __typename?: 'Attachment';
        id: string;
        name: string;
        type: string;
        size: number;
        createdAt: string;
      }> | null;
    }>;
  } | null;
};

export type SupportRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type SupportRequestsQuery = {
  __typename?: 'Query';
  supportRequests: Array<{
    __typename?: 'SupportRequest';
    id: string;
    status: SupportRequestStatus;
    title: string;
    createdAt: string;
    hasNewActivity: boolean;
    author: { __typename?: 'Person'; name: string };
    organization: { __typename?: 'Organization'; name: string };
  }>;
};

export type SupportRequestsActivityQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SupportRequestsActivityQuery = {
  __typename?: 'Query';
  supportRequests: Array<{
    __typename?: 'SupportRequest';
    id: string;
    hasNewActivity: boolean;
  }>;
};

export type CreateSupportRequestMutationVariables = Exact<{
  input: CreateSupportRequestInput;
}>;

export type CreateSupportRequestMutation = {
  __typename?: 'Mutation';
  createSupportRequest: {
    __typename?: 'SupportRequest';
    id: string;
    status: SupportRequestStatus;
    title: string;
    description: string;
    application: string;
    hasNewActivity: boolean;
    createdAt: string;
    organization: { __typename?: 'Organization'; name: string };
    author: { __typename?: 'Person'; name: string };
    referencePerson?: { __typename?: 'Person'; name: string } | null;
    details?: {
      __typename?: 'SupportRequestDetails';
      userAgent: string;
      screenSize: {
        __typename?: 'SupportRequestSize';
        width: number;
        height: number;
      };
      browserWindowSize: {
        __typename?: 'SupportRequestSize';
        width: number;
        height: number;
      };
    } | null;
    attachments?: Array<{
      __typename?: 'Attachment';
      id: string;
      name: string;
      type: string;
      size: number;
      createdAt: string;
    }> | null;
  };
};

export type UpdateSupportRequestMutationVariables = Exact<{
  input: UpdateSupportRequestInput;
}>;

export type UpdateSupportRequestMutation = {
  __typename?: 'Mutation';
  updateSupportRequest: {
    __typename?: 'SupportRequest';
    id: string;
    status: SupportRequestStatus;
    title: string;
    hasNewActivity: boolean;
    createdAt: string;
    organization: { __typename?: 'Organization'; name: string };
    author: { __typename?: 'Person'; name: string };
    comments: Array<{
      __typename?: 'Comment';
      id: string;
      text: string;
      createdAt: string;
      author: { __typename?: 'PersonReference'; name: string; id: number };
      attachments?: Array<{
        __typename?: 'Attachment';
        id: string;
        name: string;
        type: string;
        size: number;
        createdAt: string;
      }> | null;
    }>;
  };
};

export type DeleteSupportRequestMutationVariables = Exact<{
  id: Scalars['ObjectID']['input'];
}>;

export type DeleteSupportRequestMutation = {
  __typename?: 'Mutation';
  deleteSupportRequest: { __typename?: 'SupportRequest'; id: string };
};

export type UploadUrlsQueryVariables = Exact<{
  filenames: Array<Scalars['String']['input']> | Scalars['String']['input'];
  type: UploadType;
  reference: UploadReference;
}>;

export type UploadUrlsQuery = {
  __typename?: 'Query';
  uploadUrls: {
    __typename?: 'UploadUrls';
    urls: Array<string>;
    kmsKeyId: string;
  };
};

export type CreateDownloadUrlMutationVariables = Exact<{
  id: Scalars['ObjectID']['input'];
  type: DownloadType;
  reference: DownloadReference;
}>;

export type CreateDownloadUrlMutation = {
  __typename?: 'Mutation';
  createDownloadUrl: string;
};

export type UserManagementItemsQueryVariables = Exact<{
  input?: InputMaybe<UserManagementItemsInput>;
}>;

export type UserManagementItemsQuery = {
  __typename?: 'Query';
  userManagementItems: Array<{
    __typename?: 'UserManagementItem';
    id: string;
    application: string;
    person: { __typename?: 'Person'; id: number; name: string };
    additionalOrganizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }>;
  }>;
};

export type CreateUserManagementItemMutationVariables = Exact<{
  input: CreateUserManagementItemInput;
}>;

export type CreateUserManagementItemMutation = {
  __typename?: 'Mutation';
  createUserManagementItem: {
    __typename?: 'UserManagementItem';
    id: string;
    application: string;
    person: { __typename?: 'Person'; id: number; name: string };
    additionalOrganizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }>;
  };
};

export type UpdateUserManagementItemMutationVariables = Exact<{
  input: UpdateUserManagementItemInput;
}>;

export type UpdateUserManagementItemMutation = {
  __typename?: 'Mutation';
  updateUserManagementItem: {
    __typename?: 'UserManagementItem';
    id: string;
    application: string;
    person: { __typename?: 'Person'; id: number; name: string };
    additionalOrganizations: Array<{
      __typename?: 'Organization';
      id: number;
      name: string;
    }>;
  };
};

export type DeleteUserManagementItemMutationVariables = Exact<{
  id: Scalars['ObjectID']['input'];
}>;

export type DeleteUserManagementItemMutation = {
  __typename?: 'Mutation';
  deleteUserManagementItem: { __typename?: 'UserManagementItem'; id: string };
};

export const PersonFieldsFragmentDoc = `
    fragment personFields on Person {
  id
  ppnuid
  name
  mail
  groups {
    id
    name
  }
  visibleGroups {
    id
    name
  }
  visibleOrganizations {
    id
    name
  }
  peerGroups {
    id
    name
  }
  visibleCategories {
    id
    name
  }
  pagCurrentAccountStatus
  deletedAt
  organization {
    id
    name
  }
  roles
  settings {
    dateFormat
    is24HourFormat
    locale
    defaultCurrency
    exportMode
    printMode
    emailReportTimezone
  }
  consents {
    userDeletion
  }
}
    `;
export const AnalyticsDocument = `
    query analytics($input: AnalyticsInput!) {
  analytics(input: $input) {
    eventCount
    eventDate
    role
  }
}
    `;

export const useAnalyticsQuery = <TData = AnalyticsQuery, TError = unknown>(
  variables: AnalyticsQueryVariables,
  options?: UseQueryOptions<AnalyticsQuery, TError, TData>,
) => {
  return useQuery<AnalyticsQuery, TError, TData>(
    ['analytics', variables],
    request<AnalyticsQuery, AnalyticsQueryVariables>(
      AnalyticsDocument,
      variables,
    ),
    options,
  );
};

useAnalyticsQuery.getKey = (variables: AnalyticsQueryVariables) => [
  'analytics',
  variables,
];

useAnalyticsQuery.fetcher = (
  variables: AnalyticsQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<AnalyticsQuery, AnalyticsQueryVariables>(
    AnalyticsDocument,
    variables,
    options,
  );

export const AnnouncementsDocument = `
    query announcements {
  announcements {
    id
    message
    isActive
    from
    to
  }
}
    `;

export const useAnnouncementsQuery = <
  TData = AnnouncementsQuery,
  TError = unknown,
>(
  variables?: AnnouncementsQueryVariables,
  options?: UseQueryOptions<AnnouncementsQuery, TError, TData>,
) => {
  return useQuery<AnnouncementsQuery, TError, TData>(
    variables === undefined ? ['announcements'] : ['announcements', variables],
    request<AnnouncementsQuery, AnnouncementsQueryVariables>(
      AnnouncementsDocument,
      variables,
    ),
    options,
  );
};

useAnnouncementsQuery.getKey = (variables?: AnnouncementsQueryVariables) =>
  variables === undefined ? ['announcements'] : ['announcements', variables];

useAnnouncementsQuery.fetcher = (
  variables?: AnnouncementsQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<AnnouncementsQuery, AnnouncementsQueryVariables>(
    AnnouncementsDocument,
    variables,
    options,
  );

export const UpdateAnnouncementDocument = `
    mutation updateAnnouncement($input: UpdateAnnouncementInput!) {
  updateAnnouncement(input: $input) {
    id
    message
    isActive
    to
    from
  }
}
    `;

export const useUpdateAnnouncementMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateAnnouncementMutation,
    TError,
    UpdateAnnouncementMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateAnnouncementMutation,
    TError,
    UpdateAnnouncementMutationVariables,
    TContext
  >(
    ['updateAnnouncement'],
    (variables?: UpdateAnnouncementMutationVariables) =>
      request<UpdateAnnouncementMutation, UpdateAnnouncementMutationVariables>(
        UpdateAnnouncementDocument,
        variables,
      )(),
    options,
  );
};

useUpdateAnnouncementMutation.fetcher = (
  variables: UpdateAnnouncementMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<UpdateAnnouncementMutation, UpdateAnnouncementMutationVariables>(
    UpdateAnnouncementDocument,
    variables,
    options,
  );

export const DashboardEmailNotificationsDocument = `
    query dashboardEmailNotifications {
  dashboardEmailNotifications {
    id
    adhoc
  }
}
    `;

export const useDashboardEmailNotificationsQuery = <
  TData = DashboardEmailNotificationsQuery,
  TError = unknown,
>(
  variables?: DashboardEmailNotificationsQueryVariables,
  options?: UseQueryOptions<DashboardEmailNotificationsQuery, TError, TData>,
) => {
  return useQuery<DashboardEmailNotificationsQuery, TError, TData>(
    variables === undefined
      ? ['dashboardEmailNotifications']
      : ['dashboardEmailNotifications', variables],
    request<
      DashboardEmailNotificationsQuery,
      DashboardEmailNotificationsQueryVariables
    >(DashboardEmailNotificationsDocument, variables),
    options,
  );
};

useDashboardEmailNotificationsQuery.getKey = (
  variables?: DashboardEmailNotificationsQueryVariables,
) =>
  variables === undefined
    ? ['dashboardEmailNotifications']
    : ['dashboardEmailNotifications', variables];

useDashboardEmailNotificationsQuery.fetcher = (
  variables?: DashboardEmailNotificationsQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<
    DashboardEmailNotificationsQuery,
    DashboardEmailNotificationsQueryVariables
  >(DashboardEmailNotificationsDocument, variables, options);

export const UpdateDashboardEmailNotificationDocument = `
    mutation updateDashboardEmailNotification($input: UpdateDashboardEmailNotificationInput!) {
  updateDashboardEmailNotification(input: $input) {
    id
    adhoc
  }
}
    `;

export const useUpdateDashboardEmailNotificationMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateDashboardEmailNotificationMutation,
    TError,
    UpdateDashboardEmailNotificationMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateDashboardEmailNotificationMutation,
    TError,
    UpdateDashboardEmailNotificationMutationVariables,
    TContext
  >(
    ['updateDashboardEmailNotification'],
    (variables?: UpdateDashboardEmailNotificationMutationVariables) =>
      request<
        UpdateDashboardEmailNotificationMutation,
        UpdateDashboardEmailNotificationMutationVariables
      >(UpdateDashboardEmailNotificationDocument, variables)(),
    options,
  );
};

useUpdateDashboardEmailNotificationMutation.fetcher = (
  variables: UpdateDashboardEmailNotificationMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<
    UpdateDashboardEmailNotificationMutation,
    UpdateDashboardEmailNotificationMutationVariables
  >(UpdateDashboardEmailNotificationDocument, variables, options);

export const CreateDashboardEmailNotificationDocument = `
    mutation createDashboardEmailNotification($input: CreateDashboardEmailNotificationInput!) {
  createDashboardEmailNotification(input: $input) {
    id
    adhoc
  }
}
    `;

export const useCreateDashboardEmailNotificationMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateDashboardEmailNotificationMutation,
    TError,
    CreateDashboardEmailNotificationMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateDashboardEmailNotificationMutation,
    TError,
    CreateDashboardEmailNotificationMutationVariables,
    TContext
  >(
    ['createDashboardEmailNotification'],
    (variables?: CreateDashboardEmailNotificationMutationVariables) =>
      request<
        CreateDashboardEmailNotificationMutation,
        CreateDashboardEmailNotificationMutationVariables
      >(CreateDashboardEmailNotificationDocument, variables)(),
    options,
  );
};

useCreateDashboardEmailNotificationMutation.fetcher = (
  variables: CreateDashboardEmailNotificationMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<
    CreateDashboardEmailNotificationMutation,
    CreateDashboardEmailNotificationMutationVariables
  >(CreateDashboardEmailNotificationDocument, variables, options);

export const DeleteDashboardEmailNotificationDocument = `
    mutation deleteDashboardEmailNotification($id: ObjectID!) {
  deleteDashboardEmailNotification(id: $id) {
    id
  }
}
    `;

export const useDeleteDashboardEmailNotificationMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    DeleteDashboardEmailNotificationMutation,
    TError,
    DeleteDashboardEmailNotificationMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    DeleteDashboardEmailNotificationMutation,
    TError,
    DeleteDashboardEmailNotificationMutationVariables,
    TContext
  >(
    ['deleteDashboardEmailNotification'],
    (variables?: DeleteDashboardEmailNotificationMutationVariables) =>
      request<
        DeleteDashboardEmailNotificationMutation,
        DeleteDashboardEmailNotificationMutationVariables
      >(DeleteDashboardEmailNotificationDocument, variables)(),
    options,
  );
};

useDeleteDashboardEmailNotificationMutation.fetcher = (
  variables: DeleteDashboardEmailNotificationMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<
    DeleteDashboardEmailNotificationMutation,
    DeleteDashboardEmailNotificationMutationVariables
  >(DeleteDashboardEmailNotificationDocument, variables, options);

export const FeatureArticleDocument = `
    query featureArticle($id: ObjectID!) {
  featureArticle(id: $id) {
    id
    title
    article
    isPublished
    publishedAt
    author {
      id
      name
    }
    organizations {
      id
      name
    }
    apps
    roles
  }
}
    `;

export const useFeatureArticleQuery = <
  TData = FeatureArticleQuery,
  TError = unknown,
>(
  variables: FeatureArticleQueryVariables,
  options?: UseQueryOptions<FeatureArticleQuery, TError, TData>,
) => {
  return useQuery<FeatureArticleQuery, TError, TData>(
    ['featureArticle', variables],
    request<FeatureArticleQuery, FeatureArticleQueryVariables>(
      FeatureArticleDocument,
      variables,
    ),
    options,
  );
};

useFeatureArticleQuery.getKey = (variables: FeatureArticleQueryVariables) => [
  'featureArticle',
  variables,
];

useFeatureArticleQuery.fetcher = (
  variables: FeatureArticleQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<FeatureArticleQuery, FeatureArticleQueryVariables>(
    FeatureArticleDocument,
    variables,
    options,
  );

export const FeatureArticlesDocument = `
    query featureArticles {
  featureArticles {
    id
    title
    article
    isPublished
    publishedAt
    author {
      id
      name
    }
    apps
    roles
  }
}
    `;

export const useFeatureArticlesQuery = <
  TData = FeatureArticlesQuery,
  TError = unknown,
>(
  variables?: FeatureArticlesQueryVariables,
  options?: UseQueryOptions<FeatureArticlesQuery, TError, TData>,
) => {
  return useQuery<FeatureArticlesQuery, TError, TData>(
    variables === undefined
      ? ['featureArticles']
      : ['featureArticles', variables],
    request<FeatureArticlesQuery, FeatureArticlesQueryVariables>(
      FeatureArticlesDocument,
      variables,
    ),
    options,
  );
};

useFeatureArticlesQuery.getKey = (variables?: FeatureArticlesQueryVariables) =>
  variables === undefined
    ? ['featureArticles']
    : ['featureArticles', variables];

useFeatureArticlesQuery.fetcher = (
  variables?: FeatureArticlesQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<FeatureArticlesQuery, FeatureArticlesQueryVariables>(
    FeatureArticlesDocument,
    variables,
    options,
  );

export const AdminFeatureArticlesDocument = `
    query adminFeatureArticles {
  featureArticles {
    id
    title
    article
    isPublished
    publishedAt
    author {
      id
      name
    }
    organizations {
      id
      name
    }
    apps
    roles
  }
}
    `;

export const useAdminFeatureArticlesQuery = <
  TData = AdminFeatureArticlesQuery,
  TError = unknown,
>(
  variables?: AdminFeatureArticlesQueryVariables,
  options?: UseQueryOptions<AdminFeatureArticlesQuery, TError, TData>,
) => {
  return useQuery<AdminFeatureArticlesQuery, TError, TData>(
    variables === undefined
      ? ['adminFeatureArticles']
      : ['adminFeatureArticles', variables],
    request<AdminFeatureArticlesQuery, AdminFeatureArticlesQueryVariables>(
      AdminFeatureArticlesDocument,
      variables,
    ),
    options,
  );
};

useAdminFeatureArticlesQuery.getKey = (
  variables?: AdminFeatureArticlesQueryVariables,
) =>
  variables === undefined
    ? ['adminFeatureArticles']
    : ['adminFeatureArticles', variables];

useAdminFeatureArticlesQuery.fetcher = (
  variables?: AdminFeatureArticlesQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<AdminFeatureArticlesQuery, AdminFeatureArticlesQueryVariables>(
    AdminFeatureArticlesDocument,
    variables,
    options,
  );

export const CreateFeatureArticleDocument = `
    mutation createFeatureArticle($input: CreateFeatureArticleInput!) {
  createFeatureArticle(featureArticle: $input) {
    id
  }
}
    `;

export const useCreateFeatureArticleMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateFeatureArticleMutation,
    TError,
    CreateFeatureArticleMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateFeatureArticleMutation,
    TError,
    CreateFeatureArticleMutationVariables,
    TContext
  >(
    ['createFeatureArticle'],
    (variables?: CreateFeatureArticleMutationVariables) =>
      request<
        CreateFeatureArticleMutation,
        CreateFeatureArticleMutationVariables
      >(CreateFeatureArticleDocument, variables)(),
    options,
  );
};

useCreateFeatureArticleMutation.fetcher = (
  variables: CreateFeatureArticleMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<CreateFeatureArticleMutation, CreateFeatureArticleMutationVariables>(
    CreateFeatureArticleDocument,
    variables,
    options,
  );

export const UpdateFeatureArticleDocument = `
    mutation updateFeatureArticle($input: UpdateFeatureArticleInput!) {
  updateFeatureArticle(featureArticle: $input) {
    id
    title
    article
    isPublished
    publishedAt
    author {
      id
      name
    }
    organizations {
      id
      name
    }
    apps
    roles
  }
}
    `;

export const useUpdateFeatureArticleMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateFeatureArticleMutation,
    TError,
    UpdateFeatureArticleMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateFeatureArticleMutation,
    TError,
    UpdateFeatureArticleMutationVariables,
    TContext
  >(
    ['updateFeatureArticle'],
    (variables?: UpdateFeatureArticleMutationVariables) =>
      request<
        UpdateFeatureArticleMutation,
        UpdateFeatureArticleMutationVariables
      >(UpdateFeatureArticleDocument, variables)(),
    options,
  );
};

useUpdateFeatureArticleMutation.fetcher = (
  variables: UpdateFeatureArticleMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<UpdateFeatureArticleMutation, UpdateFeatureArticleMutationVariables>(
    UpdateFeatureArticleDocument,
    variables,
    options,
  );

export const DeleteFeatureArticleDocument = `
    mutation deleteFeatureArticle($id: ObjectID!) {
  deleteFeatureArticle(id: $id) {
    id
  }
}
    `;

export const useDeleteFeatureArticleMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    DeleteFeatureArticleMutation,
    TError,
    DeleteFeatureArticleMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    DeleteFeatureArticleMutation,
    TError,
    DeleteFeatureArticleMutationVariables,
    TContext
  >(
    ['deleteFeatureArticle'],
    (variables?: DeleteFeatureArticleMutationVariables) =>
      request<
        DeleteFeatureArticleMutation,
        DeleteFeatureArticleMutationVariables
      >(DeleteFeatureArticleDocument, variables)(),
    options,
  );
};

useDeleteFeatureArticleMutation.fetcher = (
  variables: DeleteFeatureArticleMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<DeleteFeatureArticleMutation, DeleteFeatureArticleMutationVariables>(
    DeleteFeatureArticleDocument,
    variables,
    options,
  );

export const OrganizationsDocument = `
    query organizations($searchString: String, $filter: OrganizationFilter) {
  organizations(searchString: $searchString, filter: $filter) {
    docs {
      id
      organizationId: id
      name
      partnerNumber
      status
    }
  }
}
    `;

export const useOrganizationsQuery = <
  TData = OrganizationsQuery,
  TError = unknown,
>(
  variables?: OrganizationsQueryVariables,
  options?: UseQueryOptions<OrganizationsQuery, TError, TData>,
) => {
  return useQuery<OrganizationsQuery, TError, TData>(
    variables === undefined ? ['organizations'] : ['organizations', variables],
    request<OrganizationsQuery, OrganizationsQueryVariables>(
      OrganizationsDocument,
      variables,
    ),
    options,
  );
};

useOrganizationsQuery.getKey = (variables?: OrganizationsQueryVariables) =>
  variables === undefined ? ['organizations'] : ['organizations', variables];

useOrganizationsQuery.fetcher = (
  variables?: OrganizationsQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    variables,
    options,
  );

export const OrganizationDocument = `
    query organization($id: BigInt) {
  organization(id: $id) {
    organizationId: id
    name
  }
}
    `;

export const useOrganizationQuery = <
  TData = OrganizationQuery,
  TError = unknown,
>(
  variables?: OrganizationQueryVariables,
  options?: UseQueryOptions<OrganizationQuery, TError, TData>,
) => {
  return useQuery<OrganizationQuery, TError, TData>(
    variables === undefined ? ['organization'] : ['organization', variables],
    request<OrganizationQuery, OrganizationQueryVariables>(
      OrganizationDocument,
      variables,
    ),
    options,
  );
};

useOrganizationQuery.getKey = (variables?: OrganizationQueryVariables) =>
  variables === undefined ? ['organization'] : ['organization', variables];

useOrganizationQuery.fetcher = (
  variables?: OrganizationQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<OrganizationQuery, OrganizationQueryVariables>(
    OrganizationDocument,
    variables,
    options,
  );

export const PersonsDocument = `
    query persons($searchString: String, $filter: PersonFilter) {
  persons(searchString: $searchString, filter: $filter) {
    docs {
      id
      name
      ppnuid
      organization {
        id
        name
      }
    }
  }
}
    `;

export const usePersonsQuery = <TData = PersonsQuery, TError = unknown>(
  variables?: PersonsQueryVariables,
  options?: UseQueryOptions<PersonsQuery, TError, TData>,
) => {
  return useQuery<PersonsQuery, TError, TData>(
    variables === undefined ? ['persons'] : ['persons', variables],
    request<PersonsQuery, PersonsQueryVariables>(PersonsDocument, variables),
    options,
  );
};

usePersonsQuery.getKey = (variables?: PersonsQueryVariables) =>
  variables === undefined ? ['persons'] : ['persons', variables];

usePersonsQuery.fetcher = (
  variables?: PersonsQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<PersonsQuery, PersonsQueryVariables>(
    PersonsDocument,
    variables,
    options,
  );

export const PersonByIdDocument = `
    query personById($id: BigInt!) {
  person(id: $id) {
    id
    name
  }
}
    `;

export const usePersonByIdQuery = <TData = PersonByIdQuery, TError = unknown>(
  variables: PersonByIdQueryVariables,
  options?: UseQueryOptions<PersonByIdQuery, TError, TData>,
) => {
  return useQuery<PersonByIdQuery, TError, TData>(
    ['personById', variables],
    request<PersonByIdQuery, PersonByIdQueryVariables>(
      PersonByIdDocument,
      variables,
    ),
    options,
  );
};

usePersonByIdQuery.getKey = (variables: PersonByIdQueryVariables) => [
  'personById',
  variables,
];

usePersonByIdQuery.fetcher = (
  variables: PersonByIdQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<PersonByIdQuery, PersonByIdQueryVariables>(
    PersonByIdDocument,
    variables,
    options,
  );

export const PersonDocument = `
    query person($ppnuid: String!) {
  person(ppnuid: $ppnuid) {
    id
    ppnuid
    name
    organization {
      parentMarket {
        organizationId: id
      }
    }
    ability {
      dashboard
    }
    roles
    settings {
      dateFormat
      is24HourFormat
      locale
    }
    consents {
      userDeletion
    }
  }
}
    `;

export const usePersonQuery = <TData = PersonQuery, TError = unknown>(
  variables: PersonQueryVariables,
  options?: UseQueryOptions<PersonQuery, TError, TData>,
) => {
  return useQuery<PersonQuery, TError, TData>(
    ['person', variables],
    request<PersonQuery, PersonQueryVariables>(PersonDocument, variables),
    options,
  );
};

usePersonQuery.getKey = (variables: PersonQueryVariables) => [
  'person',
  variables,
];

usePersonQuery.fetcher = (
  variables: PersonQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<PersonQuery, PersonQueryVariables>(
    PersonDocument,
    variables,
    options,
  );

export const UpdatePersonSettingsDocument = `
    mutation updatePersonSettings($input: UpdatePersonSettingsInput!) {
  updatePersonSettings(input: $input) {
    ppnuid
    settings {
      locale
    }
  }
}
    `;

export const useUpdatePersonSettingsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdatePersonSettingsMutation,
    TError,
    UpdatePersonSettingsMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdatePersonSettingsMutation,
    TError,
    UpdatePersonSettingsMutationVariables,
    TContext
  >(
    ['updatePersonSettings'],
    (variables?: UpdatePersonSettingsMutationVariables) =>
      request<
        UpdatePersonSettingsMutation,
        UpdatePersonSettingsMutationVariables
      >(UpdatePersonSettingsDocument, variables)(),
    options,
  );
};

useUpdatePersonSettingsMutation.fetcher = (
  variables: UpdatePersonSettingsMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<UpdatePersonSettingsMutation, UpdatePersonSettingsMutationVariables>(
    UpdatePersonSettingsDocument,
    variables,
    options,
  );

export const UpdatePersonDocument = `
    mutation updatePerson($input: UpdatePersonInput!) {
  updatePerson(input: $input) {
    id
    ppnuid
    consents {
      userDeletion
    }
  }
}
    `;

export const useUpdatePersonMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdatePersonMutation,
    TError,
    UpdatePersonMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdatePersonMutation,
    TError,
    UpdatePersonMutationVariables,
    TContext
  >(
    ['updatePerson'],
    (variables?: UpdatePersonMutationVariables) =>
      request<UpdatePersonMutation, UpdatePersonMutationVariables>(
        UpdatePersonDocument,
        variables,
      )(),
    options,
  );
};

useUpdatePersonMutation.fetcher = (
  variables: UpdatePersonMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<UpdatePersonMutation, UpdatePersonMutationVariables>(
    UpdatePersonDocument,
    variables,
    options,
  );

export const PersonInfoDocument = `
    query personInfo($id: BigInt!) {
  person(id: $id) {
    ...personFields
    ability {
      dashboard
    }
  }
}
    ${PersonFieldsFragmentDoc}`;

export const usePersonInfoQuery = <TData = PersonInfoQuery, TError = unknown>(
  variables: PersonInfoQueryVariables,
  options?: UseQueryOptions<PersonInfoQuery, TError, TData>,
) => {
  return useQuery<PersonInfoQuery, TError, TData>(
    ['personInfo', variables],
    request<PersonInfoQuery, PersonInfoQueryVariables>(
      PersonInfoDocument,
      variables,
    ),
    options,
  );
};

usePersonInfoQuery.getKey = (variables: PersonInfoQueryVariables) => [
  'personInfo',
  variables,
];

usePersonInfoQuery.fetcher = (
  variables: PersonInfoQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<PersonInfoQuery, PersonInfoQueryVariables>(
    PersonInfoDocument,
    variables,
    options,
  );

export const PersonInfoForFieldForceManagementDocument = `
    query personInfoForFieldForceManagement($id: BigInt!) {
  person(id: $id) {
    ...personFields
    ability {
      fieldForceManagement
    }
  }
  userManagementItems(
    input: {filter: {person: {_eq: $id}, application: {_eq: "FIELD_FORCE_MANAGEMENT"}}}
  ) {
    additionalOrganizations {
      id
      name
    }
  }
}
    ${PersonFieldsFragmentDoc}`;

export const usePersonInfoForFieldForceManagementQuery = <
  TData = PersonInfoForFieldForceManagementQuery,
  TError = unknown,
>(
  variables: PersonInfoForFieldForceManagementQueryVariables,
  options?: UseQueryOptions<
    PersonInfoForFieldForceManagementQuery,
    TError,
    TData
  >,
) => {
  return useQuery<PersonInfoForFieldForceManagementQuery, TError, TData>(
    ['personInfoForFieldForceManagement', variables],
    request<
      PersonInfoForFieldForceManagementQuery,
      PersonInfoForFieldForceManagementQueryVariables
    >(PersonInfoForFieldForceManagementDocument, variables),
    options,
  );
};

usePersonInfoForFieldForceManagementQuery.getKey = (
  variables: PersonInfoForFieldForceManagementQueryVariables,
) => ['personInfoForFieldForceManagement', variables];

usePersonInfoForFieldForceManagementQuery.fetcher = (
  variables: PersonInfoForFieldForceManagementQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<
    PersonInfoForFieldForceManagementQuery,
    PersonInfoForFieldForceManagementQueryVariables
  >(PersonInfoForFieldForceManagementDocument, variables, options);

export const PersonInfoForRetailDesignDocument = `
    query personInfoForRetailDesign($id: BigInt!) {
  person(id: $id) {
    ...personFields
    ability {
      retailDesign
    }
  }
  userManagementItems(
    input: {filter: {person: {_eq: $id}, application: {_eq: "RETAIL_DESIGN"}}}
  ) {
    additionalOrganizations {
      id
      name
    }
  }
}
    ${PersonFieldsFragmentDoc}`;

export const usePersonInfoForRetailDesignQuery = <
  TData = PersonInfoForRetailDesignQuery,
  TError = unknown,
>(
  variables: PersonInfoForRetailDesignQueryVariables,
  options?: UseQueryOptions<PersonInfoForRetailDesignQuery, TError, TData>,
) => {
  return useQuery<PersonInfoForRetailDesignQuery, TError, TData>(
    ['personInfoForRetailDesign', variables],
    request<
      PersonInfoForRetailDesignQuery,
      PersonInfoForRetailDesignQueryVariables
    >(PersonInfoForRetailDesignDocument, variables),
    options,
  );
};

usePersonInfoForRetailDesignQuery.getKey = (
  variables: PersonInfoForRetailDesignQueryVariables,
) => ['personInfoForRetailDesign', variables];

usePersonInfoForRetailDesignQuery.fetcher = (
  variables: PersonInfoForRetailDesignQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<
    PersonInfoForRetailDesignQuery,
    PersonInfoForRetailDesignQueryVariables
  >(PersonInfoForRetailDesignDocument, variables, options);

export const PersonInfoForPorscheStrategy2030Document = `
    query personInfoForPorscheStrategy2030($id: BigInt!) {
  person(id: $id) {
    ...personFields
    ability {
      porscheStrategy2030
    }
  }
  userManagementItems(
    input: {filter: {person: {_eq: $id}, application: {_eq: "PORSCHE_STRATEGY_2030"}}}
  ) {
    additionalOrganizations {
      id
      name
    }
  }
}
    ${PersonFieldsFragmentDoc}`;

export const usePersonInfoForPorscheStrategy2030Query = <
  TData = PersonInfoForPorscheStrategy2030Query,
  TError = unknown,
>(
  variables: PersonInfoForPorscheStrategy2030QueryVariables,
  options?: UseQueryOptions<
    PersonInfoForPorscheStrategy2030Query,
    TError,
    TData
  >,
) => {
  return useQuery<PersonInfoForPorscheStrategy2030Query, TError, TData>(
    ['personInfoForPorscheStrategy2030', variables],
    request<
      PersonInfoForPorscheStrategy2030Query,
      PersonInfoForPorscheStrategy2030QueryVariables
    >(PersonInfoForPorscheStrategy2030Document, variables),
    options,
  );
};

usePersonInfoForPorscheStrategy2030Query.getKey = (
  variables: PersonInfoForPorscheStrategy2030QueryVariables,
) => ['personInfoForPorscheStrategy2030', variables];

usePersonInfoForPorscheStrategy2030Query.fetcher = (
  variables: PersonInfoForPorscheStrategy2030QueryVariables,
  options?: RequestInit['headers'],
) =>
  request<
    PersonInfoForPorscheStrategy2030Query,
    PersonInfoForPorscheStrategy2030QueryVariables
  >(PersonInfoForPorscheStrategy2030Document, variables, options);

export const PersonInfoForSurveyDocument = `
    query personInfoForSurvey($id: BigInt!) {
  person(id: $id) {
    ...personFields
    ability {
      survey
    }
  }
  userManagementItems(
    input: {filter: {person: {_eq: $id}, application: {_eq: "SURVEY"}}}
  ) {
    additionalOrganizations {
      id
      name
    }
  }
}
    ${PersonFieldsFragmentDoc}`;

export const usePersonInfoForSurveyQuery = <
  TData = PersonInfoForSurveyQuery,
  TError = unknown,
>(
  variables: PersonInfoForSurveyQueryVariables,
  options?: UseQueryOptions<PersonInfoForSurveyQuery, TError, TData>,
) => {
  return useQuery<PersonInfoForSurveyQuery, TError, TData>(
    ['personInfoForSurvey', variables],
    request<PersonInfoForSurveyQuery, PersonInfoForSurveyQueryVariables>(
      PersonInfoForSurveyDocument,
      variables,
    ),
    options,
  );
};

usePersonInfoForSurveyQuery.getKey = (
  variables: PersonInfoForSurveyQueryVariables,
) => ['personInfoForSurvey', variables];

usePersonInfoForSurveyQuery.fetcher = (
  variables: PersonInfoForSurveyQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<PersonInfoForSurveyQuery, PersonInfoForSurveyQueryVariables>(
    PersonInfoForSurveyDocument,
    variables,
    options,
  );

export const PersonInfoForGreenlandDocument = `
    query personInfoForGreenland($id: BigInt!) {
  person(id: $id) {
    ...personFields
    ability {
      greenland
    }
  }
  userManagementItems(
    input: {filter: {person: {_eq: $id}, application: {_eq: "GREENLAND"}}}
  ) {
    additionalOrganizations {
      id
      name
    }
  }
}
    ${PersonFieldsFragmentDoc}`;

export const usePersonInfoForGreenlandQuery = <
  TData = PersonInfoForGreenlandQuery,
  TError = unknown,
>(
  variables: PersonInfoForGreenlandQueryVariables,
  options?: UseQueryOptions<PersonInfoForGreenlandQuery, TError, TData>,
) => {
  return useQuery<PersonInfoForGreenlandQuery, TError, TData>(
    ['personInfoForGreenland', variables],
    request<PersonInfoForGreenlandQuery, PersonInfoForGreenlandQueryVariables>(
      PersonInfoForGreenlandDocument,
      variables,
    ),
    options,
  );
};

usePersonInfoForGreenlandQuery.getKey = (
  variables: PersonInfoForGreenlandQueryVariables,
) => ['personInfoForGreenland', variables];

usePersonInfoForGreenlandQuery.fetcher = (
  variables: PersonInfoForGreenlandQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<PersonInfoForGreenlandQuery, PersonInfoForGreenlandQueryVariables>(
    PersonInfoForGreenlandDocument,
    variables,
    options,
  );

export const PersonInfoForMysteryShoppingDocument = `
    query personInfoForMysteryShopping($id: BigInt!) {
  person(id: $id) {
    ...personFields
    ability {
      mysteryShopping
    }
  }
  userManagementItems(
    input: {filter: {person: {_eq: $id}, application: {_eq: "MYSTERY_SHOPPING"}}}
  ) {
    additionalOrganizations {
      id
      name
    }
  }
}
    ${PersonFieldsFragmentDoc}`;

export const usePersonInfoForMysteryShoppingQuery = <
  TData = PersonInfoForMysteryShoppingQuery,
  TError = unknown,
>(
  variables: PersonInfoForMysteryShoppingQueryVariables,
  options?: UseQueryOptions<PersonInfoForMysteryShoppingQuery, TError, TData>,
) => {
  return useQuery<PersonInfoForMysteryShoppingQuery, TError, TData>(
    ['personInfoForMysteryShopping', variables],
    request<
      PersonInfoForMysteryShoppingQuery,
      PersonInfoForMysteryShoppingQueryVariables
    >(PersonInfoForMysteryShoppingDocument, variables),
    options,
  );
};

usePersonInfoForMysteryShoppingQuery.getKey = (
  variables: PersonInfoForMysteryShoppingQueryVariables,
) => ['personInfoForMysteryShopping', variables];

usePersonInfoForMysteryShoppingQuery.fetcher = (
  variables: PersonInfoForMysteryShoppingQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<
    PersonInfoForMysteryShoppingQuery,
    PersonInfoForMysteryShoppingQueryVariables
  >(PersonInfoForMysteryShoppingDocument, variables, options);

export const PersonInfoForNetworkPlanningDocument = `
    query personInfoForNetworkPlanning($id: BigInt!) {
  person(id: $id) {
    ...personFields
    ability {
      networkPlanning
    }
  }
  userManagementItems(
    input: {filter: {person: {_eq: $id}, application: {_eq: "NETWORK_PLANNING"}}}
  ) {
    additionalOrganizations {
      id
      name
    }
  }
}
    ${PersonFieldsFragmentDoc}`;

export const usePersonInfoForNetworkPlanningQuery = <
  TData = PersonInfoForNetworkPlanningQuery,
  TError = unknown,
>(
  variables: PersonInfoForNetworkPlanningQueryVariables,
  options?: UseQueryOptions<PersonInfoForNetworkPlanningQuery, TError, TData>,
) => {
  return useQuery<PersonInfoForNetworkPlanningQuery, TError, TData>(
    ['personInfoForNetworkPlanning', variables],
    request<
      PersonInfoForNetworkPlanningQuery,
      PersonInfoForNetworkPlanningQueryVariables
    >(PersonInfoForNetworkPlanningDocument, variables),
    options,
  );
};

usePersonInfoForNetworkPlanningQuery.getKey = (
  variables: PersonInfoForNetworkPlanningQueryVariables,
) => ['personInfoForNetworkPlanning', variables];

usePersonInfoForNetworkPlanningQuery.fetcher = (
  variables: PersonInfoForNetworkPlanningQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<
    PersonInfoForNetworkPlanningQuery,
    PersonInfoForNetworkPlanningQueryVariables
  >(PersonInfoForNetworkPlanningDocument, variables, options);

export const PersonInfoForMultisensoryExperienceDocument = `
    query personInfoForMultisensoryExperience($id: BigInt!) {
  person(id: $id) {
    ...personFields
    ability {
      multisensoryExperience
    }
  }
  userManagementItems(
    input: {filter: {person: {_eq: $id}, application: {_eq: "MULTISENSORY_EXPERIENCE"}}}
  ) {
    additionalOrganizations {
      id
      name
    }
  }
}
    ${PersonFieldsFragmentDoc}`;

export const usePersonInfoForMultisensoryExperienceQuery = <
  TData = PersonInfoForMultisensoryExperienceQuery,
  TError = unknown,
>(
  variables: PersonInfoForMultisensoryExperienceQueryVariables,
  options?: UseQueryOptions<
    PersonInfoForMultisensoryExperienceQuery,
    TError,
    TData
  >,
) => {
  return useQuery<PersonInfoForMultisensoryExperienceQuery, TError, TData>(
    ['personInfoForMultisensoryExperience', variables],
    request<
      PersonInfoForMultisensoryExperienceQuery,
      PersonInfoForMultisensoryExperienceQueryVariables
    >(PersonInfoForMultisensoryExperienceDocument, variables),
    options,
  );
};

usePersonInfoForMultisensoryExperienceQuery.getKey = (
  variables: PersonInfoForMultisensoryExperienceQueryVariables,
) => ['personInfoForMultisensoryExperience', variables];

usePersonInfoForMultisensoryExperienceQuery.fetcher = (
  variables: PersonInfoForMultisensoryExperienceQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<
    PersonInfoForMultisensoryExperienceQuery,
    PersonInfoForMultisensoryExperienceQueryVariables
  >(PersonInfoForMultisensoryExperienceDocument, variables, options);

export const CheckMeetingAbilitiesDocument = `
    query checkMeetingAbilities($meetingId: ObjectID!, $personId: BigInt!) {
  person(id: $personId) {
    id
    ability {
      fieldForceManagementSubset {
        checkMeeting(id: $meetingId, includeActionItems: true) {
          subject
          read {
            allowed
            reason
          }
          update {
            allowed
            reason
          }
          delete {
            allowed
            reason
          }
          actionItems {
            subject
            read {
              allowed
              reason
            }
            update {
              allowed
              reason
            }
            delete {
              allowed
              reason
            }
          }
        }
      }
    }
  }
}
    `;

export const useCheckMeetingAbilitiesQuery = <
  TData = CheckMeetingAbilitiesQuery,
  TError = unknown,
>(
  variables: CheckMeetingAbilitiesQueryVariables,
  options?: UseQueryOptions<CheckMeetingAbilitiesQuery, TError, TData>,
) => {
  return useQuery<CheckMeetingAbilitiesQuery, TError, TData>(
    ['checkMeetingAbilities', variables],
    request<CheckMeetingAbilitiesQuery, CheckMeetingAbilitiesQueryVariables>(
      CheckMeetingAbilitiesDocument,
      variables,
    ),
    options,
  );
};

useCheckMeetingAbilitiesQuery.getKey = (
  variables: CheckMeetingAbilitiesQueryVariables,
) => ['checkMeetingAbilities', variables];

useCheckMeetingAbilitiesQuery.fetcher = (
  variables: CheckMeetingAbilitiesQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<CheckMeetingAbilitiesQuery, CheckMeetingAbilitiesQueryVariables>(
    CheckMeetingAbilitiesDocument,
    variables,
    options,
  );

export const PpnMasterdataImportStatusDocument = `
    query ppnMasterdataImportStatus {
  ppnMasterdataImportStatus {
    isRunning
    startedAt
    finishedAt
    organizationsFinishedAt
    personsFinishedAt
    groupsFinishedAt
    error
  }
}
    `;

export const usePpnMasterdataImportStatusQuery = <
  TData = PpnMasterdataImportStatusQuery,
  TError = unknown,
>(
  variables?: PpnMasterdataImportStatusQueryVariables,
  options?: UseQueryOptions<PpnMasterdataImportStatusQuery, TError, TData>,
) => {
  return useQuery<PpnMasterdataImportStatusQuery, TError, TData>(
    variables === undefined
      ? ['ppnMasterdataImportStatus']
      : ['ppnMasterdataImportStatus', variables],
    request<
      PpnMasterdataImportStatusQuery,
      PpnMasterdataImportStatusQueryVariables
    >(PpnMasterdataImportStatusDocument, variables),
    options,
  );
};

usePpnMasterdataImportStatusQuery.getKey = (
  variables?: PpnMasterdataImportStatusQueryVariables,
) =>
  variables === undefined
    ? ['ppnMasterdataImportStatus']
    : ['ppnMasterdataImportStatus', variables];

usePpnMasterdataImportStatusQuery.fetcher = (
  variables?: PpnMasterdataImportStatusQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<
    PpnMasterdataImportStatusQuery,
    PpnMasterdataImportStatusQueryVariables
  >(PpnMasterdataImportStatusDocument, variables, options);

export const StartPpnMasterdataImportDocument = `
    mutation startPpnMasterdataImport {
  startPpnMasterdataImport {
    isRunning
    startedAt
    finishedAt
    organizationsFinishedAt
    personsFinishedAt
    groupsFinishedAt
    error
  }
}
    `;

export const useStartPpnMasterdataImportMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    StartPpnMasterdataImportMutation,
    TError,
    StartPpnMasterdataImportMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    StartPpnMasterdataImportMutation,
    TError,
    StartPpnMasterdataImportMutationVariables,
    TContext
  >(
    ['startPpnMasterdataImport'],
    (variables?: StartPpnMasterdataImportMutationVariables) =>
      request<
        StartPpnMasterdataImportMutation,
        StartPpnMasterdataImportMutationVariables
      >(StartPpnMasterdataImportDocument, variables)(),
    options,
  );
};

useStartPpnMasterdataImportMutation.fetcher = (
  variables?: StartPpnMasterdataImportMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<
    StartPpnMasterdataImportMutation,
    StartPpnMasterdataImportMutationVariables
  >(StartPpnMasterdataImportDocument, variables, options);

export const SupportRequestDocument = `
    query supportRequest($id: ObjectID!) {
  supportRequest(id: $id) {
    id
    status
    title
    description
    application
    organization {
      name
    }
    author {
      name
    }
    referencePerson {
      name
    }
    details {
      userAgent
      screenSize {
        width
        height
      }
      browserWindowSize {
        width
        height
      }
    }
    attachments {
      id
      name
      type
      size
      createdAt
    }
    comments {
      id
      text
      createdAt
      author {
        id: personId
        name
      }
      attachments {
        id
        name
        type
        size
        createdAt
      }
    }
    hasNewActivity
    createdAt
  }
}
    `;

export const useSupportRequestQuery = <
  TData = SupportRequestQuery,
  TError = unknown,
>(
  variables: SupportRequestQueryVariables,
  options?: UseQueryOptions<SupportRequestQuery, TError, TData>,
) => {
  return useQuery<SupportRequestQuery, TError, TData>(
    ['supportRequest', variables],
    request<SupportRequestQuery, SupportRequestQueryVariables>(
      SupportRequestDocument,
      variables,
    ),
    options,
  );
};

useSupportRequestQuery.getKey = (variables: SupportRequestQueryVariables) => [
  'supportRequest',
  variables,
];

useSupportRequestQuery.fetcher = (
  variables: SupportRequestQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<SupportRequestQuery, SupportRequestQueryVariables>(
    SupportRequestDocument,
    variables,
    options,
  );

export const SupportRequestsDocument = `
    query supportRequests {
  supportRequests {
    id
    status
    title
    author {
      name
    }
    organization {
      name
    }
    createdAt
    hasNewActivity
  }
}
    `;

export const useSupportRequestsQuery = <
  TData = SupportRequestsQuery,
  TError = unknown,
>(
  variables?: SupportRequestsQueryVariables,
  options?: UseQueryOptions<SupportRequestsQuery, TError, TData>,
) => {
  return useQuery<SupportRequestsQuery, TError, TData>(
    variables === undefined
      ? ['supportRequests']
      : ['supportRequests', variables],
    request<SupportRequestsQuery, SupportRequestsQueryVariables>(
      SupportRequestsDocument,
      variables,
    ),
    options,
  );
};

useSupportRequestsQuery.getKey = (variables?: SupportRequestsQueryVariables) =>
  variables === undefined
    ? ['supportRequests']
    : ['supportRequests', variables];

useSupportRequestsQuery.fetcher = (
  variables?: SupportRequestsQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<SupportRequestsQuery, SupportRequestsQueryVariables>(
    SupportRequestsDocument,
    variables,
    options,
  );

export const SupportRequestsActivityDocument = `
    query supportRequestsActivity {
  supportRequests {
    id
    hasNewActivity
  }
}
    `;

export const useSupportRequestsActivityQuery = <
  TData = SupportRequestsActivityQuery,
  TError = unknown,
>(
  variables?: SupportRequestsActivityQueryVariables,
  options?: UseQueryOptions<SupportRequestsActivityQuery, TError, TData>,
) => {
  return useQuery<SupportRequestsActivityQuery, TError, TData>(
    variables === undefined
      ? ['supportRequestsActivity']
      : ['supportRequestsActivity', variables],
    request<
      SupportRequestsActivityQuery,
      SupportRequestsActivityQueryVariables
    >(SupportRequestsActivityDocument, variables),
    options,
  );
};

useSupportRequestsActivityQuery.getKey = (
  variables?: SupportRequestsActivityQueryVariables,
) =>
  variables === undefined
    ? ['supportRequestsActivity']
    : ['supportRequestsActivity', variables];

useSupportRequestsActivityQuery.fetcher = (
  variables?: SupportRequestsActivityQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<SupportRequestsActivityQuery, SupportRequestsActivityQueryVariables>(
    SupportRequestsActivityDocument,
    variables,
    options,
  );

export const CreateSupportRequestDocument = `
    mutation createSupportRequest($input: CreateSupportRequestInput!) {
  createSupportRequest(input: $input) {
    id
    status
    title
    description
    application
    organization {
      name
    }
    author {
      name
    }
    referencePerson {
      name
    }
    details {
      userAgent
      screenSize {
        width
        height
      }
      browserWindowSize {
        width
        height
      }
    }
    attachments {
      id
      name
      type
      size
      createdAt
    }
    hasNewActivity
    createdAt
  }
}
    `;

export const useCreateSupportRequestMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateSupportRequestMutation,
    TError,
    CreateSupportRequestMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateSupportRequestMutation,
    TError,
    CreateSupportRequestMutationVariables,
    TContext
  >(
    ['createSupportRequest'],
    (variables?: CreateSupportRequestMutationVariables) =>
      request<
        CreateSupportRequestMutation,
        CreateSupportRequestMutationVariables
      >(CreateSupportRequestDocument, variables)(),
    options,
  );
};

useCreateSupportRequestMutation.fetcher = (
  variables: CreateSupportRequestMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<CreateSupportRequestMutation, CreateSupportRequestMutationVariables>(
    CreateSupportRequestDocument,
    variables,
    options,
  );

export const UpdateSupportRequestDocument = `
    mutation updateSupportRequest($input: UpdateSupportRequestInput!) {
  updateSupportRequest(input: $input) {
    id
    status
    title
    organization {
      name
    }
    author {
      name
    }
    comments {
      id
      text
      createdAt
      author {
        id: personId
        name
      }
      attachments {
        id
        name
        type
        size
        createdAt
      }
    }
    hasNewActivity
    createdAt
  }
}
    `;

export const useUpdateSupportRequestMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateSupportRequestMutation,
    TError,
    UpdateSupportRequestMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateSupportRequestMutation,
    TError,
    UpdateSupportRequestMutationVariables,
    TContext
  >(
    ['updateSupportRequest'],
    (variables?: UpdateSupportRequestMutationVariables) =>
      request<
        UpdateSupportRequestMutation,
        UpdateSupportRequestMutationVariables
      >(UpdateSupportRequestDocument, variables)(),
    options,
  );
};

useUpdateSupportRequestMutation.fetcher = (
  variables: UpdateSupportRequestMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<UpdateSupportRequestMutation, UpdateSupportRequestMutationVariables>(
    UpdateSupportRequestDocument,
    variables,
    options,
  );

export const DeleteSupportRequestDocument = `
    mutation deleteSupportRequest($id: ObjectID!) {
  deleteSupportRequest(id: $id) {
    id
  }
}
    `;

export const useDeleteSupportRequestMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    DeleteSupportRequestMutation,
    TError,
    DeleteSupportRequestMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    DeleteSupportRequestMutation,
    TError,
    DeleteSupportRequestMutationVariables,
    TContext
  >(
    ['deleteSupportRequest'],
    (variables?: DeleteSupportRequestMutationVariables) =>
      request<
        DeleteSupportRequestMutation,
        DeleteSupportRequestMutationVariables
      >(DeleteSupportRequestDocument, variables)(),
    options,
  );
};

useDeleteSupportRequestMutation.fetcher = (
  variables: DeleteSupportRequestMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<DeleteSupportRequestMutation, DeleteSupportRequestMutationVariables>(
    DeleteSupportRequestDocument,
    variables,
    options,
  );

export const UploadUrlsDocument = `
    query uploadUrls($filenames: [String!]!, $type: UploadType!, $reference: UploadReference!) {
  uploadUrls(filenames: $filenames, type: $type, reference: $reference) {
    urls
    kmsKeyId
  }
}
    `;

export const useUploadUrlsQuery = <TData = UploadUrlsQuery, TError = unknown>(
  variables: UploadUrlsQueryVariables,
  options?: UseQueryOptions<UploadUrlsQuery, TError, TData>,
) => {
  return useQuery<UploadUrlsQuery, TError, TData>(
    ['uploadUrls', variables],
    request<UploadUrlsQuery, UploadUrlsQueryVariables>(
      UploadUrlsDocument,
      variables,
    ),
    options,
  );
};

useUploadUrlsQuery.getKey = (variables: UploadUrlsQueryVariables) => [
  'uploadUrls',
  variables,
];

useUploadUrlsQuery.fetcher = (
  variables: UploadUrlsQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<UploadUrlsQuery, UploadUrlsQueryVariables>(
    UploadUrlsDocument,
    variables,
    options,
  );

export const CreateDownloadUrlDocument = `
    mutation createDownloadUrl($id: ObjectID!, $type: DownloadType!, $reference: DownloadReference!) {
  createDownloadUrl(id: $id, type: $type, reference: $reference)
}
    `;

export const useCreateDownloadUrlMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateDownloadUrlMutation,
    TError,
    CreateDownloadUrlMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateDownloadUrlMutation,
    TError,
    CreateDownloadUrlMutationVariables,
    TContext
  >(
    ['createDownloadUrl'],
    (variables?: CreateDownloadUrlMutationVariables) =>
      request<CreateDownloadUrlMutation, CreateDownloadUrlMutationVariables>(
        CreateDownloadUrlDocument,
        variables,
      )(),
    options,
  );
};

useCreateDownloadUrlMutation.fetcher = (
  variables: CreateDownloadUrlMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<CreateDownloadUrlMutation, CreateDownloadUrlMutationVariables>(
    CreateDownloadUrlDocument,
    variables,
    options,
  );

export const UserManagementItemsDocument = `
    query userManagementItems($input: UserManagementItemsInput) {
  userManagementItems(input: $input) {
    id
    person {
      id
      name
    }
    application
    additionalOrganizations {
      id
      name
    }
  }
}
    `;

export const useUserManagementItemsQuery = <
  TData = UserManagementItemsQuery,
  TError = unknown,
>(
  variables?: UserManagementItemsQueryVariables,
  options?: UseQueryOptions<UserManagementItemsQuery, TError, TData>,
) => {
  return useQuery<UserManagementItemsQuery, TError, TData>(
    variables === undefined
      ? ['userManagementItems']
      : ['userManagementItems', variables],
    request<UserManagementItemsQuery, UserManagementItemsQueryVariables>(
      UserManagementItemsDocument,
      variables,
    ),
    options,
  );
};

useUserManagementItemsQuery.getKey = (
  variables?: UserManagementItemsQueryVariables,
) =>
  variables === undefined
    ? ['userManagementItems']
    : ['userManagementItems', variables];

useUserManagementItemsQuery.fetcher = (
  variables?: UserManagementItemsQueryVariables,
  options?: RequestInit['headers'],
) =>
  request<UserManagementItemsQuery, UserManagementItemsQueryVariables>(
    UserManagementItemsDocument,
    variables,
    options,
  );

export const CreateUserManagementItemDocument = `
    mutation createUserManagementItem($input: CreateUserManagementItemInput!) {
  createUserManagementItem(input: $input) {
    id
    person {
      id
      name
    }
    application
    additionalOrganizations {
      id
      name
    }
  }
}
    `;

export const useCreateUserManagementItemMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateUserManagementItemMutation,
    TError,
    CreateUserManagementItemMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateUserManagementItemMutation,
    TError,
    CreateUserManagementItemMutationVariables,
    TContext
  >(
    ['createUserManagementItem'],
    (variables?: CreateUserManagementItemMutationVariables) =>
      request<
        CreateUserManagementItemMutation,
        CreateUserManagementItemMutationVariables
      >(CreateUserManagementItemDocument, variables)(),
    options,
  );
};

useCreateUserManagementItemMutation.fetcher = (
  variables: CreateUserManagementItemMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<
    CreateUserManagementItemMutation,
    CreateUserManagementItemMutationVariables
  >(CreateUserManagementItemDocument, variables, options);

export const UpdateUserManagementItemDocument = `
    mutation updateUserManagementItem($input: UpdateUserManagementItemInput!) {
  updateUserManagementItem(input: $input) {
    id
    person {
      id
      name
    }
    application
    additionalOrganizations {
      id
      name
    }
  }
}
    `;

export const useUpdateUserManagementItemMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateUserManagementItemMutation,
    TError,
    UpdateUserManagementItemMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateUserManagementItemMutation,
    TError,
    UpdateUserManagementItemMutationVariables,
    TContext
  >(
    ['updateUserManagementItem'],
    (variables?: UpdateUserManagementItemMutationVariables) =>
      request<
        UpdateUserManagementItemMutation,
        UpdateUserManagementItemMutationVariables
      >(UpdateUserManagementItemDocument, variables)(),
    options,
  );
};

useUpdateUserManagementItemMutation.fetcher = (
  variables: UpdateUserManagementItemMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<
    UpdateUserManagementItemMutation,
    UpdateUserManagementItemMutationVariables
  >(UpdateUserManagementItemDocument, variables, options);

export const DeleteUserManagementItemDocument = `
    mutation deleteUserManagementItem($id: ObjectID!) {
  deleteUserManagementItem(id: $id) {
    id
  }
}
    `;

export const useDeleteUserManagementItemMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    DeleteUserManagementItemMutation,
    TError,
    DeleteUserManagementItemMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    DeleteUserManagementItemMutation,
    TError,
    DeleteUserManagementItemMutationVariables,
    TContext
  >(
    ['deleteUserManagementItem'],
    (variables?: DeleteUserManagementItemMutationVariables) =>
      request<
        DeleteUserManagementItemMutation,
        DeleteUserManagementItemMutationVariables
      >(DeleteUserManagementItemDocument, variables)(),
    options,
  );
};

useDeleteUserManagementItemMutation.fetcher = (
  variables: DeleteUserManagementItemMutationVariables,
  options?: RequestInit['headers'],
) =>
  request<
    DeleteUserManagementItemMutation,
    DeleteUserManagementItemMutationVariables
  >(DeleteUserManagementItemDocument, variables, options);
