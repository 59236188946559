import { Auth } from '@aws-amplify/auth';
import { PToast } from '@porsche-design-system/components-react';
import { Analytics } from '@porsche-kado/analytics-client';
import { useAuth } from '@porsche-kado/auth';
import { ErrorBoundary } from '@porsche-kado/ui';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import config from './config/aws-exports';
import {
  PersonActionKind,
  useAbilityContext,
  usePersonContext,
} from './context';
import './lib/i18n';
import { Migrations } from './migrations';
import { routeTree } from './routeTree.gen';

const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

Auth.configure(config.Auth);
Analytics.configure(config.Analytics);

const App = () => {
  const { dispatch: dispatchPerson } = usePersonContext();
  const ability = useAbilityContext();

  useAuth(
    (user: { ppnuid: string }) => {
      dispatchPerson({ type: PersonActionKind.Authenticate, payload: user });
    },
    () => {
      dispatchPerson({ type: PersonActionKind.Reset });
      ability.update([]);
    },
  );

  return (
    <ErrorBoundary name="dashboard">
      <Migrations>
        <RouterProvider router={router} />
        <PToast />
      </Migrations>
    </ErrorBoundary>
  );
};

export default App;
