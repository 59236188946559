import { DataTable } from '@porsche-kado/ui';
import { useParams } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimeOutput } from '../../../components';
import { NAMESPACES } from '../../../config/i18n';
import { usePersonInfoQuery } from '../../../graphql';

export const Consents = () => {
  const { t } = useTranslation(NAMESPACES);
  const { personId } = useParams({
    from: '/admin/person-info/$personId/consents',
  });

  const { data: person, isLoading } = usePersonInfoQuery(
    { id: +personId },
    {
      select: (data) => data.person,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const consents = Object.entries(person?.consents ?? {}).map(
    ([name, value]) => ({ name, value }),
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<(typeof consents)[0]>();

    return [
      columnHelper.accessor('name', {
        cell: (data) => data.getValue(),
        header: () => t('common:name'),
      }),
      columnHelper.accessor('value', {
        cell: (data) => {
          const value = data.getValue();
          return value ? <DateTimeOutput date={value} /> : '-';
        },
        header: () => t('common:value'),
        enableColumnFilter: false,
        enableSorting: false,
      }),
    ];
  }, [t]);

  return (
    <DataTable
      isLoading={isLoading}
      caption={t('admin.personInfo.consents')}
      data={consents}
      columns={columns}
      idAccessor="name"
      pagination={{
        position: 'bottom',
      }}
      i18n={{
        filterLabel: (columnName) =>
          t('common:iconLabel.filter', { columnName }),
        optionAll: t('common:all'),
        buttonReset: t('common:action.reset'),
        buttonFilter: t('common:action.filter'),
        actionSearch: t('common:action.search'),
        noData: t('common:noData'),
      }}
    />
  );
};
