import {
  PButtonPure,
  PHeading,
  PTag,
} from '@porsche-design-system/components-react';
import { DataTable, Spacer, styled } from '@porsche-kado/ui';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAppName } from '../../../../components';
import { App } from '../../../../config/app';
import { NAMESPACES } from '../../../../config/i18n';
import {
  UserManagementItemsQuery,
  useUserManagementItemsQuery,
} from '../../../../graphql';
import { DeleteAdditionalOrganization } from './DeleteAdditionalOrganization';
import { EditAdditionalOrganization } from './EditAdditionalOrganization';

const TableCellActions = styled('div', {
  display: 'flex',
  justifyContent: 'end',
  gap: '$small',
});

const TagsContainer = styled('div', {
  display: 'inline-grid',
  gap: '$small',
  gridAutoFlow: 'column',
  gridAutoColumns: 'max-content',
});

const columnHelper =
  createColumnHelper<UserManagementItemsQuery['userManagementItems'][0]>();

export const AdditionalOrganizationOverview = () => {
  const { t } = useTranslation(NAMESPACES);

  const { data, isLoading } = useUserManagementItemsQuery(undefined, {
    select: (data) => data.userManagementItems,
  });

  const userManagementItems = useMemo(() => data, [data]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('person.name', {
        cell: (data) => data.getValue(),
        header: t('common:person'),
        meta: {
          name: t('common:person'),
        },
        filterFn: 'includesString',
      }),
      columnHelper.accessor('application', {
        cell: (data) => getAppName(data.getValue(), t),
        header: t('common:application'),
        meta: {
          name: t('common:application'),
          filterType: 'select',
          filterOptions: Object.values(App).map((app) => ({
            label: getAppName(app, t),
            value: app,
          })),
        },
        filterFn: 'equalsString',
      }),
      columnHelper.accessor('additionalOrganizations', {
        cell: (data) => {
          const additionalOrganizations = data.getValue();
          return (
            <TagsContainer>
              {additionalOrganizations.map((organization) => (
                <PTag key={organization.id}>{organization.name}</PTag>
              ))}
            </TagsContainer>
          );
        },
        header: () => t('common:additionalOrganizations'),
        meta: {
          name: t('common:additionalOrganizations'),
        },
        filterFn: (row, columnId, filterValue) => {
          const data = row.getValue<{ id: number; name: string }[]>(columnId);
          return data.some(({ name }) =>
            name.toLowerCase().includes(filterValue.toLowerCase()),
          );
        },
        enableSorting: false,
      }),
      columnHelper.display({
        header: ' ',
        size: 50,
        cell: (data) => {
          const userManagementItem = data.row.original;
          return (
            <TableCellActions>
              <EditAction userManagementItem={userManagementItem} />
              <DeleteAction userManagementItem={userManagementItem} />
            </TableCellActions>
          );
        },
        enableColumnFilter: false,
        enableSorting: false,
      }),
    ],
    [t],
  );

  return (
    <>
      <PHeading role="heading" aria-level={2} tag="h2" size="small">
        {t('admin.userManagement.additionalOrganization.overview')}
      </PHeading>

      <Spacer mb="$medium" />

      <DataTable
        isLoading={isLoading}
        caption={t('admin.userManagement.additionalOrganization.overview')}
        data={userManagementItems ?? []}
        columns={columns}
        idAccessor="id"
        pagination={{
          active: true,
          pageSize: 10,
          position: 'bottom',
        }}
        i18n={{
          filterLabel: (columnName) =>
            t('common:iconLabel.filter', { columnName }),
          optionAll: t('common:all'),
          buttonReset: t('common:action.reset'),
          buttonFilter: t('common:action.filter'),
          actionSearch: t('common:action.search'),
          noData: t('common:noData'),
        }}
      />
    </>
  );
};

const EditAction = ({
  userManagementItem,
}: {
  userManagementItem: {
    id: string;
    application: string;
    person: {
      id: number;
      name: string;
    };
    additionalOrganizations: {
      id: number;
      name: string;
    }[];
  };
}) => {
  const { t } = useTranslation(NAMESPACES);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <PButtonPure
        type="button"
        role="button"
        icon="edit"
        aria-label={t('common:action.edit')}
        onClick={() => setIsModalOpen(true)}
        hideLabel
      />

      <EditAdditionalOrganization
        isOpen={isModalOpen}
        userManagementItem={userManagementItem}
        onClose={onClose}
      />
    </>
  );
};

const DeleteAction = ({
  userManagementItem,
}: {
  userManagementItem: {
    id: string;
    application: string;
    person: {
      id: number;
      name: string;
    };
    additionalOrganizations: {
      id: number;
      name: string;
    }[];
  };
}) => {
  const { t } = useTranslation(NAMESPACES);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <PButtonPure
        type="button"
        role="button"
        icon="delete"
        aria-label={t('common:action.delete')}
        onClick={() => setIsModalOpen(true)}
        hideLabel
      />

      <DeleteAdditionalOrganization
        isOpen={isModalOpen}
        userManagementItem={userManagementItem}
        onClose={onClose}
      />
    </>
  );
};
