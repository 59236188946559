import { useMatch, useNavigate, useRouterState } from '@tanstack/react-router';
import { useEffect } from 'react';
import {
  SupportRequestFilterActionKind,
  SupportRequestFilterState,
  useSupportRequestFilterContext,
} from '../context';

/**
 * Checks if there are any filters in the state or query parameters.
 *
 * @param state Filter state
 * @returns boolean
 */
const hasFilters = (state: SupportRequestFilterState): boolean =>
  Object.keys(state.columnFilters || {}).length > 0 ||
  Object.keys(state.sorting || {}).length > 0 ||
  typeof state.skip === 'number' ||
  typeof state.limit === 'number';

/**
 * Hook to manage filters for the support request page.
 *
 * @returns void
 */
export const useSupportRequestsFilters = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useSupportRequestFilterContext();
  const { pathname, search } = useMatch({ from: '/support/' });
  const { resolvedLocation } = useRouterState();

  // Note: The initial filter state (app boot) is handled by the FilterProvider and includes the filters from the search query.

  useEffect(() => {
    const hasSearchParams = hasFilters(search);
    const hasFiltersInContext = hasFilters(state);

    if (!hasSearchParams && hasFiltersInContext) {
      // If there are no filters in the search query but in the previous location and also in the filter context, reset the filter state.
      // -> This ensures the browser backward navigation works correctly.
      if (hasFilters(resolvedLocation.search)) {
        dispatch({ type: SupportRequestFilterActionKind.Reset });
        return;
      }

      // Update the search query with the current filters.
      const { skip, limit, sorting, columnFilters } = state;
      navigate({
        to: pathname,
        search: (prev) => ({
          ...prev,
          columnFilters,
          sorting,
          skip,
          limit,
        }),
        replace: true,
        params: true,
      });
      return;
    }

    // Update the filter context if there are filters in the search query.
    if (hasSearchParams) {
      const { skip, limit, sorting, columnFilters = [] } = search;
      dispatch({
        type: SupportRequestFilterActionKind.Set,
        payload: { skip, limit, sorting, columnFilters },
      });
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
};
