import { PIcon } from '@porsche-design-system/components-react';

const fileTypes: { type: string; extension: string }[] = [
  { type: 'text/plain', extension: 'txt' },
  {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    extension: 'docx',
  },
  { type: 'application/msword', extension: 'doc' },
  { type: 'text/csv', extension: 'csv' },
  { type: 'application/pdf', extension: 'pdf' },
  { type: 'message/rfc822', extension: 'eml' },
  { type: 'application/vnd.ms-outlook', extension: 'msg' },
  { type: 'application/vnd.ms-excel', extension: 'xls' },
  {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: 'xlsx',
  },
  { type: 'application/vnd.ms-excel.sheet.macroenabled.12', extension: 'xlsm' },
  { type: 'application/vnd.ms-powerpoint', extension: 'ppt' },
  {
    type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    extension: 'pptx',
  },
  { type: 'image/png', extension: 'png' },
  { type: 'image/bmp', extension: 'bmp' },
  { type: 'image/gif', extension: 'gif' },
  { type: 'image/jpeg', extension: 'jpg' },
  { type: 'image/pjpeg', extension: 'jpeg' },
  { type: 'video/x-ms-wmv', extension: 'wmv' },
  { type: 'video/mpeg', extension: 'mpeg' },
  { type: 'video/mp4', extension: 'mp4' },
  { type: 'video/ogg', extension: 'ogg' },
  { type: 'video/x-msvideo', extension: 'avi' },
  { type: 'video/webm', extension: 'webm' },
  { type: 'video/quicktime', extension: 'mov' },
];

const mapTypeToIconName = (type: string) => {
  switch (type) {
    case 'image':
      return 'image';
    case 'video':
      return 'video';
    default:
      return 'document';
  }
};

export const FileType = ({
  value,
  hideIcon,
}: {
  value: string;
  hideIcon?: boolean;
}) => {
  const extension =
    fileTypes.find(({ type }) => type === value)?.extension ??
    value.split('/').at(-1);

  if (hideIcon) {
    return <>{extension}</>;
  }

  const type = value.split('/')[0];
  return <PIcon name={mapTypeToIconName(type)} aria-label={extension} />;
};
