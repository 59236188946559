import { defaultParseSearch } from '@tanstack/react-router';
import { createContext, useContext, useReducer, type ReactNode } from 'react';

enum FilterActionKind {
  Set = 'SET',
  Reset = 'RESET',
}

type FilterState = {
  limit?: number;
  skip?: number;
  columnFilters?: {
    id: string;
    value?: unknown;
  }[];
  sorting?: { id: string; desc: boolean }[];
};

type SideEffect = (state: FilterState | undefined) => void;

type Action =
  | {
      type: FilterActionKind.Set;
      payload: FilterState;
      sideEffect?: SideEffect;
    }
  | {
      type: FilterActionKind.Reset;
    };

const FilterContext = createContext<
  { state: FilterState; dispatch: (action: Action) => void } | undefined
>(undefined);

const filterReducer = (state: FilterState, action: Action) => {
  switch (action.type) {
    case FilterActionKind.Set: {
      const newState = parseFilter(
        cleanUp({
          ...state,
          ...action.payload,
        }),
      );

      if (action.sideEffect) {
        action.sideEffect(newState);
      }

      return newState;
    }
    case FilterActionKind.Reset: {
      return {};
    }
    default: {
      throw new Error('Unhandled action type');
    }
  }
};

const parseSearch = defaultParseSearch;

const FilterProvider = ({ children }: { children: ReactNode }) => {
  // Use search params as initial state value on first app render
  const searchParams: FilterState = parseSearch(window.location.search);
  const initialValues: FilterState = parseFilter(searchParams || {});

  const [state, dispatch] = useReducer(filterReducer, initialValues);

  const value = { state, dispatch };
  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilterContext must be used within a FilterProvider');
  }
  return context;
};

const cleanUp = (filter: FilterState): FilterState =>
  Object.entries(filter)
    .filter(([_, value]) => {
      if (
        Array.isArray(value) &&
        value[0] &&
        'id' in value[0] &&
        !value[0].id
      ) {
        return false;
      }

      return true;
    })
    .reduce((prev, [key, value]) => {
      return {
        ...prev,
        [key]: value,
      };
    }, {});

const parseFilter = (filter: FilterState): FilterState => {
  const filterMap = new Map<
    keyof FilterState,
    FilterState[keyof FilterState]
  >();

  for (const [key, value] of Object.entries(filter) as [
    keyof FilterState,
    FilterState[keyof FilterState],
  ][]) {
    filterMap.set(key, value);
  }

  return Object.fromEntries(filterMap);
};

export {
  FilterActionKind as SupportRequestFilterActionKind,
  FilterProvider as SupportRequestFilterProvider,
  useFilterContext as useSupportRequestFilterContext,
};

export type {
  SideEffect as SupportRequestFilterSideEffect,
  FilterState as SupportRequestFilterState,
};
