import { PHeading } from '@porsche-design-system/components-react';
import { Spacer } from '@porsche-kado/ui';
import { useTranslation } from 'react-i18next';
import {
  AdditionalOrganizationOverview,
  CreateAdditionalOrganization,
} from './additionalOrganization';

export const UserManagement = () => {
  const { t } = useTranslation();

  return (
    <>
      <PHeading role="heading" size="large" aria-level={1} tag="h1">
        {t('admin.userManagement.headline')}
      </PHeading>

      <Spacer mb="$medium" />

      <CreateAdditionalOrganization />

      <Spacer mb="$large" />

      <AdditionalOrganizationOverview />
    </>
  );
};
