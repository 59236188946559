import { PInlineNotification } from '@porsche-design-system/components-react';
import {
  Dropzone,
  DropzoneFiles,
  DropzoneInner,
  Label,
  styled,
  useDropzone,
} from '@porsche-kado/ui';
import ObjectId from 'bson-objectid';
import get from 'lodash/get';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fileTypes as defaultFileTypes } from '../config/fileTypes';
import { NAMESPACES } from '../config/i18n';
import { UploadProgress } from '../lib/uploader';

const Error = styled(PInlineNotification, {
  marginBottom: '$small',
});

export const FileUploader = ({
  label,
  value,
  onChange,
  progress,
  fileTypes = defaultFileTypes,
  multiple = true,
}: {
  label?: string;
  value: {
    attachments: {
      id: string;
      name: string;
      size: number;
      type: string;
    }[];
    attachmentsToUpload: {
      id: string;
      file: File;
    }[];
  };
  onChange: (value: {
    attachments: {
      id: string;
      name: string;
      size: number;
      type: string;
    }[];
    attachmentsToUpload: {
      id: string;
      file: File;
    }[];
  }) => void;
  progress?: UploadProgress;
  fileTypes?: typeof defaultFileTypes;
  multiple?: boolean;
}) => {
  const { t } = useTranslation(NAMESPACES);

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      onChange({
        ...value,
        attachmentsToUpload: [
          ...value.attachmentsToUpload,
          ...acceptedFiles.map((acceptedFile) => ({
            id: new ObjectId().toHexString(),
            file: acceptedFile,
          })),
        ],
      });
    },
    [onChange, value],
  );

  const onDropRejected = useCallback(() => {
    setIsErrorVisible(true);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    useFsAccessApi: false,
    multiple,
    accept: fileTypes.reduce(
      (memo, { type, extension }) => ({ ...memo, [type]: [`.${extension}`] }),
      {},
    ),
  });

  return (
    <>
      {isErrorVisible && (
        <Error
          state="error"
          heading={t('common:upload.typeError.title')}
          onDismiss={() => setIsErrorVisible(false)}
        >
          {t('common:upload.typeError.description', {
            allowedTypes: fileTypes
              .map(({ extension }) => extension)
              .join(', '),
          })}
        </Error>
      )}
      {label && <Label label={label} />}
      <Dropzone {...getRootProps({ role: 'button', 'aria-label': label })}>
        <input {...getInputProps()} />
        <DropzoneInner
          isDragActive={isDragActive}
          description={t('common:upload.description')}
          dragDescription={t('common:upload.dragDescription')}
        />
      </Dropzone>
      <DropzoneFiles
        progress={value.attachmentsToUpload.map((file) =>
          get(progress, file.id, -1),
        )}
        existingFiles={value.attachments}
        files={value.attachmentsToUpload}
        onDelete={(index) => {
          onChange({
            ...value,
            attachmentsToUpload: value.attachmentsToUpload.filter(
              (_, i) => i !== index,
            ),
          });
        }}
        onDeleteExisting={(idToDelete) => {
          onChange({
            ...value,
            attachments: value.attachments.filter(
              ({ id }) => id !== idToDelete,
            ),
          });
        }}
      />
    </>
  );
};
