import {
  PButton,
  PCheckboxWrapper,
  PPagination,
  PTable,
  PTableBody,
  PTableCell,
  PTableHead,
  PTableHeadCell,
  PTableHeadRow,
  PTableRow,
} from '@porsche-design-system/components-react';
import {
  CellContext,
  ColumnDef,
  ColumnFiltersState,
  OnChangeFn,
  PaginationState,
  RowData,
  RowSelectionState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Fragment, useEffect, useMemo, useState } from 'react';
import {
  ActionGroup,
  Popover,
  Spacer,
  TableCellLoader,
  TableHeadFilter,
  TableHeadSortButton,
} from '../';
import { styled } from '../../stitches.config';
import { ColumnFilter } from './Filter';

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    name?: string;
    filterType?: 'text' | 'date' | 'select' | 'organization';
    filterOptions?: { label: string; value: string | boolean }[] | 'auto';
    filterOptionsTransformer?: (
      values: (string | boolean)[],
    ) => (string | boolean)[];
    filterOptionsKey?: string; // the key of the filter option if it's an object and filterOptions = auto
    testId?: string;
    onClick?: (data: TData) => void;
  }
}

interface DataTableProps<TData extends RowData> {
  caption: string;
  data: TData[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[];
  defaultColumn?: Partial<ColumnDef<TData, unknown>>;
  idAccessor: keyof TData | ((d: TData) => string);
  isLoading?: boolean;
  i18n: {
    filterLabel: (columnName: string) => string;
    optionAll: string;
    buttonReset: string;
    buttonFilter: string;
    actionSearch: string;
    noData: string;
    customizeTable?: string;
  };
  actions?: {
    top?: React.ReactNode;
    bottom?: React.ReactNode;
  };
  lastRowConfig?: { id: string; cell: () => React.ReactNode }[];
  isSelectable?: boolean;
  onSelect?: (selectedIds: string[]) => void;
  preSelectedItemIds?: string[];
  disabledSelectedItemIds?: string[];
  pagination?: {
    active?: boolean;
    pageSize?: number;
    position?: 'both' | 'bottom';
  };
  defaultSort?: SortingState;
  sort?: { field: string; order: string };
  onSort?: (sort: { field: string; order: 'asc' | 'desc' | '' }) => void;
  isColumnsCustomizable?: boolean;
  initialColumnVisibility?: Record<string, boolean>;
  initialColumnFilters?: ColumnFiltersState;
  isMultiRowSelectable?: boolean;
  onColumnFiltersChange?: OnChangeFn<ColumnFiltersState>;
}

export const DataTable = <TData,>({
  caption,
  data,
  columns,
  defaultColumn,
  isLoading,
  actions,
  lastRowConfig,
  i18n,
  idAccessor,
  isSelectable = false,
  isColumnsCustomizable = false,
  isMultiRowSelectable,
  initialColumnVisibility = {},
  preSelectedItemIds = [],
  disabledSelectedItemIds = [],
  defaultSort = [],
  onSelect = (_) => _,
  onSort,
  sort,
  pagination,
  initialColumnFilters,
  onColumnFiltersChange,
  ...props
}: DataTableProps<TData>) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    initialColumnFilters ?? [],
  );
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    mapIdToIndex(data, preSelectedItemIds, idAccessor),
  );
  useEffect(() => {
    if (!isLoading) {
      // fixup initial selection after data is loaded
      setRowSelection(mapIdToIndex(data, preSelectedItemIds, idAccessor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  const {
    active: isPaginationActive = true,
    position: paginationPosition = 'both',
    pageSize: paginationPageSize = 10,
  } = pagination || {};
  const [sorting, setSorting] = useState<SortingState>(defaultSort);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: paginationPageSize,
  });
  const paginationState = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageIndex, pageSize],
  );
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    initialColumnVisibility,
  );
  const [isCustomizeTableVisible, setIsCustomizeTableVisible] = useState(false);

  useEffect(() => {
    onSelect?.(mapIndexToId(data, rowSelection, idAccessor));
  }, [data, idAccessor, onSelect, rowSelection]);

  useEffect(() => {
    const newOrder = sorting[0] ? (sorting[0]?.desc ? 'desc' : 'asc') : '';
    if ((sorting[0]?.id || '') != sort?.field || newOrder != sort?.order) {
      onSort?.({
        field: sorting[0]?.id || '',
        order: newOrder,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting[0]?.id, sorting[0]?.desc]);

  useEffect(() => {
    const current = pageIndex + 1;
    const total = Math.ceil(data.length / pageSize);

    if (current > total) {
      // Reset page manually, because data was changed and current page is greater-than total count.
      // This is necessary, because we set autoResetPageIndex to false
      setPagination((pagination) => ({
        ...pagination,
        pageIndex: 0,
      }));
    }
  }, [data.length, pageIndex, pageSize]);

  const table = useReactTable({
    data,
    columns: [
      ...(isSelectable
        ? [
            {
              id: 'selectColumn',
              size: 20,
              cell: ({ row }: CellContext<TData, unknown>) => (
                <PCheckboxWrapper hideLabel>
                  <input
                    type="checkbox"
                    {...{
                      checked: row.getIsSelected(),
                      disabled: !row.getCanSelect(),
                      onChange: row.getToggleSelectedHandler(),
                    }}
                  />
                </PCheckboxWrapper>
              ),
            },
          ]
        : []),
      ...columns,
    ],
    defaultColumn,
    state: {
      sorting,
      pagination: paginationState,
      columnFilters,
      ...(isSelectable && { rowSelection }),
      columnVisibility,
    },
    manualSorting: !!sort,
    manualPagination: !isPaginationActive,
    autoResetPageIndex: false,
    enableMultiRowSelection: isMultiRowSelectable,
    enableRowSelection: (row) => {
      const id = String(
        typeof idAccessor === 'function'
          ? idAccessor(row.original)
          : row.original[idAccessor],
      );
      return isSelectable && !disabledSelectedItemIds?.includes(id);
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnFiltersChange: (...args) => {
      setColumnFilters(...args);

      // Reset page index manually, because we set autoResetPageIndex to false
      setPagination((pagination) => ({
        ...pagination,
        pageIndex: 0,
      }));

      onColumnFiltersChange?.(...args);
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const showTopPagination = isPaginationActive && paginationPosition === 'both';
  const showTopAction = isColumnsCustomizable || actions?.top;

  const customizeTableButton = isColumnsCustomizable ? (
    <Popover
      open={isCustomizeTableVisible}
      onChange={(open) => setIsCustomizeTableVisible(open)}
      target={
        <PButton
          onClick={() => setIsCustomizeTableVisible((open) => !open)}
          icon={`arrow-head-${isCustomizeTableVisible ? 'up' : 'down'}`}
        >
          {i18n.customizeTable}
        </PButton>
      }
      placement="bottom-start"
    >
      {table.getAllLeafColumns().map((column) => (
        <Fragment key={column.id}>
          <PCheckboxWrapper label={column.columnDef.header as string}>
            <input
              type="checkbox"
              name={column.id}
              checked={column.getIsVisible()}
              onChange={column.getToggleVisibilityHandler()}
            />
          </PCheckboxWrapper>

          <Spacer pb="$medium" />
        </Fragment>
      ))}
    </Popover>
  ) : null;

  return (
    <>
      {showTopAction || showTopPagination ? (
        <>
          <ActionGroup>
            {showTopAction ? (
              <>
                {isColumnsCustomizable && actions?.top ? (
                  <ActionGroup spacing="none">
                    {customizeTableButton}
                    {actions.top}
                  </ActionGroup>
                ) : null}

                {isColumnsCustomizable && !actions?.top
                  ? customizeTableButton
                  : null}

                {!isColumnsCustomizable && actions?.top ? actions.top : null}
              </>
            ) : (
              <div />
            )}

            {showTopPagination && !isLoading && !!data?.length ? (
              <PPagination
                totalItemsCount={table.getPageCount() * paginationPageSize}
                itemsPerPage={paginationPageSize}
                activePage={table.getState().pagination.pageIndex + 1}
                onUpdate={(event) => {
                  table.setPageIndex(event.detail.page - 1);
                }}
              />
            ) : null}
          </ActionGroup>

          <Spacer mb="$medium" />
        </>
      ) : null}

      <PTable
        role="table"
        data-testid={isLoading ? 'table-loading' : 'table-ready'}
        caption={caption}
        {...props}
      >
        <PTableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <PTableHeadRow key={headerGroup.id}>
              {headerGroup.headers.map((header, i) => {
                const isSorted = header.column.getIsSorted();

                return (
                  <PTableHeadCell
                    key={`${header.id}_${i}`}
                    style={{
                      width: `${
                        header.column.id === 'selectColumn' ? '20px' : 'auto'
                      }`,
                      minWidth: header.column.getSize(),
                    }}
                  >
                    {header.column.getCanSort() ? (
                      <TableHeadSortButton
                        onClick={() =>
                          header.column.toggleSorting(isSorted === 'asc')
                        }
                        {...(isSorted && { direction: isSorted })}
                        isActive={!!isSorted}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </TableHeadSortButton>
                    ) : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </>
                    )}

                    {header.column.getCanFilter() ? (
                      <TableHeadFilter>
                        <ColumnFilter
                          column={header.column}
                          i18n={{
                            ...i18n,
                            filterLabel: i18n.filterLabel(
                              header.column.columnDef.meta?.name ??
                                header.column.id,
                            ),
                          }}
                        />
                      </TableHeadFilter>
                    ) : null}
                  </PTableHeadCell>
                );
              })}
            </PTableHeadRow>
          ))}
        </PTableHead>
        <PTableBody>
          {!isLoading
            ? table.getRowModel().rows.map((row) => (
                <PTableRow role="row" key={row.id}>
                  {row.getVisibleCells().map((cell, i) => {
                    const testId = cell.column.columnDef.meta?.testId;
                    const onClick = cell.column.columnDef.meta?.onClick;

                    return (
                      <PTableCell
                        role="cell"
                        {...(onClick && {
                          onClick: () => onClick(row.original),
                        })}
                        key={`${cell.id}_${i}`}
                        style={{
                          minWidth: cell.column.getSize(),
                          ...(onClick && { cursor: 'pointer' }),
                        }}
                        {...(testId && { 'data-testId': testId })}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </PTableCell>
                    );
                  })}
                </PTableRow>
              ))
            : null}

          {isLoading
            ? Array.from({ length: 3 }).map((_, index) => (
                <PTableRow key={`TableCellLoader_${index}`}>
                  {table.getAllColumns().map((col) => (
                    <PTableCell key={col.id}>
                      <TableCellLoader />
                    </PTableCell>
                  ))}
                </PTableRow>
              ))
            : null}

          {!isLoading && lastRowConfig && !!data.length ? (
            <PTableRow>
              {table.getVisibleFlatColumns().map((c) => {
                const config = lastRowConfig.find((r) => r.id === c.id);

                if (config) {
                  return <PTableCell key={c.id}>{config.cell()}</PTableCell>;
                }

                return <PTableCell key={c.id} />;
              })}
            </PTableRow>
          ) : null}
        </PTableBody>
      </PTable>

      {!isLoading && !data.length ? (
        <>
          <Center>{i18n.noData}</Center>
          <Spacer mb="$medium" />
        </>
      ) : null}

      {isPaginationActive || actions?.bottom ? (
        <ActionGroup>
          {actions?.bottom ? actions.bottom : <div />}
          {isPaginationActive && !isLoading && !!data?.length ? (
            <PPagination
              totalItemsCount={table.getPageCount() * paginationPageSize}
              itemsPerPage={paginationPageSize}
              activePage={table.getState().pagination.pageIndex + 1}
              onUpdate={(event) => {
                table.setPageIndex(event.detail.page - 1);
              }}
            />
          ) : null}
        </ActionGroup>
      ) : null}
    </>
  );
};

// This function is only necessary for the row selection feature because react-table uses the row index
// to mark the selected rows.
const mapIdToIndex = <TData,>(
  data: TData[],
  ids: string[],
  idAccessor: keyof TData | ((d: TData) => string),
) => {
  return ids.reduce((acc, id) => {
    const index = data.findIndex((item) => {
      const itemId = String(
        typeof idAccessor === 'function' ? idAccessor(item) : item[idAccessor],
      );
      return itemId === id;
    });
    if (index !== -1) {
      acc[index] = true;
    }
    return acc;
  }, {} as RowSelectionState);
};

// Map back to the ids for the onSelect callback
const mapIndexToId = <TData,>(
  data: TData[],
  rowSelection: RowSelectionState,
  idAccessor: keyof TData | ((d: TData) => string),
) => {
  return Object.entries(rowSelection).map(([index]) => {
    const item = data[+index];
    return String(
      typeof idAccessor === 'function' ? idAccessor(item) : item[idAccessor],
    );
  });
};

const Center = styled('div', { display: 'flex', justifyContent: 'center' });
