import {
  PHeading,
  PLink,
  PText,
} from '@porsche-design-system/components-react';
import { COMMON_NAMESPACE } from '@porsche-kado/i18n';
import { Spacer, styled } from '@porsche-kado/ui';
import NotLoggedInIcon from '@porsche-kado/ui/icons/not-logged-in.svg?react';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '$xLarge',
});

const Icon = styled(NotLoggedInIcon, {
  fontSize: '180px',
  margin: '$xLarge 0',
});

export const NotSignedIn = () => {
  const { t } = useTranslation(COMMON_NAMESPACE);
  const PPN_URL = `https://${
    process.env.REACT_APP_ENVIRONMENT_SLUG === 'production' ? 'ppn' : 'ppnlite'
  }.porsche.com/`;

  return (
    <Wrapper>
      <PHeading role="heading" tag="h3" size="x-large">
        {t('notLoggedIn.title')}
      </PHeading>
      <Icon />
      <PText align="center">{t('notLoggedIn.text')}</PText>
      <Spacer mt="$medium" />
      <PLink href={PPN_URL}>{t('notLoggedIn.goToPPN')}</PLink>
    </Wrapper>
  );
};
