import { createContext, useContext, useEffect, useMemo } from 'react';
import { useMigrateSupportRequestsSeenStatus } from './useMigrateSupportRequestsSeenStatus';

const MigrationsContext = createContext({
  isMigratingSupportRequestSeenStatus: false,
  hasMigratedSupportRequestSeenStatus: false,
});

export const Migrations = ({ children }: { children: React.ReactNode }) => {
  const {
    migrate: migrateSupportRequestSeenStatus,
    isMigrating: isMigratingSupportRequestSeenStatus,
    hasMigrated: hasMigratedSupportRequestSeenStatus,
  } = useMigrateSupportRequestsSeenStatus();

  // TODO: better way?!
  useEffect(() => {
    migrateSupportRequestSeenStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const status = useMemo(
    () => ({
      isMigratingSupportRequestSeenStatus,
      hasMigratedSupportRequestSeenStatus,
    }),
    [isMigratingSupportRequestSeenStatus, hasMigratedSupportRequestSeenStatus],
  );

  return (
    <MigrationsContext.Provider value={status}>
      {children}
    </MigrationsContext.Provider>
  );
};

export const useMigrationsStatus = () => {
  return useContext(MigrationsContext);
};
