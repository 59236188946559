export const FileSize = ({ value }: { value: number }) => {
  const size = ['Bytes', 'KB', 'MB', 'GB', 'TB'].reduce(
    (acc: number | string, abbr: string) => {
      if (typeof acc === 'number') {
        if (acc > 1000) {
          return acc / 1000;
        }
        return `${Math.round(acc * 10) / 10} ${abbr}`;
      }
      return acc;
    },
    value,
  );

  return <>{size}</>;
};
