import { Select, SelectProps } from '@porsche-kado/ui';
import { useTranslation } from 'react-i18next';
import { App } from '../../config/app';
import { NAMESPACES } from '../../config/i18n';

// react-select requires an object as option type (https://github.com/JedWatson/react-select/issues/5032)
export const ApplicationSelect = <IsMulti extends boolean = false>(
  props: SelectProps<{ value: string; label?: string }, IsMulti>,
) => {
  const { t } = useTranslation(NAMESPACES);

  const apps = [
    {
      value: App.FieldForceManagement,
      label: t('common:app.ffm'),
    },
    {
      value: App.Greenland,
      label: t('common:app.greenland'),
    },
    {
      value: App.PorscheStrategy2030,
      label: t('common:app.porscheStrategy2030'),
    },
    {
      value: App.NetworkPlanning,
      label: t('common:app.networkPlanning'),
    },
    {
      value: App.RetailDesign,
      label: t('common:app.retailDesign'),
    },
    {
      value: App.Survey,
      label: t('common:app.survey'),
    },
    {
      value: App.MysteryShopping,
      label: t('common:app.mysteryShopping'),
    },
    {
      value: App.MultisensoryExperience,
      label: t('common:app.multisensoryExperience'),
    },
  ];

  return (
    <Select
      {...props}
      options={apps}
      getOptionLabel={(option) =>
        option.label ??
        apps.find((app) => app.value === option.value)?.label ??
        ''
      }
    />
  );
};
