import { Select, SelectProps } from '@porsche-kado/ui';
import { Role } from '../../config/role';

// react-select requires an object as option type (https://github.com/JedWatson/react-select/issues/5032)
export const RoleSelect = <IsMulti extends boolean = false>(
  props: SelectProps<{ value: string; label?: string }, IsMulti>,
) => {
  const options = Object.values(Role).map((role) => ({
    label: role,
    value: role,
  }));

  return (
    <Select
      {...props}
      options={options}
      getOptionLabel={(option) =>
        option.label ??
        options?.find(({ value }) => value === option.value)?.label ??
        ''
      }
    />
  );
};
