import {
  PButton,
  PInlineNotification,
  PSelectWrapper,
} from '@porsche-design-system/components-react';
import { ActionGroup, Modal, Spacer } from '@porsche-kado/ui';
import { useQueryClient } from '@tanstack/react-query';
import { ClientError } from 'graphql-request';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getAppName, OrganizationSelect } from '../../../../components';
import { NAMESPACES } from '../../../../config/i18n';
import {
  UserManagementItemsQuery,
  useUpdateUserManagementItemMutation,
  useUserManagementItemsQuery,
} from '../../../../graphql';
import { useNotification } from '../../../../hooks';

type FormValues = {
  additionalOrganizationIds: number[];
};

export const EditAdditionalOrganization = ({
  isOpen,
  userManagementItem,
  onClose: onCloseCallback,
}: {
  isOpen: boolean;
  userManagementItem: {
    id: string;
    application: string;
    person: {
      id: number;
      name: string;
    };
    additionalOrganizations: {
      id: number;
      name: string;
    }[];
  };
  onClose: () => void;
}) => {
  const { t } = useTranslation(NAMESPACES);
  const { addToast } = useNotification();
  const queryClient = useQueryClient();

  const updateUserManagementItemMutation = useUpdateUserManagementItemMutation({
    onSuccess: (data) => {
      queryClient.setQueryData<UserManagementItemsQuery>(
        useUserManagementItemsQuery.getKey(),
        (cache) =>
          cache?.userManagementItems
            ? {
                userManagementItems: cache.userManagementItems.map(
                  (userManagementItems) =>
                    userManagementItems.id === data.updateUserManagementItem.id
                      ? { ...data.updateUserManagementItem }
                      : userManagementItems,
                ),
              }
            : undefined,
      );
    },
  });

  const {
    handleSubmit,
    control,
    formState,
    reset: resetForm,
  } = useForm<FormValues>({
    values: {
      additionalOrganizationIds: userManagementItem.additionalOrganizations.map(
        ({ id }) => id,
      ),
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async ({
    additionalOrganizationIds,
  }) => {
    const currentAdditionalOrganizationIds =
      userManagementItem.additionalOrganizations.map(({ id }) => id);
    const push = additionalOrganizationIds.filter(
      (id) => !currentAdditionalOrganizationIds.includes(id),
    );
    const pull = currentAdditionalOrganizationIds.filter(
      (id) => !additionalOrganizationIds.includes(id),
    );

    return updateUserManagementItemMutation.mutateAsync(
      {
        input: {
          id: userManagementItem.id,
          additionalOrganizationIds: {
            ...(pull.length > 0 && { _pull: pull }),
            ...(push.length > 0 && { _push: push }),
          },
        },
      },
      {
        onSuccess: () => {
          addToast({
            text: t(
              'admin.userManagement.additionalOrganization.updatedSuccessfully',
            ),
            state: 'success',
          });

          onClose();
        },
      },
    );
  };

  const onClose = () => {
    onCloseCallback();

    resetForm();

    if (updateUserManagementItemMutation.isError) {
      updateUserManagementItemMutation.reset();
    }
  };

  return (
    <Modal
      open={isOpen}
      disableBackdropClick
      dismissButton={false}
      aria={{
        'aria-label': t(
          'admin.userManagement.additionalOrganization.editHeading',
        ),
      }}
    >
      <Modal.Header>
        {t('admin.userManagement.additionalOrganization.editHeading')}
      </Modal.Header>

      {updateUserManagementItemMutation.isError ? (
        <>
          <PInlineNotification
            heading={t('common:error')}
            description={`${
              updateUserManagementItemMutation.error instanceof ClientError
                ? updateUserManagementItemMutation.error.response.errors?.[0]
                    .message ?? updateUserManagementItemMutation.error.message
                : `${updateUserManagementItemMutation.error}`
            }`}
            state="error"
            dismissButton={false}
          />

          <Spacer mb="$medium" />
        </>
      ) : null}

      <form onSubmit={handleSubmit(onSubmit)}>
        <PSelectWrapper label={t('common:person')} hideLabel={false}>
          <select name="person" disabled required>
            <option value={userManagementItem.person.id}>
              {userManagementItem.person.name}
            </option>
          </select>
        </PSelectWrapper>

        <Spacer mb="$medium" />

        <PSelectWrapper label={t('common:application')} hideLabel={false}>
          <select name="application" disabled required>
            <option value={userManagementItem.application}>
              {getAppName(userManagementItem.application, t)}
            </option>
          </select>
        </PSelectWrapper>

        <Spacer mb="$medium" />

        <Controller
          control={control}
          name="additionalOrganizationIds"
          rules={{ required: true }}
          render={({
            field: { ref, onChange, value, ...field },
            fieldState,
          }) => (
            <OrganizationSelect
              {...field}
              isMulti
              isRequired
              label={t('common:additionalOrganizations')}
              aria-label={t('common:additionalOrganizations')}
              message={
                fieldState.isTouched ? fieldState.error?.message : undefined
              }
              onChange={(organizations) => {
                onChange(organizations.map((organization) => organization.id));
              }}
              value={value?.map((id) => ({ id })) ?? []}
            />
          )}
        />

        <ActionGroup>
          <PButton
            type="button"
            role="button"
            variant="secondary"
            onClick={onClose}
            icon="close"
          >
            {t('common:action.cancel')}
          </PButton>

          <PButton
            type="submit"
            variant="primary"
            loading={formState.isSubmitting}
            role="button"
          >
            {t('common:action.save')}
          </PButton>
        </ActionGroup>
      </form>
    </Modal>
  );
};
