import { Auth } from '@aws-amplify/auth';
import { PLinkProps } from '@porsche-design-system/components-react';
import {
  ContentWrapper,
  Navigation,
  NavigationItem,
  NavigationLink,
  Header as StyledHeader,
  styled,
} from '@porsche-kado/ui';
import kadoLogoSrc from '@porsche-kado/ui/icons/kado.svg';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { usePersonContext } from '../../context';
import { COMMON_NAMESPACE, NAMESPACES } from './../../config/i18n';
import { AdminNavigation } from './AdminNavigation';
import { SupportNavigationItem } from './SupportNavigationItem';

const Content = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
});

export const Header = () => {
  const { t } = useTranslation(NAMESPACES);
  const { state: personState } = usePersonContext();

  const isPasswordFlowActive =
    process.env.REACT_APP_IS_PASSWORD_FLOW_ACTIVE === 'true';

  const handleSignOut = () => {
    Auth.signOut();
  };

  return (
    <header>
      <ContentWrapper>
        <StyledHeader />
        {personState.isAuthenticated && (
          <Content>
            <nav>
              <Navigation>
                <NavigationItem divider>
                  <NavigationLink iconSource={kadoLogoSrc}>
                    <Link to="/">{t('title', { ns: COMMON_NAMESPACE })}</Link>
                  </NavigationLink>
                </NavigationItem>
                <NavigationItem>
                  <ConnectedNavigationLink to="/" icon="none">
                    {t('title')}
                  </ConnectedNavigationLink>
                </NavigationItem>
                <NavigationItem>
                  <ConnectedNavigationLink to="/news" icon="none">
                    {t('featureArticles')}
                  </ConnectedNavigationLink>
                </NavigationItem>
              </Navigation>
            </nav>
            <Navigation>
              {personState.isAuthorized && (
                <>
                  <NavigationItem data-testid="profile">
                    <NavigationLink icon="user" hideLabel>
                      <Link to="/profile">{t('common:profile.headline')}</Link>
                    </NavigationLink>
                  </NavigationItem>

                  <SupportNavigationItem />

                  <AdminNavigation />
                </>
              )}

              {isPasswordFlowActive && (
                <NavigationItem>
                  <NavigationLink
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      handleSignOut();
                    }}
                    icon="logout"
                    hideLabel
                  />
                </NavigationItem>
              )}
            </Navigation>
          </Content>
        )}
      </ContentWrapper>
    </header>
  );
};

const ConnectedNavigationLink = ({
  to,
  ...props
}: { to: string } & PLinkProps) => {
  return (
    <Link to={to}>
      {({ isActive }) => (
        <NavigationLink href={to} isActive={isActive} {...props} />
      )}
    </Link>
  );
};
