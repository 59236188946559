import { subject } from '@casl/ability';
import {
  PButton,
  PCheckboxWrapper,
  PHeading,
  PInlineNotification,
  PLinkPure,
  PPagination,
  PTable,
  PTableBody,
  PTableCell,
  PTableHead,
  PTableHeadCell,
  PTableHeadRow,
  PTableRow,
  PTextFieldWrapper,
} from '@porsche-design-system/components-react';
import {
  ActionGroup,
  ColumnFilter,
  dateBetweenFilterFn,
  Editor,
  Modal,
  Spacer,
  TableCellLoader,
  TableHeadFilter,
  TableHeadSortButton,
} from '@porsche-kado/ui';
import { useQueryClient } from '@tanstack/react-query';
import { Link, useNavigate } from '@tanstack/react-router';
import {
  ColumnFiltersState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ClientError } from 'graphql-request';
import { useMemo, useState } from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ApplicationSelect,
  DateTimeOutput,
  OrganizationSelect,
  PersonSelect,
  SupportRequestStatusTag,
} from '../components';
import { FileUploader } from '../components/FileUploader';
import { NAMESPACES } from '../config/i18n';
import {
  SupportRequestFilterActionKind,
  SupportRequestFilterSideEffect,
  useAbilityContext,
  useSupportRequestFilterContext,
} from '../context';
import {
  SupportRequestsQuery,
  SupportRequestStatus,
  UploadReference,
  UploadType,
  useCreateSupportRequestMutation,
  useSupportRequestsQuery,
} from '../graphql';
import { useNotification, useSupportRequestsFilters } from '../hooks';
import { uploader, UploadProgress } from '../lib/uploader';

const defaultRequests: [] = [];

export const SupportRequests = () => {
  const { t } = useTranslation(NAMESPACES);
  const ability = useAbilityContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    dispatch,
    state: { limit = 10, skip = 0, columnFilters, sorting },
  } = useSupportRequestFilterContext();
  const navigate = useNavigate();
  const sideEffect: SupportRequestFilterSideEffect = (state) => {
    setTimeout(
      // Do not interfere while components are still updating
      () =>
        navigate({
          to: '/support',
          search: {
            columnFilters:
              state?.columnFilters &&
              Object.keys(state.columnFilters).length > 0
                ? state.columnFilters
                : undefined,
            sorting:
              state?.sorting && Object.keys(state.sorting).length > 0
                ? state.sorting
                : undefined,
            limit: state?.limit,
            skip: state?.skip,
          },
        }),
      0,
    );
  };

  // Apply filters
  useSupportRequestsFilters();

  const { data, isLoading } = useSupportRequestsQuery();

  const columns = useMemo(() => {
    const columnHelper =
      createColumnHelper<NonNullable<typeof data>['supportRequests'][number]>();

    return [
      columnHelper.accessor('id', {
        header: t('supportRequest.ticketId'),
        enableSorting: false,
        cell: (data) => (
          <LinkedCell
            id={data.row.original?.id}
            unread={data.row.original.hasNewActivity}
            value={data.getValue()}
          />
        ),
      }),
      columnHelper.accessor('createdAt', {
        header: t('common:date'),
        cell: (data) => (
          <LinkedCell
            id={data.row.original?.id}
            unread={data.row.original.hasNewActivity}
            value={<DateTimeOutput date={data.getValue()} />}
          />
        ),
        filterFn: dateBetweenFilterFn,
        meta: {
          filterType: 'date',
        },
      }),
      columnHelper.accessor('title', {
        header: t('supportRequest.title'),
        cell: (data) => (
          <LinkedCell
            id={data.row.original?.id}
            unread={data.row.original.hasNewActivity}
            value={data.getValue()}
          />
        ),
      }),
      columnHelper.accessor('organization.name', {
        header: t('common:organization'),
        cell: (data) => (
          <LinkedCell
            id={data.row.original?.id}
            unread={data.row.original.hasNewActivity}
            value={data.getValue()}
          />
        ),
      }),
      // Only show author column if user can see other items than their own
      ...(ability.can(
        'read',
        subject('SupportRequest', {
          author: 0,
        }),
      )
        ? [
            columnHelper.accessor('author.name', {
              header: t('common:person'),
              cell: (data) => (
                <LinkedCell
                  id={data.row.original?.id}
                  unread={data.row.original.hasNewActivity}
                  value={data.getValue()}
                />
              ),
            }),
          ]
        : []),
      columnHelper.accessor('status', {
        header: t('common:status'),
        cell: (data) => (
          <LinkedCell
            id={data.row.original?.id}
            value={<SupportRequestStatusTag status={data.getValue()} />}
          />
        ),
        meta: {
          name: t('common:status'),
          filterType: 'select',
          filterOptions: Object.values(SupportRequestStatus).map((status) => ({
            label: t(`supportRequest.statusValues.${status}`),
            value: status,
          })),
        },
        filterFn: 'equalsString',
      }),
    ];
  }, [t, ability]);

  const pagination = useMemo(
    () => ({ pageSize: limit, pageIndex: skip }),
    [limit, skip],
  );

  const table = useReactTable({
    data: data?.supportRequests ?? defaultRequests,
    columns,
    autoResetPageIndex: false,
    state: {
      sorting,
      pagination,
      columnFilters: columnFilters as ColumnFiltersState,
    },
    onSortingChange: (updateOrValue) => {
      dispatch({
        type: SupportRequestFilterActionKind.Set,
        payload: {
          limit: 10,
          skip: 0,
          sorting:
            typeof updateOrValue === 'function'
              ? updateOrValue(sorting ?? [])
              : updateOrValue,
        },
        sideEffect,
      });
    },
    onPaginationChange: (updateOrValue) => {
      const value =
        typeof updateOrValue === 'function'
          ? updateOrValue(pagination)
          : updateOrValue;
      dispatch({
        type: SupportRequestFilterActionKind.Set,
        payload: {
          limit: value.pageSize,
          skip: value.pageIndex,
        },
        sideEffect,
      });
    },
    onColumnFiltersChange: (updateOrValue) => {
      dispatch({
        type: SupportRequestFilterActionKind.Set,
        payload: {
          limit: 10,
          skip: 0,
          columnFilters:
            typeof updateOrValue === 'function'
              ? updateOrValue((columnFilters as ColumnFiltersState) ?? [])
              : updateOrValue,
        },
        sideEffect,
      });
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <>
      <PHeading role="heading" aria-level={2} tag="h2" size="large">
        {t('supportRequest.headline')}
      </PHeading>

      <Spacer mb="$medium" />

      <PTable role="table" caption={t('supportRequest.requests')}>
        <PTableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <PTableHeadRow key={headerGroup.id}>
              {headerGroup.headers.map((header, i) => {
                const isSorted = header.column.getIsSorted();

                return (
                  <PTableHeadCell
                    key={`${header.id}_${i}`}
                    style={{
                      width: `${
                        header.column.id === 'selectColumn' ? '20px' : 'auto'
                      }`,
                      minWidth: header.column.getSize(),
                    }}
                  >
                    {header.column.getCanSort() ? (
                      <TableHeadSortButton
                        onClick={() =>
                          header.column.toggleSorting(isSorted === 'asc')
                        }
                        {...(isSorted && { direction: isSorted })}
                        isActive={!!isSorted}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </TableHeadSortButton>
                    ) : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </>
                    )}

                    {header.column.getCanFilter() && (
                      <TableHeadFilter>
                        <ColumnFilter
                          column={header.column}
                          i18n={{
                            filterLabel: t('common:iconLabel.filter', {
                              columnName:
                                header.column.columnDef.meta?.name ??
                                header.column.id,
                            }),
                            optionAll: t('common:all'),
                            buttonReset: t('common:action.reset'),
                            buttonFilter: t('common:action.filter'),
                            actionSearch: t('common:action.search'),
                          }}
                        />
                      </TableHeadFilter>
                    )}
                  </PTableHeadCell>
                );
              })}
            </PTableHeadRow>
          ))}
        </PTableHead>
        <PTableBody>
          {table.getRowModel().rows.map((row) => (
            <PTableRow role="row" key={row.id}>
              {row.getVisibleCells().map((cell, i) => {
                const testId = cell.column.columnDef.meta?.testId;
                const onClick = cell.column.columnDef.meta?.onClick;

                return (
                  <PTableCell
                    role="cell"
                    {...(onClick && {
                      onClick: () => onClick(row.original),
                    })}
                    key={`${cell.id}_${i}`}
                    style={{
                      minWidth: cell.column.getSize(),
                      verticalAlign: 'top',
                      ...(onClick && { cursor: 'pointer' }),
                    }}
                    {...(testId && { 'data-testId': testId })}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </PTableCell>
                );
              })}
            </PTableRow>
          ))}

          {isLoading &&
            Array.from({ length: pagination.pageSize }).map((_, index) => (
              <PTableRow key={`TableCellLoader_${index}`}>
                {table.getAllColumns().map((col) => (
                  <PTableCell key={col.id}>
                    <TableCellLoader />
                  </PTableCell>
                ))}
              </PTableRow>
            ))}
        </PTableBody>
      </PTable>

      <ActionGroup>
        <PButton
          aria={{ 'aria-haspopup': 'dialog' }}
          role="button"
          type="button"
          name={t('supportRequest.createRequest')}
          icon="add"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          {t('supportRequest.createRequest')}
        </PButton>

        <PPagination
          totalItemsCount={table.getPageCount() * 10}
          itemsPerPage={10}
          activePage={table.getState().pagination.pageIndex + 1}
          onUpdate={(event) => {
            table.setPageIndex(event.detail.page - 1);
          }}
        />
      </ActionGroup>

      <SupportRequestForm
        isOpen={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
      />
    </>
  );
};

type FormValues = {
  title: string;
  application: string;
  organization: {
    id: number;
    name: string;
  };
  referencePerson?: {
    id: number;
    name: string;
  };
  description: string;
  attachments: {
    id: string;
    file: File;
  }[];
  refuseTechnicalDetails: boolean;
};

const SupportRequestForm = ({
  isOpen,
  onDismiss,
}: {
  isOpen: boolean;
  onDismiss: () => void;
}) => {
  const { t } = useTranslation(NAMESPACES);
  const { addToast } = useNotification();
  const queryClient = useQueryClient();
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>();
  const [uploadError, setUploadError] = useState<unknown | undefined>(
    undefined,
  );

  const {
    handleSubmit,
    control,
    register,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    shouldUnregister: true,
    defaultValues: {
      refuseTechnicalDetails: false,
      attachments: [],
    },
  });

  const [attachmentController, setAttachmentController] = useState(
    () => new AbortController(),
  );

  const handleDismiss = () => {
    attachmentController.abort;
    setAttachmentController(() => new AbortController());

    onDismiss();
  };

  const { mutateAsync: createSupportRequest } = useCreateSupportRequestMutation(
    {
      onSuccess: (data) => {
        queryClient.setQueryData<SupportRequestsQuery>(
          useSupportRequestsQuery.getKey(),
          (cache) =>
            cache?.supportRequests
              ? {
                  supportRequests: [
                    data.createSupportRequest,
                    ...cache.supportRequests,
                  ],
                }
              : undefined,
        );
      },
    },
  );

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const {
      organization,
      attachments,
      referencePerson,
      refuseTechnicalDetails,
      ...input
    } = values;

    let uploadFailed = false;
    const uploadedAttachments =
      attachments.length > 0
        ? (
            await uploader(
              attachments ?? [],
              UploadType.Attachment,
              UploadReference.Dashboard,
              {
                onProgress: (progress) => {
                  setUploadProgress({ ...progress });
                },
                onFailure: (_file, error) => {
                  uploadFailed = true;

                  // Don't show error if request was canceled by user
                  if ((error as { code?: string })?.code === 'ERR_CANCELED') {
                    return;
                  }

                  setUploadError(error);
                },
                signal: attachmentController.signal,
              },
            )
          ).filter(<T,>(d: T | undefined): d is T => !!d)
        : [];

    if (uploadFailed) {
      return;
    }

    await createSupportRequest({
      input: {
        ...input,
        organizationId: organization.id,
        ...(referencePerson && {
          referencePersonId: referencePerson.id,
        }),

        ...(uploadedAttachments.length > 0 && {
          attachments: uploadedAttachments.map((attachment) => ({
            id: attachment.id,
            name: attachment.file.name,
            size: attachment.file.size,
            type: attachment.file.type,
          })),
        }),

        ...(!refuseTechnicalDetails && {
          details: {
            userAgent: navigator.userAgent,
            browserWindowSize: {
              width: window.innerWidth,
              height: window.innerHeight,
            },
            screenSize: {
              width: window.screen.width,
              height: window.screen.height,
            },
          },
        }),
      },
    });

    addToast({
      text: t('supportRequest.createdSuccessfully'),
      state: 'success',
    });

    onDismiss();
  };

  return (
    <Modal open={isOpen} onDismiss={handleDismiss} disableBackdropClick>
      <Modal.Header>{t('supportRequest.createRequest')}</Modal.Header>

      <form onSubmit={handleSubmit(onSubmit)}>
        {!!uploadError && (
          <>
            <PInlineNotification
              heading={t('supportRequest.uploadError')}
              description={`${
                uploadError instanceof ClientError
                  ? uploadError.response.errors?.[0].message ??
                    uploadError.message
                  : `${uploadError}`
              }`}
              state="error"
              dismissButton={false}
            />

            <Spacer mb="$medium" />
          </>
        )}

        <Controller
          control={control}
          name="application"
          rules={{ required: true }}
          render={({
            field: { ref, value, onChange, ...field },
            fieldState,
          }) => (
            <ApplicationSelect
              isRequired
              label={t('supportRequest.application')}
              aria-label={t('supportRequest.application')}
              message={
                fieldState.isTouched ? fieldState.error?.message : undefined
              }
              onChange={(option) => {
                onChange(option?.value ?? undefined);
              }}
              value={{ value }}
              {...field}
            />
          )}
        />

        <Spacer mb="$medium" />

        <Controller
          control={control}
          name="organization"
          rules={{ required: true }}
          render={({ field: { ref, onChange, ...field }, fieldState }) => (
            <OrganizationSelect
              label={t('common:organization')}
              aria-label={t('common:organization')}
              isRequired
              message={
                fieldState.isTouched ? fieldState.error?.message : undefined
              }
              onChange={onChange}
              {...field}
            />
          )}
        />

        <Spacer mb="$medium" />

        <Controller
          control={control}
          name="referencePerson"
          render={({ field: { ref, onChange, ...field }, fieldState }) => (
            <PersonSelect
              label={t('supportRequest.referencePerson')}
              aria-label={t('supportRequest.referencePerson')}
              message={
                fieldState.isTouched ? fieldState.error?.message : undefined
              }
              onChange={onChange}
              {...field}
            />
          )}
        />

        <Spacer mb="$medium" />

        <PTextFieldWrapper label={t('supportRequest.title')}>
          <input
            type="text"
            required
            {...register('title', { required: true })}
          />
        </PTextFieldWrapper>

        <Spacer mb="$medium" />

        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => (
            <Editor
              required
              label={t('supportRequest.description')}
              message={
                fieldState.isTouched ? fieldState.error?.message : undefined
              }
              initialValue={field.value && JSON.parse(field.value)}
              onChange={(value) => field.onChange(JSON.stringify(value))}
              i18n={{
                gdprTooltip: t('common:editor.confidentialData'),
                captionPlaceholder: t('common:editor.captionPlaceholder'),
                imageUploadHeading: t('common:editor.insertImage'),
                dropzoneDescription: t('common:editor.description'),
                dragDescription: t('common:editor.dragDescription'),
                uploadButtonText: t('common:editor.uploadImage'),
                resetButtonText: t('common:action.reset'),
              }}
            />
          )}
        />

        <Spacer mb="$medium" />

        <Controller
          control={control}
          name="attachments"
          render={({ field: { value, onChange } }) => (
            <FileUploader
              label={t('supportRequest.attachments')}
              value={{
                attachments: [],
                attachmentsToUpload: value ?? [],
              }}
              onChange={(value) => {
                onChange(value.attachmentsToUpload);
              }}
              progress={uploadProgress}
            />
          )}
        />

        <Spacer mb="$medium" />

        <PCheckboxWrapper label={t('supportRequest.refuseTechnicalDetails')}>
          <input type="checkbox" {...register('refuseTechnicalDetails')} />
        </PCheckboxWrapper>

        <ActionGroup>
          <PButton
            variant="secondary"
            type="button"
            role="button"
            disabled={isSubmitting}
            onClick={handleDismiss}
          >
            {t('common:action.cancel')}
          </PButton>

          <PButton
            type="submit"
            role="button"
            variant="primary"
            loading={isSubmitting}
          >
            {t('common:action.save')}
          </PButton>
        </ActionGroup>
      </form>
    </Modal>
  );
};

const LinkedCell = ({
  id,
  unread,
  value,
}: {
  id?: string;
  unread?: boolean;
  value: React.ReactNode;
}) => (
  <PLinkPure icon="none" stretch>
    <Link to={`/support/${id}`}>
      {unread ? <strong>{value}</strong> : value}
    </Link>
  </PLinkPure>
);
