import { PorscheDesignSystemProvider } from '@porsche-design-system/components-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ClientError } from 'graphql-request';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import {
  AbilityProvider,
  PersonProvider,
  SupportRequestFilterProvider,
} from './context';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (error instanceof ClientError) {
          if (error.response.status === 401) {
            // retry auth errors
            return failureCount < 3;
          }
          if (error.response.status >= 400 && error.response.status < 500) {
            // Do not retry client-side errors (wrong variables, etc.)
            return false;
          }
        }
        return failureCount < 3;
      },
    },
    mutations: {
      retry: (failureCount, error) => {
        if (error instanceof ClientError) {
          if (error.response.status === 401) {
            // retry auth errors
            return failureCount < 3;
          }
        }
        return false;
      },
    },
  },
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <PorscheDesignSystemProvider>
      <PersonProvider>
        <SupportRequestFilterProvider>
          <AbilityProvider>
            <QueryClientProvider client={queryClient}>
              <App />
              <ReactQueryDevtools />
            </QueryClientProvider>
          </AbilityProvider>
        </SupportRequestFilterProvider>
      </PersonProvider>
    </PorscheDesignSystemProvider>
  </React.StrictMode>,
);
