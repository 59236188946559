import {
  PButton,
  PButtonGroup,
  PCheckboxWrapper,
  PInlineNotification,
  PTextFieldWrapper,
} from '@porsche-design-system/components-react';
import { ActionGroup, Editor, Spacer, styled } from '@porsche-kado/ui';
import ObjectID from 'bson-objectid';
import dayjs from 'dayjs';
import { ClientError } from 'graphql-request';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ApplicationSelect,
  OrganizationSelect,
  PersonSelect,
  RoleSelect,
} from '.';
import { App } from '../config/app';
import { imageTypes } from '../config/fileTypes';
import { COMMON_NAMESPACE, NAMESPACES } from '../config/i18n';
import { Role, rolesFor } from '../config/role';
import { UploadReference, UploadType } from '../graphql';
import { getType, uploader } from '../lib/uploader';
import { DateInput } from './DateInput';

export const FeatureArticleForm = ({
  error,
  defaultValues,
  onSubmit,
  onCancel,
}: {
  error: unknown;
  defaultValues?: {
    id?: string;
    title: string;
    article: string;
    apps?: string[] | null;
    roles?: string[] | null;
    organizations?: { id: number; name: string }[] | null;
    isPublished?: boolean | null;
    publishedAt?: string | null;
    author?: { id: number; name: string } | null;
  } | null;
  onSubmit: (
    article: {
      id?: string;
      title: string;
      article: string;
      apps?: string[];
      roles?: string[];
      organizations?: number[];
      isPublished?: boolean;
      publishedAt?: string;
      author?: number;
    },
    navigate: 'exit' | 'preview',
  ) => Promise<void>;
  onCancel: () => void;
}) => {
  const { t } = useTranslation(NAMESPACES);

  const [imageController, setImageController] = useState(
    () => new AbortController(),
  );

  const formMethods = useForm({
    values: {
      title: defaultValues?.title ?? '',
      article: defaultValues?.article ?? '',
      apps: defaultValues?.apps ?? [],
      roles: defaultValues?.roles ?? [],
      organizations: defaultValues?.organizations ?? [],
      isPublished: defaultValues?.isPublished ?? false,
      publishedAt: defaultValues?.publishedAt,
      author: defaultValues?.author ?? null,
    },
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  return (
    <>
      {error && (
        <>
          <PInlineNotification
            heading={t('featureArticle.error')}
            state="error"
            dismissButton={false}
          >
            {error instanceof ClientError
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                t((error.response.errors?.[0].message as any) ?? error.message)
              : `${error}`}
          </PInlineNotification>

          <Spacer mb="$medium" />
        </>
      )}

      <form
        onSubmit={formMethods.handleSubmit(
          async ({ organizations, author, publishedAt, ...values }, evt) => {
            const navigate =
              ((evt?.nativeEvent as SubmitEvent).submitter as HTMLButtonElement)
                ?.value ?? 'exit';

            try {
              await onSubmit(
                {
                  author: author?.id,
                  publishedAt:
                    publishedAt ??
                    (values.isPublished
                      ? dayjs().format('YYYY-MM-DD')
                      : undefined),
                  organizations: organizations.map((org) => org.id),
                  ...values,
                },
                navigate as 'exit' | 'preview',
              );
            } catch (err) {
              formMethods.setError('root', {
                message: err instanceof Error ? err.message : `${err}`,
              });
            }
          },
        )}
      >
        <Controller
          control={formMethods.control}
          name="title"
          render={({ field, fieldState }) => (
            <PTextFieldWrapper
              label={t('featureArticle.title')}
              message={
                fieldState.isTouched ? fieldState.error?.message : undefined
              }
              state={
                fieldState.isTouched && fieldState.error ? 'error' : undefined
              }
            >
              <input required type="text" {...field} />
            </PTextFieldWrapper>
          )}
        />

        <Spacer mb="$medium" />

        <Controller
          control={formMethods.control}
          name="article"
          render={({ field, fieldState }) => (
            <Editor
              required
              label={t('featureArticle.article')}
              message={
                fieldState.isTouched ? fieldState.error?.message : undefined
              }
              initialValue={field.value && JSON.parse(field.value)}
              onChange={(value) => field.onChange(JSON.stringify(value))}
              i18n={{
                gdprTooltip: t('common:editor.confidentialData'),
                captionPlaceholder: t('common:editor.captionPlaceholder'),
                imageUploadHeading: t('common:editor.insertImage'),
                dropzoneDescription: t('common:editor.description'),
                dragDescription: t('common:editor.dragDescription'),
                uploadButtonText: t('common:editor.uploadImage'),
                resetButtonText: t('common:action.reset'),
              }}
              onCancelImageUpload={() => {
                imageController.abort();
                setImageController(() => new AbortController());
              }}
              onUploadImage={async (dataOrFile, { onProgress }) => {
                const file =
                  dataOrFile instanceof File
                    ? dataOrFile
                    : await (async () => {
                        const filename = new ObjectID().toHexString();

                        const type = getType(dataOrFile);

                        const response = await fetch(dataOrFile);
                        const blob = await response.blob();

                        return new File([blob], filename, { type });
                      })();

                const id = new ObjectID().toHexString();

                let uploadFailed = false;
                await uploader(
                  [
                    {
                      id,
                      file,
                    },
                  ],
                  UploadType.Image,
                  UploadReference.Dashboard,
                  {
                    onProgress: (progress) => {
                      onProgress?.(progress[file.name]);
                    },
                    onFailure: () => {
                      uploadFailed = true;
                    },
                    signal: imageController.signal,
                  },
                );

                if (uploadFailed) {
                  throw new Error('Upload failed');
                }

                setImageController(() => new AbortController());

                return `/images/${id}`;
              }}
              allowedImageTypes={imageTypes}
            />
          )}
        />

        <Spacer mb="$medium" />

        <Controller
          control={formMethods.control}
          name="author"
          render={({ field: { ref, onChange, ...field }, fieldState }) => (
            <PersonSelect
              filter={{ deletedAt: { _exists: false } }}
              label={t('author')}
              aria-label={t('author')}
              message={
                fieldState.isTouched ? fieldState.error?.message : undefined
              }
              onChange={(evt) => onChange(evt)}
              {...field}
            />
          )}
        />

        <Spacer mb="$medium" />

        <Controller
          control={formMethods.control}
          name="organizations"
          render={({ field: { ref, onChange, ...field }, fieldState }) => (
            <OrganizationSelect
              label={t('organizations')}
              aria-label={t('organizations')}
              message={
                fieldState.isTouched ? fieldState.error?.message : undefined
              }
              isMulti
              onChange={(evt) =>
                onChange(evt.map((v) => ({ id: v.id, name: v.name })))
              }
              placeholder={t('emptyMeansEverything')}
              {...field}
            />
          )}
        />

        <Spacer mb="$medium" />

        <Controller
          control={formMethods.control}
          name="apps"
          render={({
            field: { ref, value, onChange, ...field },
            fieldState,
          }) => (
            <ApplicationSelect
              label={t('apps')}
              aria-label={t('apps')}
              message={
                fieldState.isTouched ? fieldState.error?.message : undefined
              }
              isMulti
              value={value.map((value) => ({ value }))}
              onChange={(evt) => {
                const apps = evt.map(({ value }) => value);
                onChange(apps);

                const roles = apps.flatMap((app) => rolesFor(app as App));
                const selectedRoles = formMethods
                  .getValues('roles')
                  .filter((role) => roles.includes(role as Role));
                formMethods.setValue('roles', selectedRoles);
              }}
              placeholder={t('emptyMeansEverything')}
              {...field}
            />
          )}
        />

        <Spacer mb="$medium" />

        <Controller
          control={formMethods.control}
          name="roles"
          render={({
            field: { ref, value, onChange, ...field },
            fieldState,
          }) => {
            const roles = formMethods
              .watch('apps')
              .flatMap((app) => rolesFor(app as App));

            return (
              <RoleSelect
                label={t('roles')}
                isDisabled={formMethods.watch('apps').length === 0}
                filterOption={({ value }) => roles.includes(value as Role)}
                aria-label={t('roles')}
                message={
                  fieldState.isTouched ? fieldState.error?.message : undefined
                }
                isMulti
                value={value.map((value) => ({ value }))}
                onChange={(evt) => onChange(evt.map((v) => v.value))}
                placeholder={t('emptyMeansEverything')}
                {...field}
              />
            );
          }}
        />

        <Spacer mb="$medium" />

        <Controller
          control={formMethods.control}
          name="isPublished"
          render={({ field: { value, ...field }, fieldState }) => (
            <PublishContainer>
              <div>
                <Spacer mb="$large" pb="$small" />
                <PCheckboxWrapper
                  label={t('published')}
                  message={
                    fieldState.isTouched ? fieldState.error?.message : undefined
                  }
                  state={
                    fieldState.isTouched && fieldState.error
                      ? 'error'
                      : undefined
                  }
                >
                  <input type="checkbox" checked={!!value} {...field} />
                </PCheckboxWrapper>
              </div>

              <Controller
                control={formMethods.control}
                name="publishedAt"
                render={({ field: { ref, ...field } }) => (
                  <DateInput
                    disabled={!value}
                    {...field}
                    inputRef={ref}
                    label={t('publishDate')}
                  />
                )}
              />
            </PublishContainer>
          )}
        />

        <ActionGroup>
          <PButton
            variant="secondary"
            type="button"
            role="button"
            disabled={formMethods.formState.isSubmitting}
            onClick={onCancel}
          >
            {t('action.cancel', { ns: COMMON_NAMESPACE })}
          </PButton>

          <PButtonGroup>
            <PButton
              type="submit"
              role="button"
              loading={formMethods.formState.isSubmitting}
              name="navigate"
              value="preview"
            >
              {t('saveAndPreview')}
            </PButton>
            <PButton
              type="submit"
              role="button"
              variant="primary"
              loading={formMethods.formState.isSubmitting}
              name="navigate"
              value="exit"
            >
              {t('save')}
            </PButton>
          </PButtonGroup>
        </ActionGroup>
      </form>
    </>
  );
};

const PublishContainer = styled('div', {
  display: 'grid',
  gap: '$medium',
  gridTemplateColumns: 'auto 1fr',
});
