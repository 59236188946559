import { useMutation, useQueryClient } from '@tanstack/react-query';
import { migrationStatus } from '../config/localStorageKeys';
import {
  SupportRequestsActivityQuery,
  SupportRequestsQuery,
  useSupportRequestsActivityQuery,
  useSupportRequestsQuery,
  useUpdateSupportRequestMutation,
} from '../graphql';

export const useMigrateSupportRequestsSeenStatus = () => {
  const queryClient = useQueryClient();

  // migrate browser-based read-status of support requests to backend (PAIM-3966)
  const {
    mutate: migrate,
    isLoading: isMigrating,
    isSuccess: hasMigrated,
    isError: hasFailedToMigrate,
  } = useMutation(async () => {
    const supportRequestsLastSeen = new Map<string, string>();

    for (const key in localStorage) {
      const value = localStorage.getItem(key);

      // check support request seen
      const match = /^kado\.dashboard\.seenSupportRequests\.(\w+)$/.exec(key);
      if (match && value) {
        const supportRequestId = match[1];
        supportRequestsLastSeen.set(
          supportRequestId,
          new Date(value).toISOString(),
        );
      }

      // comments-read-status can be ignored because date of support request is already the latest
    }

    for (const [id, lastSeenAt] of supportRequestsLastSeen) {
      await useUpdateSupportRequestMutation.fetcher({
        input: { id, lastSeenAt },
      })();

      for (const key in localStorage) {
        if (
          /^kado\.dashboard\.seenSupportRequests\.\w+$/.test(key) ||
          /^kado\.dashboard\.seenSupportRequestComments\.\w+:\w+$/.test(key)
        ) {
          localStorage.removeItem(key);
        }
      }

      queryClient.setQueryData<SupportRequestsQuery>(
        useSupportRequestsQuery.getKey(),
        (cache) =>
          cache
            ? {
                ...cache,
                supportRequests: cache.supportRequests.map((sr) =>
                  sr.id === id ? { ...sr, hasNewActivity: false } : sr,
                ),
              }
            : undefined,
      );
      queryClient.setQueryData<SupportRequestsActivityQuery>(
        useSupportRequestsActivityQuery.getKey(),
        (cache) =>
          cache
            ? {
                ...cache,
                supportRequests: cache.supportRequests.map((sr) =>
                  sr.id === id ? { ...sr, hasNewActivity: false } : sr,
                ),
              }
            : undefined,
      );
    }

    localStorage.setItem(
      migrationStatus('supportRequestsSeenStatus'),
      'success',
    );
  });

  if (localStorage.getItem(migrationStatus('supportRequestsSeenStatus'))) {
    return {
      migrate: () => {},
      isMigrating: false,
      hasMigrated: true,
      hasFailedToMigrate: false,
    };
  }

  return { migrate, isMigrating, hasMigrated, hasFailedToMigrate };
};
