import { PButton, PButtonPure } from '@porsche-design-system/components-react';
import { useState } from 'react';
import starFilledIconSrc from '../../icons/star-filled.svg';
import { styled } from '../stitches.config';
import { ActionGroup } from './ActionGroup';
import { Modal } from './Modal';
import { Spacer } from './Spacer';
import { SpinnerOverlay } from './Spinner';

const List = styled('ul', {
  margin: 0,
  padding: 0,
  listStyleType: 'none',
});

const ListItem = styled('li', {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  gap: '$small',
  cursor: 'pointer',
  margin: 0,
  padding: '$small',
  borderRadius: '$small',

  '&:hover': {
    backgroundColor: '$contrastLow',
  },
});

export interface CreateInput<T> {
  name: string;
  isFavorite?: boolean;
  filter: T;
}

export type PresetListProps<T> = {
  presets: {
    id: string;
    name: string;
    isFavorite?: boolean | null;
    filter: T;
  }[];
  selectedFilter: T;
  onFavor: (id: string, isFavorite: boolean) => void;
  onSelect: (preset: T) => void;
  onRemove: (id: string) => void;
  i18n: {
    delete: {
      confirmation: string;
      cancel: string;
      confirm: string;
    };
  };
  showSpinner?: boolean;
  children: React.ReactNode;
};

export const PresetList = <T,>(props: PresetListProps<T>) => {
  const { showSpinner, i18n, children, onFavor, onRemove, onSelect, presets } =
    props;
  const [deleteModal, setDeleteModal] = useState<{
    id?: string;
    isOpen: boolean;
  }>({
    isOpen: false,
  });

  return (
    <>
      {showSpinner && <SpinnerOverlay />}
      <Modal
        open={deleteModal.isOpen}
        size="small"
        onDismiss={() => setDeleteModal({ isOpen: false })}
        aria={{ 'aria-label': i18n.delete.confirmation }}
      >
        <Modal.Header>{i18n.delete.confirmation}</Modal.Header>

        <ActionGroup spacing="none">
          <PButton
            type="button"
            variant="secondary"
            onClick={(evt) => {
              evt.stopPropagation();
              setDeleteModal({
                isOpen: false,
              });
            }}
          >
            {i18n.delete.cancel}
          </PButton>
          <PButton
            type="button"
            variant="primary"
            onClick={(evt) => {
              evt.stopPropagation();
              if (deleteModal.id) {
                onRemove(deleteModal.id);
              }
              setDeleteModal({
                isOpen: false,
              });
            }}
          >
            {i18n.delete.confirm}
          </PButton>
        </ActionGroup>
      </Modal>
      <List>
        {presets
          .sort((preset) => (preset.isFavorite ? -1 : 1))
          .map((preset) => (
            <ListItem
              data-testid="filter-preset-list"
              key={preset.name}
              onClick={() => onSelect(preset.filter)}
            >
              <PButtonPure
                hideLabel
                onClick={(evt) => {
                  evt.stopPropagation();
                  onFavor(preset.id, !preset.isFavorite);
                }}
                {...(preset.isFavorite && {
                  iconSource: starFilledIconSrc,
                })}
                {...(!preset.isFavorite && { icon: 'star' })}
              />
              <div>{preset.name}</div>
              <PButtonPure
                hideLabel
                icon="delete"
                onClick={(evt) => {
                  evt.stopPropagation();
                  setDeleteModal({
                    id: preset.id,
                    isOpen: true,
                  });
                }}
              />
            </ListItem>
          ))}

        <Spacer mb="$medium" />

        {children}
      </List>
    </>
  );
};
