import { PText } from '@porsche-design-system/components-react';
import {
  AttachmentList,
  FileSize,
  FileType,
  RichText,
  Spacer,
} from '@porsche-kado/ui';
import dayjs from 'dayjs';
import saveAs from 'file-saver';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../config/i18n';
import { usePersonContext } from '../../context';
import {
  Attachment,
  DownloadReference,
  DownloadType,
  useCreateDownloadUrlMutation,
} from '../../graphql';

export const Comment = ({
  comment,
}: {
  comment: {
    id: string;
    author: { id: number; name: string };
    createdAt: string;
    text: string;
    attachments?: Attachment[] | null;
  };
}) => {
  const { t } = useTranslation(NAMESPACES);

  const dateFormat =
    usePersonContext().state.settings?.dateFormat ?? 'YYYY/MM/DD';
  const hourFormat = usePersonContext().state.settings?.is24HourFormat
    ? 'HH:mm'
    : 'HH:mm a';

  const { mutateAsync: createDownloadUrl } = useCreateDownloadUrlMutation();

  return (
    <div>
      <PText>
        {dayjs(comment.createdAt).format(`${dateFormat} ${hourFormat}`)} —{' '}
        {comment.author.name}
      </PText>

      <Spacer mb="$small" />

      <RichText value={JSON.parse(comment.text)} />

      <Spacer mt="$medium" />

      <AttachmentList>
        {comment.attachments?.map((attachment) => (
          <AttachmentList.Item
            key={attachment.id}
            onClick={() =>
              createDownloadUrl({
                id: attachment.id,
                type: DownloadType.Attachment,
                reference: DownloadReference.Dashboard,
              }).then(async ({ createDownloadUrl }) => {
                const response = await fetch(createDownloadUrl);
                const blob = await response.blob();
                saveAs(blob, attachment.name);
              })
            }
            canPreview={attachment.type.startsWith('image/')}
            previewHeading={attachment.name}
            onPreview={() =>
              createDownloadUrl({
                id: attachment.id,
                type: DownloadType.Attachment,
                reference: DownloadReference.Dashboard,
              }).then(async ({ createDownloadUrl }) => createDownloadUrl)
            }
            i18n={{
              cancel: t('common:action.cancel'),
              download: t('common:download'),
            }}
          >
            <AttachmentList.Item.Meta
              label={t('common:upload.uploadDateShort')}
              value={
                dayjs(attachment.createdAt).isValid()
                  ? t('common:upload.uploadDateDate', {
                      date: new Date(attachment.createdAt),
                    })
                  : null
              }
            />
            <AttachmentList.Item.Meta
              label={t('common:upload.fileSize')}
              value={<FileSize value={attachment.size} />}
            />
            <AttachmentList.Item.Meta
              label={t('common:upload.fileType')}
              value={<FileType value={attachment.type} hideIcon />}
            />
          </AttachmentList.Item>
        ))}
      </AttachmentList>
    </div>
  );
};
